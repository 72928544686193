<script setup lang="ts"></script>

<template>
  <svg viewBox="0 0 50 48" class="flag-flag-icon">
    <g stroke-width="1" fill="none">
      <polygon
        class="flag"
        points="0 28 0 46.9408 2.1042 46.9408 2.1042 38.0338 14.0704 38.0338 10.9666 32.9710 13.8855 28"
      />
      <circle class="circle" cx="22" cy="20" r="8" />
      <polygon
        class="star"
        points="39.5 14.25 33.9160 17.1856 34.9824 10.9678 30.4649 6.5643 36.7080 5.6571 39.5 0 42.2919 5.6571 48.5350 6.5643 44.0175 10.9678 45.0839 17.1856"
      />
    </g>
  </svg>
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.flag-flag-icon {
  .flag {
    stroke: colors-old.$error-color;
  }

  .circle {
    stroke: colors-old.$primary-color;
  }

  .star {
    stroke: colors-old.$success-color;
  }
}
</style>
