import { ComputedRef, Ref, onUnmounted, ref, watch } from "vue";
import { TippyOptions, useTippy } from "vue-tippy";

export type TooltipProp = TippyOptions | TippyOptions["content"] | undefined;

type Props = Readonly<{
  el: Ref<HTMLElement | undefined>;
  tooltip: ComputedRef<TooltipProp>;
}>;

export function useTooltip({ el, tooltip }: Props) {
  const tippyRef = ref<ReturnType<typeof useTippy>>();

  onUnmounted(() => {
    tippyRef.value?.destroy();
  });

  watch(
    tooltip,
    () => {
      if (tooltip.value !== undefined) {
        if (tippyRef.value) {
          tippyRef.value.setProps(getTippyOpts(tooltip.value));
        } else {
          tippyRef.value = useTippy(el, getTippyOpts(tooltip.value));
        }
      }
    },
    { immediate: true, deep: true },
  );

  return tippyRef.value;
}

const getTippyOpts = (tooltip: TooltipProp) => {
  const opts: TippyOptions = { delay: [500, 0], animation: false };
  if (typeof tooltip === "object" && "content" in tooltip) {
    Object.assign(opts, tooltip);
  } else {
    Object.assign(opts, { content: tooltip });
  }
  return opts;
};
