<script setup lang="ts">
import { computed } from "vue";

import { getRiskScenarioColor } from "../../utils/color";

interface Props {
  value: number;
  max?: number;
}

const props = withDefaults(defineProps<Props>(), {
  max: 100,
});

const percentage = computed(() => (props.value / props.max) * 100);

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
}

const arcPath = computed(() => {
  const startAngle = -90; // Start at the top
  const endAngle = startAngle + (percentage.value / 100) * 180;
  return describeArc(150, 150, 120, startAngle, endAngle);
});

const arcColor = computed(() => getRiskScenarioColor(percentage.value));
</script>

<template>
  <div class="gauge-chart">
    <svg viewBox="0 0 300 140" xmlns="http://www.w3.org/2000/svg">
      <!-- Static Background Arc -->
      <path
        d="M 30 150 A 120 120 0 0 1 270 150"
        fill="none"
        stroke="#F4F4F5"
        stroke-width="60"
      />
      <!-- Dynamic Foreground Arc -->
      <path :d="arcPath" :stroke="arcColor" fill="none" stroke-width="60" />
    </svg>
  </div>
</template>

<style scoped>
.gauge-chart {
  width: 100%;
  overflow: hidden;
}

svg {
  width: 100%;
  height: auto;
  display: block;
}
</style>
