<script setup lang="ts">
import { type ComputedRef, computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListFooter from "@/components-ng/BaseList/components/BaseListFooter/BaseListFooter.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import BaseInput from "@/components/ui/BaseInput/BaseInput.vue";
import { i18n } from "@/i18n";
import type { Art, Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useTeamStore } from "@/store/team";

const { t } = useI18n();

type Props = {
  selected?: Team;
  title?: string;
  showCurrentTeam?: boolean;
  showClear?: boolean;
  showSearch?: boolean;
  showOtherArts?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  title: i18n.global.t("action.team"),
  selected: undefined,
  showCurrentTeam: false,
  showClear: false,
  showSearch: false,
  showOtherArts: false,
});

type Emits = { select: [Team | null] };
const emit = defineEmits<Emits>();

const showingArts = ref<boolean>(false);
const selectedArt = ref<Art | null>(null);
const searchVal = ref<string>("");

// Teams in the current ART (potentially excluding the current team)
const teamsInCurrentArt: ComputedRef<Team[]> = computed(() => {
  const currentTeam = useTeamStore().currentTeam;
  return useTeamStore()
    .teamsInArt(selectedArt.value?.id || "")
    .filter((team) => props.showCurrentTeam || team !== currentTeam);
});

// Teams to show (current list, filtered by the current search if applicable)
const teamsList = computed(() => filterBySearch(teamsInCurrentArt.value));

// All ARTs
const arts: ComputedRef<Art[]> = computed(() => useArtStore().arts || []);

// ARTs to show (filtered by current search if applicable)
const artsList = computed(() => filterBySearch(arts.value));

// Show the 'Other ARTs' button when there is >1 ART
const showArtNav = computed(() => props.showOtherArts && arts.value.length > 1);

// Search input placeholder depends on the context (Teams/ARTs)
const searchPlaceholder = computed(() => {
  if (showArtNav.value && showingArts.value) {
    return t("action.searchArts");
  }
  if (showArtNav.value && !showingArts.value) {
    return t("action.searchTeams");
  }
  return t("action.search");
});

// By default, show teams for the current ART
onMounted(() => (selectedArt.value = useArtStore().currentArt));

/**
 * Emit the selected team
 */
const selectTeam = (team: Team | null) => emit("select", team);

/**
 * Show teams for the selected ART
 */
const selectArt = (art: Art) => {
  selectedArt.value = art;
  showingArts.value = false;
  searchVal.value = "";
};

/**
 * Show all ARTs the user can choose from
 */
const showArts = () => {
  showingArts.value = true;
  searchVal.value = "";
};

/**
 * Filter the given list by the search input (partial match on 'name' property)
 * @param list List of Teams or ARTs
 */
const filterBySearch = (list: (Team | Art)[]) => {
  const input = searchVal.value?.toLowerCase();

  return input
    ? list.filter((item) => item.name.toLowerCase().includes(input))
    : list;
};
</script>

<template>
  <BaseList
    class="team-selector"
    aria-labelledby="team-selector-title"
    role="dialog"
  >
    <BaseListTitle id="team-selector-title">{{ title }}</BaseListTitle>
    <!-- Search Input -->
    <div class="search-container">
      <BaseInput
        v-model="searchVal"
        v-autofocus
        control-type="text"
        icon-after="search"
        size="small"
        :icon-after-title="$t('label.teamSelector.searchTitle')"
        :placeholder="searchPlaceholder"
        @focusout.stop
      />
    </div>

    <!-- List of teams -->
    <template v-if="!showingArts">
      <!-- Button to show list of ARTs -->
      <BaseListItems>
        <BaseListItem
          v-if="showArtNav"
          class="list-item"
          @click.stop="showArts()"
        >
          <template #before>
            <SvgIcon
              width="20"
              height="20"
              name="arrow/left"
              class="arrow-icon"
            />
          </template>
          {{ $t("programBoard.otherArts") }}
        </BaseListItem>

        <!-- Teams -->
        <BaseListItem
          v-for="team in teamsList"
          :key="team.id"
          class="list-item"
          :class="{ active: team.id === selected?.id }"
          @click.stop="selectTeam(team)"
        >
          <template #before>
            <SvgIcon width="20" height="20" name="team" class="team-icon" />
          </template>
          {{ team.name }}
        </BaseListItem>
      </BaseListItems>

      <!-- No Results text -->
      <BaseListItem v-if="!teamsList.length" class="no-results" static>
        {{ $t("searchResults.noResults") }}
      </BaseListItem>
    </template>

    <!-- List of ARTs -->
    <template v-else>
      <BaseListItems>
        <BaseListItem
          v-for="art in artsList"
          :key="art.id"
          class="list-item"
          @click.stop="selectArt(art)"
        >
          <template #before>
            <SvgIcon width="20" height="20" name="art" class="art-icon" />
          </template>
          {{ art.name }}
          <template #after>
            <SvgIcon
              width="20"
              height="20"
              name="arrow/right"
              class="arrow-icon"
            />
          </template>
        </BaseListItem>

        <!-- No Results text -->
        <BaseListItem v-if="!artsList.length" class="no-results" static>
          {{ $t("searchResults.noResults") }}
        </BaseListItem>
      </BaseListItems>
    </template>

    <BaseListFooter
      v-if="showClear"
      class="list-footer"
      @click="selectTeam(null)"
    />
  </BaseList>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.team-selector {
  .search-container {
    padding: 8px;
  }
}

.list-item:not(:active) {
  .arrow-icon,
  .team-icon,
  .art-icon {
    color: colors-old.$text-secondary-color;
  }
}
</style>
