import { round } from "lodash-es";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { riskyRickExplain } from "@/backend/Backend";
import { Subscription } from "@/backend/BackendSession";
import { Board } from "@/model/board";
import { Card } from "@/model/card";
import { isWorkitem } from "@/model/stickyType";
import { AuthUser } from "@/model/user";
import { useBoardStore } from "@/store/board";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";

import { initRiskyRickChat } from "../services/ai.service";
import { Pattern, PatternSavedConfig, RiskItem, RiskScenario } from "../types";
import { loadPatterns } from "../utils/patterns";
import {
  isBoardScopePattern,
  isIterationScopePattern,
  isStickyScopePattern,
  isTeamScopePattern,
} from "../utils/types";

export type Message = {
  user: AuthUser;
  message: string;
  status: "to-do" | "in-progress" | "done";
  isUserMessage: boolean;
  context?: any; // it needs to be JSON serializable
};

type RiskyRickResponse = {
  type: "stream" | "end";
  answer: string;
};

const aiUserMock = {
  imageUrl: "avatars/risky-rick.png",
  name: "Risky Rick",
} as AuthUser;

export const useRiskAnalysisStore = defineStore("riskAnalysis", () => {
  const isSidebarOpen = ref(false);
  const isConfigOpen = ref(false);
  const hoveredRiskItemId = ref<string | null>(null);
  const selectedRiskItemId = ref<string | null>(null);
  const selectedRiskScenarioId = ref<number | null>(null);
  const patterns = ref(loadPatterns());
  const messages = ref<Message[]>([]);
  const currentSub = ref<Subscription | Promise<Subscription> | null>(null);

  const riskItems = computed<RiskItem[]>(() => {
    const board = useBoardStore().boardByType("program");
    const stickies = Object.values(board.cards);
    const workItemStickies = stickies.filter((card) => isWorkitem(card.data));

    const result = workItemStickies.map((sticky) => {
      let totalScore = 0;
      let totalWeight = 0;

      const riskScenarios: RiskScenario[] = [];

      patterns.value.forEach((pattern) => {
        const scenarios = processPattern(pattern, sticky.data, board);
        scenarios.forEach((scenario) => {
          // Skip scenarios with weight 0
          if (!scenario.weight) return;

          totalScore += scenario.score * scenario.weight;
          totalWeight += scenario.weight;

          riskScenarios.push(scenario);
        });
      });

      riskScenarios.sort((a, b) => b.score - a.score);

      return {
        sticky,
        totalScore: round(totalScore / (totalWeight || 1)),
        riskScenarios,
      };
    });

    result.sort((a, b) => b.totalScore - a.totalScore);

    return result;
  });

  const selectedRiskItem = computed<RiskItem | null>(() => {
    return (
      riskItems.value.find(
        (item) => item.sticky.data.id === selectedRiskItemId.value,
      ) || null
    );
  });

  const selectedRiskScenario = computed<RiskScenario | null>(() => {
    return (
      selectedRiskItem.value?.riskScenarios.find(
        (scenario) => scenario.patternId === selectedRiskScenarioId.value,
      ) || null
    );
  });

  const totalRiskScore = computed<number>(() => {
    return (
      riskItems.value?.reduce((acc, item) => acc + item.totalScore, 0) /
      riskItems.value.length
    );
  });

  const toggle = () => {
    isSidebarOpen.value = !isSidebarOpen.value;
  };

  const updatePattern = (id: number, data: { weight?: number }) => {
    const pattern = patterns.value.find((pattern) => pattern.id === id);

    if (pattern) {
      pattern.weight = data.weight ?? pattern.weight;

      updatePatternLocalConfig(id, {
        weight: pattern.weight,
      });
    }
  };

  const setSelectedRiskItemId = (id: string | null) => {
    selectedRiskItemId.value = id;
  };

  const setSelectedRiskScenarioId = (id: number | null) => {
    selectedRiskScenarioId.value = id;
  };

  const addUserMessage = async (
    message: Message,
    context: {
      predefinedPrompt: number;
      scenarios: RiskScenario[];
    },
    args: {
      sessionId: string;
      boardId: string;
      stickyId: string;
    },
  ) => {
    messages.value.push(message);
    messages.value.push({
      isUserMessage: false,
      message: "I'm thinking...",
      status: "in-progress",
      user: aiUserMock,
    });

    currentSub.value = await riskyRickExplain(
      args.stickyId,
      async (_args, { kwargs }: { kwargs: RiskyRickResponse }) => {
        const { answer, type } = kwargs;
        const currentMessage = messages.value[messages.value.length - 1];

        if (answer === "...") {
          return;
        }

        if (answer === "") {
          currentMessage.message = "";
          return;
        }

        if (type === "stream") {
          currentMessage.message += answer;
          return;
        }

        if (type === "end") {
          currentMessage.status = "done";
          currentMessage.message = answer;
          (await currentSub.value)?.unsubscribe(); // Unsubscribe when type is "end"
        }
      },
    );

    try {
      await initRiskyRickChat(
        args.sessionId,
        args.boardId,
        args.stickyId,
        context,
      );
    } catch (err) {
      const lastMessage = messages.value[messages.value.length - 1];
      lastMessage.message =
        "I'm sorry, I looks like I'm having some issues. Please try again later.";
      currentSub.value?.unsubscribe(); // Unsubscribe when type is "end"
    }
  };

  const resetMessages = () => {
    messages.value = [];
  };

  const endConversation = async () => {
    const resolved = await currentSub.value;
    resolved?.unsubscribe();
  };

  return {
    // state
    isSidebarOpen,
    isConfigOpen,
    hoveredRiskItemId,
    selectedRiskItemId,
    selectedRiskScenarioId,
    patterns,
    messages,

    // getters
    riskItems,
    selectedRiskItem,
    selectedRiskScenario,
    totalRiskScore,

    // actions
    toggle,
    updatePattern,
    setSelectedRiskItemId,
    setSelectedRiskScenarioId,
    sendUserMessage: addUserMessage,
    resetMessages,
    endConversation,
  };
});

function processPattern(pattern: Pattern, sticky: Card, board: Board) {
  const iterations = useSessionStore().iterations;
  const teams = useTeamStore().teamsInCurrentArt;

  const team = teams.find((team) => team.id === sticky.teamId);
  const iteration = iterations.find((iter) => iter.id === sticky.iterationId);

  if (!team || !iteration) {
    // eslint-disable-next-line no-console
    console.error("Team or iteration not found", {
      team,
      iteration,
    });
    return [];
  }

  if (isTeamScopePattern(pattern)) {
    return pattern.process(team);
  }

  if (isIterationScopePattern(pattern)) {
    return pattern.process(iteration);
  }

  if (isBoardScopePattern(pattern)) {
    return pattern.process(board);
  }

  if (isStickyScopePattern(pattern)) {
    return pattern.process(sticky);
  }

  // eslint-disable-next-line no-console
  console.error("Unknown pattern scope");
  return [];
}

function updatePatternLocalConfig(
  patternId: number,
  newConfig: { weight?: number },
) {
  const config = JSON.parse(
    localStorage.getItem("pattern-config") || "[]",
  ) as PatternSavedConfig[];

  const savedConfig = config.find((c) => c.id === patternId) || {
    id: patternId,
    weight: 1,
  };

  savedConfig.weight = newConfig.weight ?? savedConfig.weight;

  const updatedConfig = config.filter((c) => c.id !== patternId);
  updatedConfig.push(savedConfig);

  localStorage.setItem("pattern-config", JSON.stringify(updatedConfig));
}
