import { clamp, floor } from "lodash-es";

import { RiskScenarioRecommendation, StickyNotePattern } from "@/coconut/types";
import { Card } from "@/model/card";
import { useSessionStore } from "@/store/session";

// 8 Later planned work has a higher risk [STICKY PATTERN]
// https://www.figma.com/design/QEGprRz74Z5m41MVuwRUI6/Plan-Analyzer?node-id=1584-2242&t=e3RucNVNkTlEBQp8-4

/**

 1.	Determine Total Iterations N:
  N = Total number of iterations 
 2.	Identify the Middle Iteration M:
  •	For both even and odd N, use:
    M = N / 2 
  •	This formula calculates the middle iteration, rounding up to the nearest integer.
 3.	Get the Feature Iteration n:
  n = \text{Iteration in which the feature is planned} 
 4.	Calculate Risk R:
  •	If the feature is planned in or before the middle iteration:
 R = 0 
  •	If the feature is planned after the middle iteration:
 R = (n - M) / (N - M) 
  •	This formula ensures the risk score increases linearly from 0 to 1 as the planning moves from the middle to the last iteration.

 Example Calculation:

 For a feature planned in the 5th iteration when there are 5 total iterations:

 •	Total iterations: N = 5 
 •	Middle iteration: M = ceil(5/2)
 •	Feature iteration: n = 5 
 •	Risk calculation:
  R = (5 - 3)/(5 - 3) = 2 / 2  = 1 

 */

const pattern: StickyNotePattern = {
  id: 8,
  title: "Later planned work",
  weight: 1,
  type: "sticky-note",
  process(card: Card) {
    // Get the total number of iterations
    const N = useSessionStore().iterations.length;

    // Calculate the midpoint of the total number of iterations, rounding up if necessary
    const M = Math.ceil(N / 2);

    // Get the card's iteration ID, default to 0 if it's not set, and increment by 1
    const n = (card.iterationId || 0) + 1;

    // Calculate a normalized value for the card's iteration ID
    // If the card's iteration ID is greater than the midpoint,
    // subtract the midpoint from the card's iteration ID and
    // divide by the difference between the total iterations and the midpoint
    // If the card's iteration ID is not greater than the midpoint, assign 0
    const val = n > M ? (n - M) / (N - M) : 0;

    // Ensure the normalized value is within the range [0, 1]
    const value = Math.min(1, Math.max(0, val));

    return [
      {
        patternId: pattern.id,
        scope: { id: card.id, type: this.type },
        title: this.title,
        weight: this.weight,
        score: floor(clamp(value * 100, 0, 100)),
        recommendations: this.recommendations,
      },
    ];
  },
};

export const recommendations: RiskScenarioRecommendation[] = [];

export default pattern;
