<script setup lang="ts">
import { groupBy } from "lodash-es";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import ScreenReaderMessage from "@/components-ng/a11y/ScreenReaderMessage.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { StickyInfo } from "@/model/card";
import { useBoardStore } from "@/store/board";
import { useSearchMenuStore } from "@/store/searchMenu";
import { NO_TEAM_ID, useTeamStore } from "@/store/team";

import {
  GroupedSearchResults,
  ItemKey,
  groupByTeamName,
  groupSearchResultsByType,
} from "./SearchResult";
import SearchResultGroup from "./SearchResultGroup.vue";
import SearchResultList from "./SearchResultList.vue";
import SearchResultSubgroup from "./SearchResultSubgroup.vue";

// Unique instance of the directive so we can render multiple
// components with this directive at once
const vKeyboardNav = keyboardNavigation();

const emit = defineEmits<{
  select: [item: ItemKey, focus?: boolean];
}>();
defineProps<{ results: GroupedSearchResults; selected?: ItemKey }>();

const currentItem = ref<ItemKey | null>(null);

const currentBoardId = computed(() => useBoardStore().currentBoard().id);

const { t } = useI18n();

function setCurrent(result: StickyInfo) {
  currentItem.value = result;
}

function resetCurrent(result: StickyInfo) {
  if (currentItem.value === result) {
    currentItem.value = null;
  }
}

function showDependencyGroupingHeader(items: StickyInfo[]) {
  return (
    items.length > 0 &&
    (useBoardStore().currentBoard().type !== "program" ||
      useSearchMenuStore().selectedTeams.length > 0)
  );
}

function groupResults(items: StickyInfo[]) {
  const typeGroupItems: { [typeName: string]: StickyInfo[] } =
    resultsByType(items);

  const searchMenuStore = useSearchMenuStore();
  if (
    !searchMenuStore.isDependencySearch ||
    !searchMenuStore.selectedTeams.length
  ) {
    return typeGroupItems;
  }

  const dependencyStickyTypes = new Set(
    useBoardStore()
      .stickyTypesByFunctionality("dependency")
      .map((stickyType) => stickyType.name),
  );

  const groupResultEntities = Object.entries(typeGroupItems).reduce(
    (groupResultEntities, typeResultGroupEntity) => {
      const [typeName, typeResultGroupItems] = typeResultGroupEntity;
      if (!dependencyStickyTypes.has(typeName)) {
        return [...groupResultEntities, typeResultGroupEntity];
      }

      return [
        ...groupResultEntities,
        ...groupDependencyResults(typeResultGroupItems),
      ];
    },
    new Array<[string, StickyInfo[]]>(),
  );

  return Object.fromEntries(groupResultEntities);
}

function groupDependencyResults(resultGroupItems: StickyInfo[]) {
  const findTeamIndex = (teamName: string) =>
    useTeamStore().teamsInCurrentArt.findIndex(
      (team) => team.name === teamName,
    );

  const redundantTypeResultGroupItems: StickyInfo[] = resultGroupItems.flatMap(
    (item) => {
      const dependencyTeamFilter =
        useSearchMenuStore().selectedDependencyTeamFilter;
      const { data: card } = useBoardStore().currentBoard().cards[item.id];
      const duppedItems = [];

      if (
        (dependencyTeamFilter === "Incoming" ||
          dependencyTeamFilter === "IncomingAndOutgoing") &&
        useSearchMenuStore().isTeamSelected(card.precondTeam?.id)
      ) {
        duppedItems.push({
          ...item,
          teamId: card.precondTeam?.id || NO_TEAM_ID,
        });
      }
      if (
        (dependencyTeamFilter === "Outgoing" ||
          dependencyTeamFilter === "IncomingAndOutgoing") &&
        useSearchMenuStore().isTeamSelected(card.dependTeam?.id)
      ) {
        duppedItems.push({
          ...item,
          teamId: card.dependTeam?.id || NO_TEAM_ID,
        });
      }
      if (
        dependencyTeamFilter === "Mutual" &&
        useSearchMenuStore().isTeamSelected(card.precondTeam?.id) &&
        useSearchMenuStore().isTeamSelected(card.dependTeam?.id)
      ) {
        duppedItems.push({
          ...item,
          teamId: card.dependTeam?.id || NO_TEAM_ID,
        });
        duppedItems.push({
          ...item,
          teamId: card.precondTeam?.id || NO_TEAM_ID,
        });
      }

      return duppedItems;
    },
  );

  return Object.entries(groupByTeamName(redundantTypeResultGroupItems)).sort(
    ([a], [b]) => findTeamIndex(a) - findTeamIndex(b),
  );
}

function resultsByType(items: StickyInfo[]) {
  return groupSearchResultsByType(items);
}

function groupSearchResultsByDependencyTeamFilter(items: StickyInfo[]) {
  return groupBy(items, (item) => {
    const card = useBoardStore().currentBoard().cards[item.id];

    if (!card || item.teamId === undefined) {
      return "";
    }

    if (item.teamId === (card.data.precondTeam?.id || NO_TEAM_ID)) {
      return t("dependencyTeamFilter.incoming");
    } else if (item.teamId === (card.data.dependTeam?.id || NO_TEAM_ID)) {
      return t("dependencyTeamFilter.outgoing");
    }
    return "";
  });
}
</script>

<template>
  <div
    v-keyboard-nav.soft-focus.ignore-trigger="{
      selector: '.result-card',
    }"
  >
    <!-- Results count (screen reader only) -->
    <ScreenReaderMessage>
      {{ $t("label.resultsCount.currentBoard", { count: results.count }) }}
    </ScreenReaderMessage>
    <div class="current-board-title">
      <div class="header">{{ results.boardName }}</div>
      <div class="items-count">
        {{ results.count }}
      </div>
    </div>
    <div v-for="(chunk, type) in groupResults(results.items)" :key="type">
      <SearchResultGroup
        :color="chunk[0].color"
        :heading="type"
        :right="chunk.length"
        class="search-result-group"
      >
        <SearchResultSubgroup
          v-for="(
            dependencyTeamFilterChunk, dependencyTeamFilter
          ) in groupSearchResultsByDependencyTeamFilter(chunk)"
          :key="dependencyTeamFilter"
          :show-header="showDependencyGroupingHeader(dependencyTeamFilterChunk)"
          :left="dependencyTeamFilter"
          :right="dependencyTeamFilterChunk.length"
        >
          <SearchResultList
            :results="dependencyTeamFilterChunk"
            :selected="selected"
            :current="currentItem"
            :board-id="currentBoardId"
            @select="(item, focus) => emit('select', item, focus)"
            @pointerenter="setCurrent"
            @pointerleave="resetCurrent"
          />
        </SearchResultSubgroup>
      </SearchResultGroup>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins" as *;
@use "@/styles/side-panel" as *;

.current-board-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  margin-top: 16px;
  margin-bottom: 16px;

  .header {
    font-weight: font.$weight-bold;
    font-size: font.$size-normal;
  }

  .items-count {
    font-weight: font.$weight-normal;
    color: colors-old.$text-secondary-color;
  }
}

.search-result-group {
  margin-top: 16px;
}
</style>
