import { sender } from "@/backend/Sender";
import { receiveLinkMove } from "@/components/card/animator";
import { BoardType, Id } from "@/model/baseTypes";
import { BoardCard, Card } from "@/model/card";
import { CardLink, Link, StickyLink } from "@/model/link";
import { Functionality } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { useCardStore } from "@/store/card";
import { useDraggingStore } from "@/store/dragging";
import { linkId, useLinkStore } from "@/store/link";
import { useToastStore } from "@/store/toast";
import {
  LinkCreateTrigger,
  LinkRemoveTrigger,
  stickyNoteLinkCreated,
  stickyNoteLinkRemoved,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import { action, defineActions } from "./actions";
import { boardActions } from "./boardActions";

export const linkActions = defineActions("link", {
  add: action(
    async (
      cardLink: { id: Link["from"]; toId: Link["to"] },
      trigger?: LinkCreateTrigger,
    ) => {
      // cardLink.id and cardLink.toId are always card ids, not groupIds
      const fromCard = useCardStore().cards[cardLink.id];
      const toCard = useCardStore().cards[cardLink.toId];

      const fromType = fromCard.type.functionality;
      const toType = toCard.type.functionality;
      const boardType = useBoardStore().currentBoard().type;
      trackLinkCreated(fromType, toType, boardType, trigger);

      const link = { from: linkId(fromCard), to: linkId(toCard) };
      const { id } = await sender.addLink(link.from, link.to);
      useLinkStore().add({ ...link, id, state: "default" });
    },
  ),
  remove: action(
    async (
      linkInfo: { id: Card["id"]; toId: Card["id"] } | { linkId: Link["id"] },
      trigger?: LinkRemoveTrigger,
    ) => {
      const link = useLinkStore().findLink(linkInfo);
      if (!link) return;

      const boardType = useBoardStore().currentBoard().type;
      const fromType = useCardStore().cards[link.from].type.functionality;
      const toType = useCardStore().cards[link.to].type.functionality;

      trackEvent(stickyNoteLinkRemoved(fromType, toType, boardType, trigger));

      await sender.deleteLink(link.id);
      useLinkStore().remove(link.id);
      linkActions.updateCardLinkedMarks("internal");
    },
  ),
  addObjective: action(
    async (
      cardId: string,
      boardId: string,
      objectiveId: string,
      trigger?: LinkCreateTrigger,
    ) => {
      const boardType = useBoardStore().currentBoard().type;
      const fromCard = useCardStore().cards[cardId];
      const fromType = fromCard.type.functionality;
      trackLinkCreated(fromType, "objective", boardType, trigger);

      await sender.addObjectiveLink(cardId, boardId, objectiveId);
      useLinkStore().addObjectiveLink(cardId, { boardId, objectiveId });
    },
  ),
  removeObjective: action(
    async (
      cardId: string,
      boardId: string,
      objectiveId: string,
      trigger?: LinkRemoveTrigger,
    ) => {
      const boardType = useBoardStore().currentBoard().type;
      const fromType = useCardStore().cards[cardId].type.functionality;

      trackLinkRemoved(fromType, "objective", boardType, trigger);

      await sender.deleteObjectiveLink(cardId, boardId, objectiveId);
      useLinkStore().removeObjectiveLink(cardId, { boardId, objectiveId });

      // Notify screen reader users that link was removed
      useToastStore().show(/*$t*/ "linkableCardRow.linkRemoved", {
        invisible: true,
      });
    },
  ),
  setCardLinkLabel: action(async (card: Card, cardLink: CardLink) => {
    if (cardLink.kind === "sticky") {
      await recreateStickyLink(cardLink);
    } else {
      await recreateObjectiveLink(card, cardLink.boardId, cardLink.objectiveId);
    }

    async function recreateStickyLink(stickyLink: StickyLink) {
      const link = useLinkStore().linksById[stickyLink.id];
      await linkActions.remove("internal", { linkId: stickyLink.id });
      if (!link) return;

      await linkActions.add("internal", { id: link.from, toId: link.to });
    }

    async function recreateObjectiveLink(
      card: Card,
      boardId: string,
      objectiveId: string,
    ) {
      await linkActions.removeObjective(
        "internal",
        card.id,
        boardId,
        objectiveId,
      );
      await linkActions.addObjective("internal", card.id, boardId, objectiveId);
    }
  }),

  addByDrag: action(
    async (dragInfo: Id & { el: HTMLElement; dragId: number }) => {
      const currentBoard = useBoardStore().currentBoard();
      const boardCard = currentBoard.cards[dragInfo.id];
      const { from: linkFrom, to: linkTo } = useLinkStore().linking;

      useLinkStore().linking.from = null;
      useLinkStore().linking.to = null;
      if (linkFrom && linkTo) {
        if (linkTo.type === "sticky") {
          await linkActions.add(
            "internal",
            { id: linkFrom.id, toId: linkTo.id },
            "drag",
          );
          useBoardStore().currentBoard().cards[linkTo.id].meta.mark = "normal";
          linkActions.updateCardLinkedMarks("internal");
        } else {
          await linkActions.addObjective(
            "dragDrop",
            linkFrom.id,
            currentBoard.id,
            linkTo.id,
            "drag",
          );
        }
        useDraggingStore().endLinkDragging(
          dragInfo.id,
          dragInfo.dragId,
          boardCard,
          dragInfo.el,
        );
      } else {
        receiveLinkMove(
          boardCard,
          useDraggingStore().dragging[dragInfo.dragId].pos,
          (coord) => useDraggingStore().dragLink(dragInfo.dragId, coord),
          () =>
            useDraggingStore().endLinkDragging(
              dragInfo.id,
              dragInfo.dragId,
              boardCard,
              dragInfo.el,
            ),
        );
      }
    },
  ),
  markCardLinkedCards: action((card: BoardCard) => {
    useLinkStore().markingCardLinkedCards = card;
    const distances = useLinkStore().getLinkedCardDistances(card);
    Object.values(useBoardStore().currentBoard().cards).forEach((boardCard) => {
      highlightLinkedCard(boardCard, distances[boardCard.data.id]);
    });
    highlightCard(card);
  }),
  updateCardLinkedMarks: action(() => {
    const markBase = useLinkStore().markingCardLinkedCards;
    if (markBase) {
      linkActions.markCardLinkedCards("internal", markBase);
    }
  }),
});

function highlightLinkedCard(card: BoardCard, distance?: number) {
  if (distance === undefined || distance > 2) {
    fadeOutCard(card);
  } else if (distance > 0) {
    card.meta.mark = distance === 1 ? "normal" : "semi-fade-out";
    card.meta.isRelatedToHighlighted = true;
    boardActions.cardToFront("internal", card.data.id);
  }
}

export function fadeOutCard(card: BoardCard) {
  card.meta.mark = "fade-out";
  card.meta.isHighlighted = false;
  card.meta.isRelatedToHighlighted = false;
}

function highlightCard(card: BoardCard) {
  card.meta.mark = "normal";
  card.meta.isHighlighted = true;
  boardActions.cardToFront("internal", card.data.id);
}

type LinkSource = Functionality;
type LinkTarget = Functionality | "objective";

function trackLinkCreated(
  from: LinkSource,
  to: LinkTarget,
  boardType: BoardType,
  trigger?: LinkCreateTrigger,
) {
  trackEvent(stickyNoteLinkCreated(from, to, boardType, trigger));
}

function trackLinkRemoved(
  from: LinkSource,
  to: LinkTarget,
  boardType: BoardType,
  trigger?: LinkRemoveTrigger,
) {
  trackEvent(stickyNoteLinkRemoved(from, to, boardType, trigger));
}
