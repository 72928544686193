<template>
  <svg viewBox="0 0 100 280">
    <defs>
      <pattern
        id="diagonalHatch"
        patternUnits="userSpaceOnUse"
        width="4"
        height="8"
        patternTransform="rotate(-45 2 2)"
      >
        <path d="M -1,2 l 6,0" class="darkest-stroke" stroke-width=".5" />
      </pattern>
    </defs>
    <path d="M 0 278 H 100 V 280 H 0 Z" class="grey-stroke grey-fill" />
    <path
      :d="`M 0,12 C 0,5.373 5.37258,0 12,0 H 88.000004 C 94.627404,0 100,5.373 100,12 V ${barHeight} H 0 Z`"
      data-testid="capacity-bar"
      :transform="`translate(0, ${barMaxHeight - barHeight} )`"
      class="grey-stroke grey-fill"
      :style="{
        'stroke-width': stroke ? 0 : 2,
        fill: pattern ? 'url(#diagonalHatch)' : fill,
      }"
    />
  </svg>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Options as Component, Prop } from "vue-property-decorator";

@Component({})
export default class LoadCapacityChartBar extends Vue {
  @Prop({ required: true, type: Number }) value!: number;
  @Prop({ required: false, type: String }) fill?: string;
  @Prop({ required: false, type: Boolean }) pattern?: boolean;
  @Prop({ required: false, type: Boolean }) stroke?: boolean;

  barMaxHeight = 280;

  get barHeight() {
    return this.barMaxHeight * this.value;
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.load-capacity-chart {
  svg {
    flex: 1 1 10px;
  }

  .darkest-stroke {
    stroke: colors-old.$menu-color;
  }

  .grey-stroke {
    stroke: colors-old.$dark-divider-color;
  }

  .grey-fill {
    fill: colors-old.$dark-divider-color;
  }
}
</style>
