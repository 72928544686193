import { Action } from "@/action/actions";
import { modKey, shiftKey } from "@/lowlevel";

export interface SimpleKeyEvent {
  code: string;
  key: string;
  shiftKey: boolean;
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
}

export type KeyAccept = (e: SimpleKeyEvent) => boolean;

export type Key = string | KeyAccept;

export type Modifier = "shift" | "altCtrl";

function hasModifiers(event: SimpleKeyEvent, modifiers: Modifier[]) {
  // shift is ok when it's not a letter as some chars are entered with shift, e.g. / on DE keyboard
  let shiftOk = !event.code.startsWith("Key") || !event.shiftKey;
  let altCtrlOk = !event.altKey && !event.ctrlKey && !event.metaKey;
  for (const m of modifiers) {
    switch (m) {
      case "shift":
        shiftOk = event.shiftKey;
        break;
      case "altCtrl":
        altCtrlOk = event.altKey || event.ctrlKey || event.metaKey;
        break;
    }
  }
  return shiftOk && altCtrlOk;
}

export function key(k: Key, ...modifiers: Modifier[]): KeyAccept {
  return (e) => accept(k)(e) && hasModifiers(e, modifiers);
}

export interface NamedKey {
  name: () => string;
  key: Key;
  modifiers: Modifier[];
}

export const noKey = {
  name: () => "",
  key: () => false,
  modifiers: [],
};

export function namedKey(
  k: string,
  options: {
    hideKey?: boolean;
    modifiers?: Modifier[];
  } = {},
): NamedKey {
  const { hideKey = false, modifiers = [] } = options;

  return {
    name: hideKey ? () => "" : keyName(k, modifiers),
    key: key(k, ...modifiers),
    modifiers,
  };
}

export function namedNoKey(name: string | (() => string)): NamedKey {
  return {
    name: typeof name === "string" ? () => name : name,
    key: () => false,
    modifiers: [],
  };
}

const keyNames: Record<string, string> = {
  Backspace: "⌫",
  ArrowUp: "↑",
  ArrowDown: "↓",
  ArrowLeft: "←",
  ArrowRight: "→",
};

function keyName(k: string, modifiers: Modifier[]) {
  return () => {
    const modifier =
      (modifiers.includes("altCtrl") ? modKey() : "") +
      (modifiers.includes("shift") ? shiftKey() : "");
    const key =
      keyNames[k] ||
      (k.startsWith("Key")
        ? k.substring(3)
        : k.startsWith("Digit")
          ? k.substring(5)
          : k);
    return modifier + key;
  };
}

export function keys(...ks: Key[]): KeyAccept {
  return (e) => ks.some((k) => accept(k)(e));
}

export interface ShortcutOptions<T> {
  prevent?: boolean;
  repeat?: boolean;
  up?: (this: T) => void;
}

export function registerShortcut<T>(
  component: T,
  k: Key,
  exec: (e: KeyboardEvent) => void,
  options?: ShortcutOptions<T>,
): void {
  shortcuts.push({
    component,
    accept: accept(k),
    exec,
    prevent: options?.prevent,
    repeat: options?.repeat,
    up: options?.up?.bind(component),
  });
}

export function registerActionShortcut<T>(
  component: T,
  action: Action,
  options?: ShortcutOptions<T>,
) {
  if (action.data.shortcut) {
    registerShortcut(
      component,
      action.data.shortcut.key,
      () => action("keyboard"),
      options,
    );
  }
}

export function accept(k: Key): KeyAccept {
  return typeof k === "string"
    ? k.startsWith("Key") || k.startsWith("Digit")
      ? (e) => e.code === k
      : (e) => e.key === k
    : k;
}

export function unregisterShortcuts(component: any) {
  shortcuts = shortcuts.filter((shortcut) => shortcut.component !== component);
}

interface Shortcut {
  component: any;
  accept: KeyAccept;
  prevent?: boolean;
  repeat?: boolean;
  exec: (e: KeyboardEvent) => void;
  up?: () => void;
}

let shortcuts = new Array<Shortcut>();
const down: { [code: string]: string } = {};

document.addEventListener("keydown", keyDown);
document.addEventListener("keyup", keyUp);
window.addEventListener("blur", allKeysUp);

export function isKeyDown(code: string) {
  return code in down;
}

function keyDown(e: KeyboardEvent) {
  const elem = e.target as HTMLInputElement;
  const nodeName = elem.nodeName;
  const isInput =
    nodeName === "INPUT" || nodeName === "TEXTAREA" || nodeName === "SELECT";
  const isActive = !elem.readOnly && !elem.disabled;
  const isAllowedInput =
    elem.classList.contains("allow-shortcuts") &&
    (e.altKey || e.ctrlKey || e.metaKey || e.key.length > 1) &&
    e.key !== "Backspace";

  const wasDown = e.code in down;
  down[e.code] = e.key;

  if (!isInput || !isActive || isAllowedInput) {
    if (e.key === " ") {
      // prevent scrolling
      e.preventDefault();
    }

    for (const cut of shortcuts) {
      if (cut.accept(e)) {
        if (cut.prevent !== false) {
          e.preventDefault();
        }
        if (cut.repeat || !wasDown) {
          cut.exec(e);
        }
      }
    }
  }
}

function allKeysUp() {
  for (const code in down) {
    keyUp({
      code,
      key: down[code],
      shiftKey: false,
      altKey: false,
      ctrlKey: false,
      metaKey: false,
    });
  }
}

function keyUp(e: SimpleKeyEvent) {
  delete down[e.code];
  for (const cut of shortcuts) {
    if (cut.up && cut.accept(e)) {
      cut.up();
    }
  }

  if (e.key === "Meta") {
    // on Mac, releasing any key together with command (=Meta), causes only a keyup event for Meta, but not the key
    // so assume that when releasing Meta every other key is also released
    allKeysUp();
  }
  if (e.key === "Escape") {
    // Adding this as a shortcut to reset the state of down[], because sometimes
    // the keyup event is not received (eg. on mac, after making a screenshot with cmd+shift+4)
    // which causes buggy behaviour since the app thinks some keys are still down
    allKeysUp();
  }
}
