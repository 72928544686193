import { Ref, computed, onMounted, watch } from "vue";

import { linkActions } from "@/action/linkActions";
import { boardKey } from "@/components/card/injectKeys";
import { useDelayedAction } from "@/composables/utils/useDelayedAction";
import { Card } from "@/model/card";
import { useDraggingStore } from "@/store/dragging";
import { useLinkStore } from "@/store/link";
import { stickyNotePinned, stickyNoteUnpined } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/context";

type Props = {
  el?: Ref<HTMLDivElement | undefined>;
  card: Card;
  disabled?: Ref<Readonly<boolean>>;
};

export function usePin({ el, card, disabled }: Props) {
  const board = injectStrict(boardKey);

  const isPinned = computed(() => useLinkStore().isMarkingLinkedCards(card.id));

  onMounted(() => {
    if (disabled?.value) return;

    el?.value?.addEventListener("pointerdown", handlePointerDown);
    el?.value?.addEventListener("pointerup", handlePointerUp);
  });

  watch(
    () => useDraggingStore().dragging[card.id],
    () => {
      cancelPin();
      cancelUnpin();
    },
  );

  const handlePointerDown = (event: PointerEvent) => {
    const target = event.target as HTMLElement;
    const isLinkDrag = target.classList.contains("link-drag");

    if (disabled?.value || isLinkDrag) return;

    if (isPinned.value) {
      delayedUnpin(event);
    } else {
      delayedPin(event);
    }
  };

  const handlePointerUp = () => {
    cancelPin();
    cancelUnpin();
  };

  // used to analytics
  const trackStateChange = (state: "pinned" | "unpinned") => {
    const stickyType = card.type.functionality;
    const boardType = board.value.type;
    if (state === "pinned") {
      trackEvent(
        stickyNotePinned(stickyType, boardType, "long-press-on-sticky-note"),
      );
    } else {
      trackEvent(
        stickyNoteUnpined(stickyType, boardType, "long-press-on-sticky-note"),
      );
    }
  };

  // prevent clicks on links when pinning/unpinning
  const linkClickPrevent = (target: HTMLElement) => {
    if (target instanceof HTMLAnchorElement) {
      target.addEventListener(
        "click",
        (e) => {
          e.preventDefault();
        },
        { once: true },
      );
    }
  };

  const pinStickyNote = (event: Event) => {
    const target = event.target as HTMLElement;
    linkClickPrevent(target);

    if (isPinned.value || useDraggingStore().isLinkDragging) return;
    linkActions.markCardLinkedCards("mouse", board.value.cards[card.id]);
    trackStateChange("pinned");
  };

  const unpinStickyNote = (event: Event) => {
    const target = event.target as HTMLElement;
    linkClickPrevent(target);

    if (!isPinned.value || useDraggingStore().isLinkDragging) return;
    useLinkStore().removeAllMarks();
    trackStateChange("unpinned");
  };

  const [delayedPin, cancelPin] = useDelayedAction(pinStickyNote, 500);
  const [delayedUnpin, cancelUnpin] = useDelayedAction(unpinStickyNote, 500);

  return { isPinned };
}
