<script setup lang="ts">
import { computed, markRaw } from "vue";
import { useI18n } from "vue-i18n";

import { useArtStore } from "@/store/art";

import MetricsModal, { Tab } from "./MetricsModal.vue";
import OverallMetricsModalCapacityAllocationBody from "./OverallMetricsModalCapacityAllocationBody.vue";
import OverallMetricsModalLoadCapacityBody from "./OverallMetricsModalLoadCapacityBody.vue";

const { t } = useI18n();

const props = withDefaults(defineProps<{ tab?: string }>(), {
  tab: "allocation",
});
const artName = computed(() => useArtStore().currentArt.name);

const tabs: Tab[] = [
  {
    id: "allocation",
    content: t("metricsModal.capacityAllocation"),
    subtitle: t("general.namedArt", { name: artName.value || "" }),
    component: markRaw(OverallMetricsModalCapacityAllocationBody),
    isActive: props.tab === "allocation",
  },
  {
    id: "load",
    content: t("metricsModal.loadVsCapacity"),
    subtitle: t("general.namedArt", { name: artName.value || "" }),
    component: markRaw(OverallMetricsModalLoadCapacityBody),
    isActive: props.tab === "load",
  },
];
</script>

<template>
  <MetricsModal :tab="props.tab" :overrides="{ tabs }" />
</template>
