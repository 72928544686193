<script lang="ts" setup>
import ROAMSticky from "@/components/card/components/ROAMSticky/ROAMSticky.vue";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { riskTitle } from "@/model/baseTypeI18n";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);
</script>

<template>
  <AttributeChip
    name="roam-chip"
    :text="card.risk"
    :text-placeholder="$t('risk.roam')"
    :dropdown-props="{ width: 'auto' }"
    :tooltip="card.risk ? $t(riskTitle(card.risk)) : $t('risk.roam')"
    :readonly="cardMeta.isReadonly"
  >
    <ROAMSticky />
  </AttributeChip>
</template>
