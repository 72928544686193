import { clamp, floor } from "lodash-es";

import {
  RiskScenario,
  RiskScenarioRecommendation,
  StickyNotePattern,
} from "@/coconut/types";
import { isBacklogBoard } from "@/model/board";
import { Card } from "@/model/card";
import { isDependency } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { getLinkTargetId, useLinkStore } from "@/store/link";
import { useSessionStore } from "@/store/session";

// 6. Distance between Feature and Dependency [STICKY PATTERN]
// https://www.figma.com/design/QEGprRz74Z5m41MVuwRUI6/Plan-Analyzer?node-id=1584-1667&t=e3RucNVNkTlEBQp8-4

const pattern: StickyNotePattern = {
  id: 6,
  title: "Proximity of Dependency to Work Completion",
  description:
    "This risk pattern occurs when dependencies are planned too close to the linked features' completion iteration. When a dependency is in the same iteration as the feature it supports, the risk is highest because there is no buffer time to address delays or issues. The further apart in time the dependency and the feature are scheduled, the lower the risk, as this allows for adjustments and error handling. Operating too close to capacity without flexibility risks significant delays and inefficiencies.",
  weight: 1,
  type: "sticky-note",
  process(card: Card): RiskScenario[] {
    const scenarios: RiskScenario[] = [];

    if (!isBacklogBoard(card.type.origin)) return scenarios;

    card.links.forEach((link) => {
      const linkedCard = useLinkStore().cardsByLink(
        getLinkTargetId(card, link),
      )[0];

      if (
        !linkedCard ||
        !isDependency(linkedCard) ||
        card.iterationId === null ||
        linkedCard.iterationId === null
      ) {
        return;
      }

      const dist = card.iterationId - linkedCard.iterationId;
      if (dist <= 0) {
        scenarios.push({
          patternId: pattern.id,
          scope: { id: linkedCard.id, type: this.type },
          title: this.title,
          weight: this.weight,
          score: 0,
          recommendations: this.recommendations,
        });
      }

      const totalIterations = useSessionStore().iterations.length;
      const value = 1 - dist / (totalIterations - 1);
      scenarios.push({
        patternId: pattern.id,
        scope: { id: linkedCard.id, type: this.type },
        title: this.title,
        weight: this.weight,
        score: floor(clamp(value * 100, 0, 100)),
        recommendations: this.recommendations,
      });
    });

    return scenarios;
  },
  present(sticky: Card) {
    const board = useBoardStore().boardByType("program");
    const boardSticky = board.cards[sticky.id];
    const links = sticky.links;

    if (!links || !links.length) return;

    const hasMarkedStickies = Object.values(board.cards).some(
      (boardCard) => boardCard.meta.mark === "highlight",
    );

    // reset all stickies
    if (hasMarkedStickies) {
      Object.values(board.cards).forEach((boardCard) => {
        boardCard.meta.mark = "normal";
      });
      return;
    }

    boardSticky.meta.mark = "highlight";

    links.forEach((link) => {
      const targetId = getLinkTargetId(boardSticky.data, link);
      const linkedSticky = useLinkStore().boardCardByLink(targetId, board);

      if (!linkedSticky || !isDependency(linkedSticky?.data)) return;
      linkedSticky.meta.mark = "highlight";
    });

    Object.values(board.cards).forEach((boardCard) => {
      if (boardCard.meta.mark === "highlight") return;
      boardCard.meta.mark = "filter-out";
    });
  },
};

export const recommendations: RiskScenarioRecommendation[] = [
  {
    title: "Schedule Dependencies Earlier",
    description:
      "Plan to complete dependencies well before the features they support. Adjust the board to ensure that dependencies are scheduled in earlier sprints, providing a buffer period for potential delays or issues.",
  },
  {
    title: "Preserve the IP Iteration as a Buffer",
    description:
      "Ensure the Innovation and Planning (IP) Iteration is free of planned work to serve as a capacity buffer. This iteration, placed at the end of the PI, provides essential time to address unexpected issues and ensure feature completion without overloading the system.",
  },
  {
    title: "Avoid Planning Dependencies and Features in the Same Sprint",
    description:
      "Establish a rule to avoid planning dependencies and their linked features in the same iteration. Ensure that dependencies are completed at least one sprint before the feature they support.",
  },
];

export default pattern;
