<template>
  <base-backlog-board
    ref="board"
    :backlog-board="board"
    :actions="actions"
    :aria-label="$t('board.solutionBacklog')"
    tabindex="0"
    @click="boardComponent()?.blur()"
  />
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";

import { useBoardStore } from "@/store/board";

import BaseBacklogBoard from "./BaseBacklogBoard.vue";
import { ActionType } from "./card/actions";

@Component({ components: { BaseBacklogBoard } })
export default class SolutionBacklogBoard extends Vue {
  actions: ActionType[] = [
    "delete",
    "close",
    "almSource",
    "mirror",
    "priority",
    "art",
    "showArt",
    "link",
  ];

  get board() {
    return useBoardStore().boardByType("solution_backlog");
  }

  boardComponent() {
    return this.$refs.board as BaseBacklogBoard;
  }

  // This must be callable by parent component
  focus() {
    this.boardComponent()?.focus();
  }
}
</script>
