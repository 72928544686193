import { objectId } from "@/utils/objectId";

import { BackendSession } from "./BackendSession";
import { ServerLink, ServerSession } from "./serverModel";

export function dummyBackendSession(): BackendSession {
  return {
    async addBoard() {
      return "";
    },

    aiSubscribe() {
      return {} as any;
    },

    addBoardToCategory() {
      return Promise.resolve(undefined);
    },

    addCategory() {
      return Promise.resolve("");
    },

    async addLink() {
      return {} as ServerLink;
    },

    async addObjectiveLink() {},

    async addReaction() {},

    almSource() {
      return undefined;
    },

    almSubscribe() {
      return {} as any;
    },

    boardPublish() {},

    boardSubscribe() {
      return {} as any;
    },

    async createTimerEvent() {
      return "";
    },

    async deleteBoard() {},

    async deleteCategory() {},

    async deleteEvent() {},

    deleteLink() {
      return Promise.resolve(undefined);
    },

    deleteObjectiveLink() {
      return Promise.resolve(undefined);
    },

    getAlmConnectionId() {
      return undefined;
    },

    async getAlmItemType() {
      return {} as any;
    },

    async getAlmItemTypeForSticky() {
      return {} as any;
    },

    async getAlmStatus() {
      return {} as any;
    },

    async getAlmUser() {
      return {} as any;
    },

    getAlmType() {
      return undefined;
    },

    async getArts() {
      return [];
    },

    async getBoardIterations() {
      return [];
    },

    async getBoardStickies() {
      return {} as any;
    },

    async getBoards() {
      return [];
    },

    async getCategories() {
      return [];
    },

    async getEvents() {
      return [];
    },

    async getFlexBackgrounds() {
      return [];
    },

    async getFlexTypes() {
      return [];
    },

    async getIterations() {
      return [];
    },

    async getLicense() {
      return {} as any;
    },

    async getLinkTypes() {
      return [];
    },

    async getLinks() {
      return [];
    },

    async getObjectives() {
      return {} as any;
    },

    async getScale() {
      return 0;
    },

    async getServerInfo() {
      return {} as any;
    },

    async getSessions() {
      return [] as ServerSession[];
    },

    async getSettings() {
      return {} as any;
    },

    subscriptionCount() {
      return 0;
    },

    async getStickies() {
      return [];
    },

    async getStickyTypes() {
      return [];
    },

    async getTeams() {
      return [];
    },

    async mirror() {
      return {} as any;
    },

    move() {},

    async newSticky() {
      return "";
    },

    async removeBoardFromCategory() {},

    async removeReaction() {},

    async addShape() {
      return objectId();
    },

    async editShape() {},

    async removeShape() {},

    async roomSubscribe() {
      return {} as any;
    },

    async searchStickies() {
      return [] as any;
    },

    async sessionSubscribe() {
      return {} as any;
    },

    async setSessionId() {
      return false;
    },

    async syncBacklog() {
      return {} as any;
    },

    async syncIteration() {
      return {} as any;
    },

    toRisk() {},

    async unsubscribe() {},

    async updateBoard() {},

    async updateCategory() {},

    async updateTimerEvent() {},

    async getAlmStickyTypes() {
      return [] as any;
    },

    async getBoardIdsOfGroupedStickies() {
      return {} as any;
    },

    async getStickyChanges() {
      return [] as any;
    },
  };
}
