import { InjectionKey, Ref } from "vue";

import { Board } from "@/model/board";
import { Card } from "@/model/card";
import { BoardCoordinate, RelativeCoordinate } from "@/model/coordinates";

import CardComponent from "./Card.vue";

export const cardKey: InjectionKey<Card> = Symbol("card");
export const cardMetaKey: InjectionKey<Readonly<CardMeta>> = Symbol("cardMeta");
export const cardMethodsKey: InjectionKey<CardMethods> = Symbol("cardMethods");

export const boardKey: InjectionKey<Readonly<Ref<Board>>> = Symbol("board");
export const boardMetaKey: InjectionKey<Readonly<BoardMeta>> =
  Symbol("boardMeta");

export type CardMethods = {
  removePin?: typeof CardComponent.prototype.removePin;
  animate?: typeof CardComponent.prototype.animate;
  animateCopy?: typeof CardComponent.prototype.animateCopy;
};

// provided from the StickyNote down to each child
export type CardMeta = Readonly<{
  rootEl: HTMLDivElement | undefined;
  pos: RelativeCoordinate;
  isLinkingTarget: boolean;
  isSelected: boolean;
  isEnlarged: boolean;
  isPinned: boolean;
  isPinningDisabled: boolean;
  levelOfDetails: 0 | 1 | 2;
  isReadonly: boolean;
}>;

// provided from the FluidBoard down to each child
export type BoardMeta = {
  size: BoardCoordinate;
};
