import { Vue } from "vue-class-component";

import EventBus from "@/EventBus";
import { Mode } from "@/components/card/actions";
import { Card } from "@/model/card";
import { ServerTime } from "@/model/timer";
import { SearchQuery } from "@/router/types";
import { EnlargeTrigger } from "@/utils/analytics/events";

// Card actions that can be passed through the event bus
interface CardActionZoom {
  action: "zoom";
  zoom: boolean;
  editMode?: boolean;
}

interface CardActionSetMode {
  action: "mode";
  mode: Mode;
}

interface CardActionDragPosition {
  action: "drag";
  x: number | null;
  y: number | null;
}

interface CardActionDragEnd {
  action: "dragEnd";
}

export type CardActionOptions = CardActionZoom | CardActionSetMode | CardActionDragPosition | CardActionDragEnd;

export const sendSetting = (name: string, value: any) => sendKeyEvent("setting", name, value);

export const sendBoardSwitch = () => sendEvent("boardSwitch");

export const sendSearch = (search: SearchQuery) => sendEvent("search", search);

export const sendLinkStateUpdate = () => sendEvent("linkStateUpdate");

export const sendServerTick = (serverTime: ServerTime) => sendEvent("serverTick", serverTime);

/**
 * Trigger an action on the given card
 */
export const sendCardAction = (id: string, options: CardActionOptions) => sendKeyEvent("cardAction", id, options);

export type Event =
  | "boardSwitch"
  | "setting"
  | "message"
  | "search"
  | "linkStateUpdate"
  | "serverTick"
  | "stickyNoteAction"
  | "cardAction";

function sendEvent(name: Event, data?: any): void {
  EventBus.emit(name, data);
}

function sendKeyEvent(name: Event, key: string, data?: any) {
  EventBus.emit(name, { key, value: data });
}

function onEvent(name: Event, callback: (e?: any) => void) {
  EventBus.on(name, callback);
}

function offEvent(name: Event, callback: (e?: any) => void) {
  EventBus.off(name, callback);
}

export default class EventBusUser extends Vue {
  callbacks: { [name: string]: (e: any) => void } = {};

  onSetting(name: string, callback: (e: any) => void) {
    this._onKeyEvent("setting", name, callback);
  }

  onSearch(callback: (search: SearchQuery) => void) {
    this._onEvent("search", callback);
  }

  onBoardSwitch(callback: () => void) {
    this._onEvent("boardSwitch", callback);
  }

  onLinkStateUpdate(callback: () => void) {
    this._onEvent("linkStateUpdate", callback);
  }

  onServerTick(callback: (serverTime: ServerTime) => void) {
    this._onEvent("serverTick", callback);
  }

  onCardAction(cardId: string, callback: (options: CardActionOptions) => void) {
    this._onKeyEvent("cardAction", cardId, callback);
  }

  _onEvent(name: Event, callback: (e?: any) => void) {
    this.callbacks[name] = callback;
    EventBus.on(name, callback);
  }

  _onKeyEvent(name: Event, key: string, callback: (e: any) => void) {
    this._onEvent(name, (e) => {
      if (e.key === key) {
        callback(e.value);
      }
    });
  }

  unmounted() {
    for (const name in this.callbacks) {
      EventBus.off(name, this.callbacks[name]);
    }
  }
}

// ------------------------------ New sticky note actions ------------------------------

export type StickyNoteActionBase<T extends string> = { action: T; id: Card["id"] };
export type StickyNoteEnlarge = StickyNoteActionBase<"enlarge"> & { focus?: boolean; trigger?: EnlargeTrigger };
export type StickyNoteShrink = StickyNoteActionBase<"shrink">;

export type StickyNoteAction = StickyNoteEnlarge | StickyNoteShrink;

type StickyNoteActionPayloads = {
  [K in StickyNoteAction["action"]]: Omit<Extract<StickyNoteAction, { action: K }>, "id">;
};

export const sendStickyNoteAction = <T extends StickyNoteAction["action"]>(
  id: string,
  action: StickyNoteActionPayloads[T],
) => {
  sendEvent("stickyNoteAction", { id, ...action });
};

export const onStickyNoteAction = <T extends StickyNoteAction["action"]>(
  id: Card["id"] | null,
  action: T,
  callback: (action: Extract<StickyNoteAction, { action: T }>) => void,
) => {
  onEvent("stickyNoteAction", (e: StickyNoteAction) => {
    if (id === null && e.action === action) {
      callback(e as Extract<StickyNoteAction, { action: T }>);
      return;
    }

    if (e.id === id && e.action === action) {
      callback(e as Extract<StickyNoteAction, { action: T }>);
    }
  });
};

export const offStickyNoteAction = <T extends StickyNoteAction["action"]>(
  callback: (action: Extract<StickyNoteAction, { action: T }>) => void, // Modify the callback function type here
) => {
  offEvent("stickyNoteAction", callback);
};
