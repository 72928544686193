import { faro } from "@grafana/faro-web-sdk";
import { JSONCodec, NatsConnection, NatsError, headers, nuid } from "nats.ws";

import { useConnectionStore } from "@/store/connection";
import { useStatisticsStore } from "@/store/statistics";

import { BackendError } from "./BackendError";
import { CancelError } from "./CancelError";
import { NatsRPCPayload } from "./NatsRPCPayload";
import { RpcBackend } from "./RpcBackend";
import { TimeoutError } from "./TimeoutError";
import { createCallContext, createCallHeaders } from "./requestHelper";
import { handleResponse } from "./responseHelper";
import { ServerAlmType } from "./serverModel";

export class NatsRpcBackend implements RpcBackend {
  private readonly codec = JSONCodec();
  private sessionId!: string;
  private almType?: ServerAlmType;

  constructor(
    private connection: NatsConnection,
    private company: string,
    private userId: string,
  ) {}

  init(sessionId: string, almType?: ServerAlmType) {
    this.sessionId = sessionId;
    this.almType = almType;
  }

  async doCall(
    path: string,
    args: any[] = [],
    kwargs?: object,
    timeout?: number,
    retry = 0,
  ): Promise<any> {
    const payload = new NatsRPCPayload({ args, kwargs });
    const context = createCallContext(path, args, kwargs, timeout, retry);
    const reconnects = useConnectionStore().reconnects;
    const callHeaders = headers();
    for (const [key, value] of Object.entries(createCallHeaders(context))) {
      callHeaders.append(key, value);
    }
    const [, methodName] = path.split(`${this.userId}.`);
    try {
      const startTime = Date.now();
      const rawResponse = await this.connection.request(
        path,
        payload.encode(),
        {
          timeout: context.requestTimeout,
          reply: `_INBOX.${this.userId}.${
            methodName || "<unknown_method>"
          }.${nuid.next()}`,
          noMux: true,
          headers: callHeaders,
        },
      );
      const elapsedTime = Date.now() - startTime;
      faro.api?.pushMeasurement({
        type: "nats_rpc_" + methodName,
        values: { elapsedTimeMs: elapsedTime },
      });
      useStatisticsStore().current.sends.addOk();
      const response = this.codec.decode(rawResponse.data);
      return handleResponse(response, context, methodName);
    } catch (err) {
      useStatisticsStore().current.sends.addFail(err);
      if (err instanceof NatsError) {
        if (err.code === "TIMEOUT") {
          if (!useConnectionStore().isConnected) {
            throw new CancelError("No Network connection");
          }
          if (useConnectionStore().reconnects !== reconnects && retry < 3) {
            return this.doCall(path, args, kwargs, timeout, retry + 1);
          }
          throw new TimeoutError({
            request: context,
            response: {
              connections: useConnectionStore().wsStates.get().map(formatState),
            },
          });
        }
        throw new BackendError(
          err.name,
          `Nats Error while calling RPC method ${methodName}`,
          {
            request: context,
            response: {
              connections: useConnectionStore().wsStates.get().map(formatState),
              message: err.message,
              code: err.code,
              cause: err.chainedError,
            },
          },
        );
      }
      throw err;
    }

    function formatState(entry: { timestamp: number; state: string }) {
      return `${new Date(entry.timestamp).toISOString()}: ${entry.state}`;
    }
  }

  async roomCall<T>(path: string, args: any[] = []) {
    const response = await this.doCall(
      `piplanning.session.6.${this.company}.${this.userId}.${path}`,
      args,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  async adminCall<T>(path: string, args: any[] = []) {
    const response = await this.doCall(
      `piplanning.admin.6.${this.company}.${this.userId}.${path}`,
      args,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  async almCall<T>(path: string, args: any[] = []) {
    const response = await this.doCall(
      `piplanning.almsession.6.${this.company}.${this.userId}.${path}`,
      args,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  async almToolCall<T>(path: string, args: any[], timeout?: number) {
    const almType = this.almType || "none";
    const response = await this.doCall(
      `piplanning.almsession.6.${this.company}.${this.userId}.${almType}.${path}`,
      args,
      undefined,
      timeout,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  async sessionCall<T>(
    path: string,
    args: any[] = [],
    kwargs?: Record<any, any>,
  ) {
    const response = await this.doCall(
      `piplanning.session.6.${this.company}.${this.userId}.${path}`,
      [this.sessionId, ...args],
      kwargs,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  async serverCall<T>(path: string) {
    const response = await this.doCall(
      `piplanning.server.6.${this.company}.${this.userId}.${path}`,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  async staticSessionCall<T>(
    path: string,
    args: any[] = [],
    kwargs?: Record<any, any>,
  ) {
    const response = await this.doCall(
      `piplanning.session.6.${this.company}.${this.userId}.${path}`,
      args,
      kwargs,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  async boardCall<T>(path: string, args: any[], kwargs?: Record<string, any>) {
    const response = await this.doCall(
      `piplanning.board.6.${this.company}.${this.userId}.${path}`,
      args,
      kwargs,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  async flexBoardCall<T>(
    path: string,
    args: any[] = [],
    kwargs?: Record<any, any>,
  ) {
    const response = await this.doCall(
      `piplanning.flexboard.6.${this.company}.${this.userId}.${path}`,
      args,
      kwargs,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  async userCall<T>(path: string) {
    const response = await this.doCall(
      `piplanning.authuser.2.${this.company}.${this.userId}.${path}`,
    );
    // FIXME: Simply casting the response is not a good idea because we are
    // basically removing all advantages of Typescript on the calling
    // functions.
    //
    // To conform to the signature of BackendSession we are still casting here,
    // but this should be changed to a parsing strategy where the format is
    // validated before being converted.
    return response as T;
  }
}
