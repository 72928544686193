<script setup lang="ts">
import { computed, ref } from "vue";

import ModalTab from "@/components/ModalTab.vue";
import SearchInputSelfFocusing from "@/components/SearchInputSelfFocusing.vue";
import TabbedModal from "@/components/TabbedModal.vue";
import { Objective } from "@/model/objective";
import { useBoardStore } from "@/store/board";
import { useCardStore } from "@/store/card";
import { useModalStore } from "@/store/modal";

import StickyLinkObjectivesTab from "./StickyLinkObjectivesTab.vue";
import StickyLinkStickiesTab from "./StickyLinkStickiesTab.vue";
import { calcLinkableCards } from "./stickyLinkStickies";

const props = defineProps<{ cardIds: string[] }>();

const cards = computed(
  () =>
    props.cardIds
      .map((id) => useCardStore().cards[id])
      .filter((card) => !!card), //cards might be loading
);

const showObjectivesTab = computed(
  () =>
    !["risk", "program"].includes(useBoardStore().currentBoard().type) ||
    cards.value.every((card) => card.teamId),
);

function close() {
  useModalStore().hide();
}

const query = ref("");

const linkableCards = computed(() => {
  const q = query.value.toLowerCase();
  return calcLinkableCards(cards.value).filter(
    (card) =>
      q.length === 0 ||
      card.text.toLowerCase().includes(q) ||
      !!card.almId?.toLowerCase().includes(q),
  );
});

const linkableObjectives = computed(() => {
  const q = query.value.toLowerCase();
  //TODO makes only sense on team board
  const board = useBoardStore().boardByType("team");
  return [
    ...board.objectives.map(makeCommittable(true)),
    ...board.stretchObjectives.map(makeCommittable(false)),
  ].filter(
    (objective) =>
      q.length === 0 ||
      objective.text.toLowerCase().includes(q) ||
      objective.description.toLowerCase().includes(q),
  );

  function makeCommittable(isCommitted: boolean) {
    return (objective: Objective) => ({
      ...objective,
      linked: cards.value.every((card) =>
        objective.cards.some(({ id }) => card.id === id),
      ),
      isCommitted,
    });
  }
});
</script>

<template>
  <TabbedModal
    :title="$t('linkModal.title', cards.length)"
    :description="$t('label.linkModal.description')"
  >
    <template #tab-header>
      <search-input-self-focusing
        v-model="query"
        wrapper-class="link-modal-search-input"
        :title="$t('label.linkModal.searchTitle')"
      />
    </template>

    <ModalTab
      id="panel-0"
      role="tabpanel"
      aria-labelledby="tab-0"
      :title="$t('linkModal.stickies')"
    >
      <StickyLinkStickiesTab
        :cards="cards"
        :linkable-cards="linkableCards"
        @link-change="close"
      />
    </ModalTab>
    <ModalTab
      v-if="showObjectivesTab"
      id="panel-1"
      role="tabpanel"
      aria-labelledby="tab-1"
      :title="$t('general.objectives')"
    >
      <StickyLinkObjectivesTab
        :cards="cards"
        :objectives="linkableObjectives"
        @link-change="close"
      />
    </ModalTab>
  </TabbedModal>
</template>

<style lang="scss">
.link-modal-search-input {
  margin: 0 12px 12px;
}
</style>
