import { ComputedRef, Ref, computed, onMounted, ref, watch } from "vue";

import { registerCardDrag } from "@/components/card/cardDragHandler";
import { sendStickyNoteAction } from "@/mixins/EventBusUser";
import { Card } from "@/model/card";
import { useBoardStore } from "@/store/board";
import { linkBetween, useLinkStore } from "@/store/link";

type Props = {
  el: Ref<HTMLElement | undefined>;
  card: Card;
  disabled?: Ref<Readonly<boolean>>;
};

type UseLinkDragReturn = {
  isLinkingTarget: ComputedRef<Readonly<boolean>>;
  isLinkingSource: ComputedRef<Readonly<boolean>>;
};

export function useLinkDrag({ el, card, disabled }: Props): UseLinkDragReturn {
  onMounted(() => {
    if (disabled?.value) return;
    el?.value?.addEventListener("pointerenter", handlePointerEnter);
    el.value?.addEventListener("pointerdown", handlePointerDown);
  });

  const linkStore = useLinkStore();
  const boardStore = useBoardStore();

  // the sticky note is being linked to
  const isLinkingTarget = computed(() => linkStore.linking.to?.id === card.id);
  // the sticky note is being linked from
  const isLinkingSource = computed(
    () => linkStore.linking.from?.id === card.id,
  );
  const enlargeAfterLinking = ref(false);

  watch(isLinkingTarget, (val) => {
    if (val) {
      el?.value?.addEventListener("pointerleave", handlePointerLeave, {
        once: true,
      });
    }
  });

  const handlePointerDown = (event: PointerEvent) => {
    const target = event.target as HTMLElement;
    const isLinkDrag = target.classList.contains("link-drag");

    if (disabled?.value || !isLinkDrag) return;

    if (boardStore.enlargedStickyNoteId === card.id) {
      enlargeAfterLinking.value = true;
      boardStore.setEnlargedStickyNoteId(null);
    }

    registerCardDrag(card, onLinkDragEnd, event);
  };

  const onLinkDragEnd = () => {
    if (enlargeAfterLinking.value) {
      enlargeAfterLinking.value = false;
      sendStickyNoteAction(card.id, {
        action: "enlarge",
        focus: true,
        trigger: "after-link-drag",
      });
    }
  };

  const handlePointerEnter = () => {
    if (
      linkStore.linking.from?.id &&
      !linkStore.isLinkedFrom(card.id) &&
      !linkBetween(card, linkStore.linking.from)
    ) {
      useLinkStore().setLinkingTarget({ id: card.id, type: "sticky" });
    }
  };

  const handlePointerLeave = () => {
    if (linkStore.linking.to?.id === card.id) {
      useLinkStore().resetLinkingTarget();
    }
  };

  return {
    isLinkingTarget,
    isLinkingSource,
  };
}
