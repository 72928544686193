import { sender } from "@/backend/Sender";
import { Board } from "@/model/board";
import { BoardCard } from "@/model/card";
import { offScreen } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";

export const internalActions = {
  switchBoard(board?: Board) {
    const { board: oldBoard, cards } = useBoardStore().switchBoard(board);
    cleanupBoard(oldBoard, cards);
  },

  leaveBoard() {
    const { board, cards } = useBoardStore().leaveBoard();
    cleanupBoard(board, cards);
  },
};

function cleanupBoard(board: Board | undefined, cards: BoardCard[]) {
  if (board) {
    for (const card of cards) {
      sender.stopAlterCard(board.id, card.data.id, {
        type: card.data.type,
      });
    }
    sender.pointerPos(board.id, offScreen);
  }
}
