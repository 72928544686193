<script setup lang="ts">
import { computed } from "vue";

import { maxVisibleUsersOnBoard } from "@/Settings";
import { useUserStore } from "@/store/user";
import { useUsersOnBoardStore } from "@/store/usersOnBoard";

import UserAvatar from "./UserAvatar.vue";
import MenuItemDelimiter from "./menu/components/MenuItemDelimiter.vue";
import BaseTooltip from "./ui/BaseTooltip/BaseTooltip.vue";

const allUsers = computed(() => useUsersOnBoardStore().usersOnBoard);
const users = computed(() =>
  allUsers.value
    .filter((user) => user.id !== useUserStore().technicalUser.id)
    .slice(0, maxVisibleUsersOnBoard),
);
const userNames = computed(() =>
  allUsers.value.map((user) => user.name).join(", "),
);
const more = computed(() => allUsers.value.length - maxVisibleUsersOnBoard);
</script>

<template>
  <div v-if="users.length > 0" id="board-users" data-no-screenshot>
    <BaseTooltip
      position="bottom"
      class="tooltip"
      interactive
      tabindex="0"
      :aria-label="$t('boardUsers.currentUsersOnBoard', { userNames })"
    >
      <div
        data-testid="board-users-preview"
        class="users-preview"
        tabindex="-1"
        aria-hidden="true"
      >
        <UserAvatar v-for="user in users" :key="user.id" :user="user" />
        <span v-if="more > 0" class="more">
          {{ $t("boardUsers.moreUsers", { more }) }}
        </span>
      </div>
      <template #content>
        <div class="users-list">
          <h3>{{ $t("boardUsers.activeMembers") }}</h3>
          <ul class="scrollable scrollable-relative">
            <li v-for="user in allUsers" :key="user.id">
              <UserAvatar :user="user" size="small" />
              {{ user.name || $t("boardUsers.unknown") }}
            </li>
          </ul>
        </div>
      </template>
    </BaseTooltip>
    <MenuItemDelimiter />
  </div>
</template>

<style lang="scss">
@use "@/styles/tooltip-users-list";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

#board-users {
  display: flex;
  flex-direction: row;
  align-items: center;

  .tooltip {
    display: flex;
    height: 40px;
    outline: revert;
  }

  .users-preview {
    display: flex;
    align-items: center;
    cursor: pointer;

    .more,
    .avatar:not(:first-child) {
      margin-left: -7px;
    }

    .more {
      border-radius: 50%;
      background-color: colors-old.$text-secondary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      color: colors-old.$back-color;
      width: 24px;
      height: 24px;
      font-size: 12px;
    }
  }
}
</style>
