import { reactive } from "vue";

import * as Environment from "@/Environment";
import { userCacheTimeout, usersInTeamCacheTimeout } from "@/Settings";
import { getAlmInfo } from "@/backend/Backend";
import { mapUserTeams } from "@/backend/BackendDataMapper";
import { CancelError } from "@/backend/CancelError";
import { ServerAuthUser, ServerUserTeam } from "@/backend/serverModel";
import { IdMap } from "@/model/baseTypes";
import { Team } from "@/model/session";
import {
  AlmUser,
  AuthUser,
  TechnicalUser,
  almUser,
  backendUser,
  isBackendUserId,
} from "@/model/user";
import { captureMessage } from "@/sentry";
import color from "@/styles/color.module.scss";
import { colorFromNumber } from "@/utils/color";
import { hash } from "@/utils/general";

import { createApiClient, modernClient } from "./api.config";

const emptyListApi = modernClient(createApiClient(Environment.authAPIUrl), {
  defaultValue: { results: [] },
});
const api = modernClient(createApiClient(Environment.authAPIUrl));

type CacheUser = AuthUser & { timestamp: number };
type Users = IdMap<CacheUser>;
type UserIdentifier = { id: string } | TechnicalUser;

export async function searchUsers(
  query: string,
): Promise<Array<AuthUser & { teams: Team[] }>> {
  const res = await emptyListApi.get("/v1/users/search", { params: { query } });
  return res.data.results.map(
    (user: ServerAuthUser & { teams: ServerUserTeam[] }) => ({
      ...mapUser(user),
      teams: mapUserTeams(user.teams),
    }),
  );
}

let usersInTeamCache = {
  teamId: "",
  timestamp: 0,
  users: new Array<AuthUser>(),
};

export async function usersInTeam(team: Team): Promise<AuthUser[]> {
  if (
    usersInTeamCache.teamId === team.id &&
    Date.now() - usersInTeamCache.timestamp < usersInTeamCacheTimeout
  ) {
    return usersInTeamCache.users;
  }
  const users = await getUsersInTeam(team);
  usersInTeamCache = { teamId: team.id, timestamp: Date.now(), users };
  return users;
}

async function getUsersInTeam(team: Team): Promise<AuthUser[]> {
  const res = await emptyListApi.get(`/v1/teams/${team.id}/users`);
  return res.data.results.map(mapUser);
}

export async function getTeams(): Promise<Team[]> {
  const res = await emptyListApi.get("/v1/users/teams");
  return res.data.results.map((team: any) => ({
    id: "" + team.id,
    name: team.name,
    artId: team.art_id ? "" + team.art_id : undefined,
  }));
}

export function loadUserImmediate(
  user: UserIdentifier & { name?: string },
  mapBackendToAlm?: boolean,
): AuthUser {
  const res = reactive(unknownUser(user));
  loadUser(user, { mapBackendToAlm }).then((loaded) => {
    res.id = loaded.id;
    res.name = loaded.name;
    res.email = loaded.email;
    res.imageUrl = loaded.imageUrl;
    res.color = loaded.color;
    res.preferredLanguage = loaded.preferredLanguage;
    res.hash = loaded.hash;
    if ("iconName" in loaded) {
      (res as AlmUser).iconName = loaded.iconName;
    }
  });
  return res;
}

let users = load();
const loading: IdMap<Promise<CacheUser>> = {};

export async function loadUser(
  user: UserIdentifier,
  options?: { useCache?: boolean; mapBackendToAlm?: boolean },
): Promise<AuthUser> {
  const useCache = options?.useCache ?? true;

  if (!user.id) {
    return add(unknownUser(user));
  }
  if (isBackendUserId(user.id)) {
    return options?.mapBackendToAlm ? almUser(getAlmInfo()) : backendUser();
  }
  const cached = users[user.id];
  if (useCache && cached && Date.now() - cached.timestamp < userCacheTimeout) {
    return cached;
  }
  if (user.id in loading) {
    return loading[user.id];
  }
  try {
    return await (loading[user.id] = getUser(user));
  } finally {
    delete loading[user.id];
  }
}

export function clearUserCache() {
  users = {};
  save(users);
}

async function getUser(user: UserIdentifier): Promise<CacheUser> {
  try {
    const res = await api.get("/v1/users/" + user.id);
    return add(mapUser(res.data));
  } catch (e: any) {
    if (e instanceof CancelError) {
      // most likely refreshing the token failed
      // don't log as we are already redirected to logout
      throw e;
    }
    captureMessage(`Could not get user: '${user.id}'`, {
      response: { message: e.message },
    });
  }
  return add(unknownUser(user));
}

function mapUser(user: ServerAuthUser): CacheUser {
  const name = userName(user);
  return {
    id: user.id,
    name,
    email: user.email,
    imageUrl: user.image_url,
    color: user.color || colorFromNumber(hash(name)),
    timestamp: 0,
    preferredLanguage: user.preferred_language,
    hash: user.hash,
  };
}

function add(user: CacheUser) {
  user.timestamp = Date.now();
  users[user.id] = user;
  save(users);
  return user;
}

function unknownUser(user: { id: string } & Partial<CacheUser>): CacheUser {
  return {
    name: userName(user),
    email: "",
    color: color.menu,
    timestamp: 0,
    preferredLanguage: "en",
    ...user,
  };
}

function userName(user: { name?: string; email?: string }) {
  return user.name || user.email?.substring(0, user.email.indexOf("@")) || "";
}

function load(): Users {
  try {
    return JSON.parse(localStorage.getItem("users") || "{}");
  } catch (e) {
    return {};
  }
}

function save(userObj: Users) {
  localStorage.setItem("users", JSON.stringify(userObj));
}
