import { BoardType, Color } from "./baseTypes";
import { Card } from "./card";

export type Functionality = "workitem" | "note" | "risk" | "dependency";

export interface Priority {
  value: number;
  text: string;
}

export interface StickyType {
  id: string;
  name: string;
  almType: string | null;
  color: Color;
  altColor: Color;
  priorities?: { [key: number]: Priority };
  functionality: Functionality;
  origin: BoardType; // the board type where the card was initially created
  flexOrigin: string | null; // the flex-board/colaboration-canvas where the card was initially created
  usable: BoardType[]; // where the sticky note can be mirrored too
  flexUsable: string[];
}

export function isDependency(card: Card) {
  return card.type.functionality === "dependency";
}

export function isWorkitem(card: Card) {
  return card.type.functionality === "workitem";
}

export function isNote(card: Card) {
  return card.type.functionality === "note";
}

export function isRisk(card: Card) {
  return card.type.functionality === "risk";
}

export interface AlmStickyType {
  id: string;
  isMapped: boolean;
}
