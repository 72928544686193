<template>
  <div class="card-flags-overlay overlay flags scrollable no-wheel" @click.stop>
    <flag-picker
      :selected="selected"
      :no-sticker-option="noStickerOption"
      @select="select"
    />
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

import FlagPicker from "@/components/flags/FlagPicker.vue";
import ShortcutUser from "@/mixins/ShortcutUser";
import CardFlag from "@/model/CardFlag";

@Component({ components: { FlagPicker }, emits: ["select"] })
export default class CardFlagsOverlay extends mixins(ShortcutUser) {
  @Prop([Array, Object]) readonly selected?: CardFlag[] | CardFlag;
  @Prop(Boolean) readonly noStickerOption: boolean | undefined;

  @Emit()
  select(_cardFlag: CardFlag) {}
}
</script>
