<template>
  <svg
    class="link-layer"
    :viewBox="`0 0 ${width} ${height}`"
    preserveAspectRatio="none"
    :style="{ stroke: color, zIndex }"
    style="fill: transparent"
    tabindex="-1"
    aria-hidden="true"
  >
    <defs>
      <g id="flashIcon" class="no-css-reset">
        <circle cx="10" cy="10" r="10" />
        <path d="M9 16v-5H5l6-7v5h4l-6 7z" fill="white" />
      </g>
    </defs>
    <g v-for="(link, linkIndex) in links" :key="linkIndex">
      <template v-if="link.clip">
        <defs>
          <mask :id="link.clip.id" class="no-css-reset">
            <rect x="0" y="0" width="1600" height="900" fill="white" />
            <rect v-bind="link.clip" fill="black" />
          </mask>
        </defs>
        <path
          :style="link.style"
          :d="link.curve"
          :mask="`url(#${link.clip.id})`"
        />
      </template>
      <path v-else :style="link.style" :d="link.curve" />
      <path
        v-if="link.arrow"
        :style="link.style"
        :fill="link.style.stroke"
        :d="link.arrow"
      />
      <use
        v-if="showRiskyIcons && link.icon"
        href="#flashIcon"
        :fill="link.style.stroke"
        stroke="transparent"
        :x="link.icon.x"
        :y="link.icon.y"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { arrowAngle, arrowBoxFactor, arrowLen } from "@/Settings";
import QuadraticSpline from "@/math/QuadraticSpline";
import EventBusUser from "@/mixins/EventBusUser";
import { Board } from "@/model/board";
import { BoardCard } from "@/model/card";
import { linkColors } from "@/model/colors";
import { RelativeCoordinate, minus, plus, times } from "@/model/coordinates";
import { isFaded, minFaded } from "@/model/markMode";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { LinkedCards, showIcon, showLink, useLinkStore } from "@/store/link";
import { useZoomStore } from "@/store/zoom";
import variables from "@/styles/variable.module.scss";

type SVGStyles = {
  stroke?: string;
};

interface Path {
  curve: string;
  style: SVGStyles;
  arrow?: string;
  icon?: RelativeCoordinate;
  clip?: {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

@Component({})
export default class LinkLayer extends mixins(EventBusUser) {
  @Prop(Object) readonly board!: Board;
  @Prop(String) readonly color!: string;
  @Prop(Boolean) readonly priority!: boolean;
  width = 1600;
  height = 900;

  get zIndex() {
    return this.priority ? this.board.maxZ - 1 : variables.zIndexLinks;
  }

  get showRiskyIcons() {
    return useBoardStore().showRiskyLinks;
  }

  get links() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const comp = this;
    const res = new Array<Path>();

    useLinkStore().linksOnBoard.forEach((linked) => addLink(linked));
    if (this.priority) {
      useDraggingStore().draggedLinks.forEach(({ card, drag }) =>
        addDragLink(card, drag.id),
      );
    }
    return res;

    function addDragLink(from: BoardCard, dragId: string) {
      const c = coordinateById(dragId);
      if (c) {
        const spline = QuadraticSpline.forLink(from.meta.pos, c);
        res.push({
          style: { stroke: comp.color },
          curve: spline.asPath(comp.width, comp.height),
        });
      }
    }

    function addLink(linked: LinkedCards) {
      // when zooming with zoom layer, don't show priority links
      // because clipping links by stickies does not work on zoom layer
      const zooming = useZoomStore().zoomingWithLayer;
      if (
        !showLink(linked) ||
        (zooming && comp.priority) ||
        (!zooming && isActiveCardLink() !== comp.priority)
      ) {
        return;
      }

      const icon = showIcon(linked.link);
      const color = icon ? linkColors[linked.link.state] : comp.color;
      const markMode = icon
        ? "normal"
        : minFaded(linked.from.meta.mark, linked.to.meta.mark);
      const transparency = markMode === "normal" ? "ff" : "80";

      const p0 = coordinateOf(linked.from);
      const p1 = coordinateOf(linked.to);
      const spline = QuadraticSpline.forLink(p0, p1);
      const path: Path = {
        style: { stroke: color + transparency },
        curve: spline.asPath(comp.width, comp.height),
      };

      if (icon) {
        path.icon = spline.iconPosition(comp.width, comp.height, 16 / 9);
      }

      const cardSize = times(
        arrowBoxFactor,
        useBoardStore().currentBoard().cardSize.factor,
      );

      // clip the link so that it does not overlap a card with lower z-index
      // it can either be the non-active card or the faded card
      if (comp.priority) {
        path.clip = clip(
          useBoardStore().activeCardId === linked.from.data.id ? p1 : p0,
          cardSize,
        );
      } else if (isFaded(linked.from.meta.mark)) {
        path.clip = clip(p0, cardSize);
      } else if (isFaded(linked.to.meta.mark)) {
        path.clip = clip(p1, cardSize);
      }

      if (linked.from.data.type.id === linked.to.data.type.id) {
        path.arrow = arrow(cardSize);
      }

      res.push(path);

      function clip(c: RelativeCoordinate, cardSize: RelativeCoordinate) {
        return {
          id: linked.link.id,
          x: (c.x - cardSize.x) * comp.width,
          y: (c.y - cardSize.y) * comp.height,
          width: 2 * cardSize.x * comp.width,
          height: 2 * cardSize.y * comp.height,
        };
      }

      function arrow(cardSize: RelativeCoordinate) {
        const tMax = spline.tMaxAtRectangle(
          minus(p1, cardSize),
          plus(p1, cardSize),
        );
        const point = spline.interpolate(tMax);
        const angle = spline.angleAtT(tMax);
        return (
          command("M", point, 0, 0) +
          command(
            "L",
            point,
            arrowLen * Math.cos(angle - arrowAngle),
            arrowLen * Math.sin(angle - arrowAngle),
          ) +
          command(
            "L",
            point,
            arrowLen * Math.cos(angle + arrowAngle),
            arrowLen * Math.sin(angle + arrowAngle),
          ) +
          " l 0 0 z"
        );
      }

      function isActiveCardLink() {
        return (
          useBoardStore().activeCardId === linked.from.data.id ||
          useBoardStore().activeCardId === linked.to.data.id
        );
      }
    }

    function coordinateOf(card: BoardCard): RelativeCoordinate {
      return coordinateById(card.data.id) || card.meta.pos;
    }

    function coordinateById(id: string): RelativeCoordinate | undefined {
      return useDraggingStore().findById(id)?.pos;
    }

    function command(
      cmd: string,
      base: RelativeCoordinate,
      x: number,
      y: number,
    ) {
      return ` ${cmd} ${(base.x + x) * comp.width} ${(base.y + y) * comp.height}`;
    }
  }
}
</script>

<style lang="scss">
@use "@/styles/z-index";

.link-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
