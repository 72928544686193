<script lang="ts" setup>
import { useRiskAnalysisStore } from "../store/riskAnalysis";
import { Pattern } from "../types";

defineProps<{
  patterns: Pattern[];
}>();

const riskStore = useRiskAnalysisStore();

const handleWeightChange = (patternId: number, weight: string) => {
  const val = parseFloat(weight);
  riskStore.updatePattern(patternId, { weight: val });
};
</script>

<template>
  <div class="weights-info">
    <div class="list">
      <slot name="before" />
      <div
        v-for="pattern in patterns"
        :key="pattern.title"
        class="pattern-weight"
      >
        <input
          type="number"
          step="0.01"
          :value="pattern.weight"
          @input="
            (event) =>
              handleWeightChange(
                pattern.id,
                (event.target as HTMLInputElement).value,
              )
          "
        />
        <span>{{ pattern.title }}</span>
      </div>
      <slot name="after" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/mixins/typography";
@use "@/styles/variables/colors";

.weights-info {
  margin: 20px;

  .title {
    display: flex;
    justify-content: space-between;
    background-color: colors.$background-grey-bold;
    color: colors.$text-inverse;
  }

  .list {
    @include typography.medium;

    padding: 40px;
    background-color: colors.$background-grey-light;
    border: 1px solid colors.$border;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    :deep(.pattern-weight) {
      input {
        width: 50px;
        border: 1px solid colors.$border;
        text-align: center;
        border-radius: 5px;
      }

      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}
</style>
./BaseExpendable/ExpandablePanel.vue./BaseExpendable/ExpandablePanelContent.vue./BaseExpendable/ExpandablePanelTitle.vue
