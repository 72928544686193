<template>
  <div
    class="input-time action"
    :class="{ unset, error }"
    @click="$refs.input.$el.click()"
  >
    <input-field
      v-bind="$attrs"
      ref="input"
      :value="value"
      @change="$emit('change', $event)"
    />
    <div>{{ suffix }}</div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import LabeledInput from "@/mixins/LabeledInput";

import InputField from "./InputField.vue";

@Component({
  inheritAttrs: false,
  components: { InputField },
  emits: ["change"],
})
export default class SuffixInput extends mixins(LabeledInput) {
  @Prop(String) readonly value!: string;
  @Prop(String) readonly suffix!: string;

  $refs!: {
    input: Element & { $el: HTMLInputElement };
  };

  get unset() {
    return !+this.value;
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.input-time {
  background-color: colors-old.$back-color;
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  border: 0.1em solid colors-old.$dark-divider-color;
  border-radius: variables.$border-radius;
  font-size: font.$size-normal;

  &.unset {
    color: colors-old.$text-secondary-color;
  }

  &.error {
    color: colors-old.$error-color;
    border-color: colors-old.$error-color;
  }

  input {
    width: 1.2em;
    padding-left: 0;
    padding-right: 0;
    border: none;
  }

  div {
    margin-left: 0.2em;
  }
}
</style>
