<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";

import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import MenuItemDelimiter from "@/components/menu/components/MenuItemDelimiter.vue";
import BaseModal from "@/components/modal/BaseModal.vue";
import MetricsModalLoadCapacityBody from "@/components/modal/MetricsModalLoadCapacityBody.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { i18n } from "@/i18n";
import { Team } from "@/model/session";
import { useModalStore } from "@/store/modal";
import { useTeamStore } from "@/store/team";
import {
  planReadoutModalClosed,
  planReadoutModalOpened,
  planReadoutTabClicked,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import ObjectivesTab from "./components/ObjectivesTab.vue";
import RisksTab from "./components/RisksTab.vue";
import TeamSelector from "./components/TeamSelector.vue";

const tabs = [
  { id: "objectives", label: i18n.global.t("general.objectives") },
  { id: "risks", label: i18n.global.t("planReadoutModal.risks") },
  { id: "capacity", label: i18n.global.t("general.capacity") },
] as const;

const props = withDefaults(
  defineProps<{ activeTab?: (typeof tabs)[number]["id"] }>(),
  { activeTab: "objectives" },
);

const currentTeam = ref(useTeamStore().currentTeam);

onMounted(() => {
  trackEvent(planReadoutModalOpened());
});

onUnmounted(() => {
  trackEvent(planReadoutModalClosed());
});

function selectTeam(team: Team) {
  currentTeam.value = team;
}

function setActiveTab(tabId: string) {
  trackEvent(planReadoutTabClicked(tabId, props.activeTab));
  useModalStore().changeAttrs({ activeTab: tabId });
}
</script>

<template>
  <BaseModal
    class="plan-readout-modal"
    fullscreen
    aria-labelledby="plan-readout-modal-title"
    :description="$t('label.planReadout.description')"
  >
    <template #header-title>
      <div class="header-container">
        <div class="left-container">
          <SvgIcon name="plan-readout" class="plan-readout-icon" />
          <h1 id="plan-readout-modal-title" v-autofocus class="title">
            {{ $t("planReadout.title") }}
          </h1>
          <MenuItemDelimiter />
          <div class="tab-container">
            <BaseButton
              v-for="tab in tabs"
              :key="tab.id"
              variant="ghost"
              color="grey"
              :activated="activeTab === tab.id"
              @click="setActiveTab(tab.id)"
            >
              <span class="tab-text">
                {{ tab.label }}
              </span>
            </BaseButton>
          </div>
        </div>
      </div>
      <TeamSelector
        :current-team="currentTeam"
        class="present-team-selector"
        @select="selectTeam"
      />
    </template>

    <div class="main-container">
      <TransitionGroup name="faster-fade">
        <div v-if="activeTab === 'capacity'" class="metrics-modal-container">
          <MetricsModalLoadCapacityBody :team="currentTeam" />
        </div>
        <RisksTab v-if="activeTab === 'risks'" :team="currentTeam" />
        <ObjectivesTab v-if="activeTab === 'objectives'" :team="currentTeam" />
      </TransitionGroup>
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;

.plan-readout-modal {
  .main-container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    flex-grow: 1;

    .metrics-modal-container {
      width: 100%;
      height: 100%;

      :deep(.left) {
        border-right: solid 1px colors-old.$divider-color;
        inset: 0 65% 0 1em;
      }

      :deep(.right) {
        inset: 0 1em 0 35%;
      }

      :deep(> *) {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
  }

  .left-container {
    display: flex;
    align-items: center;
    gap: 16px;

    .plan-readout-icon {
      width: 18px;
      height: 18px;
    }

    .title {
      margin: 0;
      font-size: font.$size-large;
      font-weight: font.$weight-extra-bold;
    }

    .tab-container {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      .tab-text {
        font-weight: font.$weight-bold;
      }
    }
  }

  // Center the team selector, without overlapping it with the tab buttons on small screens
  .present-team-selector {
    position: absolute;
    left: max(600px, 50%);
    transform: translateX(-50%);
  }
}
</style>
