<script lang="ts" setup></script>

<template>
  <div class="base-list-item-icon">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.base-list-item-icon {
  display: inline-block;
  min-width: 28px;
  flex-shrink: 0;
}
</style>
