<template>
  <div
    class="flex-board-elem"
    @click="!dragging && $emit('select', board)"
    @keydown.enter.stop
    @keydown.space.stop
  >
    <div class="image">
      <FlexBackground
        :type="board.flexType.background"
        class="action background"
        :name="board.name"
        aria-hidden="true"
      />

      <div v-if="!deleting && !edit && !dragging" class="icons">
        <IconButton
          v-if="!readOnly"
          icon="pen"
          class="action action-icon"
          :title="$t('label.flexModal.canvasEdit', { value: board.name })"
          @click.stop="$emit('edit', board)"
        />
        <IconButton
          v-if="canDelete"
          icon="action-menu/delete"
          class="action action-icon"
          :title="$t('label.flexModal.canvasDelete', { value: board.name })"
          @click.stop="deleting = true"
        />
      </div>
      <div v-if="deleting" v-autofocus class="delete">
        {{ $t("flexBoardElem.confirm") }}
        <div class="bottom">
          <button
            class="action line line-right item"
            @click.stop="deleting = false"
          >
            {{ $t("general.cancel") }}
          </button>
          <button class="action item" @click.stop="deleteCanvas">
            <SvgIcon name="action-menu/delete" width="20" height="20" />
            {{ $t("flexBoardElem.delete") }}
          </button>
        </div>
      </div>
    </div>
    <div class="text" @click.stop>
      <input-field
        ref="input"
        :value="board.name"
        :max-length="30"
        :read-only="readOnly"
        underline
        tabindex="-1"
        :placeholder="$t('flexModal.defaultCanvasName')"
        :aria-label="$t('flexModal.defaultCanvasName')"
        @change="$emit('setName', board, $event)"
        @focus="$emit('edit', board)"
        @blur="$emit('edit')"
        @keydown.stop
      />
      <SvgIcon v-show="!readOnly && edit" name="check" width="20" height="20" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";

import { flexActions } from "@/action/flexActions";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import FlexBackground from "@/components/background/FlexBackground.vue";
import InputField from "@/components/input/InputField.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { BoardData } from "@/model/board";
import { goToBoard } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useFlexStore } from "@/store/flex";
import { useUserStore } from "@/store/user";

@Component({
  components: { SvgIcon, InputField, FlexBackground, IconButton },
  emits: ["select", "edit", "setName", "deleted"],
})
export default class FlexBoardElem extends Vue {
  @Prop(Object) readonly board!: BoardData<"flex">;
  @Prop(Boolean) readonly dragging!: boolean;
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop(Boolean) readonly edit!: boolean;

  @Ref("input") inputElem!: Vue;
  deleting = false;

  @Watch("edit")
  editChanged(e: boolean) {
    if (e) {
      const el = this.inputElem.$el as HTMLElement;
      el.focus();
      el.click();
    }
  }

  get canDelete() {
    return useUserStore().isAllowed("deleteBoard");
  }

  deleteCanvas() {
    this.$emit("deleted");
    if (useBoardStore().currentBoard().id === this.board.id) {
      goToBoard("team");
    }
    if (useFlexStore().currentFlexBoard?.id === this.board.id) {
      useFlexStore().currentFlexBoard = null;
    }
    flexActions.delete("modal", this.board.id);
  }
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.flex-board-elem {
  outline: revert;

  .icons {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .image {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .action-icon {
    outline: revert;
    color: colors.$icon !important;

    svg {
      height: 30px !important;
      width: 30px !important;
    }
  }

  .delete {
    position: absolute;
    background-color: colors-old.$delete-color;
    color: colors-old.$back-color;
    inset: 0 0 -2em;
    text-align: center;
    padding: 1em;

    .action svg {
      height: 1.5em;
      vertical-align: middle;
    }
  }

  .text {
    display: flex;
    align-items: center;

    input {
      text-align: center;
      border: none;
      padding: 0 20px;

      &.edit {
        border-bottom: 0.1em solid colors-old.$input-border-color;
      }
    }
  }
}
</style>
