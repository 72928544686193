import { i18n } from "@/i18n";
import { Icon } from "@/types/icon";

import { AlmTypeInfo } from "./baseTypes";
import { Team } from "./session";

// basic user information about the current user returned from the isAuthenticated endpoint
export interface TechnicalUser {
  id: string;
  company: string;
  role: Role;
  teams: Team[];
}

const roles = ["observer", "user", "admin", "planning_interval_admin"] as const;

export type Role = (typeof roles)[number];

// extended user information about any user returned from the getUser endpoint
export type AuthUser = PersonalUser | BackendUser | AlmUser;

export interface PersonalUser {
  id: string;
  name: string;
  email: string;
  imageUrl?: string;
  color: string;
  preferredLanguage: string;
  hash?: string;
}

// user running tasks in the backend
export interface BackendUser extends PersonalUser {
  id: "backend";
}

// alm tool user e.g. jira
export interface AlmUser extends PersonalUser {
  id: "alm";
  iconName: Icon; // (e.g. the 'jira' icon)
}

export type BoardUser = AuthUser & {
  boardVisitTimestamp: number;
};

export function technicalUser(
  id: string,
  company: string,
  role: string,
): TechnicalUser {
  return { id, company, role: asRole(role), teams: [] };
}

function asRole(s: string): Role {
  const r = s as Role;
  return roles.includes(r) ? r : "observer";
}

const baseUser = {
  email: "",
  color: "black",
  preferredLanguage: "en",
};

export function backendUser(): BackendUser {
  return {
    ...baseUser,
    id: "backend",
    name: "System",
  };
}

export function isBackendUserId(userId: string) {
  return userId === "backend";
}

/**
 * Generates a user representing the session's ALM tool
 * (since a session can only have one ALM, we know any
 * 'backend' events are coming from that ALM)
 */
export function almUser(almInfo: AlmTypeInfo): AlmUser {
  return {
    ...baseUser,
    id: "alm",
    name: almInfo.name,
    iconName: almInfo.colorIcon,
  };
}

export function isAlmUser(user: AuthUser | null): user is AlmUser {
  return user?.id === "alm";
}

export function unmappedUser(id: string, color: string): PersonalUser {
  return {
    ...baseUser,
    id,
    name: id.substring(0, 6) + " " + id.substring(id.length - 6),
    color,
  };
}

export function isUnmappedUser(user: AuthUser | null) {
  return user && user.name.substring(0, 6) === user.id.substring(0, 6);
}

export function noUser(color: string): AuthUser {
  return {
    ...baseUser,
    id: "no",
    name: i18n.global.t("general.noAssignee"),
    color,
  };
}

export function isUnknownUser(user: AuthUser | null) {
  return !user || !user.email;
}
