import { clamp } from "lodash-es";

import { boardAspect, cornerFactor, fakeZoom, stickySize } from "@/Settings";
import { isFeatureEnabled } from "@/feature";
import {
  BoardCoordinate,
  Rectangle,
  RelativeCoordinate,
  WindowCoordinate,
  boardCoord,
  clientCoord,
  divided,
  minus,
  plus,
  relativeCoord,
  times,
  windowCoord,
} from "@/model/coordinates";
import { ZoomedAppSize } from "@/model/size";
import { getRouter } from "@/router";
import { useActivityStore } from "@/store/activity";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useTimerStore } from "@/store/timer";
import { useZoomStore } from "@/store/zoom";
import { getPanelWidth, isScrollbarVisible } from "@/utils/dom";

/*
 * Convert a window coordinate into a board coordinate.
 * @param e: The window coordinate to be converted.
 * @param limitCoords: If the coordinate should be checked and adjusted if needed
 *    - "input": Check the window coordinate parameter to be inside the window
 *    - "output": Check the resulting board coordinate to be inside the board
 * @param additionalOffsets: A list of additional offsets to the given coordinate that should also be checked.
 */
export function windowToBoard(
  e: WindowCoordinate,
  limitCoords: "input" | "output" | "none" = "output",
  additionalOffsets: Array<{ offset: WindowCoordinate }> = [],
): BoardCoordinate {
  const appSize = useAppSizeStore().appSize;
  const zoom = useZoomStore().factor;
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const limited = limit(e);
  for (const additional of additionalOffsets) {
    combineDeltas(limited, limit(plus(e, times(additional.offset, zoom))));
  }
  return times(plus(limited, limited.delta), fakeZoom);

  function limit(e: WindowCoordinate) {
    const inp = limitCoords === "input" ? insideWindow(e) : e;
    const c = boardCoord(
      (inp.x - appSize.left) / zoom - xHalf * appSize.width,
      (inp.y - appSize.top) / zoom - yHalf * appSize.height,
    );
    return {
      ...c,
      delta:
        limitCoords === "output" ? minus(insideBoard(c), c) : boardCoord(0, 0),
    };
  }

  function combineDeltas(
    target: { delta: BoardCoordinate },
    combine: { delta: BoardCoordinate },
  ) {
    if (Math.abs(combine.delta.x) > Math.abs(target.delta.x)) {
      target.delta.x = combine.delta.x;
    }
    if (Math.abs(combine.delta.y) > Math.abs(target.delta.y)) {
      target.delta.y = combine.delta.y;
    }
  }
}

function insideWindow(e: WindowCoordinate): WindowCoordinate {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const appSize = useAppSizeStore().appSize;
  return windowCoord(
    clamp(
      e.x,
      xHalf * appSize.width + appSize.padding.left,
      window.innerWidth - xHalf * appSize.width,
    ),
    clamp(
      e.y,
      yHalf * appSize.height + appSize.padding.top,
      window.innerHeight - yHalf * appSize.height,
    ),
  );
}

function insideBoard(e: BoardCoordinate): BoardCoordinate {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const h = useAppSizeStore().appSize.height;
  return boardCoord(
    clamp(e.x, 0, (1 - 2 * xHalf) * useAppSizeStore().appSize.width),
    clamp(
      e.y,
      yHalf * 2 * getCornerFactor() * h,
      (1 - yHalf * 2 * (1 - getCornerFactor())) * h,
    ),
  );
}

export function windowToRelative(e: WindowCoordinate): RelativeCoordinate {
  const appSize = useAppSizeStore().appSize;
  const zoom = useZoomStore().factor;
  const xBase = (e.x - appSize.left + window.scrollX) / zoom;
  const yBase = (e.y - appSize.top + window.scrollY) / zoom;
  return relativeCoord(xBase / appSize.width, yBase / appSize.height);
}

export function relativeToWindow(
  e: RelativeCoordinate,
  appSize: ZoomedAppSize = {
    ...useAppSizeStore().appSize,
    zoom: useZoomStore().factor,
  },
): WindowCoordinate {
  return windowCoord(
    appSize.zoom * (e.x * appSize.width) + appSize.left,
    appSize.zoom * (e.y * appSize.height) + appSize.top,
  );
}

export function boardSize(): Rectangle<WindowCoordinate> {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const h = useAppSizeStore().appSize.height;
  const p0 = boardToWindow(
    boardCoord(0, fakeZoom * yHalf * 2 * getCornerFactor() * h),
  );
  const p1 = boardToWindow(
    boardCoord(
      fakeZoom * (1 - 2 * xHalf) * useAppSizeStore().appSize.width,
      fakeZoom * (1 - yHalf * 2 * (1 - getCornerFactor())) * h,
    ),
  );
  return {
    p0: windowCoord(Math.floor(p0.x), Math.floor(p0.y)),
    p1: windowCoord(Math.ceil(p1.x), Math.ceil(p1.y)),
  };
}

export function boardToWindow(e: BoardCoordinate): WindowCoordinate {
  const appSize = useAppSizeStore().appSize;
  const zoom = useZoomStore().factor;
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  return windowCoord(
    (e.x / fakeZoom + xHalf * appSize.width) * zoom + appSize.left,
    (e.y / fakeZoom + yHalf * appSize.height) * zoom + appSize.top,
  );
}

export function boardToWindowSimple(c: BoardCoordinate): WindowCoordinate {
  return divided(c, fakeZoom) as unknown as WindowCoordinate;
}

export function windowToBoardSimple(c: WindowCoordinate): BoardCoordinate {
  return times(c, fakeZoom) as unknown as BoardCoordinate;
}

/**
 * Converts a board coordinate for the top-left corner of the card to
 * a relative coordinate for the center of the card
 *
 * @param coordinates: The board coordinate for the top-left corner of the card
 * @returns The relative coordinate for the center of the card
 */
export function boardToRelative(
  coordinates: Partial<BoardCoordinate>,
): RelativeCoordinate {
  if (coordinates.x === undefined || coordinates.y === undefined) {
    return relativeCoord(0.5, 0.5);
  }
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  return relativeCoord(
    coordinates.x / (useAppSizeStore().appSize.width * fakeZoom) + xHalf,
    coordinates.y / (useAppSizeStore().appSize.height * fakeZoom) +
      yHalf * (1 - 2 * getCornerFactor()),
  );
}

/**
 * Direct conversion from board to relative coordinate
 */
export function boardToRelativeSimple(e: BoardCoordinate): RelativeCoordinate {
  return relativeCoord(
    e.x / (useAppSizeStore().appSize.width * fakeZoom),
    e.y / (useAppSizeStore().appSize.height * fakeZoom),
  );
}

// to be removed after sticky redesign
export function relativeToBoardOld(e: RelativeCoordinate): BoardCoordinate {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  return boardCoord(
    (e.x + xHalf) * useAppSizeStore().appSize.width * fakeZoom,
    (e.y + yHalf) * useAppSizeStore().appSize.height * fakeZoom,
  );
}

/**
 * Converts a relative coordinate for the top-left corner of a card to a board coordinate
 * for the center of the card
 *
 * @param e: The relative coordinate for the top-left corner of the card
 * @returns The board coordinate for the center of the card
 */
export function relativeToBoard(e: RelativeCoordinate): BoardCoordinate {
  const cardSize = useBoardStore().currentBoard().cardSize;
  return boardCoord(
    (e.x / cardSize.x + 0.5) * stickySize,
    (e.y / cardSize.y + 0.5) * stickySize,
  );
}

/**
 * If the card is outside the board/viewport, moves it back inside the board.
 * This modifies the first input parameter (a) in place.
 *
 * @returns The offset by which the card was moved
 *          (explicitly as {offsetX: number, offsetY: number} to avoid confusion)
 */
export function cardInsideBoardAndViewport(
  pos: BoardCoordinate,
  size: { width: number; height: number },
): { offsetX: number; offsetY: number } {
  const { x: originalX, y: originalY } = pos;
  const appSize = useAppSizeStore().appSize;
  const zoom = useZoomStore().factor;

  // Adjust viewport size for any open side panels
  const panelWidth = getPanelWidth();
  const leftPanelSize = useSearchMenuStore().isSearchActive ? panelWidth : 0;
  const rightPanelSize =
    useTimerStore().active || useActivityStore().active ? panelWidth : 0;

  // card inside board
  if (pos.x < 0) {
    pos.x = 0;
  }
  let x = pos.x + size.width - appSize.width * fakeZoom;
  if (x > 0) {
    pos.x -= x;
  }
  if (pos.y < 0) {
    pos.y = 0;
  }
  let y = pos.y + size.height - appSize.height * fakeZoom;
  if (y > 0) {
    pos.y -= y;
  }

  const scrollbarsVisible = isScrollbarVisible();
  const scrollbarSize = useAppSizeStore().scrollbarSize;
  const scrollbarX = scrollbarsVisible.x ? scrollbarSize : 0;
  const scrollbarY = scrollbarsVisible.y ? scrollbarSize : 0;

  const outline = 2; // the outline of the sticky note
  const gap = 2; // desired gap between sticky note and viewport

  // card inside view port (and not hidden by side panels)
  const viewportLeft =
    window.scrollX - appSize.left + leftPanelSize + outline + gap;
  const viewportRight =
    viewportLeft +
    window.innerWidth -
    leftPanelSize -
    rightPanelSize -
    scrollbarY -
    outline * 3 -
    gap;
  const viewportTop = window.scrollY + outline + gap;
  const viewportBottom =
    window.scrollY -
    appSize.top -
    outline -
    scrollbarX -
    gap +
    window.innerHeight;

  x = pos.x * zoom - viewportLeft * fakeZoom;
  if (x < 0) {
    pos.x -= x / zoom;
  }
  x = (pos.x + size.width) * zoom - viewportRight * fakeZoom;
  if (x > 0) {
    pos.x -= x / zoom;
  }

  y = pos.y * zoom - viewportTop * fakeZoom;
  if (y < 0) {
    pos.y -= y / zoom;
  }
  y = (pos.y + size.height) * zoom - viewportBottom * fakeZoom;
  if (y > 0) {
    pos.y -= y / zoom;
  }

  return { offsetX: pos.x - originalX, offsetY: pos.y - originalY };
}

export function calcBoardSize(
  zoom: number = useZoomStore().factor,
): ZoomedAppSize {
  const size = useAppSizeStore().appSize;
  const width = document.documentElement.clientWidth;
  const height = document.documentElement.clientHeight;
  const cw = width - (size.margin.right || size.margin.left);
  const ch = height - size.margin.top;
  const ratio = cw / ch;
  const top = size.padding.top + size.margin.top;
  const left = size.margin.right ? 0 : size.padding.left + size.margin.left;
  const base = {
    zoom,
    padding: { ...size.padding },
    margin: { ...size.margin },
  };
  if (ratio < boardAspect) {
    const h = cw / boardAspect;
    return {
      ...base,
      top: top + Math.max(0, (ch - h * zoom) / 2),
      left: left + Math.max(0, (cw * (1 - zoom)) / 2),
      height: h,
      width: cw,
    };
  }
  const w = ch * boardAspect;
  return {
    ...base,
    top: top + Math.max(0, (ch * (1 - zoom)) / 2),
    left: left + Math.max(0, (cw - w * zoom) / 2),
    height: ch,
    width: w,
  };
}

// Temporal solution to disable cornerFactor for the new implementation sticky notes
const getCornerFactor = () => {
  const router = getRouter();
  if (!router) {
    return cornerFactor;
  }

  if (isFeatureEnabled(router.currentRoute.value, "sticky-note")) {
    return 0;
  }

  return cornerFactor;
};

export function relativeClientCoord(elem: MouseEvent): RelativeCoordinate {
  return windowToRelative(clientCoord(elem));
}
