<script lang="ts" setup>
export type Props = {
  width?: "xs" | "sm" | "md" | "lg" | "auto" | "full";
  maxHeight?: "xs" | "sm" | "md" | "lg" | "xl" | "auto";
};

const props = withDefaults(defineProps<Props>(), {
  width: "auto",
  maxHeight: "auto",
});
</script>

<template>
  <div :class="['base-popup', `w-${props.width}`, `max-h-${props.maxHeight}`]">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/font";
@use "@/styles/mixins";

.base-popup {
  $max-height: (
    "xs": 250px,
    "sm": 300px,
    "md": 350px,
    "lg": 450px,
    "xg": 550px,
    "auto": auto,
  );
  $width: (
    "xs": 120px,
    "sm": 200px,
    "md": 260px,
    "lg": 300px,
    "auto": fit-content,
    "full": 100%,
  );

  @include mixins.default-shadow;

  display: flex;
  flex-direction: column;
  background-color: colors-old.$back-color;
  color: colors-old.$menu-color;
  border-radius: 6px;
  overflow: hidden;
  font-size: 14px;
  font-weight: font.$weight-semi-bold;

  @each $key, $value in $max-height {
    &.max-h-#{$key} {
      max-height: $value;
    }
  }

  @each $key, $value in $width {
    &.w-#{$key} {
      width: $value;
    }
  }
}
</style>
