<script lang="ts" setup>
import {
  ComponentPublicInstance,
  FunctionalComponent,
  computed,
  h,
  nextTick,
  ref,
  watch,
} from "vue";

import { cardActions } from "@/action/cardActions";
import AttributeChip from "@/components-ng/StickyNote/components/AttributeChip/AttributeChip.vue";
import UsersListTooltip from "@/components-ng/StickyNote/components/StickyNoteFooter/components/NoteFooter/components/UsersListTooltip/UsersListTooltip.vue";
import StickyReactions from "@/components-ng/StickyNote/components/StickyNoteFooter/components/StickyReactions/StickyReactions.vue";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
// icons
import IconHot from "@/icons/misc/hot.svg?component";
import IconIncrement from "@/icons/misc/increment.svg?component";
import IconLove from "@/icons/misc/love.svg?component";
import IconThinEmoji from "@/icons/thin/emoji.svg?component";
import { Reaction, ReactionIcons, reactions } from "@/model/card";
import { animateCopy } from "@/utils/animation";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);

const stickyHasAllReactions = computed(() => {
  if (!card.reactions) return false;
  return Object.values(card.reactions).every((arr) => arr.length > 0);
});

const reactionIcons = ref<ReactionIcons>(
  Object.fromEntries(
    Object.values(reactions).map((key) => [key, null]),
  ) as ReactionIcons,
);

const setRef = (key: Reaction) => (cmp: ComponentPublicInstance) => {
  reactionIcons.value[key] = cmp;
};

const handleToggleReaction = async (reaction: Reaction) => {
  if (cardMeta.isReadonly) return;

  await cardActions.toggleReaction("card", card.id, reaction);
};

const icons: { [key in Reaction]: FunctionalComponent } = {
  hot: IconHot,
  increment: IconIncrement,
  love: IconLove,
};

const animateElement = (el: HTMLElement) => {
  for (let i = 0; i < 5; i++) {
    window.setTimeout(
      () =>
        animateCopy(
          el,
          "floating",
          (el) => (el.style.left = Math.random() * 500 - 250 + "px"),
        ),
      Math.random() * 150,
    );
  }
};

watch(
  () => card.reactions,
  (newReactions, oldReactions) => {
    if (!newReactions) return;
    Object.keys(newReactions).forEach(async (reaction) => {
      const oldUsers = oldReactions?.[reaction as Reaction] || [];
      const newUsers = newReactions[reaction as Reaction];

      if (newUsers.length > oldUsers.length) {
        await nextTick();
        const iconRef = (reactionIcons.value as ReactionIcons)[
          reaction as Reaction
        ]?.$el;
        if (iconRef) {
          animateElement(iconRef);
        }
      }
    });
  },
);
</script>

<template>
  <AttributeChip
    v-if="!stickyHasAllReactions && !cardMeta.isReadonly"
    name="user-reactions-chip"
    :tooltip="$t('cardAction.reactions')"
    :dropdown-props="{ class: 'user-reactions', width: 'auto' }"
  >
    <template #icon>
      <IconThinEmoji />
    </template>
    <StickyReactions class="sticky-reactions" />
  </AttributeChip>
  <template v-for="(users, reaction) in card.reactions" :key="reaction">
    <AttributeChip
      v-if="users.length > 0"
      :tooltip="h(UsersListTooltip, { card, reaction, icon: icons[reaction] })"
      :text="users.length.toString()"
      :dropdown-props="{ class: 'user-reaction-chip' }"
      :readonly="cardMeta.isReadonly"
      @click="handleToggleReaction(reaction)"
    >
      <template #icon>
        <component
          :is="icons[reaction]"
          :ref="setRef(reaction)"
          class="reaction-icon"
        />
      </template>
    </AttributeChip>
  </template>
</template>

<style lang="scss">
.base-popup.content.user-reactions {
  border-radius: 60px;
  overflow: visible;
}
</style>

<style lang="scss" scoped>
@use "@/styles/board";

@keyframes float {
  100% {
    transform: translateX(board.len(10px));
  }
}

.reaction-icon {
  position: absolute;
  opacity: 1;
  height: 80%;
  top: 10%;
  left: 8%;
}

.floating {
  width: 20px;
  height: 20px;
  animation: float 0.6s -0.3s alternate infinite ease-in-out;
  transform: translateX(board.len(-100px));
  transition-property: top, left, opacity;
  transition-duration: 2s;
  transition-timing-function: linear;
  top: board.len(-1000px) !important;
  opacity: 0;
}

.sticky-reactions {
  height: 36px;
}

.dropdown-menu.user-reaction-chip {
  overflow: visible;
}
</style>
