import { Ref, onMounted, ref } from "vue";

import { cardActions } from "@/action/cardActions";
import { registerCardDrag } from "@/components/card/cardDragHandler";
import { Card } from "@/model/card";

type Props = Readonly<{
  el?: Ref<HTMLDivElement | undefined>;
  card: Card;
  disabled: Ref<Readonly<boolean>>;
}>;

export function useDrag({ el, card, disabled }: Props) {
  onMounted(() => {
    if (disabled.value) return;
    el?.value?.addEventListener("pointerdown", handlePointerDown);
  });

  const isDragging = ref(false);

  const handlePointerDown = (event: PointerEvent) => {
    const target = event.target as HTMLElement;

    const isLinkDrag = target.classList.contains(".link-drag");
    if (disabled.value || isLinkDrag) return;

    registerCardDrag(card, onDragEnd, event);
    isDragging.value = true;
  };

  const onDragEnd = () => {
    cardActions.stopAlter("internal", card.id);
    isDragging.value = false;
  };

  return { isDragging };
}
