import { clamp, floor } from "lodash-es";

import { RiskScenarioRecommendation, TeamPattern } from "@/coconut/types";
import { countStickiesInTeam } from "@/coconut/utils/data";
import { calculateStandardDeviation } from "@/coconut/utils/statistics";
import { Team } from "@/model/session";
import { useBoardStore } from "@/store/board";
import { useSessionStore } from "@/store/session";

// 1. Distribution of work inside of a team [TEAM PATTERN]
// https://www.figma.com/file/QEGprRz74Z5m41MVuwRUI6/Plan-Analyzer?type=design&node-id=1579-13159&mode=design&t=aRI1tzOVjdTBnhBW-4

const distributionInTeam: TeamPattern = {
  id: 1,
  title: "Distribution in team",
  description: "",
  type: "team",
  weight: 1,
  process(team: Team) {
    const board = useBoardStore().boardByType("program");
    const iterations = useSessionStore().iterations;
    const teamData = countStickiesInTeam(team, board, iterations);

    const value = calculateStandardDeviation(Object.values(teamData));
    return [
      {
        patternId: this.id,
        scope: { id: team.id, type: "team" },
        title: this.title,
        description: this.description,
        weight: this.weight,
        score: floor(clamp(value * 100, 0, 100)),
        recommendations: this.recommendations,
      },
    ];
  },
};

export const recommendations: RiskScenarioRecommendation[] = [];

export default distributionInTeam;
