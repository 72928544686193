<script setup lang="ts">
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { useShortcuts } from "@/composables/useShortcuts";
import { Icon } from "@/types/icon";

defineProps<{ icon?: Icon; title: string }>();
const emit = defineEmits<{ close: [] }>();

const { globalShortcut } = useShortcuts();

globalShortcut("Escape", () => emit("close"));
</script>

<template>
  <aside
    class="side-panel-right"
    aria-labelledby="side-panel-title"
    @click.stop
  >
    <div class="header">
      <SvgIcon
        v-if="icon"
        :name="icon"
        width="20"
        height="20"
        class="panel-icon"
        aria-hidden="true"
      />
      <h4 id="side-panel-title" style="flex-grow: 1">{{ title }}</h4>
      <IconButton
        icon="menu-shift"
        data-testid="side-panel-close"
        :aria-label="$t('general.close')"
        @click="emit('close')"
      />
    </div>
    <div v-if="$slots['sub-title']" class="sub-header">
      <slot name="sub-title" />
    </div>
    <hr aria-hidden="true" />
    <div class="content scrollable">
      <slot></slot>
    </div>
  </aside>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/side-panel" as *;
@use "@/styles/mixins" as *;

.side-panel-right {
  @include side-panel;

  right: 0;

  @include default-shadow;

  &.v-enter-active,
  &.v-leave-active {
    transition: transform $panel-transition;
  }

  &.v-enter-from,
  &.v-leave-to {
    transform: translateX(100%);
    width: $panel-width;
  }
}
</style>
