<script setup lang="ts">
import { computed, ref } from "vue";

import { ApplicationError } from "@/ApplicationError";
import { currentRoute } from "@/router/navigation";
import { useGlobalErrorStore } from "@/store/globalError";

const showDetails = ref(false);

function reload() {
  location.reload();
}

const message = computed(() => {
  const error = useGlobalErrorStore().globalError;
  if (!error) {
    return "";
  }
  return (
    new Date(error.timestamp).toUTCString() +
    "\n" +
    window.location.href +
    "\n" +
    currentRoute()?.name?.toString() +
    "\n" +
    error.error.message +
    "\n" +
    error.message +
    "\n" +
    JSON.stringify((error.error as ApplicationError)?.contexts, null, 2)
  );
});
</script>

<template>
  <div v-if="message" class="global-error">
    <div class="content">
      <div class="text">
        {{ $t("globalError.somethingWrong") }}
        <br />
        <I18nT keypath="globalError.tryReload" scope="global">
          <template #reload>
            <a @click="reload">{{ $t("globalError.tryReload.reload") }}</a>
          </template>
        </I18nT>
        <br />
        {{ $t("globalError.informAdmin") }}
      </div>
      <div class="info">
        <a @click="showDetails = !showDetails">{{
          $t("globalError.technicalDetails")
        }}</a>
      </div>
      <textarea
        v-if="showDetails"
        class="scrollable"
        :value="message"
        readonly
      />
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/modal" as *;

.global-error {
  @include modal-background;

  .content {
    position: fixed;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: colors-old.$back-color;
    width: 500px;
    padding: 20px;

    .text {
      line-height: 1.5em;
    }

    .info {
      margin-top: 20px;
      font-size: 80%;
    }

    .scrollable {
      position: relative;
    }

    textarea {
      margin-top: 20px;
      font-size: 80%;
      width: 400px;
      height: 200px;
      text-align: left;
    }

    a {
      text-decoration: underline;
      color: colors-old.$primary-color;
      cursor: pointer;
    }
  }
}
</style>
