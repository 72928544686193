<script setup lang="ts">
import DropdownMenu from "./DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "./DropdownMenu/DropdownTrigger.vue";
import ListItem from "./ListItem/ListItem.vue";

const props = defineProps<{ modelValue: string; triggerLabel?: string }>();
const emit = defineEmits<{ "update:modelValue": [font: string] }>();

const fonts = ["Mulish", "Caveat", "Natural", "Child", "Lefthand", "Bedtime"];

function select(font: string) {
  emit("update:modelValue", font);
}
</script>

<template>
  <DropdownMenu
    class="settings-font-dropdown"
    :offset="{ top: 15 }"
    :disable-teleport="true"
    :is-aria-menu="true"
  >
    <template #trigger>
      <DropdownTrigger
        :aria-label="props.triggerLabel"
        :value="modelValue"
        :style="{ fontFamily: modelValue }"
      />
    </template>
    <div v-keyboard-nav @keydown.escape.stop>
      <ListItem
        v-for="font in fonts"
        :key="font"
        :style="{ fontFamily: font }"
        @click="select(font)"
      >
        {{ font }}
      </ListItem>
    </div>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
.settings-font-dropdown {
  .dropdown-trigger > :deep(span:first-child) {
    width: 80px;
    font-family: inherit;
  }
}
</style>
