<template>
  <side-panel-right :title="$t('gptPanel.title')" @close="close">
    <div class="gpt-panel-body">
      <h1>{{ objectiveTitle }}</h1>
    </div>
  </side-panel-right>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";

import RightSidePanel from "@/mixins/RightSidePanel";
import { useGPTStore } from "@/store/gpt";

import SidePanelRight from "./SidePanelRight.vue";

@Component({ components: { SidePanelRight } })
export default class GPTPanel extends mixins(RightSidePanel) {
  close() {
    useGPTStore().collapseGPT();
  }

  get objectiveTitle() {
    return useGPTStore().objective !== null
      ? useGPTStore().objective?.text
      : "";
  }
}
</script>
<style lang="scss">
.gpt-panel-body {
  padding: 16px;
}
</style>
