<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { cardActions } from "@/action/cardActions";
import { getBoardIdsOfGroupedStickies } from "@/backend/Backend";
import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import {
  boardKey,
  cardKey,
  cardMetaKey,
  cardMethodsKey,
} from "@/components/card/injectKeys";
import { isFeatureEnabled } from "@/feature";
import { boardIcon, boardTypeName } from "@/model/baseTypeI18n";
import type { MirrorableBoard } from "@/model/board";
import { currentRoute } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { stickyNoteMirrored } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/context";

const boardsStickyMirroredTo = ref<Array<string>>([]);

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const cardMeta = injectStrict(cardMetaKey);
const dropdown = injectStrict(dropdownKey);
const cardMethods = injectStrict(cardMethodsKey);

const emit = defineEmits<{ mirror: [] }>();

const props = defineProps<{
  trigger: "action-menu" | "attribute-chip"; // used for event tracking analytics
}>();

const { t } = useI18n();

onMounted(async () => {
  boardsStickyMirroredTo.value = await getBoardIdsOfGroupedStickies(card.id);
});

const boards = computed(() => useBoardStore().mirrorTargetBoards([card]));

const boardName = (board: MirrorableBoard): string => {
  return board.type === "team" ? board.team.name : t(boardTypeName(board.type));
};

const mirrorToBoard = async (targetBoard: MirrorableBoard) => {
  if (cardMeta.isReadonly) return;
  if (isAlreadyMirrored(targetBoard.id)) return;

  boardsStickyMirroredTo.value.push(targetBoard.id);

  trackEvent(
    stickyNoteMirrored(
      card.type.functionality,
      board.value.type,
      targetBoard.type,
      props.trigger,
    ),
  );

  await cardActions.mirror("card", card.id, card.teamId, targetBoard);
  emit("mirror");

  dropdown.close();
  if (isFeatureEnabled(currentRoute(), "sticky-note")) {
    cardMethods.animate?.("mirroring");
  } else {
    cardMethods.animateCopy?.("mirroring");
  }
};

const isAlreadyMirrored = (boardId: string) => {
  return boardsStickyMirroredTo.value.includes(boardId);
};
</script>

<template>
  <BaseList
    class="mirror-sticky"
    role="dialog"
    aria-labelledby="mirror-sticky-title"
  >
    <BaseListTitle id="mirror-sticky-title">
      {{ $t("cardAction.mirror") }}
    </BaseListTitle>
    <BaseListItems>
      <BaseListItem
        v-for="targetBoard in boards"
        :key="targetBoard.id"
        :data-testid="`mirror-sticky-list-item-${targetBoard.id}`"
        :class="{ disabled: isAlreadyMirrored(targetBoard.id) }"
        :static="cardMeta.isReadonly || isAlreadyMirrored(targetBoard.id)"
        @click="mirrorToBoard(targetBoard)"
      >
        <template #before>
          <SvgIcon width="20" height="20" :name="boardIcon(targetBoard)" />
        </template>
        <span>{{ boardName(targetBoard) }}</span>
        <template #after>
          <template v-if="isAlreadyMirrored(targetBoard.id)">
            <SvgIcon width="20" height="20" name="check" />
            {{ $t("actionMenu.mirrored") }}
          </template>
          <template v-else-if="!cardMeta.isReadonly">
            <span class="mirror">
              {{ $t("cardAction.mirror") }}
            </span>
          </template>
        </template>
      </BaseListItem>
    </BaseListItems>
  </BaseList>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.mirror-sticky {
  .disabled {
    pointer-events: none;
    cursor: default;
  }

  .after {
    display: flex;
    align-items: center;
  }

  .mirror {
    color: colors-old.$primary-color;
  }
}
</style>
