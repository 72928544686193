<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";

import { boardActions } from "@/action/boardActions";
import { Card } from "@/model/card";
import { isWorkitem } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { useSearchMenuStore } from "@/store/searchMenu";

import { useRiskAnalysisStore } from "../store/riskAnalysis";
import RiskItems from "./RiskItems/RiskItems.vue";
import RiskScenarioInfo from "./RiskScenarios/RiskScenarioInfo.vue";
import RiskScenarios from "./RiskScenarios/RiskScenarios.vue";
import RiskScoreOverview from "./RiskScoreOverview/RiskScoreOverview.vue";
import RiskSidePanelHeader from "./RiskSidePanelHeader.vue";
import SidePanelResizable from "./SidePanelResizable.vue";
import StickyNoteRiskHighlight from "./StickyNoteRiskHighlight.vue";
import TopBar from "./TopBar.vue";
import WeightsConfig from "./WeightsConfig.vue";

const riskStore = useRiskAnalysisStore();
const boardStore = useBoardStore();
const searchMenuStore = useSearchMenuStore();

const { totalRiskScore, riskItems, selectedRiskScenario, selectedRiskItem } =
  storeToRefs(riskStore);

// close the sidebar when the board changes
watch(
  () => boardStore.board,
  () => (riskStore.isSidebarOpen = false),
);

// deselect all items when the sidebar is closed
watch(
  () => riskStore.isSidebarOpen,
  (val) => {
    if (val) return;
    riskStore.setSelectedRiskItemId(null);
    riskStore.setSelectedRiskScenarioId(null);
    // TODO: unsubscribe from ai messages too
  },
);

// close the sidebar when the search is active
watch(
  () => searchMenuStore.isSearchActive,
  (val) => {
    if (!val) return;
    riskStore.isSidebarOpen = false;
  },
);

const handleRiskScenarioSelected = (id: number | null) => {
  riskStore.selectedRiskScenarioId = id;
};

const riskHeader = computed(() => {
  if (selectedRiskScenario.value) {
    return "Back to Risk Scenarios";
  }

  if (selectedRiskItem.value && !selectedRiskScenario.value) {
    return "Back to Risk Overview";
  }

  return "Plan Analyzer";
});

const handleNavigateBack = () => {
  if (selectedRiskScenario.value) {
    riskStore.setSelectedRiskScenarioId(null);
    return;
  }

  if (selectedRiskItem.value) {
    riskStore.setSelectedRiskItemId(null);
    riskStore.resetMessages();
  }
};

const handleRiskItemHovered = (id: string | null) => {
  riskStore.hoveredRiskItemId = id;
};

const handleRiskItemtSelected = (id: string) => {
  const board = boardStore.currentBoard();
  riskStore.hoveredRiskItemId = null;

  riskStore.setSelectedRiskItemId(id);
  boardActions.cardToFront("internal", id, board.id);
};

const isHighlightVisible = (sticky: Card) => {
  const eid = boardStore.enlargedStickyNoteId;
  const sid = riskStore.selectedRiskItemId;

  if (!eid && !sid && isWorkitem(sticky)) return true;
  if (sid === sticky.id) return true;
  return false;
};
</script>

<template>
  <TopBar v-if="riskStore.isSidebarOpen" />
  <SidePanelResizable
    v-if="riskStore.isSidebarOpen"
    v-scrollable-on-board
    class="risk-panel"
  >
    <RiskSidePanelHeader
      :title="riskHeader"
      :hide-back-button="!selectedRiskItem"
      @back="handleNavigateBack"
    />
    <div class="main">
      <WeightsConfig
        v-if="riskStore.isConfigOpen"
        :patterns="riskStore.patterns"
      />

      <template v-for="item in riskItems" :key="item.sticky.data.id">
        <template v-if="isHighlightVisible(item.sticky.data)">
          <Teleport :to="`[id=\'${item.sticky.data.id}\']`">
            <StickyNoteRiskHighlight
              :selected-risk-item="selectedRiskItem"
              :pulse="item.sticky.data.id === riskStore.hoveredRiskItemId"
              :score="item.totalScore"
            />
          </Teleport>
        </template>
      </template>

      <RiskItems
        v-if="!selectedRiskItem"
        :risk-items="riskItems"
        @item-selected="handleRiskItemtSelected"
        @item-hovered="handleRiskItemHovered"
      />
      <RiskScenarios
        v-if="selectedRiskItem && !selectedRiskScenario"
        :selected-risk-item="selectedRiskItem"
        @item-selected="handleRiskScenarioSelected"
      />

      <RiskScenarioInfo
        v-if="selectedRiskScenario && selectedRiskItem"
        :selected-risk-item="selectedRiskItem"
        :scenario="selectedRiskScenario"
      />
    </div>
    <RiskScoreOverview
      :total-score="totalRiskScore"
      :selected-risk-item="selectedRiskItem"
      :selected-risk-scenario="selectedRiskScenario"
    />
  </SidePanelResizable>
</template>

<style lang="scss" scoped>
.risk-panel {
  display: grid;
  grid-template-rows: auto 1fr auto;

  .main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
</style>
