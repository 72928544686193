<script lang="ts" setup>
import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import { AuthUser } from "@/model/user";

import AIUserAvatar from "./AIUserAvatar.vue";

const buddies = [
  { name: "Risky Rick", imageUrl: "avatars/risky-rick.png" },
  { name: "Searching Sue", imageUrl: "avatars/searching-sue.png" },
  { name: "Planning Pete", imageUrl: "avatars/planning-pete.png" },
  { name: "History Harry", imageUrl: "avatars/history-harry.png" },
  {
    name: "We’re recruiting new buddies...",
    imageUrl: "avatars/placeholder.png",
  },
] as AuthUser[];

defineEmits<{ selected: [] }>();
</script>

<template>
  <BaseList class="list">
    <BaseListTitle>
      <span>Choose Buddy</span>
    </BaseListTitle>
    <BaseListItem
      v-for="(buddy, i) in buddies"
      :key="i"
      :static="i !== 0"
      @click="$emit('selected')"
    >
      <AIUserAvatar
        :user="buddy"
        :class="{ disabled: i === buddies.length - 1 }"
      />
    </BaseListItem>
  </BaseList>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";

.main {
  width: 100%;

  .placeholder {
    color: colors.$text-placeholder;
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  filter: grayscale(1);
  color: colors.$text-subtle;
}
</style>
