import { Color } from "@/model/baseTypes";
import color from "@/styles/color.module.scss";

import { colorPalette } from "./colorPalette";
import { toHex } from "./general";

export const black: Color = [0, 0, 0, 1];
export const white: Color = [1, 1, 1, 1];

export function toCssColor(c: Color): string {
  const hex = (v: number) => toHex(Math.round(v), 2);
  return (
    "#" + hex(c[0] * 255) + hex(c[1] * 255) + hex(c[2] * 255) + hex(c[3] * 255)
  );
}

/**
 * Converts a hexadecimal color code to an RGB color representation.
 *
 * @param hex - The hexadecimal color code to convert.
 * @returns The RGB color representation as an array of numbers.
 */
export function fromCssColor(hex: string): Color {
  const normalizedHex = hex.replace(/^#/, "");
  const r = parseInt(normalizedHex.substring(0, 2), 16) / 255;
  const g = parseInt(normalizedHex.substring(2, 4), 16) / 255;
  const b = parseInt(normalizedHex.substring(4, 6), 16) / 255;
  return [r, g, b, 1];
}

export function colorFromNumber(h: number): string {
  // omit grays and the first shade of every color
  const a = Math.abs(h);
  const hue = a % 6;
  const shade = (Math.floor(a / 6) % 3) + 1;
  return colorPalette[hue * 4 + shade].color;
}

export function isDarkColor(c: Color): boolean {
  return brightness(c) < 0.55;
}

export function contrastCssColor(c: Color): string {
  return isDarkColor(c) ? color.back : color.textPrimary;
}

export function shadeColor(c: Color, amount = 0.25): Color {
  return isDarkColor(c) === amount > 0
    ? interpolate(c, white, Math.abs(amount))
    : interpolate(c, black, Math.abs(amount));
}

function brightness(c: Color): number {
  return c[0] * 0.299 + c[1] * 0.587 + c[2] * 0.114;
}

function interpolate(c: Color, d: Color, f: number): Color {
  return [
    c[0] + (d[0] - c[0]) * f,
    c[1] + (d[1] - c[1]) * f,
    c[2] + (d[2] - c[2]) * f,
    c[3],
  ];
}

/**
 * Converts a hexadecimal color code to an RGB color representation.
 *
 * @param hex - The hexadecimal color code to convert.
 * @returns The RGB color representation as an array of numbers.
 */
export function hexToColor(hex: string): Color {
  const normalizedHex = hex.replace(/^#/, "");
  const r = parseInt(normalizedHex.substring(0, 2), 16) / 255;
  const g = parseInt(normalizedHex.substring(2, 4), 16) / 255;
  const b = parseInt(normalizedHex.substring(4, 6), 16) / 255;
  return [r, g, b, 1];
}

// convers hex to rgba color. First param is the hex color and the second is the alpha value
export function hexToRgba(hex: string, alpha: number): string {
  const color = hexToColor(hex);
  return `rgba(${color[0] * 255}, ${color[1] * 255}, ${color[2] * 255}, ${alpha})`;
}
