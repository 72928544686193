<template>
  <button
    :id="optionId"
    ref="main"
    class="category item hoverable"
    :class="{ selected: current, all: !cat.id }"
    :aria-label="cat.name"
    @keydown="keyDownListener"
    @focus="focusedElement = 'main'"
  >
    <SvgIcon
      v-if="!readOnly"
      name="arrow/up-down"
      width="20"
      height="20"
      class="hover-icon"
    />
    <div class="text">
      <input-field
        :value="cat.name"
        :max-length="30"
        :read-only="readOnly || !cat.id || !editing"
        underline
        tabindex="-1"
        :aria-label="$t('label.flexModal.categoryName')"
        @change="setName"
        @blur="editing = false"
        @keydown.stop
      />
      <SvgIcon
        v-show="!readOnly && editing"
        name="check"
        width="20"
        height="20"
      />
    </div>
    <IconButton
      v-if="!readOnly"
      ref="edit"
      icon="pen"
      width="20"
      height="20"
      class="action hover-icon button"
      tabindex="-1"
      :aria-label="$t('label.flexModal.categoryEdit', { value: cat.name })"
      @click.stop="editMode"
    />
    <IconButton
      v-if="!readOnly"
      ref="delete"
      icon="action-menu/delete"
      width="20"
      height="20"
      class="action hover-icon button"
      tabindex="-1"
      :aria-label="$t('label.flexModal.categoryDelete', { value: cat.name })"
      @click.stop="remove"
    />
    <IconButton
      v-show="showMoveUp"
      ref="moveUp"
      icon="arrow/up"
      width="20"
      height="20"
      class="action hover-icon button sr-only"
      tabindex="-1"
      :aria-label="$t('objective.moveUp')"
      @click.stop="$emit('moveUp')"
    />
    <IconButton
      v-show="showMoveDown"
      ref="moveDown"
      icon="arrow/down"
      width="20"
      height="20"
      class="action hover-icon button sr-only"
      tabindex="-1"
      :aria-label="$t('objective.moveDown', { value: cat.name })"
      @click.stop="$emit('moveDown')"
    />
  </button>
</template>

<script lang="ts">
import { clamp } from "lodash-es";
import { Options as Component, Vue } from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

import { categoryActions } from "@/action/categoryActions";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import InputField from "@/components/input/InputField.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { Category } from "@/model/flexboard";

@Component({
  components: { SvgIcon, InputField, IconButton },
  emits: ["delete", "moveUp", "moveDown"],
})
export default class FlexCategoryElem extends Vue {
  @Prop(Object) readonly cat!: Category;
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop(Boolean) readonly current!: boolean;
  @Prop(Number) readonly listLength!: number;
  @Prop(Number) readonly position!: number;

  @Ref("main") readonly main!: HTMLElement;
  @Ref("edit") readonly edit!: HTMLElement;
  @Ref("delete") readonly delete!: HTMLElement;
  @Ref("moveUp") readonly moveUp!: HTMLElement;
  @Ref("moveDown") readonly moveDown!: HTMLElement;

  editing = false;

  // Tracks where the focus is for keyboard users (allowing them to move
  // through the various buttons nested in this element)
  focusableElements = ["main", "edit", "delete", "moveUp", "moveDown"] as const;
  focusedElement: (typeof this.focusableElements)[number] = "main";

  mounted() {
    if (!this.readOnly && this.cat.fresh) {
      this.cat.fresh = false;
      this.editMode();
    }
  }

  remove() {
    this.$emit("delete", this.cat);
  }

  setName(name: string) {
    categoryActions.update("modal", this.cat.id, { name });
  }

  editMode() {
    if (this.editing) {
      this.editing = false;
    } else {
      this.editing = true;
      // let the input-field update its readOnly state before invoking click on it
      this.$nextTick(() => {
        const input = document.querySelector(
          "#" + this.optionId + " input",
        ) as HTMLInputElement;
        input.focus();
        input.click();
      });
    }
  }

  /**
   * Handle left/right arrow key presses on this component (moving focus internally)
   */
  keyDownListener(event: KeyboardEvent) {
    // The 'All' option has no nested buttons, and readonly users don't see the buttons either
    if (this.$el.classList.contains("all") || this.readOnly) {
      return;
    }

    if (event.key === "ArrowRight") {
      this.moveInternalFocus(1);
      event.stopPropagation();
      event.preventDefault();
    }

    if (event.key === "ArrowLeft") {
      this.moveInternalFocus(-1);
      event.stopPropagation();
      event.preventDefault();
    }
  }

  /**
   * Move focus one element to the right (+1) or left (-1) of where it currently is
   * (allows keyboard users to access the buttons nested within this component)
   */
  moveInternalFocus(change: -1 | 1) {
    const i = this.focusableElements.indexOf(this.focusedElement);
    const newIndex = clamp(i + change, 0, this.focusableElements.length - 1);
    this.focusedElement = this.focusableElements[newIndex];
    this[this.focusedElement]?.focus();
  }

  get optionId() {
    return "flexModal-category-" + this.cat.id;
  }

  get showMoveUp() {
    // 'All' has index of 0
    return !this.readOnly && this.position > 1;
  }

  get showMoveDown() {
    return !this.readOnly && this.position < this.listLength - 1;
  }
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.category {
  position: relative;
  height: 35px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.1em solid colors-old.$divider-color;
  outline: revert;

  // Only highlight focused item when user is interacting with the list
  &:not(:focus-within).current {
    background: none;
  }

  .hover-icon {
    opacity: 0;

    &:focus-visible {
      opacity: 1;
    }

    &.button {
      padding: 5px;
    }

    &:last-child {
      margin-right: 5px;
    }

    &.sr-only {
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0;
      z-index: z-index.$a11y-hidden;

      &:focus-visible {
        opacity: 1;
        z-index: z-index.$low;
        background-color: colors.$background-grey-light;
      }
    }
  }

  &.all .hover-icon {
    display: none;
  }

  &:hover,
  &:focus-within,
  &:focus-visible {
    &:not(.drag-category-over, .all) .hover-icon:not(.sr-only) {
      opacity: 1;
    }
  }

  &.drag-board-over {
    border: 2px solid colors-old.$menu-color;
  }

  .text {
    display: flex;
    align-items: center;

    input {
      padding-right: 10px;
      border-bottom-style: none;
    }
  }

  & > :nth-child(2) {
    flex-grow: 1;
  }
}
</style>
