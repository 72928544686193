<template>
  <textarea
    v-if="edit && editable"
    ref="text"
    class="card-text-input"
    :maxlength="maxLength"
    :value="value"
    :style="{ fontSize: fontSize + '%' }"
    @blur="requestEdit(false)"
    @input="update"
    @keydown="keyDown"
    @pointerdown.stop
  >
  </textarea>
  <div
    v-else
    class="card-text-input"
    :style="{ fontSize: fontSize + '%' }"
    @click="requestEdit(true, $event)"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-for="(line, index) in lines" :key="index" v-html="line" />
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { accept } from "@/Shortcuts";
import { cardActions } from "@/action/cardActions";
import { replaceEmoji } from "@/emojiReplacer";
import { optimalFontSize } from "@/fontSizeOptimizer";
import EventBusUser from "@/mixins/EventBusUser";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useZoomStore } from "@/store/zoom";
import { removeNonPrintable } from "@/utils/general";

import { TextFontData } from "./fontSizeCache";

@Component({ emits: ["requestEdit", "input"] })
export default class CardTextInput extends mixins(EventBusUser) {
  @Prop(String) readonly value!: string;
  @Prop(String) readonly allowed?: string;
  @Prop(Number) readonly maxLength!: number;
  @Prop(Boolean) readonly edit!: boolean;
  @Prop(Boolean) readonly newlines!: boolean;
  @Prop(Boolean) readonly editable!: boolean;

  $refs!: {
    text: HTMLTextAreaElement;
  };

  fontSize = 300;
  lines = new Array<string>();

  @Watch("edit")
  editChanged(edit: boolean, old: boolean) {
    if (edit !== old) {
      if (edit && this.editable) {
        // wait for textarea to be rendered in DOM
        this.$nextTick(() => this.$refs.text.focus());
      }
    }
  }

  @Watch("values", { deep: true })
  calcFontSize() {
    // let the DOM update before calculating the font size
    this.$nextTick(() => {
      if (this.$el && this.$el.clientHeight > 0) {
        optimalFontSize(this.$el as HTMLElement, this.value, this.edit).then(
          (fontData) => {
            this.fontSize = fontData.size;
            if (!this.edit) {
              this.lines = (fontData as TextFontData).htmlLines;
            }
          },
        );
      }
    });
  }

  get values() {
    return [
      this.edit,
      this.value,
      useClientSettingsStore().textScale,
      useClientSettingsStore().stickyFont,
    ];
  }

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.onBoardSwitch(check);
    check();

    function check() {
      doCheck(30);

      function doCheck(count: number) {
        if (that.$el && that.$el.clientHeight > 0) {
          that.calcFontSize();
        } else if (count > 0) {
          // TODO is this needed anymore?
          window.setTimeout(() => doCheck(count - 1), 40);
        }
      }
    }
  }

  requestEdit(edit: boolean, e?: Event) {
    if (useZoomStore().zooming) {
      return;
    }
    // ignore clicks on links inside the text
    if (e && (e.target as HTMLElement)?.nodeName === "A") {
      e.stopPropagation();
    } else {
      this.$emit("requestEdit", edit);
    }
  }

  keyDown(e: KeyboardEvent) {
    if (useBoardStore().magnifying && e.key === " ") {
      e.preventDefault();
    }
    if (accept(cardActions.duplicate.data.shortcut!.key)(e)) {
      cardActions.duplicate("keyboard");
      e.preventDefault();
    }
    if (this.allowed && e.key.length === 1 && this.allowed.indexOf(e.key) < 0) {
      e.preventDefault();
    }
    if (
      e.key === "Escape" ||
      (e.key === "Enter" && (!this.newlines || (!e.altKey && !e.shiftKey)))
    ) {
      e.preventDefault();
      this.requestEdit(false);
    }
  }

  sanitize(value: string) {
    if (!this.newlines) {
      value = removeNonPrintable(value);
    }
    return value;
  }

  update() {
    const textElem = this.$refs.text;
    textElem.value = this.sanitize(textElem.value);
    const replaced = replaceEmoji(textElem.value);
    if (replaced) {
      const cursor = textElem.selectionStart;
      this.$emit("input", replaced.text);
      this.$nextTick(() => {
        textElem.selectionStart = cursor - replaced.deltaLen;
        textElem.selectionEnd = cursor - replaced.deltaLen;
      });
    } else {
      this.$emit("input", textElem.value);
    }
  }
}
</script>

<style lang="scss">
@use "sass:math";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.card-text-input {
  font-family: var(--card-font);
  line-height: 1.2;
}

textarea.card-text-input {
  user-select: auto;
  border: none;
  padding: 0;
  resize: none;
  background-color: colors-old.$active-textarea-color;
  overflow: hidden;
  cursor: text;
  transform: scale(variables.$edit-zoom);
  transform-origin: top left;
  width: math.div(100%, variables.$edit-zoom);
  height: math.div(100%, variables.$edit-zoom);

  &:focus {
    outline: none;
  }
}

div.card-text-input {
  overflow: hidden;
  white-space: pre;
  width: 100%;
  height: 100%;

  & > div {
    font-family: inherit;
  }
}
</style>
