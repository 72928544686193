<template>
  <div :id="board.id" class="board board-flex">
    <loading-indicator v-if="!board.loaded" global />
    <link-layers :board="board" :color="linkColor" />
    <FlexBackground :type="background" class="background" />
    <template v-if="isNewStickyNoteEnabled">
      <StickyNote
        v-for="card in board.cards"
        :key="card.data.id"
        :card="card.data"
        :card-meta="card.meta"
        :level-of-details="levelOfDetails"
      />
    </template>
    <template v-else>
      <card
        v-for="card in board.cards"
        :key="card.data.id"
        :draggable="!readOnly"
        :card="card.data"
        :meta="card.meta"
        :color="card.data.type.color"
        :link-color="linkColor"
        :height="board.cardSize.y * height"
        :width="board.cardSize.x * width"
        :board="board"
        :board-width="width"
        :board-height="height"
        :actions="actions"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";

import StickyNote from "@/components-ng/StickyNote/StickyNote.vue";
import { isFeatureEnabled } from "@/feature";
import { normalLinkColors } from "@/model/colors";
import { RelativeCoordinate } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";

import FluidBoard, { ContextInfo } from "./FluidBoard";
import LinkLayers from "./LinkLayers.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import FlexBackground from "./background/FlexBackground.vue";
import { ActionType } from "./card/actions";

@Component({
  components: { LinkLayers, LoadingIndicator, FlexBackground, StickyNote },
})
export default class FlexBoard extends mixins(FluidBoard) {
  linkColor = normalLinkColors.flex;
  actions: ActionType[] = ["delete", "link", "dragLink", "close"];

  contextActions(_c?: RelativeCoordinate): ContextInfo {
    return {
      syncProgramBacklog: false,
      draw: true,
      selection: {
        stickyMove: true,
        link: false,
        mirror: false,
        team: false,
      },
    };
  }

  get isNewStickyNoteEnabled() {
    return isFeatureEnabled(this.$route, "sticky-note");
  }

  get board() {
    return useBoardStore().boardByType("flex");
  }

  get background() {
    return this.board.flexType?.background;
  }
}
</script>

<style lang="scss">
.board-flex {
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
