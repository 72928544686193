import { sortBy } from "lodash-es";
import { defineStore } from "pinia";

import { Id } from "@/model/baseTypes";
import { BoardId, BoardWithObjectives, isBacklogBoard } from "@/model/board";
import { Card } from "@/model/card";
import { LinkableCardTree } from "@/model/link";
import { Objective, ObjectiveType, ObjectiveUpdate } from "@/model/objective";
import { Team } from "@/model/session";
import { isDependency } from "@/model/stickyType";
import { captureMessage } from "@/sentry";
import { objectId } from "@/utils/objectId";

import { useBoardStore } from "./board";
import { isCardFromOtherTeam, useCardStore } from "./card";
import { getLinkTargetId, useLinkStore } from "./link";

export const useObjectiveStore = defineStore("objective", {
  getters: {
    objectiveById() {
      return (
        objectiveId: Objective["id"],
        { teamId }: { teamId?: Team["id"] } = {},
      ): Objective | undefined => {
        const board = useBoardStore().boardByType("team", { teamId });
        const objectives = [
          ...(board.objectives ?? []),
          ...(board.stretchObjectives ?? []),
        ];

        return objectives.find((objective) => objective.id === objectiveId);
      };
    },
    objectiveTypeById() {
      return (
        objectiveId: Objective["id"],
        { teamId }: { teamId?: Team["id"] } = {},
      ): ObjectiveType | undefined => {
        const objective = this.objectiveById(objectiveId, { teamId });
        if (!objective) return;
        const board = useBoardStore().boardByType("team", { teamId });
        const isInObjectives = board.objectives.some(
          (objective) => objective.id === objectiveId,
        );
        return isInObjectives ? "committed" : "uncommitted";
      };
    },
    linkedCardTree() {
      return (
        objective: Objective,
        { teamId }: { teamId?: Team["id"] },
      ): LinkableCardTree => {
        const indirectIds = new Set<string>();
        const cards = sorted(
          objective.cards.flatMap((ref) => {
            const card = useCardStore().cards[ref.id];
            if (!card || !ref.isOrigin) {
              return [];
            }
            const childIds = linkedToCard(card).map((card) => card.id);
            childIds.forEach((id) => indirectIds.add(id));
            return { ...card, linked: true, childIds };
          }),
        );
        cards.forEach((card) => indirectIds.delete(card.id));

        const count = cards.length + indirectIds.size;
        return { cards, indirectLinkedIds: [...indirectIds], count };

        function linkedToCard(card: Card) {
          return sorted(
            useLinkStore()
              .linksByCard(card.id)
              .flatMap((link) => {
                const linkedId = getLinkTargetId(card, link);
                const linkedCard = useCardStore().cards[linkedId];
                return linkedCard &&
                  !(
                    isDependency(card) &&
                    isCardFromOtherTeam(linkedCard, teamId)
                  )
                  ? linkedCard
                  : [];
              }),
          );
        }

        function sorted<T extends Card>(cards: T[]) {
          return sortBy(cards, (card) =>
            isBacklogBoard(card.type.origin) ? 1 : isDependency(card) ? 2 : 3,
          );
        }
      };
    },
  },
  actions: {
    add(e: Partial<Objective> & BoardId, isCommitted = true) {
      const board = useBoardStore().boards[e.boardId] as BoardWithObjectives;
      const id = e.id || objectId();
      const text = e.text ? e.text : "";
      const description = e.description ? e.description : "";
      const obj = { id, text, bv: 0, av: null, description, cards: [] };
      (isCommitted ? board.objectives : board.stretchObjectives).unshift(obj);
      return obj;
    },
    remove(item: Id & BoardId) {
      const boardId = item.boardId || useBoardStore().boardId();
      const board = useBoardStore().boards[boardId] as BoardWithObjectives;
      const objectiveIndex = board.objectives.findIndex(
        (o) => o.id === item.id,
      );

      if (objectiveIndex > -1) {
        board.objectives.splice(objectiveIndex, 1);
      }

      const stretchObjectiveIndex = board.stretchObjectives.findIndex(
        (o) => o.id === item.id,
      );
      if (stretchObjectiveIndex > -1) {
        board.stretchObjectives.splice(stretchObjectiveIndex, 1);
      }
    },
    move(e: Id & BoardId & { stretch: boolean; rank: number }) {
      const boardId = e.boardId || useBoardStore().boardId();
      const board = useBoardStore().boards[boardId] as BoardWithObjectives;
      const { objective, objectives, pos } = objectiveById(board, e.id);
      if (!objective) {
        captureMessage("Move of unknown objective", { info: { data: e } });
        return;
      }
      objectives.splice(pos, 1);
      const target = e.stretch ? board.stretchObjectives : board.objectives;
      target.splice(e.rank, 0, objective);
    },
    update(e: Id & BoardId & ObjectiveUpdate) {
      const board = useBoardStore().boards[e.boardId] as BoardWithObjectives;
      const { objective } = objectiveById(board, e.id);
      if (!objective) {
        if (e.text === "") {
          this.add(e);
        } else {
          captureMessage("Update of unknown objective", { info: { data: e } });
        }
        return;
      }

      objective.text = e.text ?? objective.text;
      objective.description = e.description ?? objective.description;
      objective.bv = e.bv ?? objective.bv;
      objective.av = e.av ?? objective.av;
      objective.cards = e.cards || objective.cards;
    },
  },
});

function objectiveById(
  board: BoardWithObjectives,
  id: string,
): {
  objective: Objective;
  objectives: Objective[];
  pos: number;
} {
  let objectives = board.objectives;
  let pos = objectives.findIndex((o) => o.id === id);
  if (pos < 0) {
    objectives = board.stretchObjectives;
    pos = objectives.findIndex((o) => o.id === id);
  }
  return { objective: objectives[pos], objectives, pos };
}
