<script setup lang="ts">
import { computed, ref, useSlots } from "vue";

import DropdownMenu, {
  type DropdownMenuProps,
} from "@/components/DropdownMenu/DropdownMenu.vue";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import { stickyNoteActionMenuItemClicked } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/context";

const board = injectStrict(boardKey);
const card = injectStrict(cardKey);

const props = defineProps<{
  active?: boolean;
  tooltipText?: string;
  width?: DropdownMenuProps["width"];
  maxHeight?: DropdownMenuProps["maxHeight"];
  disabled?: boolean;
  name: string;
  ariaLabel?: string;
  ariaControls?: string;
  dropdownProps?: Partial<DropdownMenuProps>;
}>();

const slots = useSlots();

const isDropdownOpen = ref(false);
const isActive = computed(() => {
  return props.active || (isDropdownOpen.value && slots.dropdown);
});

const handleClick = () => {
  trackEvent(
    stickyNoteActionMenuItemClicked(
      props.name,
      card.type.functionality,
      board.value.type,
    ),
  );
};
</script>

<template>
  <div ref="rootRef" class="action-menu-item" @click="handleClick">
    <DropdownMenu
      stay-open-on-content-click
      disable-teleport
      :disabled="disabled"
      :width="width || 'md'"
      :max-height="maxHeight || 'md'"
      @open="isDropdownOpen = true"
      @close="isDropdownOpen = false"
    >
      <template #trigger>
        <BaseTooltip :delay="[500, 0]" :offset="[0, 8]" class="tooltip">
          <button
            :class="['content', { active: isActive }]"
            :disabled="disabled"
            :aria-label="ariaLabel"
            :aria-expanded="!!$slots.dropdown ? isDropdownOpen : undefined"
            :aria-controls="ariaControls"
          >
            <slot />
          </button>
          <template v-if="tooltipText" #content>{{ tooltipText }}</template>
        </BaseTooltip>
      </template>

      <div v-if="$slots.dropdown" class="wrapper">
        <slot name="dropdown" />
      </div>
    </DropdownMenu>

    <div v-if="$slots.dropdown" class="dropdown-icon">
      <svg
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.99993 1.06863e-10C5.55221 -7.98692e-06 5.99993 0.447701 5.99994 0.999986L6 4.99999C6 5.2652 5.89465 5.51956 5.70711 5.7071C5.51958 5.89464 5.26522 6 5 6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H3.99999L3.99994 1.00001C3.99993 0.44773 4.44764 7.98713e-06 4.99993 1.06863e-10Z"
          fill="white"
          fill-opacity="0.5"
        />
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@/styles/variables/colors";

.action-menu-item {
  position: relative;
  height: 36px;
  width: 36px;

  .tooltip {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .content {
    padding: 8px;
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
    }

    &:hover:not(.disabled, .active),
    &:focus-within:not(.disabled, .active) {
      background-color: colors.$white-alpha-20;
    }

    &:active:not(.disabled) {
      background-color: colors.$white-alpha-40;
    }
  }

  .active {
    background-color: colors.$white-alpha-40;
  }

  .dropdown-icon {
    pointer-events: none;
    display: grid;
    place-items: center;
    position: absolute;
    bottom: 4px;
    right: 4px;
  }

  .wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
}
</style>
