<script setup lang="ts">
defineProps<{ modelValue: boolean; inputId?: string }>();
const emit = defineEmits<{ "update:modelValue": [checked: boolean] }>();

function select(e: Event) {
  emit("update:modelValue", (e.target as HTMLInputElement).checked);
}
</script>

<template>
  <div>
    <label class="switch">
      <input
        :id="inputId"
        :checked="modelValue"
        type="checkbox"
        @click="select"
      />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: colors-old.$dark-divider-color;
  transition: background-color 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 6px;
  background-color: colors-old.$back-color;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: colors-old.$checkbox-color;
}

.switch input:focus-visible + .slider {
  outline: 3px solid colors.$outline;
  outline-offset: 3px;
}

input:checked + .slider::before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}
</style>
