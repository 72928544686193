<template>
  <textarea
    v-bind="$attrs"
    ref="text"
    v-model="textValue"
    class="textarea"
    @click.stop
    @pointerdown.stop
    @keydown.esc.stop="unfocus"
    @keydown.enter="newline"
  />
</template>

<script lang="ts">
import { Options as Component } from "vue-class-component";
import { Prop, Ref, Vue, Watch } from "vue-property-decorator";

@Component({ emits: ["update:modelValue"] })
export default class BaseTextarea extends Vue {
  @Prop({ type: String, required: true }) readonly modelValue!: string;
  @Prop({ type: Boolean, default: false }) readonly autofocus!: boolean;
  @Ref("text") readonly textElem!: HTMLTextAreaElement;

  mounted() {
    this.adjustHeight();

    if (this.autofocus) {
      this.focus();
    }
    window.addEventListener("resize", this.adjustHeight);
  }

  activated() {
    this.adjustHeight();

    if (this.autofocus) {
      this.focus();
    }
    window.addEventListener("resize", this.adjustHeight);
  }

  unmounted() {
    window.removeEventListener("resize", this.adjustHeight);
  }

  deactivated() {
    window.removeEventListener("resize", this.adjustHeight);
  }

  @Watch("modelValue")
  valueChanged() {
    this.adjustHeight();
  }

  get textValue() {
    return this.modelValue;
  }

  set textValue(newValue) {
    this.$emit("update:modelValue", newValue);
  }

  unfocus() {
    this.textElem.blur();
  }

  focus() {
    this.textElem.focus();
  }

  newline(event: KeyboardEvent) {
    if (!event.altKey && !event.shiftKey) {
      event.preventDefault();
      this.unfocus();
    }
  }

  adjustHeight() {
    // Sets height to 0 and then computes it manually depending on remaining scroll height
    // and border widths. As a result textarea is adaptively resizing depending on it's content height.
    if (!this.textElem) return;

    // Adjust height of text area right after DOM is updated for correct calculation
    this.$nextTick(() => {
      const computedStyle = window.getComputedStyle(this.$el);
      const borderTopWidth = computedStyle.borderTopWidth;
      const borderBottomWidth = computedStyle.borderBottomWidth;

      this.textElem.style.height = "0";
      this.textElem.style.height = `calc(${borderTopWidth} + ${borderBottomWidth} + ${this.textElem.scrollHeight}px)`;
    });
  }
}
</script>

<style scoped lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.textarea {
  padding: 0.5rem;
  width: 100%;
  font-size: font.$size-normal;
  font-weight: font.$weight-bold;
  border-radius: 2px;
  border: 1px solid transparent;
  resize: none;
  overflow: hidden;
  margin: 0;

  &:focus {
    outline: none;
    border: 1px solid colors-old.$input-focus-border-color;
  }

  &.error {
    border-color: colors-old.$input-error-color;
  }

  &:hover:not(:focus) {
    background-color: colors-old.$input-hover-background-color;
  }

  &::placeholder {
    color: colors-old.$placeholder-color;
    font-weight: font.$weight-medium;
  }
}
</style>
