<template>
  <div class="resizable-pane" :style="{ width: paneWidth + 'px' }">
    <slot />
    <div class="resizer" @mousedown="startResize"></div>
  </div>
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref, watch } from "vue";

import { useAppSizeStore } from "@/store/appSize";

const appSizeStore = useAppSizeStore();

let paneWidth = computed(() => appSizeStore.leftPanelWidth);
let startX = ref(0);

watch(paneWidth, () => appSizeStore.setLeft(0, paneWidth.value), {
  immediate: true,
});

onUnmounted(() => useAppSizeStore().setLeft(68, 0));

const startResize = (event: MouseEvent) => {
  startX.value = event.pageX - paneWidth.value;

  document.addEventListener("mousemove", doResize);
  document.addEventListener("mouseup", stopResize);
};

const doResize = (event: MouseEvent) => {
  const val = event.pageX - startX.value;
  appSizeStore.setLeftPanelWidth(val);
};

const stopResize = () => {
  document.removeEventListener("mousemove", doResize);
  document.removeEventListener("mouseup", stopResize);
};
</script>

<style scoped lang="scss">
@use "@/styles/variables" as vars;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.resizable-pane {
  position: fixed;
  top: vars.$top-header-height;
  left: 0;
  height: calc(100% - vars.$top-header-height);
  background-color: colors.$background-ui;
  box-shadow: 0 0 10px -2px colors.$black-alpha-30; // check-style-disable
  z-index: z-index.$menu-sidebar;
  user-select: text;
}

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  cursor: ew-resize;
}
</style>
