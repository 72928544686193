<template>
  <div class="overlay points">
    <div class="points">
      <div
        v-for="(point, index) in pointValues"
        :key="index"
        class="entry hoverable"
        :class="{ selected: point === points }"
        @click.stop="emit(point)"
      >
        {{ point }}
      </div>
    </div>
    <div class="point-value">{{ $t("cardPointsOverlay.setOwnValue") }}</div>
    <input-field
      :value="'' + points"
      type="float"
      :max-length="3"
      @click.stop
      @change="emit"
    />
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import InputField from "@/components/input/InputField.vue";

@Component({ components: { InputField }, emits: ["select"] })
export default class CardPointsOverlay extends Vue {
  @Prop(Array) readonly pointValues!: number[];
  @Prop(Number) readonly points!: number;

  emit(val: number) {
    this.$emit("select", val);
  }
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.overlay.points {
  overflow: hidden;
  text-align: center;
  padding: 0.5em 3em;

  .points {
    font-size: 120%;
    display: flex;
    flex-wrap: wrap;
    border: 0.1em solid colors-old.$menu-color;

    .entry {
      border: 0.05em solid colors-old.$divider-color;
      flex-grow: 1;
      width: 2.2em;
      margin: 0;
      padding: 0;
    }
  }

  .point-value {
    margin: 0.5em;
    display: block;
  }

  input {
    width: 100%;
  }
}
</style>
