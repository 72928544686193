import { FieldChange } from "@/model/change";

import BoardChange from "./components/change/BoardChange.vue";
import FlagChange from "./components/change/FlagChange.vue";
import IterationChange from "./components/change/IterationChange.vue";
import LinkChange from "./components/change/LinkChange.vue";
import PointsChange from "./components/change/PointsChange.vue";
import PriorityChange from "./components/change/PriorityChange.vue";
import ReactionsChange from "./components/change/ReactionsChange.vue";
import RiskChange from "./components/change/RiskChange.vue";
import StatusChange from "./components/change/StatusChange.vue";
import TeamChange from "./components/change/TeamChange.vue";
import TextChange from "./components/change/TextChange.vue";
import TypeChange from "./components/change/TypeChange.vue";
import UserChange from "./components/change/UserChange.vue";

const fieldChanges: {
  [name in FieldChange["name"]]: {
    key: string;
    keyNew: string;
    component: any;
  };
} = {
  text: {
    key: /*$t*/ "stickyChange.summary",
    keyNew: /*$t*/ "stickyChange.summary.new",
    component: TextChange,
  },
  type: {
    key: /*$t*/ "stickyChange.stickyType",
    keyNew: /*$t*/ "stickyChange.stickyType.new",
    component: TypeChange,
  },
  points: {
    key: /*$t*/ "stickyChange.storyPoints",
    keyNew: /*$t*/ "stickyChange.storyPoints.new",
    component: PointsChange,
  },
  priority: {
    key: /*$t*/ "stickyChange.wsjf",
    keyNew: /*$t*/ "stickyChange.wsjf.new",
    component: PriorityChange,
  },
  risk: {
    key: /*$t*/ "stickyChange.risk",
    keyNew: /*$t*/ "stickyChange.risk.new",
    component: RiskChange,
  },
  iterationId: {
    key: /*$t*/ "stickyChange.iteration",
    keyNew: /*$t*/ "stickyChange.iteration.new",
    component: IterationChange,
  },
  teamId: {
    key: /*$t*/ "stickyChange.team",
    keyNew: /*$t*/ "stickyChange.team.new",
    component: TeamChange,
  },
  reactions: {
    key: "",
    keyNew: "",
    component: ReactionsChange,
  },
  flagType: {
    key: /*$t*/ "stickyChange.flag",
    keyNew: /*$t*/ "stickyChange.flag.new",
    component: FlagChange,
  },
  dependTeamId: {
    key: /*$t*/ "stickyChange.dependsOn",
    keyNew: /*$t*/ "stickyChange.dependsOn.new",
    component: TeamChange,
  },
  assignee: {
    key: /*$t*/ "stickyChange.assignee",
    keyNew: /*$t*/ "stickyChange.assignee.new",
    component: UserChange,
  },
  reporter: {
    key: /*$t*/ "stickyChange.reporter",
    keyNew: /*$t*/ "stickyChange.reporter.new",
    component: UserChange,
  },
  status: {
    key: /*$t*/ "stickyChange.status",
    keyNew: /*$t*/ "stickyChange.status.new",
    component: StatusChange,
  },
} as const;

const otherStickyChanges: {
  [name in FieldChange["name"]]: {
    key: string;
    keyNew: string;
    component: any;
  };
} = {
  //
  mirrorBoard: {
    key: "",
    keyNew: "",
    component: BoardChange,
  },
  link: {
    key: "",
    keyNew: "",
    component: LinkChange,
  },
} as const;

export const stickyChanges = {
  ...fieldChanges,
  ...otherStickyChanges,
};
