<script setup lang="ts">
import { CSSProperties, computed, onMounted, onUnmounted, ref } from "vue";

import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { useModalStore } from "@/store/modal";

import { useSimpleDraggable } from "./useSimpleDraggable";

const props = defineProps<{
  title?: string;
  fullscreen?: boolean;
  width?: string;
  minHeight?: string;
  description: string | null; // (aria-description) If null, JAWS will read the entire contents of the modal when it opens
  // This should only be null for short modals where it makes sense to read as a whole (eg. delete confirmation)
  ariaLabelledby?: string;
}>();

const style = computed(() => {
  const style = {} as CSSProperties;
  if (!props.fullscreen) {
    if (props.width) {
      style.width = props.width;
    }
    if (props.minHeight) {
      style["min-height"] = props.minHeight;
    }
  }
  return style;
});

onMounted(() => {
  document.body.style.overflow = "hidden";
});

onUnmounted(() => {
  document.body.style.overflow = "unset";
});

function hide() {
  useModalStore().hide();
}

const modal = ref<HTMLDivElement>();
const { startDrag, endDrag } = useSimpleDraggable(modal);
</script>

<template>
  <div class="wrapper" @click="hide" @wheel.stop>
    <div
      ref="modal"
      v-focus-trap
      :class="{ fullscreen }"
      class="modal"
      role="dialog"
      aria-modal="true"
      :aria-labelledby="ariaLabelledby || 'base-modal-title'"
      :aria-description="description"
      :style="style"
      @click.stop
    >
      <div class="header-container">
        <div class="header" @pointerdown="startDrag" @pointerup="endDrag">
          <slot name="header-title">
            <h1 id="base-modal-title" v-autofocus class="title">{{ title }}</h1>
          </slot>
          <IconButton
            icon="close"
            :aria-label="$t('general.close')"
            @click="hide"
          />
        </div>
        <slot name="header-content"></slot>
      </div>
      <div class="body scrollable">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.wrapper {
  position: fixed;
  z-index: z-index.$modal-overlay;
  background: colors-old.$modal-background-overlay-color;
  margin: 0;
  inset: 0;

  .modal {
    position: relative;
    background: colors-old.$modal-background-color;
    border-radius: variables.$modal-border-radius;
    box-sizing: border-box;
    margin: 20vh auto 0;
    width: variables.$modal-width;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .title {
      font-size: font.$size-normal;
      font-weight: font.$weight-bold;
      color: colors-old.$text-primary-color;
    }

    .body {
      padding: 1rem 1.25rem;
      font-size: font.$size-normal;
      height: 100%;
      flex-grow: 1;
      min-height: 10vh;
      display: flex;
      flex-direction: column;

      &.scrollable {
        position: relative;
        overflow-y: scroll;
        max-height: 60vh;
      }
    }

    &.fullscreen {
      width: 100%;
      height: 100%;
      margin-top: 0;
      border-radius: 0;

      .body.scrollable {
        max-height: unset;
        height: 100%;
        padding: 0;
      }

      .title {
        font-size: font.$size-large;
      }

      button.close {
        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }

    .header-container {
      border-bottom: 1px solid colors-old.$modal-header-border-color;
    }

    .header {
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem;
      cursor: grab;

      button.close {
        padding: 0.25rem 0.5rem;

        svg {
          stroke: colors-old.$icon-base-color;
        }

        &:hover,
        &:focus {
          svg {
            stroke: colors-old.$modal-close-hover-color;
          }
        }
      }
    }
  }
}
</style>
