<template>
  <div class="overlay priorities">
    <input-field
      v-if="!priorities"
      ref="input"
      :value="priority.toString()"
      type="float"
      :max-length="3"
      @change="emit"
    />
    <template v-else>
      <div
        v-for="(prio, index) in priorities"
        :key="index"
        @click.stop="emit(prio.value.toString())"
      >
        <span
          class="entry hoverable"
          :class="{ selected: prio.value === priority }"
          >{{ prio.text }}</span
        >
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

import InputField from "@/components/input/InputField.vue";
import { Priority } from "@/model/stickyType";

@Component({ components: { InputField }, emits: ["select"] })
export default class CardPrioritiesOverlay extends Vue {
  @Prop(Object) readonly priorities: { [key: number]: Priority } | undefined;
  @Prop(Number) readonly priority!: number;
  @Ref("input") readonly inputElem!: InputField;

  mounted() {
    this.inputElem?.init();
  }

  emit(prio: string) {
    this.$emit("select", +prio);
  }
}
</script>

<style lang="scss">
.overlay.priorities {
  overflow: hidden;
  text-align: center;

  div {
    text-align: left;
  }

  input {
    font-size: 150%;
    text-align: center;
    margin-top: 2em;
    width: 50%;
  }
}
</style>
