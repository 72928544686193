<script lang="ts" setup>
import { computed } from "vue";

import BaseListItemButton from "@/components-ng/BaseList/components/BaseListItemFluid/BaseListItemButton.vue";
import BaseListItemFluid from "@/components-ng/BaseList/components/BaseListItemFluid/BaseListItemFluid.vue";
import BaseListItemIcon from "@/components-ng/BaseList/components/BaseListItemFluid/BaseListItemIcon.vue";
import BaseListItemText from "@/components-ng/BaseList/components/BaseListItemFluid/BaseListItemText.vue";
import TypeDot from "@/components/TypeDot.vue";

import { RiskItem } from "../../types";
import RiskProgressBadge from "../RiskProgressBadge.vue";

type Props = {
  item: RiskItem;
  maxScore: number;
  static?: boolean;
};

const props = defineProps<Props>();

const riskScenariosCount = computed(() => {
  return props.item.riskScenarios.filter((scenario) => scenario.score > 0.1)
    .length;
});
</script>

<template>
  <BaseListItemFluid class="risk-item">
    <BaseListItemButton>
      <BaseListItemIcon>
        <TypeDot size="medium" :color="item.sticky.data.type.color" />
      </BaseListItemIcon>
      <BaseListItemText>
        <div class="text">
          {{ item.sticky.data.text }}
        </div>
        <template #secondary>
          {{ riskScenariosCount }} Risk Scenarios
        </template>
      </BaseListItemText>
      <RiskProgressBadge :score="item.totalScore" :max-score="maxScore" />
    </BaseListItemButton>
  </BaseListItemFluid>
</template>

<style lang="scss" scoped>
.risk-item {
  user-select: none;

  .text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: calc(1.5em * 2);
  }
}
</style>
