<script lang="ts" setup>
import { FunctionalComponent } from "vue";

import { cardActions } from "@/action/cardActions";
import { cardKey } from "@/components/card/injectKeys";
import IconHot from "@/icons/misc/hot.svg?component";
import IconIncrement from "@/icons/misc/increment.svg?component";
import IconLove from "@/icons/misc/love.svg?component";
import { Reaction, reactions } from "@/model/card";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);

const handlePointerEnter = (event: PointerEvent) => {
  const target = event.target as HTMLElement;
  const siblings = target.parentElement?.children || [];

  Array.from(siblings).forEach((sibling) => {
    if (sibling === target) {
      sibling.classList.add("active");
      return;
    }
    sibling.classList.add("transparent");
  });
};

const handlePointerLeave = (event: PointerEvent) => {
  const target = event.target as HTMLElement;
  const siblings = target.parentElement?.children || [];

  Array.from(siblings).forEach((sibling) => {
    sibling.classList.remove("active", "transparent");
  });
};

const handleToggleReaction = async (reaction: Reaction) => {
  await cardActions.toggleReaction("card", card.id, reaction);
};

const icons: { [key in Reaction]: FunctionalComponent } = {
  hot: IconHot,
  increment: IconIncrement,
  love: IconLove,
};
</script>

<template>
  <div class="sticky-reactions">
    <span
      v-for="reaction in reactions"
      :key="reaction"
      class="reaction"
      @pointerenter="handlePointerEnter"
      @pointerleave="handlePointerLeave"
      @click="handleToggleReaction(reaction)"
    >
      <component :is="icons[reaction]" width="20" height="20" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use "sass:color";
@use "@/styles/colors";

.sticky-reactions {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  border-radius: 30px;
  overflow: hidden;

  .reaction {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.active {
      display: inline-block;
      transform: scale(1.4);
    }

    &.transparent::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: color.change(colors.$back-color, $alpha: 0.3);
    }

    transform: scale(1.2);
  }
}
</style>
