import { registerActionShortcut } from "@/Shortcuts";
import { CardCreateProps, cardActions } from "@/action/cardActions";
import { lineSeparator } from "@/lowlevel";
import { relativeToWindow } from "@/math/coordinate-systems";
import { AlmSourceId } from "@/model/baseTypes";
import { BoardCard } from "@/model/card";
import { RelativeCoordinate, centerCoord, minus } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useModalStore } from "@/store/modal";
import { useSelectionStore } from "@/store/selection";

interface SerializedCardData {
  text: string;
  typeId: string;
  almSourceId: AlmSourceId | null;
  pos: RelativeCoordinate;
}

export function registerCopyPaste() {
  registerActionShortcut("", cardActions.duplicate, { prevent: true });

  document.addEventListener("paste", (e) => {
    if (cardPasteTarget(e.target)) {
      const data = parseJson(
        e.clipboardData?.getData("text/sticky"),
      ) as SerializedCardData[];
      if (data) {
        if (data.length === 1) {
          cardActions.paste("keyboard", deserializeCard(data[0]));
        } else {
          useSelectionStore().paste(
            data.map((card) => completeCard(deserializeCard(card), card.pos)),
          );
        }
      } else {
        const text = e.clipboardData?.getData("text");
        if (text) {
          cardActions.pasteText("keyboard", { text });
        }
      }
    }
  });

  document.addEventListener("copy", (e) => {
    const cards = useBoardStore().selectedOrActiveCards;
    if (cards.length > 0 && e.clipboardData) {
      cardActions.copy("keyboard");
      if (cards.length === 1 && selectedText(e.target)) {
        setClipboard(e.clipboardData, [
          cardWithText(cards[0], selectedText(e.target)),
        ]);
      } else {
        setClipboard(e.clipboardData, cards);
      }
      e.preventDefault();
    }
  });
}

function cardWithText(card: BoardCard, text: string) {
  return { ...card, data: { ...card.data, text } };
}

function setClipboard(clipboard: DataTransfer, cards: BoardCard[]) {
  clipboard.setData(
    "text/plain",
    cards.map((card) => card.data.text).join(lineSeparator),
  );
  clipboard.setData(
    "text/sticky",
    JSON.stringify(cards.map((card) => serializeCard(cards[0], card))),
  );
}

function cardPasteTarget(e?: any) {
  return (
    !useModalStore().isModalOpen() &&
    (!e || (e.nodeName !== "INPUT" && e.nodeName !== "TEXTAREA"))
  );
}

function selectedText(e?: any) {
  if (e && e.value) {
    const start = e.selectionStart;
    const end = e.selectionEnd;
    if (start !== null && end !== null) {
      return e.value.substring(start, end);
    }
  }
  return null;
}

function parseJson(s?: string): any | null {
  try {
    return s ? JSON.parse(s) : null;
  } catch (e) {
    return null;
  }
}

function serializeCard(base: BoardCard, card: BoardCard): SerializedCardData {
  return {
    typeId: card.data.type.id,
    almSourceId: card.data.almSourceId,
    text: card.data.text,
    pos: minus(base.meta.pos, card.meta.pos),
  };
}

function deserializeCard(data: SerializedCardData): Partial<CardCreateProps> {
  return {
    text: data.text,
    almSourceId: data.almSourceId ?? undefined,
    type: useBoardStore()
      .currentBoard()
      .stickyTypes.find((type) => type.id === data.typeId),
  };
}

function completeCard(
  card: Partial<CardCreateProps>,
  pos: RelativeCoordinate,
): CardCreateProps {
  return {
    ...card,
    type: card.type || useBoardStore().allStickyTypes[0],
    pos: minus(centerCoord(), relativeToWindow(pos)),
  };
}
