<template>
  <div
    v-keyboard-nav.soft-focus-initial="{ selector: '.navigable-item' }"
    class="keyboard-navigable-list"
    role="menu"
  >
    <slot name="first-list-header"></slot>
    <div
      v-for="item in firstList"
      :id="'navigable-item-' + item.id"
      :key="item.id"
      role="menuitem"
      tabindex="0"
      class="item navigable-item"
      @click.prevent="selectItem(item)"
    >
      <slot name="row" :item="item"></slot>
    </div>

    <slot name="second-list-header"></slot>
    <div
      v-for="item in secondList"
      :id="'navigable-item-' + item.id"
      :key="item.id"
      role="menuitem"
      tabindex="0"
      class="item navigable-item"
      @click.prevent="selectItem(item)"
    >
      <slot name="second-list-row" :item="item"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export interface ItemModel {
  id: string;
}

@Component({ emits: ["select"] })
export default class NavigableList extends Vue {
  @Prop({ type: Array, required: true }) readonly firstList!: ItemModel[];
  @Prop({ type: Array, required: false, default: () => [] })
  readonly secondList!: ItemModel[];

  selectItem(item: ItemModel) {
    this.$emit("select", item);
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.keyboard-navigable-list {
  cursor: pointer;

  .item {
    padding: 0 0.8em;
  }
}
</style>
