<script setup lang="ts">
import { computed, ref } from "vue";

import { Objective } from "@/model/objective";
import { Team } from "@/model/session";
import { useBoardStore } from "@/store/board";
import { useObjectiveStore } from "@/store/objective";

import ObjectiveCard from "./ObjectiveCard.vue";
import ObjectivesList from "./ObjectivesList.vue";

type Props = { team: Team };
const props = defineProps<Props>();

const selectedObjective = ref<Objective | null>(null);

const board = computed(() =>
  useBoardStore().boardByType("team", { teamId: props.team.id }),
);

const objectiveType = computed(() => {
  if (selectedObjective.value) {
    return useObjectiveStore().objectiveTypeById(selectedObjective.value.id, {
      teamId: props.team.id,
    });
  }
  return null;
});

const index = computed(() => {
  if (!(objectiveType.value && selectedObjective.value)) return null;
  const objectives =
    objectiveType.value === "committed"
      ? board.value.objectives
      : board.value.stretchObjectives;
  const index = objectives.findIndex(
    (objective) => objective.id === selectedObjective.value!.id,
  );

  return index !== -1 ? index + 1 : null;
});

const selectObjective = (objective: Objective | null) => {
  selectedObjective.value = objective;
};
</script>

<template>
  <div v-autofocus v-keyboard-nav.select.no-wrap class="content">
    <div class="left" data-testid="objective-list-container">
      <ObjectivesList
        :team="team"
        :selected-objective="selectedObjective"
        @select="selectObjective"
      />
    </div>
    <div class="right" data-testid="objective-detail-container">
      <div class="objective-card-container">
        <transition name="faster-fade" mode="out-in">
          <ObjectiveCard
            v-if="selectedObjective && objectiveType && index"
            :key="selectedObjective.id"
            :objective-id="selectedObjective.id"
            :type="objectiveType"
            :index="index"
            :board="board"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  // TODO Still show this for non-mouse users
  &:focus {
    outline: 0;
  }

  .left {
    flex: 0 1 min(35%, 600px);
    min-width: 0;
    border-right: solid 1px colors-old.$divider-color;
  }

  .right {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    flex-basis: 65%;
    justify-content: center;
    padding: 60px;

    .objective-card-container {
      max-width: 700px;
      width: 100%;
    }
  }
}
</style>
