<template>
  <div class="overlay risks scrollable no-wheel">
    <div
      v-for="(art, index) in arts"
      :key="art.name"
      @pointermove="selectIndex = index"
      @click.stop="select(art)"
    >
      <span
        class="entry"
        :class="{
          selected: selected.name === art.name,
          current: index === selectIndex,
        }"
      >
        <img class="icon" src="@/assets/art.svg" />
        {{ art.name }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import SelectList from "@/mixins/SelectList";
import { Art } from "@/model/session";

@Component({ emits: ["select"] })
export default class CardArtsOverlay extends mixins(SelectList) {
  @Prop(Object) readonly selected!: Art;
  @Prop(Array) readonly arts!: Art[];

  selectList = this.arts;
  selectIndex = 0;

  select(art: Art) {
    this.$emit("select", art);
  }
}
</script>
