<script lang="ts" setup>
import { computed } from "vue";

import UserAvatar from "@/components/UserAvatar.vue";
import { AuthUser } from "@/model/user";

type Props = {
  user: AuthUser;
  bold?: boolean;
};

const placeholder = "avatars/placeholder.png";

const props = withDefaults(defineProps<Props>(), {
  avatar: placeholder,
  bold: false,
});

const isPlaceholder = computed(() => props.avatar === placeholder);
</script>

<template>
  <div class="ai-user-avatar">
    <UserAvatar :user="user" />
    <span
      class="name"
      :class="{ placeholder: isPlaceholder, bold: props.bold }"
      >{{ user.name }}</span
    >
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";
@use "@/styles/mixins/typography";

.ai-user-avatar {
  @include typography.small("bold");

  display: flex;
  gap: 8px;
  align-items: center;

  .avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .name {
    &.placeholder {
      color: colors.$text-placeholder;
    }

    &.bold {
      @include typography.medium("bold");
    }
  }
}
</style>
