import { Board } from "@/model/board";
import { Group, Iteration } from "@/model/session";

export type TeamData = Record<string, number>;

export function countStickiesInTeam(
  group: Group,
  board: Board,
  iterations: Iteration[],
) {
  const backlogCards = Object.values(board.cards).filter(
    (card) => card.data.type.origin === "backlog",
  );

  // Initialize the team distribution object
  const teamData: TeamData = {};

  iterations.forEach((iteration) => {
    teamData[iteration.name] = backlogCards.filter(
      (card) =>
        card.data.iterationId === iteration.id && card.data.teamId === group.id,
    ).length;
  });

  return teamData;
}
type IterationId = Iteration["id"];
export function countStickiesInIteration(
  iterations: Iteration[],
  board: Board,
): Record<IterationId, number> {
  const iterMap = iterations.reduce<Record<number, 0>>((acc, iter) => {
    acc[iter.id] = 0;
    return acc;
  }, {});

  Object.values(board.cards).forEach((card) => {
    if (card.data.iterationId === null) return;
    iterMap[card.data.iterationId] += 1;
  });

  return iterMap;
}

export function weightedSum(values: Array<{ value: number; weight: number }>) {
  let weightedValueSum = 0;
  let weightSum = 0;

  for (let i = 0; i < values.length; i++) {
    weightedValueSum += values[i].value * values[i].weight;
    weightSum += values[i].weight;
  }
  return weightedValueSum / weightSum;
}
