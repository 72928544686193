import { defineStore } from "pinia";

import { Objective } from "@/model/objective";

export const useGPTStore = defineStore("gpt", {
  state: () => ({
    isGPTActive: false,
    objective: null as Objective | null,
  }),
  getters: {},
  actions: {
    expandGPT() {
      this.isGPTActive = true;
    },
    collapseGPT() {
      this.isGPTActive = false;
      this.objective = null;
    },
  },
});
