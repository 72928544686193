<script setup lang="ts">
import { debounce } from "lodash-es";
import { computed, ref, watch } from "vue";

import SinglePendingPromise from "@/SinglePendingPromise";
import { searchStickies } from "@/backend/Backend";
import { SearchStickiesQuery } from "@/backend/BackendSession";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { StickyInfo } from "@/model/card";
import { SearchResult } from "@/model/search";
import { goToBoard } from "@/router/navigation";
import { useBoardStore } from "@/store/board";

import {
  ItemKey,
  groupSearchResultsByArt,
  sortSearchResults,
} from "./SearchResult";
import SearchResultList from "./SearchResultList.vue";

// Unique instance of the directive so we can render multiple
// components with this directive at once
const vKeyboardNav = keyboardNavigation();

const props = defineProps<{ query: SearchStickiesQuery }>();

const results = ref<SearchResult[]>([]);
const searching = ref(false);
const currentItem = ref<ItemKey | null>(null);

const backendSearchPromise = new SinglePendingPromise();
const backendSearch = debounce(
  (query: SearchStickiesQuery, action: (results: SearchResult[]) => void) => {
    backendSearchPromise.execute(
      () => searchStickies(query),
      (results) => action(results),
    );
  },
  500,
  { leading: true },
);

watch(() => props.query, search, { immediate: true });

const resultsByArt = computed(() => {
  const searchResults = groupSearchResultsByArt(results.value);
  const currentArtIndex = searchResults.findIndex(
    (item) => item.artId === currentArtId.value,
  );
  if (currentArtIndex > -1) {
    searchResults.splice(currentArtIndex, 1);
  }
  return searchResults;
});

const currentArtSearchResult = computed(() => {
  const searchResults = groupSearchResultsByArt(results.value);
  return searchResults.find((item) => item.artId === currentArtId.value);
});

const currentBoardId = computed(() => useBoardStore().currentBoard().id);
const currentArtId = computed(
  () => useBoardStore().boardById(currentBoardId.value).artId,
);
const showCurrentArt = computed(() => {
  if (!currentArtSearchResult.value) {
    return false;
  }
  const anotherBoardIndex = currentArtSearchResult.value.boards.findIndex(
    (item) => item.boardId !== currentBoardId.value,
  );
  return anotherBoardIndex > -1;
});
const showOtherBoardsSearchResults = computed(
  () =>
    !searching.value && (showCurrentArt.value || resultsByArt.value.length > 0),
);

function search() {
  backendSearchPromise.cancel();
  searching.value = true;
  backendSearch(props.query, (backendResults) => {
    backendResults = backendResults.map((item) => ({
      ...item,
      artId: useBoardStore().boardById(item.boardId).artId,
    }));
    results.value = sortSearchResults(
      backendResults.filter(
        (result) => result.boardId !== currentBoardId.value,
      ),
    );
    searching.value = false;
  });
}

function isCurrentBoard(boardId: string): boolean {
  return currentBoardId.value === boardId;
}

function select(item: ItemKey) {
  goToBoard(useBoardStore().boardById(item.boardId!), { searchId: item.id });
}

function setCurrent(result: StickyInfo) {
  currentItem.value = result;
}

function resetCurrent(result: StickyInfo) {
  if (currentItem.value === result) {
    currentItem.value = null;
  }
}
</script>

<template>
  <div
    v-if="showOtherBoardsSearchResults"
    v-keyboard-nav.soft-focus.ignore-trigger="{
      selector: '.result-card',
    }"
  >
    <div class="message divider-container">
      {{ $t("searchResults.resultsBoards") }}
    </div>
    <template v-if="showCurrentArt">
      <div class="art-name divider-container">
        {{ currentArtSearchResult?.artName }}
      </div>
      <div v-for="board in currentArtSearchResult?.boards" :key="board.boardId">
        <template v-if="!isCurrentBoard(board.boardId)">
          <div class="action location">
            <div class="header">{{ board.boardName }}</div>
            <div>
              {{ board.count }}
            </div>
          </div>
          <SearchResultList
            :results="board.items"
            :current="currentItem"
            :board-id="board.boardId"
            @select="select"
            @pointerenter="setCurrent"
            @pointerleave="resetCurrent"
          />
        </template>
      </div>
    </template>

    <div v-for="art in resultsByArt" :key="art.artId">
      <div class="art-name divider-container">{{ art.artName }}</div>

      <div v-for="board in art.boards" :key="board.boardId">
        <div class="action location">
          <div class="header">{{ board.boardName }}</div>
          <div>
            {{ board.count }}
          </div>
        </div>
        <SearchResultList
          :results="board.items"
          :current="currentItem"
          :board-id="board.boardId"
          @select="select"
          @pointerenter="setCurrent"
          @pointerleave="resetCurrent"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

#search-results {
  .divider-container {
    background-color: colors-old.$light-background-color;
    font-size: font.$size-small;
  }

  .message {
    margin-top: 20px;
    padding: 16px;
    text-align: center;
  }

  .art-name {
    padding: 8px 12px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;

    & div:nth-child(1) {
      font-weight: font.$weight-bold;
    }

    & div:nth-child(2) {
      font-weight: font.$weight-normal;
      color: colors-old.$text-secondary-color;
    }
  }
}
</style>
