import { BackendError } from "./BackendError";
import { NoSuccessError } from "./NoSuccessError";
import { CallContext } from "./requestHelper";

type ServerResponse = SuccessServerResponse | ErrorServerResponse;

interface SuccessServerResponse {
  success: true;
  response: any;
}

interface ErrorServerResponse {
  success: false;
  error: { message: string; code?: string; details?: any };
}

function hasResponseFormat(response: any): response is ServerResponse {
  return (
    typeof response === "object" && response !== null && "success" in response
  );
}

function isSuccessResponse(response: any): response is SuccessServerResponse {
  return (
    hasResponseFormat(response) && response.success && "response" in response
  );
}

function isErrorResponse(response: any): response is ErrorServerResponse {
  return (
    hasResponseFormat(response) &&
    !response.success &&
    "error" in response &&
    "message" in response.error
  );
}

export function handleResponse(
  response: any,
  context: CallContext,
  methodName: string,
) {
  if (isSuccessResponse(response)) {
    return response.response;
  }
  if (isErrorResponse(response)) {
    throw new NoSuccessError(methodName, {
      request: context,
      response: response.error,
    });
  }
  throw new BackendError(
    "Bad Response Format",
    `Unexpected server response format calling RPC method ${methodName}`,
    { request: context, response },
  );
}
