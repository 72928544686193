import { groupBy } from "lodash-es";

import { StickyChange } from "@/model/change";
import { atDayStart } from "@/utils/date";

// StickyChange with properties related to how it should be rendered
interface DisplayableStickyChange extends StickyChange {
  expandable: boolean;
}

export interface StickyChangesByDay {
  date: Date;
  changes: DisplayableStickyChange[];
}

export interface StickyChangesBySticky {
  id: string;
  changes: DisplayableStickyChange[];
}

export function groupByDay(changes: StickyChange[]): StickyChangesByDay[] {
  const byDay = Object.entries(
    groupBy(changes, (change) => atDayStart(change.timestamp).getTime()),
  );
  return byDay.map(([date, changes]) => ({
    date: new Date(+date),
    changes: changes.map(makeDisplayable),
  }));
}

export function groupBySticky(
  changes: StickyChange[],
): StickyChangesBySticky[] {
  const bySticky = Object.entries(
    groupBy(changes, (change) => change.stickyId),
  );
  return bySticky.map(([id, changes]) => ({
    id,
    changes: changes.map(makeDisplayable),
  }));
}

function makeDisplayable(change: StickyChange) {
  return {
    ...change,
    expandable: isExpandable(change),
  };
}

function isExpandable(change: StickyChange) {
  return ["update", "mirror", "unmirror", "link", "unlink"].includes(
    change.kind,
  );
}
