<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import * as Gestures from "@/Gestures";
import { snapDistance } from "@/Settings";
import { key } from "@/Shortcuts";
import { drawActions } from "@/action/drawActions";
import { useNativeEvents } from "@/composables/useNativeEvents";
import {
  relativeClientCoord,
  windowToRelative,
} from "@/math/coordinate-systems";
import {
  CoordinateComponent,
  Line,
  LineComponent,
  RelativeCoordinate,
  WindowCoordinate,
  clampRelativeCoord,
  clientCoord,
  distance2,
  isHorizontal,
  minus,
  nearestLinePoint,
  oppositeLineComponent,
  plus,
  relativeCoord,
} from "@/model/coordinates";
import { Shape, fixLine } from "@/model/shape";
import { useBoardStore } from "@/store/board";
import { useContextMenuStore } from "@/store/contextMenu";
import { Selected, useDrawStore } from "@/store/draw";
import cssValue from "@/styles/variable.module.scss";
import { terminateEvent } from "@/utils/dom";

import DrawContextMenu from "./DrawContextMenu.vue";
import { usePointerPosition } from "./usePointerPosition";
import { useSvgShapes } from "./useSvgShape";

const width = 1600;
const height = 900;

const borderLines = [
  fixLine("v1", relativeCoord(0, 0), relativeCoord(0, 1)),
  fixLine("v2", relativeCoord(1, 0), relativeCoord(1, 1)),
  fixLine("h1", relativeCoord(0, 0), relativeCoord(1, 0)),
  fixLine("h2", relativeCoord(0, 1), relativeCoord(1, 1)),
];

const root = ref();

const { line, rectAroundPoint, rectAlongLine } = useSvgShapes(width, height);
const anchor = rectAroundPoint(8);
const editableLine = rectAlongLine(2);

const { pointerPos } = usePointerPosition(root);

const { addDocumentEventListener } = useNativeEvents();
onMounted(() => {
  addDocumentEventListener("keydown", keyHandler, true);
  Gestures.dragScroll(root.value);
});

type Anchor = LineComponent | "line";

const selected = computed(() => useDrawStore().selected);
const board = computed(() => useBoardStore().currentBoard());
const active = computed(() => useDrawStore().active);
const zIndex = computed(() =>
  active.value ? board.value.maxZ + 1 : cssValue.zIndexBoard,
);
const pointerEvents = computed(() => (active.value ? "all" : "none"));

function keyHandler(e: KeyboardEvent) {
  if (active.value) {
    if (
      key(drawActions.removeShape.data.shortcut!.key)(e) ||
      e.key === "Delete"
    ) {
      terminateEvent(e);
      if (selected.value) {
        drawActions.removeShape("keyboard", selected.value.shape.id);
      }
    }
  }
}

function shapeDown(shape: Shape, anchor: Anchor, e: PointerEvent) {
  useDrawStore().selected = {
    shape,
    anchor,
    offset: {
      p0: minus(shape.p0, relativeClientCoord(e)),
      p1: minus(shape.p1, relativeClientCoord(e)),
    },
  };
}

function up() {
  if (selected.value?.anchor) {
    drawActions.editShape("mouse", selected.value.shape);
    selected.value.anchor = null;
  }
}

function move(event: PointerEvent) {
  if (selected.value?.anchor) {
    moveShape(selected.value.shape, selected.value, relativeClientCoord(event));
    event.stopPropagation();
  } else if (canStartShape() && pointerPos.down) {
    startShape(pointerPos.down);
  }
}

function moveShape(shape: Shape, selected: Selected, pos: RelativeCoordinate) {
  if (selected.anchor === "line") {
    shape.p0 = clampRelativeCoord(plus(selected.offset.p0, pos));
    shape.p1 = clampRelativeCoord(plus(selected.offset.p1, pos));
  } else if (selected.anchor) {
    const fixPoint = shape[oppositeLineComponent(selected.anchor)];
    shape[selected.anchor] =
      Math.abs(fixPoint.x - pos.x) < Math.abs(fixPoint.y - pos.y)
        ? relativeCoord(fixPoint.x, pos.y)
        : relativeCoord(pos.x, fixPoint.y);
  }

  for (const other of board.value.shapes) {
    snap(shape, other);
  }
  for (const border of borderLines) {
    snap(shape, border);
  }
}

function snap(shape: Shape, other: Shape) {
  if (
    other.id !== shape.id &&
    other.type === "line" &&
    isHorizontal(shape) !== isHorizontal(other)
  ) {
    snapPoint("p0");
    snapPoint("p1");
  }

  function snapPoint(point: LineComponent) {
    const n = nearestLinePoint(shape[point], other);
    if (distance2(n.point, shape[point]) < snapDistance) {
      const comp = components(shape);
      if (n.t < 0 || n.t > 1) {
        // near the end of the other line -> snap the whole line
        shape.p0[comp.fix] = n.point[comp.fix];
        shape.p1[comp.fix] = n.point[comp.fix];
      }
      // snap the near point only
      shape[point][comp.flex] = n.point[comp.flex];
    }
  }
}

function components(line: Line<RelativeCoordinate>): {
  fix: CoordinateComponent;
  flex: CoordinateComponent;
} {
  return isHorizontal(line) ? { fix: "y", flex: "x" } : { fix: "x", flex: "y" };
}

function canStartShape() {
  return useDrawStore().tool === "line" && !selected.value?.anchor;
}

function startShape(pos: WindowCoordinate) {
  const shape: Shape = {
    id: "",
    type: "line",
    fixed: false,
    p0: windowToRelative(pos),
    p1: windowToRelative(pos),
  };
  useDrawStore().selected = { shape, anchor: "p1" };
  drawActions.addShape("mouse", shape).then((shape) => {
    selected.value!.shape = shape;
  });
}

function isSelected(shape: Shape) {
  return shape.id === selected.value?.shape.id;
}

function contextMenu(e: MouseEvent) {
  useContextMenuStore().open(DrawContextMenu, {
    position: clientCoord(e),
  });
}
</script>

<template>
  <svg
    ref="root"
    class="draw-layer"
    :class="{ active }"
    :viewBox="`0 0 ${width} ${height}`"
    preserveAspectRatio="none"
    :style="{ zIndex, pointerEvents }"
    aria-hidden="true"
    @pointermove.capture="move"
    @pointerup="up"
    @pointerleave="up"
    @contextmenu="contextMenu"
  >
    <g
      v-for="shape in board.shapes"
      :key="shape.id"
      :class="{ selected: isSelected(shape) }"
    >
      <line v-if="!active || shape.fixed" v-bind="line(shape)" />
      <template v-else>
        <rect
          class="line"
          v-bind="editableLine(shape)"
          @pointerdown="shapeDown(shape, 'line', $event)"
        />
        <rect
          class="anchor"
          v-bind="anchor(shape.p0)"
          @pointerdown="shapeDown(shape, 'p0', $event)"
        />
        <rect
          class="anchor"
          v-bind="anchor(shape.p1)"
          @pointerdown="shapeDown(shape, 'p1', $event)"
        />
      </template>
    </g>
  </svg>
</template>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "sass:color";

.draw-layer {
  position: absolute;
  width: 100% * variables.$fake-zoom;
  height: 100% * variables.$fake-zoom;
  fill: none;

  // this is not inside line element because it would break the screenshot function
  // if different elements have different stroke/fill make sure screenshot still works
  stroke: colors-old.$border-menu-color;
  stroke-width: 2;

  &.active {
    background-color: color.change(colors-old.$back-color, $alpha: 0.5);

    line {
      stroke: colors-old.$menu-color;
    }
  }

  rect {
    &.line {
      cursor: move;
      fill: colors-old.$menu-color;
      stroke: transparent;
      stroke-width: 8;
    }

    &.anchor {
      cursor: move;
      stroke: colors-old.$menu-color;
      stroke-width: 2;
      rx: 2;
    }
  }

  g.selected {
    rect.anchor {
      fill: colors-old.$menu-color;
    }
  }
}
</style>
