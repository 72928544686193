<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { setSessionId } from "@/backend/Backend";
import ChooseButton from "@/components/ChooseButton/ChooseButton.vue";
import HomeHeader from "@/components/HomeHeader.vue";
import { handleError } from "@/errorHandler";
import { Session } from "@/model/session";
import { navigateForward } from "@/router/navigation";
import { useLicenseStore } from "@/store/license";
import { useSessionStore } from "@/store/session";
import { useUserStore } from "@/store/user";
import {
  sessionSelected,
  showArchivedSessionClicked,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { isAfter, isBefore, plusDays } from "@/utils/date";
import { formatLongDate } from "@/utils/dateFormat";

const licenseStore = useLicenseStore();
const loading = ref(false);
const archived = ref(false);
const formatDate = formatLongDate;

const route = useRoute();

const filteredSessions = computed(() =>
  useSessionStore().filterArchived(archived.value),
);
const authid = computed(() => {
  const user = useUserStore().technicalUser;
  return user.company + "." + user.id;
});

watch(
  () => route.params.session,
  (sessionId: any) => {
    const session = useSessionStore().session.list.find(
      (session) => session.id === sessionId,
    );
    if (session) {
      select(session);
    }
  },
  { immediate: true },
);

function startDate(session: Session) {
  return session.startDate || session.iterations[0].start;
}

function endDate(session: Session) {
  return session.iterations[session.iterations.length - 1].end;
}

function isActive(session: Session) {
  return (
    isBefore(startDate(session), new Date()) &&
    isAfter(endDate(session), new Date())
  );
}

async function select(session: Session) {
  if (loading.value) return;
  trackEvent(sessionSelected());
  loading.value = true;
  try {
    await setSessionId(session.id);
    useSessionStore().selectSession(session);
    navigateForward();
  } catch (err: any) {
    handleError(err);
  }
}

function handleShowArchivedChange(event: Event) {
  const target = event.target as HTMLInputElement;
  trackEvent(showArchivedSessionClicked(target.checked));
}
</script>

<template>
  <main
    v-autofocus
    class="home-overview session-page"
    tabindex="-1"
    aria-labelledby="session-page-subtitle"
  >
    <HomeHeader>
      <template #title>
        <h1 id="session-page-title">
          {{ $t("sessionPage.piPlannings", { safe: "SAFe®" }) }}
        </h1>
      </template>
      <template #subtitle>
        <div id="session-page-subtitle">{{ $t("sessionPage.select") }}</div>
      </template>
    </HomeHeader>
    <div class="custom-checkbox">
      <input
        id="archived"
        v-model="archived"
        type="checkbox"
        @change="handleShowArchivedChange"
      />
      <label for="archived">{{ $t("sessionPage.showArchived") }}</label>
    </div>
    <div v-if="!licenseStore.license.usable" class="expired">
      {{ $t("sessionPage.licenseExpired") }}
      <div class="info">
        {{ $t("sessionPage.authid", { authid }) }}
      </div>
    </div>
    <hr class="separator" aria-hidden="true" />
    <div class="scrollable scrollable-relative scrollable-content">
      <ChooseButton
        v-for="session in filteredSessions"
        :key="session.id"
        class="item"
        :class="{ mark: isActive(session), archived: session.archived }"
        size="medium"
        @click="select(session)"
      >
        <div>
          <div>{{ session.name }}</div>
          <div class="date">
            {{
              $t("date.range", {
                from: formatDate(startDate(session)),
                to: formatDate(plusDays(endDate(session), -1)),
              })
            }}
          </div>
        </div>
      </ChooseButton>
    </div>
  </main>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/checkbox" as *;
@use "@/styles/animations" as *;
@use "@/styles/team-art-list" as *;

.session-page {
  position: relative;

  hr.separator {
    margin-top: 30px;
  }

  .expired {
    margin-top: 2em;
    font-size: 150%;

    .info {
      margin-top: 1em;
      color: colors-old.$dark-divider-color;
    }
  }

  .scrollable-content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .item {
      .date {
        margin-top: 10px;
        font-weight: font.$weight-normal;
        font-size: 14px;
      }

      &.archived {
        color: colors-old.$inactive-text-color;
      }
    }
  }
}
</style>
