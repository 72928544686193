import { CSSProperties, Ref, computed } from "vue";

import { cardZoomHeight, stickySize, zoomFactor } from "@/Settings";
import { boardKey, boardMetaKey } from "@/components/card/injectKeys";
import { cardInsideBoardAndViewport } from "@/math/coordinate-systems";
import { Board, isBacklogBoard } from "@/model/board";
import { Card, CardMeta } from "@/model/card";
import {
  BoardCoordinate,
  RelativeCoordinate,
  boardCoord,
} from "@/model/coordinates";
import { isFullyFaded } from "@/model/markMode";
import { useDraggingStore } from "@/store/dragging";
import { useZoomStore } from "@/store/zoom";
import variables from "@/styles/variable.module.scss";
import { contrastCssColor, toCssColor } from "@/utils/color";
import { injectStrict } from "@/utils/context";

type Args = Readonly<{
  card: Card;
  cardMeta: CardMeta;
  board: Ref<Board>;
  override: Ref<{ size: number; position: RelativeCoordinate } | undefined>;
  isEnlarged: Ref<Readonly<boolean>>;
  isActive: Ref<Readonly<boolean>>;
}>;

export const useStyles = ({
  card,
  board,
  cardMeta,
  override,
  isEnlarged,
  isActive,
}: Args) => {
  const boardData = injectStrict(boardKey);
  const boardMeta = injectStrict(boardMetaKey);

  const sizes = computed(() => {
    const normalSize =
      override.value?.size ||
      boardMeta.size.x * boardData.value.cardSize.factor * zoomFactor.x;
    const enlargedSize = isEnlarged.value
      ? (cardZoomHeight * boardMeta.size.y) / useZoomStore().factor
      : 0;
    return enlargedSize > normalSize
      ? { effective: enlargedSize, enlarged: enlargedSize / normalSize }
      : { effective: normalSize, enlarged: 1 };
  });
  const zIndex = computed(() => {
    if (isEnlarged.value) {
      return boardData.value.maxZ + 100;
    }

    return isFullyFaded(cardMeta.mark) && !isActive.value
      ? +variables.zIndexLinks - 1
      : cardMeta.zIndex;
  });

  const draggingPosition = computed(() => {
    return useDraggingStore().dragging[card.id]?.pos;
  });

  const position = computed(() => {
    const relative =
      override.value?.position || draggingPosition.value || cardMeta.pos;
    const size = sizes.value.effective;

    const pos = boardCoord(
      relative.x * boardMeta.size.x - size / 2,
      relative.y * boardMeta.size.y - size / 2,
    );

    let offset = boardCoord(0, 0);

    if (isEnlarged.value) {
      const o = cardInsideBoardAndViewport(pos, { width: size, height: size });
      offset = boardCoord(o.offsetX, o.offsetY);
    }

    if (!override.value?.position) {
      const posFix = fixPositionBoardOverflow(pos, boardMeta.size, size);
      return { ...posFix, offset };
    }

    return { ...pos, offset };
  });

  const backgroundColor = computed(() => {
    if (isBacklogBoard(board.value.type) && card.teamId) {
      return card.type.altColor;
    }
    return card.type.color;
  });
  const contrastingTextColor = computed(() =>
    contrastCssColor(backgroundColor.value),
  );

  const styles = computed<CSSProperties>(() => ({
    zIndex: zIndex.value,
    color: contrastingTextColor.value,
    backgroundColor: toCssColor(backgroundColor.value),
    top: position.value.y + "px",
    left: position.value.x + "px",
    transform: `scale(${sizes.value.effective / stickySize})`,
  }));

  return { styles, sizes, position };
};

// make sure that the sticky is inside the board
function fixPositionBoardOverflow(
  pos: BoardCoordinate,
  boardSize: BoardCoordinate,
  stickySize: number,
) {
  const newPos = { ...pos };

  const overflowBottom = stickySize + pos.y > boardSize.y;
  const overflowTop = pos.y < 0;
  const overflowRight = stickySize + pos.x > boardSize.x;
  const overflowLeft = pos.x < 0;

  if (overflowBottom) {
    newPos.y = boardSize.y - stickySize;
  }

  if (overflowTop) {
    newPos.y = 0;
  }

  if (overflowRight) {
    newPos.x = boardSize.x - stickySize;
  }

  if (overflowLeft) {
    newPos.x = 0;
  }

  return newPos;
}
