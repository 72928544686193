<script lang="ts" setup>
import { computed } from "vue";

import { getRiskScenarioColor } from "@/coconut/utils/color";

type Props = {
  score: number;
  maxScore: number;
  fluid?: boolean;
};
const props = defineProps<Props>();

const width = computed(() => {
  const minWidth = 24;
  const val = Math.floor((props.score / props.maxScore) * 100);
  return Math.max(minWidth, val);
});
const backgroundColor = computed(() => getRiskScenarioColor(props.score));
</script>

<template>
  <div class="risk-progress-badge">
    <div class="score" :style="{ width: `${width}px`, backgroundColor }">
      <span>{{ Math.floor(score) }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/mixins/typography";
@use "@/styles/variables/colors";

.risk-progress-badge {
  width: 100px;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  margin-left: 12px;

  .score {
    @include typography.small("extra-bold");

    height: 24px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    color: colors.$text-inverse;

    span {
      text-align: center;
      padding: 0 5px;
      min-width: 25px;
    }
  }
}
</style>
