<script setup lang="ts">
import { computed, ref } from "vue";

import BaseBoardInput from "./BaseBoardInput.vue";

const props = defineProps<{
  value: number;
  maxlength: number;
  noBorders?: boolean;
  allowFloat?: boolean;
}>();
const emit = defineEmits<{ input: [value: number] }>();

const rootRef = ref<HTMLElement>();

const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
const numbers = computed(() =>
  props.allowFloat ? allowedChars : allowedChars.slice(0, -1),
);

const inputValue = computed({
  get: () => "" + props.value,
  set(value: string) {
    emit("input", +value);
  },
});

function keyPressed(event: KeyboardEvent) {
  if (!numbers.value.includes(event.key)) {
    event.preventDefault();
  }
}

function unfocus() {
  rootRef.value!.blur();
}
</script>

<template>
  <BaseBoardInput
    ref="rootRef"
    v-model="inputValue"
    class="board-number-input"
    type="text"
    :maxlength="maxlength"
    :no-borders="noBorders"
    @keypress="keyPressed"
    @keydown.esc.stop="unfocus"
  />
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.board-number-input {
  &:read-only {
    border: none;
    cursor: default;
    background-color: colors-old.$input-readonly-background-color;
  }
}
</style>
