import { defineStore } from "pinia";

import { Card } from "@/model/card";
import { StickyChange } from "@/model/change";

import { useCardStore } from "./card";

export const useActivityStore = defineStore("activity", {
  state: () => ({
    active: false,
    card: null as Card | null,
    stickyChanges: [] as StickyChange[],
    incomplete: false,
  }),
  actions: {
    setStickyChanges(changes: StickyChange[]) {
      const len = changes.length;
      this.incomplete = len > 0 && changes[len - 1].kind !== "create";
      // if we have an incomplete history, remove the first change
      // as it's an artificial change from "no fields" to "fields at the moment sticky activity was implemented"
      if (this.incomplete) {
        changes.splice(len - 1, 1);
      }
      this.stickyChanges = changes;
    },
    toggleCard(id: string) {
      if (this.active && this.card?.id === id) {
        this.inactivate();
      } else {
        this.active = true;
        this.card = useCardStore().cards[id];
      }
    },
    inactivate() {
      this.active = false;
      this.card = null;
      this.stickyChanges = [];
    },
  },
});
