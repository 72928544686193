<script setup lang="ts">
import { computed, ref } from "vue";

const props = defineProps<{
  modelValue: string;
  type: string;
  maxlength: number;
  noBorders?: boolean;
}>();
const emit = defineEmits<{ "update:modelValue": [value: string] }>();

const rootRef = ref<HTMLElement>();

defineExpose({
  blur: () => rootRef.value?.blur(),
});

const inputValue = computed({
  get: () => props.modelValue,
  set(value: string) {
    emit("update:modelValue", value);
  },
});

function focused(e: FocusEvent) {
  const target = e.target as HTMLInputElement;
  target.setSelectionRange(0, ("" + target.value).length);
}
</script>

<template>
  <input
    ref="rootRef"
    v-bind="$attrs"
    v-model="inputValue"
    :type="type"
    :maxlength="maxlength"
    class="base-board-input"
    :class="{ 'has-borders': !noBorders }"
    autocomplete="false"
    @focus="focused"
  />
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.base-board-input {
  cursor: initial;
  background-color: transparent;
  outline: none;
  width: 100%;
  font-weight: font.$weight-semi-bold;

  &.has-borders {
    border: board.len(3px) solid colors-old.$input-border-color;
    border-radius: board.len(10px);
    height: 100%;
  }

  &:focus {
    border-color: colors-old.$input-focus-border-color;
  }
}
</style>
