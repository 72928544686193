<script setup lang="ts">
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { Toast, useToastStore } from "@/store/toast";

defineProps<Toast>();

function close() {
  useToastStore().hide(true);
}
</script>

<template>
  <div v-if="messageKey" id="message" data-no-screenshot :class="{ invisible }">
    <div v-if="closeable" class="close">
      <SvgIcon name="close" width="15" height="15" @click="close" />
    </div>
    <div v-if="icon">
      <SvgIcon :name="icon" width="20" height="20" />
    </div>
    <div v-if="imageUrl">
      <img :src="imageUrl" />
    </div>
    <div role="alert">
      <div v-if="titleKey" class="title">{{ $t(titleKey) }}</div>
      {{ $t(messageKey) }}
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/mixins" as *;
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

#message {
  display: flex;
  align-items: center;
  gap: 1em;
  position: fixed;
  bottom: 4em;
  left: 50%;
  transform: translateX(-50%);
  max-width: 35em;
  padding: 0.5em 1em;
  z-index: z-index.$menu;
  background-color: colors-old.$back-color;
  border-radius: 10px;

  @include default-shadow;

  // Notifications intended only for screen reader users
  &.invisible {
    z-index: -1;
    opacity: 0;
  }

  .title {
    font-weight: font.$weight-bold;
    margin-bottom: 0.5em;
  }

  img {
    width: 40px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}
</style>
