<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { Objective, ObjectiveType } from "@/model/objective";
import { Team } from "@/model/session";
import { useBoardStore } from "@/store/board";
import { planReadoutSelectObjective } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import ListFooter from "./ListFooter.vue";
import ListHeader from "./ListHeader.vue";
import PresentationObjectiveListItem from "./PresentationObjectiveListItem.vue";

type ObjectiveList = {
  type: ObjectiveType;
  label: string;
  items: Objective[];
};

type Props = {
  team: Team;
  selectedObjective: Objective | null;
};
const props = defineProps<Props>();

type Emits = {
  select: [objective: Objective | null];
};
const emit = defineEmits<Emits>();

const { t } = useI18n();

const collapsedLists = ref(
  Object.fromEntries(["committed", "uncommitted"].map((type) => [type, false])),
);

watch(
  () => props.team,
  () => resetListView(),
);

const scrollContainerEl = ref<HTMLElement | null>(null);

const objectiveLists = computed(() => {
  const board = useBoardStore().boardByType("team", { teamId: props.team.id });

  return [
    {
      type: "committed" as ObjectiveType,
      label: t("objectives.committed"),
      items: board.objectives,
    },
    {
      type: "uncommitted" as ObjectiveType,
      label: t("objectives.uncommitted"),
      items: board.stretchObjectives,
    },
  ];
});

const objectivesSorted = computed(() => {
  const sortedObjectives = objectiveLists.value.flatMap((list) => list.items);

  checkSelectedObjective(sortedObjectives);

  return sortedObjectives;
});

const checkSelectedObjective = (sortedObjectives: Objective[]) => {
  if (
    !sortedObjectives.find((objective) => objective === props.selectedObjective)
  ) {
    selectFirstObjective(sortedObjectives);
  }
};

const selectFirstObjective = (sortedObjectives: Objective[]) => {
  const firstObjective = sortedObjectives[0];
  if (firstObjective) {
    emit("select", firstObjective);
  }
};

const selectObjective = (objective: Objective, type: ObjectiveType) => {
  emit("select", objective);

  trackEvent(planReadoutSelectObjective(objective.id, type));
};

const toggleList = (list: ObjectiveList) => {
  if (!isListDisabled(list)) {
    collapsedLists.value[list.type] = !collapsedLists.value[list.type];
  }
};

/**
 * Show all lists as expanded and scroll to top
 */
const resetListView = () => {
  scrollContainerEl.value?.scrollTo({ top: 0, behavior: "smooth" });
  for (const type in collapsedLists.value) {
    collapsedLists.value[type] = false;
  }
};

const isListDisabled = (list: ObjectiveList) => !list.items.length;
</script>

<template>
  <div class="list-container">
    <div ref="scrollContainerEl" class="scroll-container">
      <header>
        <h2 class="title">
          {{ $t("planReadoutModal.objectives.title", { teamName: team.name }) }}
        </h2>
        <div class="subtitle">
          {{ $t("objectives.count", objectivesSorted.length) }}
        </div>
      </header>

      <template v-for="list in objectiveLists" :key="list.type">
        <div class="collapsible-container">
          <ListHeader
            :collapsed="collapsedLists[list.type]"
            :disabled="isListDisabled(list)"
            :title="list.label"
            :subtitle="$t('objectives.count', list.items.length)"
            @click.stop="toggleList(list)"
          />
          <SelectableList
            v-if="!collapsedLists[list.type]"
            variant="cards"
            :values="list.items"
            :model-value="selectedObjective"
            @update:model-value="selectObjective($event, list.type)"
          >
            <template #default="{ value, active }">
              <PresentationObjectiveListItem
                v-if="value"
                :item="value"
                :type="list.type"
                :selected="active"
              />
            </template>
          </SelectableList>
        </div>
      </template>
    </div>

    <ListFooter keypath="planReadoutModal.listFooter.objectives" />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .scroll-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 32px;
    overflow-y: auto;
  }
}

.title,
.subtitle {
  padding-left: 32px;
}

.title {
  font-weight: font.$weight-extra-bold;
  font-size: font.$size-large;
  line-height: 20px;
  margin-top: unset;
  margin-bottom: 8px;
}

.subtitle {
  color: colors-old.$text-secondary-color;
  font-weight: font.$weight-semi-bold;
  font-size: font.$size-small;
  line-height: 15px;
}

.collapsible-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

// Remove the default SelectableList list-item padding
:deep(.list-item) {
  padding: 0;
}
</style>
