<template>
  <span class="scaling-text" :style="{ maxWidth: maxWidth + 'px' }">{{
    text
  }}</span>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Options as Component, Prop, Watch } from "vue-property-decorator";

import { optimalOneLineFontSize } from "@/fontSizeOptimizer";

@Component({})
export default class ScalingText extends Vue {
  @Prop(String) readonly text!: string;
  @Prop(Number) readonly maxWidth!: number;
  @Prop(Number) readonly minScale!: number;
  @Prop(Number) readonly maxScale!: number;

  @Watch("text", { immediate: true })
  textChanged() {
    this.$nextTick(() => {
      optimalOneLineFontSize(
        this.$el as HTMLElement,
        this.maxWidth,
        this.minScale,
        this.maxScale,
      );
    });
  }
}
</script>

<style lang="scss">
.scaling-text {
  white-space: nowrap;
  display: inline-block;
}
</style>
