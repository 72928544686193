<script async setup lang="ts">
import { computed } from "vue";

import { Icon } from "@/types/icon";

import { getIconComponent } from "./loader";

type Props = { name: Icon };
const props = defineProps<Props>();

const IconComponent = computed(() => getIconComponent(props.name));
</script>

<template>
  <IconComponent />
</template>
