import { round } from "lodash-es";
import { defineStore } from "pinia";

import { mapReactions } from "@/backend/BackendDataMapper";
import CardFlag from "@/model/CardFlag";
import { AlmSourceId, Id, IdMap, StatusClass } from "@/model/baseTypes";
import { Board, BoardId } from "@/model/board";
import { Card, CardMeta, Reaction, Reactions } from "@/model/card";
import { RelativeCoordinate } from "@/model/coordinates";
import { StickyType } from "@/model/stickyType";
import { PersonalUser } from "@/model/user";
import { captureMessage } from "@/sentry";
import { loadUserImmediate } from "@/services/user.service";
import { firstValue } from "@/utils/general";

import { useAlmItemTypeStore } from "./almItemType";
import { useBoardStore } from "./board";
import { useLinkStore } from "./link";
import { useStickyTypeStore } from "./stickyType";
import { useTeamStore } from "./team";
import { useUserStore } from "./user";

export type CardEvent = Id & { pos: RelativeCoordinate } & BoardId &
  Partial<Card> &
  Pick<
    Card,
    | "type"
    | "priority"
    | "teamId"
    | "iterationId"
    | "flagType"
    | "objectives"
    | "reactions"
  > &
  Pick<CardMeta, "zIndex" | "shouldAnimate">;

export const useCardStore = defineStore("card", {
  state: () => ({ cards: {} as IdMap<Card> }),
  getters: {},
  actions: {
    add(cardProperties: CardEvent) {
      const board = useBoardStore().boards[cardProperties.boardId];
      const id = cardProperties.id;
      const card: Card = {
        id,
        groupId: cardProperties.groupId,
        status: cardProperties.status,
        text: cardProperties.text || "",
        points: cardProperties.points || 0,
        priority: cardProperties.priority,
        iterationId:
          cardProperties.iterationId === undefined
            ? null
            : cardProperties.iterationId,
        editor: null,
        links: useLinkStore().linksByCard(cardProperties.groupId || id),
        type: cardProperties.type,
        flagType: cardProperties.flagType,
        almId: cardProperties.almId || "",
        almSourceId: cardProperties.almSourceId ?? null,
        almIssueUrl: cardProperties.almIssueUrl,
        teamId: cardProperties.teamId || null,
        precondTeam: cardProperties.precondTeam,
        dependTeam: cardProperties.dependTeam,
        risk: cardProperties.risk,
        objectives: cardProperties.objectives || [],
        reactions: cardProperties.reactions,
        artId: cardProperties.artId || null,
        assignee: cardProperties.assignee || null,
        reporter: cardProperties.reporter || null,
      };
      const cardMeta: CardMeta = {
        pos: cardProperties.pos,
        zIndex: cardProperties.zIndex || board.maxZ,
        mark: "normal",
        editing: false,
        dragging: false,
        isHighlighted: false,
        isRelatedToHighlighted: false,
        dependencyTeamFilter: null,
        shouldAnimate: cardProperties.shouldAnimate,
      };
      this.cards[id] = card;
      board.cards[id] = { data: card, meta: cardMeta };
      if (cardProperties.zIndex > board.maxZ) {
        board.maxZ = cardProperties.zIndex;
      }
      if (cardProperties.groupId) {
        useLinkStore().addToLinkGroup(
          cardProperties.groupId,
          cardProperties.boardId,
          board.cards[id],
        );
      }
      useLinkStore().setCardLinkStates(card);
      this.updateLoad(card.id, card.iterationId, card.points);
      this.setType({
        id,
        type: cardProperties.type.id,
        boardId: cardProperties.boardId,
      });
      if (cardProperties.almSourceId !== undefined) {
        this.setAlmSource({ id, almSourceId: cardProperties.almSourceId });
      }
      return id;
    },

    setAlmSource(e: Id & { almSourceId: AlmSourceId | null }) {
      const card = this.cards[e.id];
      card.almSourceId = e.almSourceId ?? null;
    },

    setPriority(
      e: Id & {
        priority: number;
        priorities?: boolean;
      },
    ) {
      const prios = this.cards[e.id].type.priorities;
      const use = e.priorities === undefined ? !!prios : e.priorities;
      const priority = use
        ? e.priority || firstValue(prios!).value
        : round(e.priority || 0, 2);
      this.cards[e.id].priority = priority;
      return { use, priority };
    },

    setPoints(e: Id & { points: number }) {
      const card = this.cards[e.id];
      card.points = card.points || 0;
      const diff = e.points - card.points;
      card.points += diff;
      this.updateLoad(e.id, card.iterationId, diff);
    },

    setFlag(e: Id & { flagType: CardFlag }) {
      this.cards[e.id].flagType = e.flagType;
    },

    setRisk(e: Id & Pick<Card, "risk">) {
      this.cards[e.id].risk = e.risk;
    },

    setText(e: Id & { text: string }) {
      this.cards[e.id].text = e.text;
    },

    setIteration(e: Id & { iterationId: number | null }) {
      const card = this.cards[e.id];
      if (card.iterationId !== e.iterationId) {
        this.updateLoad(e.id, card.iterationId, -card.points);
        this.updateLoad(e.id, e.iterationId, card.points);
        card.iterationId = e.iterationId;
        useLinkStore().setCardLinkStates(card);
        return true;
      }
    },

    setTeam(e: Id & { teamId: string | null }) {
      const card = this.cards[e.id];
      card.teamId = e.teamId;
      useLinkStore().setCardLinkStates(card);
    },

    setArt(e: Id & { artId: string | null }) {
      const card = this.cards[e.id];
      card.artId = e.artId;
      useLinkStore().setCardLinkStates(card);
    },

    setPrecondTeam(e: Id & { teamId?: string; teamName?: string }) {
      this.cards[e.id].precondTeam = useTeamStore().findTeam({
        id: e.teamId,
        name: e.teamName,
      });
    },

    setDependTeam(e: Id & { teamId?: string; teamName?: string }) {
      this.cards[e.id].dependTeam = useTeamStore().findTeam({
        id: e.teamId,
        name: e.teamName,
      });
    },

    setDependAction(e: Id & { teamId: string }) {
      const boardStore = useBoardStore();
      const board = boardStore.boardByType("team", { teamId: e.teamId });
      const card = this.cards[e.id];
      card.flagType = new CardFlag("flag", 0);
      card.precondTeam = useTeamStore().currentTeam;
      card.dependTeam = board.team;
      return { board, card };
    },

    setType(e: Id & { boardId?: string; type: string }) {
      const card = this.cards[e.id];
      const board = e.boardId
        ? useBoardStore().boards[e.boardId]
        : useBoardStore().currentBoard();
      const type = useStickyTypeStore().findStickyType(
        board.stickyTypes,
        e.type,
      );
      const changes: {
        board: Board;
        props: Partial<Card>;
        priorities: boolean;
      } = {
        board,
        props: {
          type,
          teamId: card.teamId,
          iterationId: card.iterationId,
        },
        priorities: false,
      };
      if (card.type && card.type.priorities !== type.priorities) {
        const { priority, use } = this.setPriority({
          id: e.id,
          priority: type.priorities ? firstValue(type.priorities).value : 0,
          priorities: !!type.priorities,
        });
        changes.props.priority = priority;
        changes.priorities = use;
      }
      this.updateLoad(e.id, card.iterationId, card.points, type);
      card.type = type;
      if (board.type === "team" && card.type.origin === "backlog") {
        const backlogAlmSources =
          useBoardStore().boardByType("backlog").almSources;
        if (backlogAlmSources.length === 1) {
          changes.props.almSourceId = backlogAlmSources[0].id;
        } else if (backlogAlmSources.length === 0) {
          changes.props.almSourceId = null;
        }
      }
      if (
        type.functionality === "dependency" &&
        card.teamId &&
        !card.precondTeam
      ) {
        card.precondTeam = useTeamStore().findTeam({ id: card.teamId });
        changes.props.precondTeam = card.precondTeam;
      }
      if (type.functionality === "workitem" && !card.status) {
        card.status = useAlmItemTypeStore().calcStatus(
          undefined,
          type,
          card.teamId,
          board.artId,
          card.almSourceId,
        );
        changes.props.status = card.status;
      }
      if (type.functionality === "note" && card.reactions === undefined) {
        useCardStore().setReactions(e.id, mapReactions({}));
      }
      return changes;
    },

    setObjectives(
      cardId: Card["id"],
      objectives: Card["objectives"] | undefined,
    ) {
      if (!objectives) {
        return;
      }
      const card = this.cards[cardId];
      if (!card) {
        captureMessage("Tried to set objectives but it is not in the state", {
          info: { cardId },
        });
        return;
      }
      card.objectives = [...objectives];
    },

    setGroup(e: Id & { groupId: string }) {
      const card = this.cards[e.id];
      if (card) {
        // TODO a bug in the backend
        card.groupId = e.groupId;
        for (const boardId in useBoardStore().boards) {
          const board = useBoardStore().boards[boardId];
          const boardCard = board.cards[e.id];
          if (boardCard) {
            useLinkStore().addToLinkGroup(e.groupId, boardId, boardCard);
          }
        }
      }
    },

    setAlmId(e: Id & { almId: string; almIssueUrl?: string }) {
      const card = this.cards[e.id];
      card.almId = e.almId;
      card.almIssueUrl = e.almIssueUrl;
    },

    readOnly(
      e: Id & ({ readOnly: true; userId: string } | { readOnly: false }),
    ) {
      const card = this.cards[e.id];
      if (card) {
        // card might just have been deleted
        if (e.readOnly) {
          card.editor = loadUserImmediate({ id: e.userId });
        } else {
          card.editor = null;
        }
      }
    },

    setStatus(
      board: Board,
      cardId: string,
      statusName: string,
      options: { transition?: string; statusClass?: StatusClass } = {},
    ) {
      const card = this.cards[cardId];
      const status = useAlmItemTypeStore().findStatusForCard(
        statusName,
        card,
        board,
        options.statusClass,
      );
      card.status = status;
      card.transition = options.transition;
      return status;
    },

    updateCardsStatus(card: Card, board: Board) {
      if (!card.status) {
        return;
      }
      const status = useAlmItemTypeStore().findStatusForCard(
        card.status.name,
        card,
        board,
      );
      for (const id in this.cards) {
        const c = this.cards[id];
        if (hasSameStatus(c, card)) {
          c.status = status;
        }
      }

      function hasSameStatus(a: Card, b: Card) {
        return (
          a.status?.id === b.status?.id &&
          a.type.id === b.type.id &&
          a.teamId === b.teamId &&
          a.almSourceId === b.almSourceId
        );
      }
    },

    hasCurrentUserReaction(cardId: string, reaction: Reaction): boolean {
      return (
        this.cards[cardId].reactions?.[reaction]?.some(
          (user) => user.id === useUserStore().technicalUser.id,
        ) ?? false
      );
    },

    setReactions(cardId: string, reactions: Reactions) {
      this.cards[cardId].reactions = reactions;
    },

    setAssignee(cardId: string, user: PersonalUser | null) {
      this.cards[cardId].assignee = user;
    },

    setReporter(cardId: string, user: PersonalUser | null) {
      this.cards[cardId].reporter = user;
    },

    delete({ id: cardId, boardId }: Id & BoardId) {
      const card = this.cards[cardId];
      const board = useBoardStore().boards[boardId];
      const existsOnTheBoard = board && board.cards[cardId];

      if (existsOnTheBoard && useBoardStore().activeCardId === cardId) {
        useBoardStore().setActiveCardId(null);
      }
      if (board) {
        delete board.selected[cardId];
      }
      if (!card || !existsOnTheBoard) {
        // might already be deleted
        return false;
      }
      useLinkStore().removeFromLinkGroup(card, boardId);
      this.updateLoad(cardId, card.iterationId, -card.points);
      delete this.cards[cardId];
      for (const b in useBoardStore().boards) {
        delete useBoardStore().boards[b].cards[cardId];
      }
      return true;
    },

    updateLoad(
      cardId: string,
      iterId: number | null | undefined,
      diff: number,
      newType?: StickyType,
    ) {
      const oldType = this.cards[cardId].type;
      if (newType) {
        if (hasLoad(oldType) && !hasLoad(newType)) {
          diff = -diff;
        }
        if (hasLoad(oldType) === hasLoad(newType)) {
          return;
        }
      } else {
        if (!hasLoad(oldType)) {
          return;
        }
      }
      // eslint-disable-next-line eqeqeq
      if (iterId != null) {
        const iters = useBoardStore().findIterations(cardId);
        if (iters) {
          const iter = iters[iterId];
          if (iter) {
            iters[iterId] = {
              velocity: iter.velocity,
              load: iter.load + diff,
              state: iter.state,
            };
          }
        }
      }

      function hasLoad(type: StickyType) {
        return (
          type.functionality !== "risk" && type.functionality !== "dependency"
        );
      }
    },
  },
});

export function mirrorOriginId(card: Card) {
  if (card.groupId && card.groupId !== card.id) {
    return card.groupId;
  }
}

export function isCardFromOtherTeam(card: Card, teamId?: string) {
  return card.type.origin === "team" && !!teamId && teamId !== card.teamId;
}
