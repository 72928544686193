import {
  BasePattern,
  BoardPattern,
  IterationPattern,
  StickyNotePattern,
  TeamPattern,
} from "../types";

export function isStickyScopePattern(
  pattern: BasePattern,
): pattern is StickyNotePattern {
  return pattern.type === "sticky-note";
}

export function isTeamScopePattern(
  pattern: BasePattern,
): pattern is TeamPattern {
  return pattern.type === "team";
}

export function isIterationScopePattern(
  pattern: BasePattern,
): pattern is IterationPattern {
  return pattern.type === "iteration";
}

export function isBoardScopePattern(
  pattern: BasePattern,
): pattern is BoardPattern {
  return pattern.type === "board";
}
