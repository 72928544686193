<template>
  <div class="overlay types scrollable no-wheel" @wheel="stopScrollPropagation">
    <div
      v-for="(type, index) in types"
      :id="optionId(type)"
      :key="type.id"
      @click.stop="select(type)"
      @pointermove="selectIndex = index"
      @pointerdown.stop
    >
      <type-dot class="absolute" :color="type.color" />
      <span
        class="entry"
        :class="{
          selected: selected === type,
          current: index === selectIndex,
        }"
        >{{ type.name }}</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import TypeDot from "@/components/TypeDot.vue";
import ScrollSupport from "@/mixins/ScrollSupport";
import SelectList from "@/mixins/SelectList";
import { StickyType } from "@/model/stickyType";

@Component({ components: { TypeDot }, emits: ["select"] })
export default class CardTypesOverlay extends mixins(
  SelectList,
  ScrollSupport,
) {
  @Prop(Array) readonly types!: StickyType[];
  @Prop(Object) readonly selected!: StickyType;

  mounted() {
    this.selectList = this.types;
    this.adjustScrollbarWidth();
  }

  select(action: StickyType) {
    this.$emit("select", action);
  }

  optionId(type: StickyType) {
    return "typesOverlay-" + type.id;
  }
}
</script>

<style lang="scss">
.overlay.types {
  & > div {
    position: relative;
  }

  .entry {
    text-align: left;
  }
}
</style>
