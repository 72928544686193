<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { AuthUser, isAlmUser, isUnmappedUser } from "@/model/user";
import colors from "@/styles/color.module.scss";
import { fromCssColor, isDarkColor } from "@/utils/color";

import SvgIcon from "./SvgIcon/SvgIcon.vue";

const props = withDefaults(
  defineProps<{
    user: AuthUser;
    neutralColor?: boolean;
    size?: "small" | "medium" | "big";
  }>(),
  { size: "medium" },
);

const isImageUrlValid = ref(true);

const { t } = useI18n();
const imageUrl = computed(() => props.user.imageUrl);
const name = computed(() => props.user.name || t("boardUsers.unknown"));
const letter = computed(() => name.value.substring(0, 1).toUpperCase());
const backgroundColor = computed(() =>
  props.neutralColor || !props.user.color ? colors.avatar : props.user.color,
);

const color = computed(() =>
  isDarkColor(fromCssColor(backgroundColor.value))
    ? colors.backColor
    : colors.textPrimary,
);

// Icon of ALM backend user or unmapped user
const svgIconName = computed(() =>
  isAlmUser(props.user)
    ? props.user.iconName
    : isUnmappedUser(props.user)
      ? "unmapped-user"
      : undefined,
);
</script>

<template>
  <div class="avatar" :class="size">
    <img
      v-if="isImageUrlValid && imageUrl"
      data-testid="avatar-image"
      :src="imageUrl"
      :alt="name"
      @error="isImageUrlValid = false"
    />
    <SvgIcon
      v-else-if="svgIconName"
      role="img"
      :name="svgIconName"
      :aria-label="name"
    />
    <span v-else :style="{ backgroundColor, color }">{{ letter }}</span>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &.small {
    width: 20px;
    height: 20px;

    span {
      font-size: font.$size-smaller;
      font-weight: font.$weight-semi-bold;
    }
  }

  &.medium {
    width: 24px;
    height: 24px;
    outline: 2px solid var(--back-color);

    span {
      font-size: font.$size-small;
      font-weight: font.$weight-semi-bold;
    }
  }

  &.big {
    width: 32px;
    height: 32px;

    span {
      font-size: font.$size-large;
      font-weight: font.$weight-normal;
    }
  }
}
</style>
