<script setup lang="ts">
import { Drag, registerDrag } from "@/Gestures";
import { cardActions } from "@/action/cardActions";
import TypeDot from "@/components/TypeDot.vue";
import { windowToRelative } from "@/math/coordinate-systems";
import { minus, windowCoord } from "@/model/coordinates";
import { StickyType } from "@/model/stickyType";
import { useDraggingStore } from "@/store/dragging";
import { stickyNoteCreated } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

defineEmits<{ create: [type: StickyType]; select: [type: StickyType] }>();
const props = defineProps<{ current: boolean; type: StickyType }>();

function pointerDown(e: PointerEvent) {
  registerDrag<void>({}, e, {
    start(drag) {
      const { functionality, origin } = props.type;
      trackEvent(
        stickyNoteCreated(functionality, origin, "board-actions-menu-drag"),
      );

      const cardProps = { pos: minus(drag.start), type: props.type };
      cardActions
        .add("dragDrop", cardProps, false, "board-actions-menu-drag")
        .then((id?: string) => id && startCardDrag(id, drag));
      return false;
    },
    move(drag) {
      return drag.pos;
    },
    stop() {},
  });
}

function startCardDrag(id: string, drag: Drag<any>) {
  const clientX = -drag.start.x;
  const clientY = -drag.start.y;
  const pos = windowToRelative(windowCoord(clientX, clientY));

  useDraggingStore().startCardDragging(id, { fromPalette: true }, pos);
  const elem = document.getElementById(id)!;
  elem.dispatchEvent(
    new PointerEvent("pointerdown", {
      pointerId: drag.pointerId,
      buttons: 1,
      clientX,
      clientY,
    }),
  );
}
</script>

<template>
  <button
    :aria-label="$t(type.name)"
    role="menuitem"
    tabindex="0"
    class="add-item-card action"
    :class="{ current }"
    @click.left.stop="$emit('create', type)"
    @click.right.prevent="$emit('select', type)"
    @pointerdown="pointerDown"
  >
    <TypeDot size="large" :color="type.color" />
  </button>
</template>

<style lang="scss">
.add-item-card {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0;
  margin: auto;

  .type-dot {
    transition: 0.3s ease-in-out transform;
  }

  &:hover {
    .type-dot {
      transform: scale(1.15);
    }
  }

  &:focus-visible {
    outline: revert;
  }
}
</style>
