<template>
  <div
    :id="board.id"
    class="board board-risk"
    :aria-label="$t('board.risk')"
    tabindex="0"
    @click="$el?.blur()"
  >
    <loading-indicator v-if="!board.loaded" global />
    <link-layers :board="board" :color="linkColor" />
    <div
      class="backdrop risk-board-grid"
      @dblclick="overview(eventLocation($event))"
    >
      <section
        v-owned-cards="{ board, location: location(0) }"
        class="field left"
        tabindex="-1"
        aria-labelledby="risks-heading-0"
        @click="blurCurrentTarget"
      >
        <h2 id="risks-heading-0" class="h3" tabindex="-1">
          {{ location(0).name }}
        </h2>
      </section>
      <section
        v-owned-cards="{ board, location: location(1) }"
        class="field right"
        tabindex="-1"
        aria-labelledby="risks-heading-1"
        @click="blurCurrentTarget"
      >
        <h2 id="risks-heading-1" class="h3" tabindex="-1">
          {{ location(1).name }}
        </h2>
      </section>
      <section
        v-owned-cards="{ board, location: location(2) }"
        class="field left"
        tabindex="-1"
        aria-labelledby="risks-heading-2"
        @click="blurCurrentTarget"
      >
        <h2 id="risks-heading-2" class="h3" tabindex="-1">
          {{ location(2).name }}
        </h2>
      </section>
      <section
        v-owned-cards="{ board, location: location(3) }"
        class="field right"
        tabindex="-1"
        aria-labelledby="risks-heading-3"
        @click="blurCurrentTarget"
      >
        <h2 id="risks-heading-3" class="h3" tabindex="-1">
          {{ location(3).name }}
        </h2>
      </section>
      <section
        v-owned-cards="{ board, location: location(4) }"
        class="field full"
        tabindex="-1"
        aria-labelledby="risks-heading-4"
        @click="blurCurrentTarget"
      >
        <h2 id="risks-heading-4" class="h3" tabindex="-1">
          {{ location(4).name }}
        </h2>
      </section>
    </div>
    <template v-if="isNewStickyNoteEnabled">
      <StickyNote
        v-for="card in board.cards"
        :key="card.data.id"
        :card="card.data"
        :card-meta="card.meta"
        :level-of-details="levelOfDetails"
      />
    </template>

    <template v-else>
      <card
        v-for="card in board.cards"
        :key="card.data.id"
        :draggable="!readOnly"
        :card="card.data"
        :meta="card.meta"
        :color="card.data.type.color"
        :link-color="linkColor"
        :height="board.cardSize.y * height"
        :width="board.cardSize.x * width"
        :board="board"
        :board-width="width"
        :board-height="height"
        :actions="actions"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";

import { ActionSource } from "@/action/actions";
import { toggleActions } from "@/action/toggleActions";
import StickyNote from "@/components-ng/StickyNote/StickyNote.vue";
import { isFeatureEnabled } from "@/feature";
import { relativeClientCoord } from "@/math/coordinate-systems";
import { normalLinkColors } from "@/model/colors";
import { RelativeCoordinate } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";

import FluidBoard, { ContextInfo } from "./FluidBoard";
import LinkLayers from "./LinkLayers.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import { RiskBoardLocation } from "./RiskBoardLocation";
import { ActionType } from "./card/actions";

@Component({
  components: { LinkLayers, LoadingIndicator, StickyNote },
})
export default class RiskBoard extends mixins(FluidBoard) {
  linkColor = normalLinkColors.risk;
  actions: ActionType[] = [
    "delete",
    "close",
    "almSource",
    "mirror",
    "link",
    "dragLink",
    "showTeam",
  ];

  get board() {
    return useBoardStore().boardByType("risk");
  }

  location(c: RelativeCoordinate | number) {
    return RiskBoardLocation.of(c);
  }

  eventLocation(e: MouseEvent) {
    return this.location(relativeClientCoord(e));
  }

  get isNewStickyNoteEnabled() {
    return isFeatureEnabled(this.$route, "sticky-note");
  }

  contextActions(c?: RelativeCoordinate): ContextInfo {
    const actions: ContextInfo = {
      syncProgramBacklog: false,
      draw: true,
      selection: {
        stickyMove: true,
        link: true,
        mirror: false,
        team: false,
      },
    };
    if (c) {
      const loc = this.location(c);
      actions.region = {
        name: loc.name,
        arrange: true,
        overview: true,
        sync: false,
        zoom: true,
      };
    }
    return actions;
  }

  overview(loc: RiskBoardLocation, source: ActionSource = "mouse") {
    const attrs = {
      boardId: this.board.id,
      location: loc.index(),
    };
    toggleActions.showOverview(source, attrs);
  }

  blurCurrentTarget(e: MouseEvent) {
    (e.currentTarget as HTMLElement)?.blur();
  }
}
</script>
<style scoped lang="scss">
@use "@/styles/mixins/a11y";

.board {
  @include a11y.board;

  .risk-board-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 35% 15%;
  }

  // Define section positions
  .field {
    @include a11y.board-section;

    position: relative;

    // Header positions are a overly-specific to match current percentage-based positions
    > h2 {
      position: absolute;
      top: 2.7em;
    }

    &.left > h2 {
      left: 3.2em;
    }

    &.right > h2 {
      right: 3.2em;
    }

    &.full {
      grid-column: 1 / span 2;

      > h2 {
        top: 1em;
        left: 3.2em;
      }
    }
  }
}
</style>
