<script lang="ts" setup>
import { computed } from "vue";

import GaugeChart from "./GaugeChart.vue";

type Props = {
  value: number;
  size: "small" | "medium" | "large";
  text: string;
};

const props = withDefaults(defineProps<Props>(), {
  size: "small",
});

const fixedValue = computed(() => Math.floor(props.value));
</script>

<template>
  <div :class="['risk-score-meter', size]">
    <div class="chart">
      <GaugeChart :value="fixedValue" />
      <div class="chart-value">{{ fixedValue }}</div>
    </div>
    <div class="risk-score-labels">
      <span class="risk-score-extreme">Low</span>
      <span class="risk-score-title">{{ text }}</span>
      <span class="risk-score-extreme">High</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";
@use "@/styles/mixins/typography";

.risk-score-meter {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  .chart {
    position: relative;

    .chart-value {
      @include typography.large("bold");

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
    }
  }

  .risk-score-labels {
    @include typography.small("semi-bold");

    padding: 0 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: colors.$text;

    .risk-score-title {
      margin: 0 auto;
    }

    .risk-score-extreme {
      @include typography.small("semi-bold");

      display: none;
      color: colors.$text-subtle;
    }
  }

  &.small {
    .chart {
      width: 100px;
    }
  }

  &.medium {
    .chart {
      width: 160px;
    }
  }

  &.large {
    gap: 10px;

    .chart {
      width: 280px;

      .chart-value {
        @include typography.display("extra-bold");
      }
    }

    .risk-score-labels {
      .risk-score-title {
        @include typography.medium("semi-bold");
      }

      .risk-score-extreme {
        display: block;
      }
    }
  }
}
</style>
