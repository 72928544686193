<template>
  <div>
    <SkipButton @click="boardComponent?.focus">
      {{ $t("label.skipToBoard") }}
    </SkipButton>
    <ToastMessage v-bind="toast" />
    <ContextMenu :board="boardComponent" />
    <menus />
    <mouse-pointers />
    <board-actions
      class="board-actions"
      :board="boardComponent"
      :class="{ 'displaced-left': sidePanelOpen }"
    />
    <connection-status />
    <div id="boards" class="boards">
      <div id="boards-border" />
      <DrawLayer v-if="showDrawLayer" />
      <zoom-layer ref="zoomLayer" />
      <paint-layer v-show="hasPaintLayer" />
      <transition name="fade">
        <keep-alive v-if="!!board">
          <component :is="board" ref="boardElem" />
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import { zoomHandler } from "@/ZoomHandler";
import SkipButton from "@/components-ng/a11y/SkipButton.vue";
import BacklogBoard from "@/components/BacklogBoard.vue";
import BoardActions from "@/components/BoardActions.vue";
import BoardBase from "@/components/BoardBase";
import ConnectionStatus from "@/components/ConnectionStatus.vue";
import ContextMenu from "@/components/ContextMenu/ContextMenu.vue";
import PaintLayer from "@/components/DefaultPaintLayer.vue";
import ZoomLayer from "@/components/DefaultZoomLayer.vue";
import DrawLayer from "@/components/Draw/DrawLayer.vue";
import FlexBoard from "@/components/FlexBoard.vue";
import MousePointers from "@/components/MousePointers.vue";
import ProgramBoard from "@/components/ProgramBoard.vue";
import ProgramObjectivesBoard from "@/components/ProgramObjectivesBoard.vue";
import RiskBoard from "@/components/RiskBoard.vue";
import SolutionBacklogBoard from "@/components/SolutionBacklogBoard.vue";
import SolutionBoard from "@/components/SolutionBoard.vue";
import TeamBoard from "@/components/TeamBoard.vue";
import { clearCache } from "@/components/input/fontSizeCache";
import Menus from "@/components/menu/Menus.vue";
import ToastMessage from "@/components/ui/ToastMessage/ToastMessage.vue";
import EventBusUser from "@/mixins/EventBusUser";
import ShortcutUser from "@/mixins/ShortcutUser";
import { BoardType } from "@/model/baseTypes";
import { pointerTrail } from "@/pointerTrail";
import { pushZoomState } from "@/router/navigation";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { useSessionStore } from "@/store/session";
import { useTimerStore } from "@/store/timer";
import { useToastStore } from "@/store/toast";
import { useZoomStore } from "@/store/zoom";

@Component({
  components: {
    ToastMessage,
    DrawLayer,
    ConnectionStatus,
    BoardActions,
    ZoomLayer,
    PaintLayer,
    MousePointers,
    ContextMenu,
    Menus,
    SkipButton,
  },
})
export default class AppPage extends mixins(ShortcutUser, EventBusUser) {
  boardComponent: BoardBase = null!;
  zoomHandler: ReturnType<typeof zoomHandler> | null = null;

  get board() {
    // components refs are not reactive, so update it manually
    this.$nextTick(
      () => (this.boardComponent = this.$refs.boardElem as BoardBase),
    );
    return useSessionStore().isSessionLoading
      ? null
      : this.boardFor(useBoardStore().currentBoard().type);
  }

  get hasPaintLayer() {
    return useBoardStore().isCurrentBoardFluid;
  }

  beforeCreate() {
    // temp solution to fix the font size cache issue
    clearCache();
  }

  mounted() {
    pointerTrail(this);
    document.addEventListener("scroll", pushZoomState);
    this.zoomHandler = zoomHandler(
      this.$refs.zoomLayer as ZoomLayer,
      () => this.boardComponent,
    );
  }

  get isZooming() {
    return useZoomStore().zooming;
  }

  get showDrawLayer() {
    return !this.isZooming || !useZoomStore().useZoomLayer;
  }

  @Watch("isZooming")
  zooming(zooming: boolean) {
    if (zooming) {
      this.zoomHandler?.startZoom();
    } else {
      this.zoomHandler?.endZoom();
    }
  }

  beforeUnmount() {
    document.removeEventListener("scroll", pushZoomState);
  }

  boardFor(type: BoardType) {
    switch (type) {
      case "backlog":
        return BacklogBoard;
      case "program":
        return ProgramBoard;
      case "solution_backlog":
        return SolutionBacklogBoard;
      case "solution":
        return SolutionBoard;
      case "risk":
        return RiskBoard;
      case "team":
        return TeamBoard;
      case "flex":
        return FlexBoard;
      case "objectives":
        return ProgramObjectivesBoard;
    }
  }

  get toast() {
    return useToastStore().toast;
  }

  get sidePanelOpen() {
    return useActivityStore().active || useTimerStore().active;
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/darkMode" as *;

.boards {
  transform-origin: top left;
  position: absolute;

  &.smooth {
    transition: left ease-in-out 0.4s;
  }

  #boards-border {
    position: absolute;
    left: variables.$fake-zoom * 100%;
    top: variables.$fake-zoom * 100%;
    transform-origin: top left;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.faster-fade-enter-active,
.faster-fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter-from,
.fade-leave-to,
.faster-fade-enter-from,
.faster-fade-leave-to {
  opacity: 0;
}

.board {
  background-color: colors-old.$back-color;
  position: absolute;
  width: variables.$fake-zoom * 100%;
  height: variables.$fake-zoom * 100%;
  font-size: variables.$fake-zoom * 100%;

  // all sizes inside .backdrop should be measured in board.len
  .backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    /* stylelint-disable-next-line */
    font-size: variables.$horizontal-board-font-size + 0vw;

    h1,
    .h1 {
      font-weight: font.$weight-black;
      font-size: board.len(180px);
      color: colors-old.$divider-color;
      text-align: center;
      margin: 0;
    }

    h2,
    .h2 {
      font-weight: font.$weight-extra-light;
      font-size: board.len(60px);
      text-align: center;
      margin: 0;
    }

    h3,
    .h3 {
      font-weight: font.$weight-black;
      font-size: board.len(22px);
      margin: 0;
      overflow: hidden;
    }

    h4,
    .h4 {
      font-weight: font.$weight-bold;
      font-size: board.len(14px);
      margin: 0;
      overflow: hidden;
    }
  }

  @media (aspect-ratio >= 16/9) {
    .backdrop {
      /* stylelint-disable-next-line */
      font-size: variables.$vertical-board-font-size + 0vh;
    }
  }

  .field {
    position: absolute;

    &.right {
      text-align: right;
    }
  }

  .top {
    top: 0;
    width: 100%;
    height: 15%;
  }
}

a,
a:visited {
  color: inherit;
}

input {
  font-size: 14px;
  padding: 0.5em 0;
  border: none;
  border-bottom: 0.1em solid colors-old.$input-border-color;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:read-only {
    cursor: default;
  }
}

.board input {
  font-size: inherit;
}

.scrollable {
  position: absolute;
  inset: 0;
  overflow: hidden auto;

  &.scrollable-relative {
    position: relative;
  }

  &.scrollable-content {
    overflow: hidden auto;
    height: calc(100vh - 260px);
    padding-bottom: 30px;
    padding-right: 15px;
    padding-top: 15px;
  }
}

.input {
  cursor: pointer;

  &.disabled {
    cursor: help;

    &.no-help {
      cursor: inherit;
    }
  }
}

.item {
  text-align: left;
  overflow: hidden;
  cursor: pointer;
}

.entry {
  position: relative;
  font-size: 150%;
  padding-right: 2.2em;
  margin-left: 1.7em;
  padding-left: 0.5em;
  width: 100%;
  line-height: 1.6em;
  display: inline-block;
  border-bottom: 0.1em solid colors-old.$divider-color;
  cursor: pointer;
  word-break: break-word;
}

.board-elem:focus-within,
.flag-icon.current,
.item.hoverable:hover,
.item.current,
.entry.hoverable:hover,
.entry.current {
  background-color: colors-old.$divider-color;
}

.item.selected,
.entry.selected {
  font-weight: font.$weight-extra-bold;

  input {
    font-weight: inherit;
  }
}

.home-overview {
  hr.separator {
    margin-left: 0;
    width: calc(100% - 15px);
    border: 0;
    border-top: 1px solid colors-old.$divider-color;
  }
}
</style>

<style scoped lang="scss">
@use "@/styles/side-panel";

// Move the board actions left when the right side-panel slides out
.board-actions {
  transition: right side-panel.$panel-transition;

  &.displaced-left {
    right: calc(side-panel.$panel-width + 10px);
  }
}
</style>
