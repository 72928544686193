<template>
  <div
    :id="board.id"
    class="board board-team"
    :aria-label="$t('board.team')"
    tabindex="0"
    @click="$el?.blur"
  >
    <loading-indicator v-if="!board.loaded" global />
    <link-layers :board="board" :color="linkColor" />
    <div class="backdrop" @dblclick="overview(eventLocation($event))">
      <team-iteration
        v-for="i in columns"
        :key="i"
        v-owned-cards="{ board, location: location(iterations[i - 1].id) }"
        :index="i - 1"
        :read-only="readOnly"
        class="field"
        style="height: 50%"
        :style="{ left: (i - 1) * fieldWidth + 'px', width: fieldWidth + 'px' }"
        @metrics="metricsAction"
      />
      <section
        v-owned-cards="{
          board,
          location: location(locationIndexes.objectives),
        }"
        class="field team-objectives"
        style="height: 50%; top: 50%"
        :style="{ width: fieldWidth + 'px' }"
        :aria-label="$t('teamObjectives.teamObjectives')"
        tabindex="0"
        @click="blurCurrentTarget"
      >
        <team-objectives />
      </section>
      <section
        v-owned-cards="{ board, location: location(locationIndexes.risks) }"
        class="field risks"
        style="height: 50%; top: 50%"
        :style="{ left: fieldWidth + 'px', width: fieldWidth + 'px' }"
        aria-labelledby="team-board-risks-header"
        tabindex="0"
        @click="blurCurrentTarget"
      >
        <h2 id="team-board-risks-header" class="header h3">
          <SvgIcon
            name="risks"
            width="1.25em"
            height="1.25em"
            aria-hidden="true"
          />
          <span>{{ $t("teamBoardLocation.risks") }}</span>
        </h2>
      </section>
      <team-iteration
        v-for="i in iterations.length - columns"
        :key="i + columns"
        v-owned-cards="{
          board,
          location: location(iterations[i + columns - 1].id),
        }"
        :index="i - 1 + columns"
        :read-only="readOnly"
        class="field"
        style="height: 50%; top: 50%"
        :style="{ left: (i + 1) * fieldWidth + 'px', width: fieldWidth + 'px' }"
        @metrics="metricsAction"
      />
    </div>
    <template v-if="isNewStickyNoteEnabled">
      <StickyNote
        v-for="card in board.cards"
        :key="card.data.id"
        :card="card.data"
        :card-meta="card.meta"
        :level-of-details="levelOfDetails"
      />
    </template>
    <template v-else>
      <card
        v-for="card in board.cards"
        :key="card.data.id"
        :draggable="!readOnly"
        :card="card.data"
        :meta="card.meta"
        :color="card.data.type.color"
        :link-color="linkColor"
        :height="board.cardSize.y * height"
        :width="board.cardSize.x * width"
        :board="board"
        :board-width="width"
        :board-height="height"
        :actions="actions(card.data)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";

import { ActionSource } from "@/action/actions";
import { toggleActions } from "@/action/toggleActions";
import { isAlmSync } from "@/backend/Backend";
import StickyNote from "@/components-ng/StickyNote/StickyNote.vue";
import { isFeatureEnabled } from "@/feature";
import { relativeClientCoord } from "@/math/coordinate-systems";
import ScrollSupport from "@/mixins/ScrollSupport";
import { BoardIteration } from "@/model/board";
import { Card } from "@/model/card";
import { normalLinkColors } from "@/model/colors";
import { RelativeCoordinate } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useConnectionStore } from "@/store/connection";
import { useModalStore } from "@/store/modal";
import { useSessionStore } from "@/store/session";

import FluidBoard, {
  ContextInfo,
  LocatedContextActionEvent,
} from "./FluidBoard";
import LinkLayers from "./LinkLayers.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import SvgIcon from "./SvgIcon/SvgIcon.vue";
import {
  EMPTY_INDEX,
  OBJECTIVES_INDEX,
  RISKS_INDEX,
  TeamBoardLocation,
} from "./TeamBoardLocation";
import TeamIteration from "./TeamIteration.vue";
import { ActionType } from "./card/actions";
import ConfirmSyncIterationModal from "./modal/ConfirmSyncIterationModal.vue";
import MetricsModal from "./modal/MetricsModal.vue";
import TeamObjectives from "./objectives/TeamObjectives.vue";

@Component({
  components: {
    LinkLayers,
    LoadingIndicator,
    SvgIcon,
    TeamObjectives,
    TeamIteration,
    StickyNote,
  },
})
export default class TeamBoard extends mixins(FluidBoard, ScrollSupport) {
  linkColor = normalLinkColors.team;
  baseActions: ActionType[] = [
    "delete",
    "close",
    "almSource",
    "move",
    "link",
    "dragLink",
  ];
  defaultActions: ActionType[] = [...this.baseActions, "points", "mirror"];
  riskActions: ActionType[] = [...this.baseActions, "risk", "mirror"];
  dependActions: ActionType[] = [...this.baseActions, "points", "program"];
  programActions: ActionType[] = [...this.baseActions, "points", "depend"];

  // Location Indexes for non-iteration locations
  locationIndexes = {
    risks: RISKS_INDEX,
    objectives: OBJECTIVES_INDEX,
    empty: EMPTY_INDEX,
  };

  get board() {
    return useBoardStore().boardByType("team");
  }

  get isNewStickyNoteEnabled() {
    return isFeatureEnabled(this.$route, "sticky-note");
  }

  contextActions(c?: RelativeCoordinate): ContextInfo {
    const loc = c ? this.location(c) : null;

    const actions: ContextInfo = {
      syncProgramBacklog: false,
      draw: true,
      selection: {
        stickyMove: true,
        link: true,
        mirror: false,
        team: false,
      },
    };

    if (loc?.isIteration || loc?.isRisks()) {
      actions.region = {
        name: loc.name,
        arrange: true,
        overview: true,
        sync: !!(loc.boardIteration && this.canSync(loc.boardIteration)),
        zoom: true,
      };
    }
    return actions;
  }

  canSync(iter: BoardIteration) {
    const { status } = iter.state;
    return useConnectionStore().alm && isAlmSync() && status !== "syncing";
  }

  metricsAction() {
    useModalStore().open(MetricsModal);
  }

  syncAction(event: LocatedContextActionEvent) {
    const loc = this.location(event.coord);

    useModalStore().open(ConfirmSyncIterationModal, {
      attrs: {
        iteration: loc.iterationId,
        status: loc.boardIteration?.state.status,
      },
    });
  }

  location(c: RelativeCoordinate | number) {
    return TeamBoardLocation.of(this.board.iterations, c);
  }

  eventLocation(e: MouseEvent) {
    return this.location(relativeClientCoord(e));
  }

  overview(loc: TeamBoardLocation, source: ActionSource = "mouse") {
    if (loc.isObjectives()) {
      return;
    }
    const attrs = {
      load: loc.boardIteration?.load,
      velocity: loc.boardIteration?.velocity,
      boardId: useBoardStore().boardByType("team").id,
      location: loc.index(),
    };
    toggleActions.showOverview(source, attrs);
  }

  actions(card: Card): ActionType[] {
    switch (card.type.functionality) {
      case "risk":
        return this.riskActions;
      case "dependency":
        return card.dependTeam ? this.dependActions : this.programActions;
      default:
        return this.defaultActions;
    }
  }

  get columns() {
    return Math.ceil(1 + this.iterations.length / 2);
  }

  get fieldWidth() {
    return this.width / this.columns;
  }

  get iterations() {
    return useSessionStore().iterations;
  }

  blurCurrentTarget(e: MouseEvent) {
    (e.currentTarget as HTMLElement)?.blur();
  }
}
</script>
<style lang="scss">
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/a11y";

.items-center {
  align-items: center;
}

.board-team {
  @include a11y.board;

  position: relative;

  h2,
  h3,
  h4 {
    display: inline-block;
    vertical-align: middle;
  }

  img.icon {
    height: board.len(28px);
    vertical-align: middle;
    border-radius: board.len(4px);
  }

  .field {
    padding: board.len(12px);
  }

  .field.risks {
    @include a11y.board-section;

    color: colors-old.$alt-error-color;
    font-size: 80%;

    h2 {
      font-weight: font.$weight-bold;
      display: flex;
      gap: 0.5em;
      align-items: center;
      margin-top: 0.5em;
    }

    span {
      vertical-align: middle;
    }
  }

  .team-objectives {
    @include a11y.board-section;

    padding: 0;

    .header {
      h3 {
        font-size: board.len(16px);
        font-weight: font.$weight-bold;
      }
    }
  }

  .cards-skip-button {
    top: board.len(80px) !important;
  }
}
</style>
