<script lang="ts" setup>
import { computed } from "vue";

import { RiskItem, RiskScenario } from "../../types";
import RiskScoreMeter from "./RiskScoreMeter.vue";

type Props = {
  totalScore: number;
  selectedRiskItem: RiskItem | null;
  selectedRiskScenario: RiskScenario | null;
};

const props = defineProps<Props>();

const size = computed(() => {
  if (props.selectedRiskScenario) return "small";
  if (props.selectedRiskItem) return "medium";
  return "large";
});
</script>

<template>
  <div :class="['risk-score-overview', size]">
    <div class="wrapper">
      <RiskScoreMeter text="Overall Score" :size="size" :value="totalScore" />
      <RiskScoreMeter
        v-if="selectedRiskItem"
        text="Sticky Score"
        :size="size"
        :value="selectedRiskItem?.totalScore"
      />
      <RiskScoreMeter
        v-if="selectedRiskScenario"
        text="Scenario Score"
        :size="size"
        :value="selectedRiskScenario.score"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";

.risk-score-overview {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid colors.$border-subtle;

  .wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    gap: 15px;
    height: 150px;
    align-items: center;
    padding: 0 25px;
  }

  &.large {
    height: 210px;

    .wrapper {
      justify-content: center;
    }
  }

  &.medium {
    height: 150px;

    .wrapper {
      max-width: 400px;
    }
  }

  &.small {
    height: 130px;

    .wrapper {
      max-width: 460px;
    }
  }
}
</style>
