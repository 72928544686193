import { AlmSourceId, BoardType, IdMap } from "./baseTypes";
import { BoardCard } from "./card";
import { RelativeCoordinate } from "./coordinates";
import { FlexType } from "./flexboard";
import { Objective } from "./objective";
import { Team } from "./session";
import { Shape } from "./shape";
import { StickyType } from "./stickyType";

export interface BoardId {
  boardId: string;
}

interface BaseBoardData {
  id: string;
  type: BoardType;
  stickyTypes: StickyType[];
  almSources: AlmSource[];
  cardSize: RelativeCoordinate & { factor: number };
  cards: IdMap<BoardCard>;
  maxZ: number;
  loaded: number;
  artId?: string;
  userHeartbeatId?: number;
  selected: IdMap<boolean>;
  shapes: Shape[];
}

export type BoardData<T extends BoardType> = BaseBoardData &
  {
    backlog: { type: "backlog" };
    program: {
      type: "program";
      objectives: Objective[];
      stretchObjectives: Objective[];
    };
    solution_backlog: { type: "solution_backlog" };
    solution: { type: "solution" };
    risk: { type: "risk" };
    team: {
      type: "team";
      iterations: BoardIteration[];
      team: Team;
      objectives: Objective[];
      stretchObjectives: Objective[];
    };
    flex: {
      type: "flex";
      flexType: FlexType;
      name: string;
    };
    objectives: { type: "objectives" };
  }[T];

export type Board =
  | BoardData<"backlog">
  | BoardData<"program">
  | BoardData<"solution_backlog">
  | BoardData<"solution">
  | BoardData<"risk">
  | BoardData<"team">
  | BoardData<"flex">
  | BoardData<"objectives">;

export const mirrorableBoards = [
  "program",
  "solution",
  "risk",
  "team",
] as const;
export type MirrorableBoard = BoardData<(typeof mirrorableBoards)[number]>;

export type BoardWithObjectives = BoardData<"program"> | BoardData<"team">;

export function isBacklogBoard(type?: BoardType) {
  return type === "backlog" || type === "solution_backlog";
}

export function isPlanningBoard(type?: BoardType) {
  return type === "program" || type === "solution";
}

export function isSolutionBoard(type?: BoardType) {
  return type === "solution" || type === "solution_backlog";
}

export function isArtBoard(type?: BoardType) {
  return type === "program" || type === "backlog";
}

export function isTeamBoard(type?: BoardType) {
  return type === "team";
}

export function isRiskBoard(type?: BoardType) {
  return type === "risk";
}

/**
 * A frontend board is a board that exists only in the frontend,
 * i.e. no database representation exists for it.
 */
export function isFrontendBoard(type?: BoardType) {
  return type === "objectives";
}

export function boardTypeOrder(type: BoardType) {
  return {
    solution_backlog: 0,
    solution: 1,
    backlog: 2,
    program: 3,
    objectives: 4,
    risk: 5,
    team: 6,
    flex: 7,
  }[type];
}

export interface AlmSource {
  id: AlmSourceId;
  name: string;
}

export interface BoardIteration {
  velocity: number;
  load: number;
  state: IterationState;
}

export interface IterationState {
  status: IterationStatus | null;
  detail: string | null;
}

export type IterationStatus = "syncing" | "synced" | "success" | "fail";
