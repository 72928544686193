import { i18n } from "@/i18n";
import { boardTypeName } from "@/model/baseTypeI18n";
import { AlmSource, Board } from "@/model/board";
import { Card } from "@/model/card";
import { isDependency, isRisk } from "@/model/stickyType";
import { useServerSettingsStore } from "@/store/serverSettings";

// action buttons on a sticky
const menuActionTypes = [
  "almSource",
  "delete",
  "remove",
  "program",
  "team",
  "art",
  "mirror",
  "move",
  "depend",
  "link",
  "close",
] as const;

// actions directly on a sticky
const cardActionTypes = [
  "flag",
  "type",
  "points",
  "risk",
  "priority",
  "linkLabel",
  "dragLink",
  "showTeam",
  "showArt",
  "actionMenu",
] as const;

// sticky modes without direct action to reach
const modeOnlyTypes = [
  "normal",
  "edit",
  "message",
  "art",
  "status",
  "reaction",
  "actionMenu",
] as const;

const actionTypes = [...menuActionTypes, ...cardActionTypes];
const modeTypes = [...modeOnlyTypes, ...actionTypes];

export type MenuActionType = (typeof menuActionTypes)[number];
export type ActionType = (typeof actionTypes)[number];
export type Mode = (typeof modeTypes)[number];

interface ModeComponent {
  card: Card;
  board: Board;
  boards: Board[];
  almSources?: AlmSource[];
}

const modeConfig: {
  [mode in Mode]?: {
    title: string;
    disabled?: (on: ModeComponent) => boolean;
    message?: (on: ModeComponent) => string;
  };
} = {
  type: {
    title: /*$t*/ "actions.selectStickyType",
    disabled: (on: ModeComponent) =>
      (on.card.type.origin && on.card.type.origin !== on.board.type) ||
      isDependency(on.card),
    message: (on: ModeComponent) =>
      isDependency(on.card)
        ? i18n.global.t("actions.noDependencyTypeChange")
        : i18n.global.t("actions.stickyMirrorMessage", {
            originName: i18n.global.t(boardTypeName(on.card.type.origin)),
          }),
  },
  team: {
    title: /*$t*/ "actions.selectTeamBoard",
  },
  art: {
    title: /*$t*/ "actions.selectArt",
  },
  status: {
    title: /*$t*/ "actions.selectStatus",
    message: () => i18n.global.t("actions.currentStatusMessage"),
  },
  depend: { title: /*$t*/ "actions.selectTeam" },
  move: {
    title: /*$t*/ "actions.selectBoard",
    disabled: (on: ModeComponent) =>
      (on.card.type.origin && on.card.type.origin !== on.board.type) ||
      (!useServerSettingsStore().moveBetweenTeams && !isRisk(on.card)),
    message: (on: ModeComponent) =>
      i18n.global.t(
        on.card.type.origin !== on.board.type
          ? /*$t*/ "actions.mirroredStickies"
          : /*$t*/ "actions.onlyRiskWarning",
      ),
  },
  priority: {
    title: /*$t*/ "actions.setWsjf",
    disabled: () => !useServerSettingsStore().isPriorityEditable,
    message: () => i18n.global.t("actions.wsjfWarning"),
  },
  flag: {
    title: /*$t*/ "actions.selectSticker",
  },
  points: {
    title: /*$t*/ "actions.setStoryPoints",
  },
  risk: {
    title: /*$t*/ "actions.roamRisk",
  },
  program: {
    title: /*$t*/ "actions.mirrorProgramBoard",
  },
  linkLabel: {
    title: /*$t*/ "actions.linkedStickies",
  },
  mirror: {
    title: /*$t*/ "actions.selectBoard",
    disabled: (on: ModeComponent) => on.boards.length === 0,
    message: () => i18n.global.t("actions.noMirroring"),
  },
  almSource: {
    title: /*$t*/ "actions.selectProject",
    disabled: (on: ModeComponent) =>
      !on.almSources || on.almSources.length <= 1,
  },
  delete: {
    title: /*$t*/ "actions.deleteSticky",
    disabled: (on: ModeComponent) =>
      !!on.card.almId &&
      !useServerSettingsStore().isStickyDeletable(on.board.type),
    message: () => i18n.global.t("actions.stickyDeleteWarning"),
  },
};

export interface ModeConfig {
  title: (mode: Mode | null) => string | undefined;
  message: (mode: Mode) => string | undefined;
  isDisabled: (mode: Mode) => boolean;
}

export function createModeConfig(on: ModeComponent): ModeConfig {
  return {
    title: (mode: Mode | null) => (mode ? modeConfig[mode]?.title : undefined),
    message: (mode: Mode) => modeConfig[mode]?.message?.(on),
    isDisabled: (mode: Mode) => !!modeConfig[mode]?.disabled?.(on),
  };
}

export function hasAction(actions: ActionType[], action: ActionType) {
  return actions.indexOf(action) >= 0;
}
