<script setup lang="ts">
import { Component, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { isCloud } from "@/Environment";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import BaseInput from "@/components/ui/BaseInput/BaseInput.vue";
import { useUserStore } from "@/store/user";

import { login } from "./LoginPage.utils";
import LoginPageTenants from "./LoginPageTenants.vue";

const registerUrl = "https://setup.piplanning.io/register";

const emit = defineEmits<{ pageSwitch: [page: Component] }>();

const email = ref("");
const error = ref("");

const route = useRoute();
const { t } = useI18n();

async function loginWithEmail() {
  error.value = "";
  try {
    const tenants = await login(
      email.value,
      "" + route.query.type,
      "" + route.query.path,
    );
    useUserStore().loginData = { email: email.value, tenants };
    if (tenants && tenants.length > 1) {
      emit("pageSwitch", LoginPageTenants);
    }
  } catch (err: any) {
    error.value = err || t("loginPage.notLogin");
  }
}
</script>

<template>
  <div v-autofocus tabindex="-1" aria-labelledby="email-page-title">
    <h1 id="email-page-title" class="login">
      {{ $t("loginPageEmail.login") }}
    </h1>
    <p class="description">{{ $t("loginPageEmail.useWorkEmail") }}</p>

    <div class="form-container">
      <form @submit.prevent>
        <BaseInput
          v-model="email"
          control-type="text"
          type="email"
          name="email"
          :label="$t('loginPageEmail.email')"
          :placeholder="$t('loginPageEmail.email')"
          autofocus
          required
        />
        <BaseButton full-width @click="loginWithEmail">
          {{ $t("loginPageEmail.continue") }}
        </BaseButton>
        <span class="error">{{ error }}</span>
      </form>
      <span v-if="isCloud">
        <p class="register">
          <I18nT keypath="loginPageEmail.noAccount" scope="global">
            <template #register>
              <a :href="registerUrl">
                {{ $t("loginPageEmail.noAccount.register") }}
              </a>
            </template>
          </I18nT>
        </p>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.login {
  font-weight: font.$weight-extra-bold;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 8px;
}

.description {
  color: colors-old.$text-secondary-color;
  font-size: 14px;
  font-weight: font.$weight-normal;
  margin-bottom: 30px;
}

.base-button {
  margin-top: 1em;
}

.register {
  color: colors-old.$text-primary-color;
  font-size: 14px;
  font-weight: font.$weight-normal;
}

.error {
  color: colors-old.$error-color;
  font-size: 14px;
}

#message.warn {
  background-color: colors-old.$error-color;
  margin-bottom: -180px;
  width: 300px;
}

a,
a:visited {
  color: colors-old.$primary-color;
  text-decoration: none;
}
</style>
