<script setup lang="ts">
import { HTMLAttributes, InputHTMLAttributes, ref } from "vue";

import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { Icon } from "@/types/icon";

import BaseInputText from "./components/BaseInputText.vue";

defineOptions({
  inheritAttrs: false,
});

interface Props extends /* @vue-ignore */ InputHTMLAttributes {
  controlType: "text";
  iconBefore?: Icon;
  iconAfter?: Icon;
  label?: string;
  iconBeforeTitle?: string; // Screen readers will see this (or the title below) as the label for the input
  iconAfterTitle?: string; // (both work identically, and if both are provided both will be read)
  size?: "small" | "medium";
  disabled?: boolean;
  wrapperClass?: HTMLAttributes["class"];
}
withDefaults(defineProps<Props>(), {
  iconBefore: undefined,
  iconAfter: undefined,
  label: undefined,
  size: "medium",
  disabled: false,
  wrapperClass: undefined,
});

const modelValue = defineModel<string>({ required: true });

const textInputElem = ref<HTMLInputElement | null>();

const focus = () => textInputElem.value?.focus();

const blur = () => textInputElem.value?.blur();

defineExpose({ focus, blur });
</script>

<template>
  <label class="base-input" :class="wrapperClass" :aria-label="label || ''">
    <div v-if="label" class="label">{{ label }}</div>
    <div class="input-area" :class="[size, { disabled }]">
      <SvgIcon
        v-if="iconBefore"
        :name="iconBefore"
        class="icon"
        :title="iconBeforeTitle"
        :role="iconBeforeTitle ? 'img' : 'presentation'"
      />
      <BaseInputText
        v-if="controlType === 'text'"
        ref="textInputElem"
        v-model="modelValue"
        v-bind="$attrs"
        class="input-text"
        :disabled="disabled"
      />
      <SvgIcon
        v-if="iconAfter"
        :name="iconAfter"
        class="icon"
        :title="iconAfterTitle"
        :role="iconAfterTitle ? 'img' : 'presentation'"
      />
    </div>
  </label>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/font";

$height-small: 30px;
$height-medium: 40px;

.base-input {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .label {
    font-size: font.$size-small;
    line-height: 15px;
    font-weight: font.$weight-bold;
    cursor: pointer;
  }

  .input-area {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 8px;
    border: 1px solid colors-old.$input-unfocused-border-color;
    border-radius: 6px;
    font-size: 14px;

    .icon {
      color: colors-old.$input-unfocused-icon-color;
      flex-shrink: 0;
    }

    .input-text {
      min-width: 0;
      flex-grow: 1;
    }

    // Size: small
    &.small {
      height: $height-small;
      padding: 10px 8px;
      font-weight: font.$weight-medium;
      line-height: 14px;

      .icon {
        width: 14px;
        height: 14px;
      }
    }

    // Size: medium
    &.medium {
      height: $height-medium;
      padding: 10px 12px;
      font-weight: font.$weight-semi-bold;
      line-height: 18px;

      .icon {
        width: 20px;
        height: 20px;
      }
    }

    // Disabled
    &.disabled {
      background-color: colors-old.$input-readonly-background-color;
      color: colors-old.$placeholder-color;

      .icon {
        color: colors-old.$placeholder-color;
      }
    }

    &:not(.disabled) {
      cursor: pointer; // To match global input styling
    }

    // Focused
    &:focus-within {
      border: 1px solid colors-old.$input-focus-border-color;

      .icon {
        color: colors-old.$input-focus-border-color;
      }
    }

    // Hover
    &:hover:not(:focus-within, .disabled) {
      margin: -1px; // Compensates for the larger border
      border: 2px solid colors-old.$input-hover-border-color;

      // Size adjustment for the larger border
      &.medium {
        height: calc($height-medium + 2px);
      }

      &.small {
        height: calc($height-small + 2px);
      }

      .icon {
        color: colors-old.$icon-base-color;
      }
    }
  }
}
</style>
