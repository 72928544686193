<template>
  <div :id="board.id" class="board planning-board" role="grid">
    <loading-indicator v-if="!board.loaded" global />
    <link-layers :board="board" :color="linkColor" />
    <div class="backdrop" @dblclick="overview(eventLocation($event))">
      <!-- Column headers -->
      <div role="row" class="iteration-header-row">
        <!-- Column 'In planning' -->
        <div
          class="column clickable"
          role="columnheader"
          :style="{
            width: fieldWidth / 2 + 'px',
            height: fieldHeight / 2 + 'px',
          }"
        >
          <section
            v-owned-cards="{
              board,
              location: location(-2, -1),
            }"
            class="top-text"
            tabindex="0"
            @click="blurCurrentTarget"
          >
            <div class="fit-font">
              <div class="title-col h3">
                {{ $t("programBoard.inPlanning") }}
              </div>
            </div>
          </section>
        </div>

        <!-- Empty column header (above the row headers) -->
        <div role="columnheader"></div>
        <!-- Iteration column headers -->
        <div
          v-for="(iter, iterIndex) in iterations"
          :key="`row-${iterIndex}`"
          role="columnheader"
          :aria-label="iter.name"
          style="position: absolute"
          :style="{
            left: fieldWidth * (iterIndex + 1) + 'px',
            width: fieldWidth + 'px',
            height: fieldHeight / 2 + 'px',
          }"
        >
          <div class="top-text">
            <div class="fit-font">
              <div class="h3">{{ iter.name }}</div>
              <div class="h4">{{ dates(iter) }}</div>
            </div>
            <status-distribution
              v-if="isExecutionMode"
              :iteration="iter"
              :data-testid="`iteration-status-distribution-${iterIndex}`"
              class="iteration-distribution"
              :value="getIterationStatusDistribution(iterIndex)"
              source-item-type="iteration"
            />
          </div>
        </div>
      </div>

      <!-- Rows -->
      <div
        v-for="(group, groupIndex) in groups"
        :key="groupKey('name', group, groupIndex)"
        class="field"
        :class="[fieldClass(group)]"
        role="row"
        :style="{
          top: fieldHeight * (groupIndex + 0.5) + 'px',
          left: fieldWidth * 0.5 + 'px',
          height: fieldHeight + 'px',
          width: fieldWidth / 2 + 'px',
        }"
      >
        <!-- Placeholder cell for the 'in planning' column (aria-rowspan doesn't work consistenly) -->
        <div role="gridcell"></div>
        <!-- Row headers (team names) -->
        <div class="group-name" role="rowheader">
          <section class="grid-cell" tabindex="0" @click="blurCurrentTarget">
            <div class="fit-font">
              <div class="h3">{{ group.name }}</div>
            </div>
            <div v-if="groupIndex === 0" class="milestones">
              <img
                v-if="groups.length < 15"
                src="@/assets/milestones-events.svg"
                role="presentation"
              />
            </div>
            <status-distribution
              v-else-if="group.id && isExecutionMode"
              :data-testid="`group-status-distribution-${group.id}`"
              class="iteration-distribution"
              :value="getGroupStatusDistribution(group.id)"
              source-item-type="iteration"
            />
          </section>
        </div>
        <!-- Cells in the row, one per iteration -->
        <div
          v-for="(iter, iterIndex) in iterationsOfGroup(group)"
          :key="iterIndex"
          class="field"
          :class="[fieldClass(group, iter)]"
          role="gridcell"
          :style="{
            top: 0,
            left: fieldWidth * (iterIndex + 0.5) + 'px',
            height: fieldHeight + 'px',
            width: fieldWidth + 'px',
          }"
        >
          <section
            v-owned-cards="{
              board,
              location: location(iterations[iterIndex].id, groupIndex),
            }"
            tabindex="0"
            class="grid-cell"
            :aria-label="$t('label.planningBoard.boardSection')"
            @click="blurCurrentTarget"
          ></section>
        </div>
      </div>

      <!-- Iteration columns -->
      <div
        v-for="(_, iterIndex) in iterations"
        :key="`row1-${iterIndex}`"
        class="column"
        :style="{
          left: fieldWidth * (iterIndex + 1) + 'px',
          width: fieldWidth + 'px',
        }"
      ></div>
    </div>

    <!-- Backdrop (presentation, not content) -->
    <div
      class="backdrop"
      :style="{ fontSize: fixedFontSize + '%' }"
      style="pointer-events: none"
    >
      <div
        v-if="isExecutionMode"
        class="time-line"
        :style="{
          width: iterationNow.iterationsPassed * fieldWidth + 'px',
          left: fieldWidth + 'px',
          top: fieldHeight * 0.5 + 'px',
        }"
      >
        <svg>
          <line x1="0" y1="0" x2="0" y2="100%" />
        </svg>
      </div>
      <div
        v-for="(group, groupIndex) in groups"
        :key="groupKey('iter', group, groupIndex)"
        class="field horizontal-line"
        :style="{
          lineHeight: fieldHeight + 'px',
          top: fieldHeight * (groupIndex + 0.5) + 'px',
          left: fieldWidth * 0.5 + 'px',
          height: fieldHeight + 'px',
        }"
      >
        <div
          v-for="(_, iterIndex) in zoomedInIterations"
          :key="iterIndex"
          class="legend group-legend"
          :style="{
            left: (iterIndex + 0.5) * fieldWidth - fieldHeight / 2 + 'px',
            width: fieldHeight + 'px',
          }"
        >
          <div>{{ group.name }}&nbsp;</div>
        </div>
      </div>
      <div
        v-for="(iter, iterIndex) in iterations"
        :key="iterIndex"
        class="column"
        :style="{
          left: fieldWidth * (iterIndex + 1) + 'px',
          width: fieldWidth + 'px',
        }"
      >
        <div
          v-for="groupIndex in zoomedInGroups"
          :key="groupIndex"
          class="legend iter-legend"
          :style="{ top: (groupIndex - 0.5) * fieldHeight + 'px' }"
        >
          <div>{{ iter.name }}</div>
        </div>
      </div>
    </div>

    <!-- Cards -->
    <template v-if="isNewStickyNoteEnabled">
      <StickyNote
        v-for="card in board.cards"
        :key="card.data.id"
        :card="card.data"
        :card-meta="card.meta"
        :level-of-details="levelOfDetails"
      />
    </template>
    <template v-else>
      <card
        v-for="card in board.cards"
        :key="card.data.id"
        :draggable="!readOnly"
        :card="card.data"
        :meta="card.meta"
        :color="card.data.type.color"
        :link-color="linkColor"
        :height="board.cardSize.y * height"
        :width="board.cardSize.x * width"
        :board="board"
        :board-width="width"
        :board-height="height"
        :actions="cardActions(card.data)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { clamp } from "lodash-es";
import { Options as Component, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { ActionSource } from "@/action/actions";
import { toggleActions } from "@/action/toggleActions";
import { useRiskAnalysisStore } from "@/coconut/store/riskAnalysis";
import StickyNote from "@/components-ng/StickyNote/StickyNote.vue";
import { isFeatureEnabled } from "@/feature";
import { relativeClientCoord } from "@/math/coordinate-systems";
import { Board, BoardIteration } from "@/model/board";
import { Card } from "@/model/card";
import { normalLinkColors } from "@/model/colors";
import { RelativeCoordinate } from "@/model/coordinates";
import { Group, Iteration } from "@/model/session";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";
import { useSessionStore } from "@/store/session";
import { useWorkModeStore } from "@/store/workMode";
import { plusDays } from "@/utils/date";
import { formatShortDate } from "@/utils/dateFormat";

import FluidBoard, { ContextInfo } from "./FluidBoard";
import LinkLayers from "./LinkLayers.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import { PlanningBoardLocation } from "./PlanningBoardLocation";
import {
  iterationStatusDistribution,
  teamStatusDistribution,
} from "./StatusDistribution";
import StatusDistribution from "./StatusDistribution.vue";
import { ActionType } from "./card/actions";

@Component({
  components: { LinkLayers, LoadingIndicator, StatusDistribution, StickyNote },
})
export default class BasePlanningBoard extends mixins(FluidBoard) {
  @Prop(Object) readonly planningBoard!: Board;
  @Prop(Object) readonly groupIterations!: Record<string, BoardIteration[]>;
  @Prop(Array) readonly cardsForDistribution!: Card[];
  @Prop(Function) readonly fieldClass!: (
    group: Group,
    iter?: BoardIteration,
  ) => string;

  legendZoomLimit = 2;
  linkColor = normalLinkColors.program;

  actions: ActionType[] = [
    "delete",
    "close",
    "almSource",
    "mirror",
    "link",
    "dragLink",
    "priority",
  ];
  riskActions: ActionType[] = [...this.actions, "risk"];
  defaultActions: ActionType[] = [...this.actions, "points"];

  activated() {
    setTimeout(() => {
      this.recalcLegendFontSizes();
      this.recalcHeadingFontSizes();
    }, 10);
  }

  get board() {
    return this.planningBoard;
  }

  get groups() {
    return useBoardStore().boardGroups();
  }

  iterationsOfGroup(group: Group) {
    return group.id ? this.groupIterations[group.id] : this.emptyIterations;
  }

  get emptyIterations() {
    return this.iterations.map(() => ({
      velocity: 0,
      load: 0,
      state: { status: null, detail: null },
    }));
  }

  location(c: RelativeCoordinate | number, top?: number) {
    return useBoardStore().boardLocation(c, top) as PlanningBoardLocation;
  }

  eventLocation(e: MouseEvent) {
    return this.location(relativeClientCoord(e));
  }

  get cards(): Card[] {
    return Object.values(this.board.cards).map((boardCard) => boardCard.data);
  }

  get iterationNow() {
    return useSessionStore().iterationProgress(new Date());
  }

  get iterations() {
    return useSessionStore().iterations;
  }

  get appSize() {
    return useAppSizeStore().appSize;
  }

  get isRiskAnalysisEnabled() {
    return useRiskAnalysisStore().isSidebarOpen;
  }

  get isNewStickyNoteEnabled() {
    return isFeatureEnabled(this.$route, "sticky-note");
  }

  @Watch("zoomFactor", { immediate: true })
  zoomChanged(zoom: number, old: number) {
    if (this.active) {
      setTimeout(() => {
        if (old <= this.legendZoomLimit) {
          this.recalcLegendFontSizes();
        }
      }, 10);
    }
  }

  @Watch("planningBoard")
  planningBoardChange() {
    setTimeout(() => {
      this.recalcLegendFontSizes();
      this.recalcHeadingFontSizes();
    }, 10);
  }

  recalcLegendFontSizes() {
    if (this.zoomFactor > this.legendZoomLimit) {
      this.recalcFontSize(".planning-board .iter-legend", 30, 80);
      this.recalcFontSize(".planning-board .group-legend", 30, 80);
    }
  }

  recalcHeadingFontSizes() {
    this.recalcFontSize(".planning-board .group-name .fit-font", 30, 100);
    const max = 1000 / (this.groups.length + 2.5); // limit the height when there are a lot of groups
    this.recalcFontSize(".planning-board .top-text .fit-font", 30, max);
  }

  get zoomedIn() {
    return this.active && this.zoomFactor > this.legendZoomLimit;
  }

  get zoomedInIterations() {
    return this.zoomedIn ? this.iterations : [];
  }

  get zoomedInGroups() {
    return this.zoomedIn ? this.groups.length + 1 : 0;
  }

  get isExecutionMode() {
    return useWorkModeStore().isExecutionMode;
  }

  getIterationStatusDistribution(index: number) {
    return iterationStatusDistribution(this.cardsForDistribution, index);
  }

  getGroupStatusDistribution(teamId: string) {
    // TODO needs some renaming/refactoring when it's also supported for solution board
    // see https://rentouch.atlassian.net/browse/REN-11477
    return teamStatusDistribution(this.cardsForDistribution, teamId);
  }

  recalcFontSize(query: string, min: number, max: number) {
    const els = document.querySelectorAll<HTMLElement>(query);

    if (els.length > 0) {
      let minQ = 1000;
      els.forEach((el) => {
        el.style.fontSize = "100%";
        el.childNodes.forEach((n) => {
          if (n.nodeType !== 8 && n.nodeName !== "IMG") {
            const e = n as HTMLElement;
            minQ = Math.min(minQ, e.offsetWidth / e.scrollWidth);
          }
        });
      });
      const size = clamp(100 * minQ, min, max);
      els.forEach((el) => {
        el.classList.toggle("min-size", size === min);
        el.style.fontSize = size + "%";
      });
    }
  }

  cardActions(card: Card): ActionType[] {
    return card.type.functionality === "risk"
      ? this.riskActions
      : this.defaultActions;
  }

  contextActions(c?: RelativeCoordinate): ContextInfo {
    const actions: ContextInfo = {
      syncProgramBacklog: false,
      draw: true,
      selection: {
        stickyMove: true,
        link: true,
        mirror: false,
        team: false,
      },
    };
    if (c) {
      const loc = this.location(c);
      actions.region = {
        name: loc.names().join(" "),
        arrange: false,
        overview: true,
        sync: false,
        zoom: false,
      };
    }
    return actions;
  }

  overview(loc: PlanningBoardLocation, source: ActionSource = "mouse") {
    const boardIter = loc.boardIteration(this.iterationsOfGroup);
    const attrs = {
      boardId: this.board.id,
      location: loc.index(),
      load: boardIter?.load,
      velocity: boardIter?.velocity,
    };
    toggleActions.showOverview(source, attrs);
  }

  get fieldWidth() {
    return this.width / (useSessionStore().iterations.length + 1);
  }

  get fieldHeight() {
    return this.height / (this.groups.length + 0.5);
  }

  groupKey(prefix: string, group: Group, index: number) {
    return (
      "group-" + prefix + (group.id ? "-id-" + group.id : "-index-" + index)
    );
  }

  dates(iter: Iteration) {
    return this.$t("date.range", {
      from: formatShortDate(iter.start),
      to: formatShortDate(plusDays(iter.end, -1)),
    });
  }

  blurCurrentTarget(e: MouseEvent) {
    (e.currentTarget as HTMLElement)?.blur();
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/time-line" as *;
@use "@/styles/z-index";
@use "@/styles/mixins/a11y";

.planning-board {
  @include a11y.board;

  .field,
  .group-name {
    .grid-cell {
      @include a11y.board-section;

      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  .field {
    &.overload {
      background-color: colors-old.$error-back-color;
    }

    &.warn {
      background-color: colors-old.$warn-back-color;
    }
  }

  .column {
    position: absolute;
    height: 100%;

    &:not(.clickable) {
      pointer-events: none;
    }
  }

  .horizontal-line {
    width: 100%;
    height: 100%;
  }

  .time-line {
    margin-top: board.len(-3px);
    margin-left: board.len(3px);
  }

  .milestones {
    display: inline-flex;
    align-items: flex-end;
    height: 75%;

    img {
      height: max(25%, 0.75em);
    }
  }

  .top-text {
    position: absolute;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    inset: 8% calc(6% / 2) 8% calc(6% / 2);

    & > div.fit-font {
      display: flex;
      overflow: hidden;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: board.len(14px);

      .h4 {
        font-weight: font.$weight-normal;
        color: colors-old.$text-secondary-color;
      }
    }
  }

  .group-current {
    background-color: colors-old.$primary-back-color;
  }

  .group-name {
    overflow: hidden;
    color: colors-old.$primary-color;
    position: absolute;
    white-space: nowrap;
    inset: 8% 6%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .fit-font.min-size {
      white-space: normal;
      word-break: break-word;
    }
  }

  .title-col {
    margin-bottom: board.len(25px) !important;
  }

  .legend {
    position: absolute;
    text-align: center;
    z-index: z-index.$board;

    div {
      display: inline-block;
      height: board.len(60px);
      line-height: board.len(60px);
      padding: 0 board.len(15px);
      border-radius: board.len(15px);
      max-width: 80%;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .iter-legend {
    left: 0;
    margin-top: board.len(-36px);
    width: 100%;
    font-size: 20%;

    div {
      background-color: colors-old.$divider-color;
    }
  }

  .group-legend {
    top: 0;
    transform: rotate(-90deg);
    margin-left: board.len(21px);
    font-size: 20%;

    div {
      background-color: colors-old.$divider-color;
    }
  }

  .iteration-distribution {
    height: board.len(8px);
    width: 100%;

    .stacked-bar-chart {
      .empty-bar rect {
        fill: colors-old.$progress-empty-fill-color;
      }

      .bordered {
        border-width: board.len(1px);
      }
    }
  }
}
</style>
