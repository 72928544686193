import { defineStore } from "pinia";

import { cardActions } from "@/action/cardActions";
import { IdMap } from "@/model/baseTypes";
import { BoardData } from "@/model/board";
import { BoardCard } from "@/model/card";
import { RelativeCoordinate } from "@/model/coordinates";
import { captureMessage } from "@/sentry";

import { useBoardStore } from "./board";
import { useCardStore } from "./card";
import { linkId, useLinkStore } from "./link";

export interface Drag {
  id: string;
  type: "card" | "link";
  pos: RelativeCoordinate;
  fromPalette?: boolean;
}

export const useDraggingStore = defineStore("dragging", {
  state: () => ({
    dragging: {} as IdMap<Drag>,
  }),
  getters: {
    findById:
      (state) =>
      (id: string): Drag | undefined => {
        for (const dragId in state.dragging) {
          if (state.dragging[dragId].id === id) {
            return state.dragging[dragId];
          }
        }
      },
    isLinkDragging: (state) =>
      Object.values(state.dragging).some((drag) => drag.type === "link"),
    draggedLinks: (state) =>
      Object.values(state.dragging).flatMap((drag) => {
        const card = useBoardStore().currentBoard().cards[drag.id.substring(2)];
        if (!card || drag.type !== "link") {
          return [];
        }
        return { card, drag };
      }),
  },
  actions: {
    startCardDragging(
      id: string,
      options: { fromPalette?: boolean } = {},
      pos: RelativeCoordinate,
    ) {
      const board = useBoardStore().currentBoard();
      const boardCard = board.cards[id];
      // TODO this is to find out about REN-12312
      if (!boardCard) {
        cardActions.stopAlter("internal", id);
        captureMessage("Cannot find card on board", {
          info: {
            id,
            boardId: board.id,
            boardType: board.type,
            boardTeam: (board as BoardData<"team">).team,
            cardCount: Object.keys(board.cards).length,
            card: useCardStore().cards[id],
          },
        });
        location.reload();
      }
      boardCard.meta.dragging = true;
      if (!(id in this.dragging)) {
        this.dragging[id] = {
          id,
          type: "card",
          fromPalette: !!options.fromPalette,
          pos,
        };
      }
    },
    startLinkDragging(id: string, dragId: number, pos: RelativeCoordinate) {
      const card = useCardStore().cards[id];
      this.dragging[dragId] = {
        id: "l_" + id,
        type: "link",
        pos,
      };
      card.links.unshift({
        id: "l_" + id,
        from: linkId(card),
        to: "",
        state: "default",
      });
      useLinkStore().linking.from = card;
    },
    dragCard(id: string, pos: RelativeCoordinate) {
      this.dragging[id].pos = pos;
    },
    dragLink(dragId: number, pos: RelativeCoordinate) {
      this.dragging[dragId].pos = pos;
    },
    endCardDragging(id: string, pos: RelativeCoordinate) {
      delete this.dragging[id];
      const boardCard = useBoardStore().currentBoard().cards[id];
      if (!boardCard) {
        return;
      }
      boardCard.meta.pos.x = pos.x;
      boardCard.meta.pos.y = pos.y;
      boardCard.meta.zIndex = ++useBoardStore().currentBoard().maxZ;
      boardCard.meta.dragging = false;
    },
    endLinkDragging(
      id: string,
      dragId: number,
      boardCard: BoardCard,
      el: HTMLElement,
    ) {
      el.style.display = "block";
      const link_id = "l_" + id;
      const link = boardCard.data.links.find((l) => l.id === link_id);
      if (link?.to === "") {
        const linkIndex = boardCard.data.links.findIndex(
          (l) => l.id === link_id,
        );
        boardCard.data.links.splice(linkIndex, 1);
      }
      delete this.dragging[dragId];
    },
  },
});
