import { clamp, floor } from "lodash-es";

import { BoardPattern, RiskScenarioRecommendation } from "@/coconut/types";
import { Board } from "@/model/board";
import { isDependency, isWorkitem } from "@/model/stickyType";
import { getLinkTargetId, useLinkStore } from "@/store/link";

// https://www.figma.com/file/QEGprRz74Z5m41MVuwRUI6/Plan-Analyzer?type=design&node-id=1584-1311&mode=design&t=aRI1tzOVjdTBnhBW-4

const distributionInTeams: BoardPattern = {
  id: 5,
  title: "High Dependency Count Risk",
  type: "board",
  description:
    "This risk pattern occurs when too many dependencies are present on the board. Each dependency increases the overall risk, making coordination more complex and increasing the chances of delays. High dependency counts can lead to bottlenecks, miscommunications, and overall inefficiency. The goal is to manage and reduce the number of dependencies to streamline processes and reduce potential risks.",
  weight: 1,
  process(board: Board) {
    const features = Object.values(board.cards).filter((sticky) => {
      return isWorkitem(sticky.data);
    });

    let totalDependencies = 0;
    features.forEach((feature) => {
      feature.data.links.forEach((link) => {
        const linkedCard = useLinkStore().cardsByLink(
          getLinkTargetId(feature.data, link),
        )[0];

        if (
          !linkedCard ||
          !isDependency(linkedCard) ||
          feature.data.iterationId === null ||
          linkedCard.iterationId === null
        ) {
          return;
        }

        totalDependencies++;
      });
    });

    const value = totalDependencies / features.length;
    return [
      {
        patternId: this.id,
        scope: { id: board.id, type: distributionInTeams.type },
        title: this.title,
        description: this.description,
        weight: this.weight,
        score: floor(clamp(value * 100, 0, 100)),
        recommendations: this.recommendations,
      },
    ];
  },
};

/**
 * Reduce Dependencies: Actively work to minimize the number of dependencies by redesigning work (Features) to be more self-contained. Encourage the breakdown of work to be less reliant on other teams or external factors.
Dependency Prioritization: Prioritize and address the most critical dependencies first. Ensuring the most essential dependencies are managed and resolved early in the PI to reduce overall risk.
Cluster Dependent Features: Group features with related dependencies closer together if possible. This may help streamline the coordination process and minimize the complexity of managing multiple dependencies across different iterations. By clustering dependent features together:
Improved Coordination: Teams can focus on completing interrelated work together, iteration, and enhancing communication and coordination efforts.
Reduced Context Switching: Reduces the need for teams to switch contexts frequently, which can lead to inefficiencies and errors.
Enhanced Focus: Allows teams to concentrate on a group of related work, making managing and resolving dependencies easier.
 */

export const recommendations: RiskScenarioRecommendation[] = [
  {
    title: "Reduce Dependencies",
    description:
      "Actively work to minimize the number of dependencies by redesigning work (Features) to be more self-contained. Encourage the breakdown of work to be less reliant on other teams or external factors.",
  },
  {
    title: "Dependency Prioritization",
    description:
      "Prioritize and address the most critical dependencies first. Ensuring the most essential dependencies are managed and resolved early in the PI to reduce overall risk.",
  },
  {
    title: "Cluster Dependent Features",
    description: `Group features with related dependencies closer together if possible. 
      This may help streamline the coordination process and minimize the complexity
       of managing multiple dependencies across different iterations. 
       By clustering dependent features together:
      <ul>
        <li>Improved Coordination: Teams can focus on completing interrelated work together, iteration, and enhancing communication and coordination efforts.</li>
        <li>Reduced Context Switching: Reduces the need for teams to switch contexts frequently, which can lead to inefficiencies and errors.</li>
        <li>Enhanced Focus: Allows teams to concentrate on a group of related work, making managing and resolving dependencies easier.</li>
      </ul>
      `,
  },
];

export default distributionInTeams;
