import { StatusClass, statusClasses } from "@/model/baseTypes";
import { Card } from "@/model/card";
import { LinkableCardTree } from "@/model/link";
import { isWorkitem } from "@/model/stickyType";
import { useCardStore } from "@/store/card";
import { getLinkTargetId, useLinkStore } from "@/store/link";

import { GroupedValues } from "./StackedBarChart";

export type StatusDistributionData = GroupedValues<
  StatusClass,
  { points: number }
>;

export type StatusDistributionSource = "sticky" | "objective" | "iteration";

export function cardStatusDistribution(
  card: Card,
  criteria?: (card: Card) => boolean,
): StatusDistributionData {
  const data = initData();
  card.links.forEach((link) => {
    const linkedCard = useLinkStore().cardsByLink(
      getLinkTargetId(card, link),
    )[0];
    addValue(data, linkedCard, criteria);
  });
  return removeEmptyStatusClasses(data);
}

export function dependencyCardStatusDistribution(
  card: Card,
): StatusDistributionData {
  return cardStatusDistribution(card, (linkedCard: Card) => {
    return (
      shouldAffectStatusDistribution(linkedCard) &&
      card.dependTeam?.id === linkedCard.teamId
    );
  });
}

export function iterationStatusDistribution(
  cards: Card[],
  iterationId: number,
): StatusDistributionData {
  const data = initData();
  cards.forEach((card) => {
    if (card.iterationId === iterationId) {
      addValue(data, card);
    }
  });
  return removeEmptyStatusClasses(data);
}

export function teamStatusDistribution(
  cards: Card[],
  teamId: string,
): StatusDistributionData {
  const data = initData();
  cards.forEach((card) => {
    if (card.teamId === teamId) {
      addValue(data, card);
    }
  });
  return removeEmptyStatusClasses(data);
}

export function objectiveStatusDistribution(
  tree: LinkableCardTree,
): StatusDistributionData {
  const linkedCards = [
    ...tree.cards,
    ...tree.indirectLinkedIds.map((id) => useCardStore().cards[id]),
  ];

  const data: StatusDistributionData = linkedCards.reduce(
    (acc, curr) => addValue(acc, curr),
    initData(),
  );

  return removeEmptyStatusClasses(data);
}

/**
 * Adds a value to the status distribution data map for a given linked card, if it meets certain criteria.
 *
 * @returns {StatusDistributionData} - The updated status distribution data map.
 */
function addValue(
  data: StatusDistributionData,
  linkedCard: Card,
  criteria = shouldAffectStatusDistribution,
): StatusDistributionData {
  const newData = new Map([...data.entries()]);
  if (criteria(linkedCard)) {
    const value = newData.get(linkedCard.status!.statusClass)!;
    value.value++;
    value.points += linkedCard.points;
  }
  return newData;
}

function initData(): StatusDistributionData {
  const data = new Map();
  // set all status classes to ensure they are ordered correctly
  for (const statusClass of statusClasses) {
    data.set(statusClass, { points: 0, value: 0 });
  }
  return data;
}

function removeEmptyStatusClasses(data: StatusDistributionData) {
  for (const [statusClass, value] of data) {
    if (value.value === 0) {
      data.delete(statusClass);
    }
  }
  return data;
}

function shouldAffectStatusDistribution(card: Card): boolean {
  // Don't aggregate status from linked ART/Solution Backlog/Planning items
  const excludedBoards = ["backlog", "program", "solution_backlog", "solution"];

  return Boolean(
    card &&
      card.status?.statusClass &&
      isWorkitem(card) &&
      !excludedBoards.includes(card.type.origin),
  );
}
