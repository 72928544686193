import { defineStore } from "pinia";

import { Size } from "@/model/size";
import { getScrollbarSize } from "@/utils/dom";

import { useBoardStore } from "./board";

export const useAppSizeStore = defineStore("appSize", {
  state: () => ({
    appSize: {
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      padding: {
        top: 0,
        left: 68,
        bottom: 60,
        right: 68,
      },
      margin: {
        top: 48,
        left: 0,
        bottom: 0,
        right: 0,
      },
    },
    leftPanelWidth: getPersistedLeftPanelWidth(),
    scrollbarSize: getScrollbarSize(),
  }),
  getters: {},
  actions: {
    setAppSize(e: Size) {
      this.appSize = {
        top: e.top,
        left: e.left,
        width: e.width,
        height: e.height,
        padding: { ...this.appSize.padding },
        margin: { ...this.appSize.margin },
      };
    },
    setLeftPanelWidth(width: number) {
      const minWidth = 420;

      if (width < minWidth) return;

      persistLeftPanelWidth(width);
      this.leftPanelWidth = width;
    },
    setLeft(space: number, margin: number) {
      if (useBoardStore().isCurrentBoardFluid) {
        this.appSize.padding.left = space;
        this.appSize.margin.left = margin;
      }
    },
    setRightMargin(margin: number) {
      this.appSize.margin.right = margin;
    },
  },
});

function getPersistedLeftPanelWidth() {
  return parseInt(localStorage.getItem("leftPanelWidth") || "500", 10);
}

function persistLeftPanelWidth(width: number) {
  localStorage.setItem("leftPanelWidth", width.toString());
}
