<script setup lang="ts">
import { computed, ref } from "vue";

import { loadBoard } from "@/backend/Backend";
import { Card } from "@/model/card";
import { Team } from "@/model/session";
import { useBoardStore } from "@/store/board";

import RiskDetail from "./RiskDetail.vue";
import RisksList from "./RisksList.vue";

type Props = { team: Team };
const props = defineProps<Props>();

const selectedRisk = ref<Card | null>(null);

/**
 * Returns an array of all cards of functionality 'risk' that belong to the current team
 */
const risks = computed(() => {
  const teamBoard = useBoardStore().boardByType("team", {
    teamId: props.team.id,
  });

  // The cards are only loaded when the board is loaded
  if (!teamBoard.loaded) {
    loadBoard(teamBoard);
  }

  // Only show risks that are on the team board
  return Object.values(teamBoard.cards)
    .filter((boardCard) => boardCard.data.type.functionality === "risk")
    .map((boardCard) => boardCard.data);
});

/**
 * Select Risk by id
 */
const selectRisk = (risk: Card | null) => {
  selectedRisk.value = risk;
};
</script>

<template>
  <div v-autofocus v-keyboard-nav.select.no-wrap class="content">
    <div class="left" data-testid="risk-list-container">
      <RisksList
        :team="team"
        :risks="risks"
        :selected-risk="selectedRisk"
        @select="selectRisk"
      />
    </div>
    <div class="right" data-testid="risk-detail-container">
      <transition name="faster-fade" mode="out-in">
        <RiskDetail
          v-if="selectedRisk"
          :key="selectedRisk.id"
          :selected-risk="selectedRisk"
        />
      </transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  // TODO Still show this for non-mouse users
  &:focus {
    outline: 0;
  }

  .left {
    flex: 0 1 min(35%, 600px);
    min-width: 0;
    border-right: solid 1px colors-old.$divider-color;
  }

  .right {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
