import { AuthUser } from "./user";

export type StickyChangeKind =
  | "create"
  | "update"
  | "delete"
  | "mirror"
  | "unmirror"
  | "link"
  | "unlink";

export interface StickyChange {
  stickyId: string;
  timestamp: Date;
  user: AuthUser;
  kind: StickyChangeKind;
  fields: FieldChange[];
}

// Sticky properties that we can render in the Activity Panel,
// with mappings from their server-key to local-key
const stickyProperties = {
  text: "text",
  type_id: "type",
  story_points: "points",
  WSJF_value: "priority",
  risk: "risk",
  iteration_number: "iterationId",
  team_id: "teamId",
  reactions: "reactions",
  flag_type: "flagType",
  depend_team_id: "dependTeamId",
  assignee: "assignee",
  reporter: "reporter",
  status: "status",
} as const;

// Other changes that we can render in the Activity Panel
const otherStickyChanges = {
  mirror_board: "mirrorBoard",
  link: "link",
};

export const stickyActivityKeys = {
  ...stickyProperties,
  ...otherStickyChanges,
};

export interface FieldChange<T = any> {
  name: (typeof stickyActivityKeys)[keyof typeof stickyActivityKeys];
  old?: T;
  new?: T;
}
