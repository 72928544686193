<template>
  <header
    class="menu-header"
    :class="{ 'dark-mode': darkMode }"
    :aria-label="$t('label.menuTop')"
  >
    <div class="menu-header-left">
      <template v-if="!isSearchActive">
        <exit-item />
        <menu-item-delimiter />
      </template>
      <search-item v-model="searchValue" />

      <template v-if="showRiskAnalysis">
        <menu-item-delimiter />
        <DropdownMenu>
          <template #trigger>
            <BaseButton variant="ghost" color="grey" icon-before="buddies" />
          </template>
          <AIFriendsDropdown @selected="handleRiskAnalisisOpen" />
        </DropdownMenu>
      </template>
      <template v-if="showMetrics">
        <menu-item-delimiter />
        <metrics-item />
      </template>

      <template v-if="showExecutionMode">
        <menu-item-delimiter />
        <work-mode-item />
      </template>
    </div>

    <BreadCrumb />

    <div class="menu-header-right">
      <alm-status class="menu-item" />

      <template v-if="links && showRiskyLinks && !isSearchActive">
        <risky-links />
        <menu-item-delimiter />
      </template>

      <board-users />
      <FacilitationMenu />
      <RunningTimers v-if="activeTimers" @click.stop="toggleTimer" />

      <template v-if="isZoomEnabled">
        <menu-item-delimiter />
        <app-zoom class="menu-item" />
      </template>
      <menu-item-delimiter />
      <user-actions is-user-on-board />
    </div>
    <transition>
      <g-p-t-panel v-if="showGPT" />
    </transition>
  </header>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";

import { toggleActions } from "@/action/toggleActions";
import AIFriendsDropdown from "@/coconut/components/RiskScenarios/AIFriendsDropdown.vue";
import { useRiskAnalysisStore } from "@/coconut/store/riskAnalysis";
import AlmStatus from "@/components/AlmStatus.vue";
import BoardUsers from "@/components/BoardUsers.vue";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import RiskyLinks from "@/components/RiskyLinks.vue";
import SearchItem from "@/components/Search/SearchItem.vue";
import RunningTimers from "@/components/Timer/RunningTimers.vue";
import UserActions from "@/components/UserActions.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { isFeatureEnabled } from "@/feature";
import EventBusUser from "@/mixins/EventBusUser";
import { isPlanningBoard } from "@/model/board";
import { currentRoute } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useGPTStore } from "@/store/gpt";
import { useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useTimerStore } from "@/store/timer";
import { useWorkModeStore } from "@/store/workMode";

import DropdownMenu from "../DropdownMenu/DropdownMenu.vue";
import AppZoom from "./AppZoom.vue";
import ExitItem from "./ExitItem.vue";
import GPTPanel from "./GPTPanel.vue";
import MetricsItem from "./MetricsItem.vue";
import FacilitationMenu from "./components/FacilitationMenu.vue";
import MenuItemDelimiter from "./components/MenuItemDelimiter.vue";
import WorkModeItem from "./components/WorkModeItem.vue";

@Component({
  components: {
    AIFriendsDropdown,
    DropdownMenu,
    BaseButton,
    BreadCrumb,
    GPTPanel,
    MetricsItem,
    SearchItem,
    ExitItem,
    AppZoom,
    UserActions,
    AlmStatus,
    BoardUsers,
    RiskyLinks,
    WorkModeItem,
    MenuItemDelimiter,
    RunningTimers,
    FacilitationMenu,
  },
})
export default class TopMenuBar extends mixins(EventBusUser) {
  searchValue = "";

  get darkMode() {
    return (
      !this.isSearchActive &&
      useWorkModeStore().showExecutionMode &&
      useWorkModeStore().isExecutionMode
    );
  }

  get isRiskAnalysisSidebarOpen() {
    return useRiskAnalysisStore().isSidebarOpen;
  }

  get isSearchActive() {
    return useSearchMenuStore().isSearchActive;
  }

  get isZoomEnabled() {
    return useBoardStore().isCurrentBoardFluid;
  }

  get showRiskyLinks() {
    return useBoardStore().showRiskyLinks;
  }

  get showMetrics() {
    return (
      useBoardStore().board?.type === "program" ||
      useBoardStore().board?.type === "team"
    );
  }

  get showExecutionMode() {
    return useWorkModeStore().showExecutionMode && !this.isSearchActive;
  }

  get showGPT() {
    return useGPTStore().isGPTActive;
  }

  get links() {
    return useLinkStore().hasRiskyLinks;
  }

  get activeTimers() {
    const allTimers = [
      ...useTimerStore().boardTimers,
      ...useTimerStore().artTimers,
    ];
    return allTimers.some((timer) => timer.data.state === "running");
  }

  toggleTimer() {
    toggleActions.showTimer("mainMenu");
  }

  get showRiskAnalysis() {
    return (
      isFeatureEnabled(currentRoute(), "coconut") &&
      isPlanningBoard(useBoardStore().currentBoard().type)
    );
  }

  handleRiskAnalisisOpen() {
    useRiskAnalysisStore().isSidebarOpen = true;
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.menu-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--back-color);
  border-bottom: 2px solid colors-old.$border-menu-color;
  height: variables.$top-header-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3px;
  padding-right: 14px;
  transition: 0.15s ease-in-out background-color;
  z-index: z-index.$menu;

  .menu-header-left,
  .menu-header-right {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
  }

  .menu-header-right {
    justify-content: flex-end;

    .menu-item-delimiter {
      margin: 0 8px;
    }
  }

  .menu-item {
    min-height: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.plan-readout-button-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
@/coconut/store/riskAnalysis
