<script lang="ts" setup>
import { computed } from "vue";

import { RiskItem as RiskItemType } from "@/coconut/types";
import BaseList from "@/components-ng/BaseList/BaseList.vue";

import RiskItem from "./RiskItem.vue";

const props = defineProps<{ riskItems: RiskItemType[] }>();
const maxScore = computed(() => {
  return Math.max(...props.riskItems.map((result) => result.totalScore));
});

defineEmits<{
  itemHovered: [id: string | null];
  itemSelected: [id: string];
}>();
</script>

<template>
  <BaseList class="sticky-risk-item-list">
    <RiskItem
      v-for="item in riskItems"
      :key="item.sticky.data.id"
      :max-score="maxScore"
      :item="item"
      @mouseover="$emit('itemHovered', item.sticky.data.id)"
      @mouseleave="$emit('itemHovered', null)"
      @click="$emit('itemSelected', item.sticky.data.id)"
    />
  </BaseList>
</template>

<style lang="scss" scoped>
.sticky-risk-item-list {
  gap: 0;
  flex-grow: 1;
  overflow: auto;

  .text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: calc(1.5em * 2);
  }
}
</style>
