import { ErrorCode, NatsError } from "nats.ws";

import { backlogSyncTimeout, iterationSyncTimeout } from "@/Settings";
import { ArtId, BoardType } from "@/model/baseTypes";
import { Reaction } from "@/model/card";
import { SessionAlmStatus } from "@/model/session";
import { Shape } from "@/model/shape";
import { TimerData } from "@/model/timer";
import { captureException } from "@/sentry";
import { useArtStore } from "@/store/art";
import { useClientStore } from "@/store/client";
import { useUserStore } from "@/store/user";

import {
  SearchStickiesQuery,
  Subscription,
  SubscriptionLevel,
} from "./BackendSession";
import { denormalizedServerAlmItemTypeMap } from "./BaseBackendSession.utils";
import { RpcBackend } from "./RpcBackend";
import {
  NormalizedServerAlmItemTypeMap,
  ServerAlmInfo,
  ServerAlmItemType,
  ServerAlmItemTypeMap,
  ServerAlmSource,
  ServerAlmStickyTypeData,
  ServerAlmType,
  ServerArt,
  ServerBacklogSyncState,
  ServerBoard,
  ServerBoardIteration,
  ServerCategory,
  ServerEvent,
  ServerFlexBackground,
  ServerFlexBoard,
  ServerFlexType,
  ServerInfo,
  ServerIteration,
  ServerIterationSyncState,
  ServerLicense,
  ServerLink,
  ServerLinkType,
  ServerMirrorResponse,
  ServerObjectives,
  ServerSearchResult,
  ServerSession,
  ServerSettings,
  ServerStickiesUpdate,
  ServerSticky,
  ServerStickyChange,
  ServerStickyGroupBoardIds,
  ServerStickyType,
  ServerSyncStatus,
  ServerTeam,
  ServerUserMapping,
  isNormalizedServerAlmItemTypeMap,
} from "./serverModel";

export class BaseBackendSession {
  private almSources?: ServerAlmSource[];
  protected almType: ServerAlmType;
  protected almSyncId?: string;
  private almConnectionId?: string;
  protected subscriptions: { [level in SubscriptionLevel]: Subscription[] } = {
    room: [],
    session: [],
    board: [],
  };
  protected sessionId = "";

  constructor(
    protected company: string,
    protected rpcBackend: RpcBackend,
  ) {}

  almSource(
    boardType: ServerBoard["board_type"],
    {
      teamId,
      artId,
    }: { teamId?: ServerBoard["user_id"]; artId: ServerBoard["art_id"] },
  ): ServerAlmSource | undefined {
    if (boardType === "team") {
      return this.almSources?.find(
        (s) => s.type === "team" && s.user_id === teamId,
      );
    }
    if (boardType === "backlog" && useArtStore().isMultiArt) {
      return this.almSources?.find(
        (source) => source.type === "art" && source.art_id === artId,
      );
    }
  }

  subscriptionCount() {
    return Object.values(this.subscriptions).reduce(
      (count, subscriptions) => count + subscriptions.length,
      0,
    );
  }

  getAlmType() {
    return this.almType;
  }

  getAlmConnectionId(): string | undefined {
    return this.almConnectionId;
  }

  async setSessionId(id: string): Promise<boolean> {
    this.sessionId = id;
    const info = await this.getAlmSessionInfo();
    this.almType = info.alm_connection_type;
    this.rpcBackend.init(this.sessionId, this.almType);
    this.almSyncId = info.sync_session_id;
    this.almConnectionId = info.alm_connection_id;
    this.almSources = [];
    if (!this.almType) {
      return false;
    }
    this.almSources = await this.getAlmSources();
    return true;
  }

  async unsubscribe(level: SubscriptionLevel, connectionOpen = true) {
    const promises = new Array<Promise<void>>();
    switch (level) {
      case "room":
        promises.concat(this.doUnsubscribe("room", connectionOpen));
      // fall through
      case "session":
        promises.concat(this.doUnsubscribe("session", connectionOpen));
      // fall through
      case "board":
        promises.concat(this.doUnsubscribe("board", connectionOpen));
    }
    await Promise.all(promises);
  }

  doUnsubscribe(
    level: SubscriptionLevel,
    connectionOpen: boolean,
  ): Array<Promise<void>> {
    const subscriptions = this.subscriptions[level];
    this.subscriptions[level] = [];
    if (!connectionOpen) {
      return [];
    }
    return subscriptions.map(async (subscription) => {
      try {
        return await this.drainAndUnsubscribe(subscription);
      } catch (error) {
        if (error instanceof NatsError) {
          if (error.code === ErrorCode.Disconnect) {
            return;
          }
        }
        captureException(error, {
          subscription: {
            id: subscription.getID(),
            subject: subscription.getSubject(),
          },
        });
      }
    });
  }

  drainAndUnsubscribe(subscription: Subscription) {
    return subscription.drain().finally(() => subscription.unsubscribe());
  }

  getLicense(): Promise<ServerLicense> {
    return this.rpcBackend.userCall("license.get");
  }

  getSessions(): Promise<ServerSession[]> {
    if (useUserStore().technicalUser.role === "admin") {
      return this.rpcBackend.adminCall("session.list", []);
    }
    return this.rpcBackend.roomCall("session.list", []);
  }

  getAlmSessionInfo(): Promise<ServerAlmInfo> {
    return this.rpcBackend.almCall("session.get", [this.sessionId]);
  }

  getAlmStatus(): Promise<SessionAlmStatus> {
    return this.rpcBackend.almToolCall("session.get_status", [this.almSyncId]);
  }

  getAlmUser(): Promise<ServerUserMapping> {
    return this.rpcBackend.almToolCall("alm_user.get", [this.almConnectionId]);
  }

  getAlmSources(): Promise<ServerAlmSource[]> {
    return this.rpcBackend.almToolCall(`project_source.list`, [
      this.almConnectionId,
    ]);
  }

  getSettings(): Promise<ServerSettings> {
    return this.rpcBackend.sessionCall("settings.get");
  }

  getTeams(): Promise<ServerTeam[]> {
    return this.rpcBackend.sessionCall("team.list");
  }

  getArts(): Promise<ServerArt[]> {
    return this.rpcBackend.sessionCall<ServerArt[]>("art.list");
  }

  getIterations(sessionId: string): Promise<ServerIteration[]> {
    return this.rpcBackend.roomCall("iteration.list", [sessionId]);
  }

  getLinks(): Promise<ServerLink[]> {
    return this.rpcBackend.sessionCall("link.list");
  }

  getLinkTypes(): Promise<ServerLinkType[]> {
    return this.rpcBackend.sessionCall("link_type.list");
  }

  async getBoards() {
    return this.rpcBackend.sessionCall<Array<ServerBoard | ServerFlexBoard>>(
      "board.list",
    );
  }

  addBoard(type: string, name: string): Promise<string> {
    return this.rpcBackend.flexBoardCall("flexboard.add", [this.sessionId], {
      board_type: "flex",
      flexboard_type: type,
      name,
    });
  }

  updateBoard(id: string, name: string): Promise<void> {
    return this.rpcBackend.flexBoardCall("flexboard.update", [id], { name });
  }

  deleteBoard(id: string): Promise<void> {
    return this.rpcBackend.flexBoardCall("flexboard.delete", [id]);
  }

  getScale(boardId: string): Promise<number> {
    return this.rpcBackend.boardCall<number>("sticky.get_scale", [boardId]);
  }

  getBoardStickies(
    boardId: string,
    since?: number,
  ): Promise<ServerSticky[] | ServerStickiesUpdate> {
    return this.rpcBackend.boardCall("sticky.list", [boardId], {
      timestamp: since,
    });
  }

  getStickies(stickyIds: string[]): Promise<ServerSticky[]> {
    return this.rpcBackend.staticSessionCall("sticky.list", [stickyIds]);
  }

  getBoardIterations(boardId: string): Promise<ServerBoardIteration[]> {
    return this.rpcBackend.boardCall("iteration.list", [boardId]);
  }

  async getStickyTypes(): Promise<ServerStickyType[]> {
    const ts =
      await this.rpcBackend.sessionCall<ServerStickyType[]>("sticky_type.list");
    return ts.sort((a, b) => (a.index || 0) - (b.index || 0));
  }

  async getAlmItemType(
    almType: string,
    boardType: BoardType,
    boardKeys: string[],
  ): Promise<ServerAlmItemTypeMap> {
    const response: NormalizedServerAlmItemTypeMap | ServerAlmItemTypeMap =
      await this.rpcBackend.almToolCall("item.get_by_alm_type", [
        this.almSyncId,
        almType,
        boardType,
        boardKeys,
      ]);

    return isNormalizedServerAlmItemTypeMap(response)
      ? denormalizedServerAlmItemTypeMap(response)
      : response;
  }

  getAlmItemTypeForSticky(stickyAlmId: string): Promise<ServerAlmItemType> {
    return this.rpcBackend.almToolCall("item.get_by_sticky", [
      this.almConnectionId,
      stickyAlmId,
    ]);
  }

  getObjectives(boardId: string): Promise<ServerObjectives> {
    return this.rpcBackend.boardCall("objective.list", [boardId]);
  }

  newSticky(
    boardId: ServerSticky["board_id"],
    props: Partial<ServerSticky>,
  ): Promise<ServerSticky["_id"]> {
    return this.rpcBackend.boardCall("sticky.create", [boardId, null, props], {
      client_id: useClientStore().clientId,
    });
  }

  mirror(cardId: string, toBoardId: string, props?: Partial<ServerSticky>) {
    return this.rpcBackend.boardCall<ServerMirrorResponse>("sticky.mirror", [
      cardId,
      toBoardId,
      props,
    ]);
  }

  move(cardId: string, teamId: string) {
    return this.rpcBackend.boardCall("sticky.move", [cardId, +teamId]);
  }

  toRisk(
    cardId: string,
    boardId: string,
    text: string,
    typeId: string,
    teamId: string,
  ) {
    return this.rpcBackend.boardCall("sticky.create_on_risk_board", [
      boardId,
      { id: cardId, text, type_id: typeId, team_id: +teamId },
    ]);
  }

  addLink(cardId: string, toId: string): Promise<ServerLink> {
    return this.rpcBackend.sessionCall("link.create", [cardId, toId]);
  }

  addObjectiveLink(
    cardId: string,
    boardId: string,
    objectiveId: string,
  ): Promise<void> {
    return this.rpcBackend.staticSessionCall<any>("objective_link.create", [
      cardId,
      boardId,
      objectiveId,
    ]);
  }

  deleteLink(id: string): Promise<void> {
    return this.rpcBackend.staticSessionCall("link.delete", [id]);
  }

  deleteObjectiveLink(
    cardId: string,
    boardId: string,
    objectiveId: string,
  ): Promise<void> {
    return this.rpcBackend.staticSessionCall<any>("objective_link.delete", [
      cardId,
      boardId,
      objectiveId,
    ]);
  }

  syncIteration(
    teamId: string,
    iterationId: number,
  ): Promise<ServerIterationSyncState> {
    return this.rpcBackend.almToolCall(
      "session.sync_iteration",
      [this.almSyncId, teamId, iterationId],
      iterationSyncTimeout,
    );
  }

  async syncBacklog(artId?: ArtId): Promise<ServerBacklogSyncState> {
    const response = await this.rpcBackend.almToolCall(
      "session.sync_program_backlog",
      [this.almSyncId, artId],
      backlogSyncTimeout,
    );
    if (typeof response === "string") {
      return { success: true, status: response as ServerSyncStatus };
    }
    return response as ServerBacklogSyncState;
  }

  getFlexBackgrounds(): Promise<ServerFlexBackground[]> {
    return this.rpcBackend.flexBoardCall("background.list", []);
  }

  getFlexTypes(): Promise<ServerFlexType[]> {
    return this.rpcBackend.flexBoardCall("board_type.list", [this.sessionId]);
  }

  getCategories(): Promise<ServerCategory[]> {
    return this.rpcBackend.flexBoardCall("category.list", [this.sessionId]);
  }

  addCategory(name: string): Promise<string> {
    return this.rpcBackend.flexBoardCall("category.add", [
      this.sessionId,
      name,
    ]);
  }

  deleteCategory(id: string): Promise<any> {
    return this.rpcBackend.flexBoardCall("category.delete", [id]);
  }

  updateCategory(
    id: string,
    update: { name?: string; position?: number },
  ): Promise<any> {
    return this.rpcBackend.flexBoardCall("category.update", [id], update);
  }

  addBoardToCategory(boardId: string, categoryId: string): Promise<any> {
    return this.rpcBackend.flexBoardCall("flexboard.add_to_category", [
      boardId,
      categoryId,
    ]);
  }

  removeBoardFromCategory(boardId: string, categoryId: string): Promise<any> {
    return this.rpcBackend.flexBoardCall("flexboard.remove_from_category", [
      boardId,
      categoryId,
    ]);
  }

  searchStickies(query: SearchStickiesQuery): Promise<ServerSearchResult[]> {
    return this.rpcBackend.sessionCall("sticky.search", [
      {
        text: query.text,
        team_id: query.teams?.map(({ id }) => (id ? +id : null)),
        type_id: query.types?.map(({ id }) => id),
        status_classes: query.statusClasses,
        flag_type: query.flags?.map((flag) =>
          flag.isEmpty() ? null : flag.toString(),
        ),
      },
    ]);
  }

  getEvents(): Promise<ServerEvent[]> {
    return this.rpcBackend.sessionCall("timer.list");
  }

  createTimerEvent(
    data: Partial<TimerData>,
    target: { boardId?: string; artId?: string },
  ): Promise<string> {
    return this.rpcBackend.sessionCall("timer.create", [data], {
      board_id: target.boardId,
      art_id: target.artId,
    });
  }

  updateTimerEvent(id: string, data: Partial<TimerData>): Promise<void> {
    return this.rpcBackend.sessionCall("timer.update", [id, data]);
  }

  deleteEvent(id: string): Promise<void> {
    return this.rpcBackend.sessionCall("timer.delete", [id]);
  }

  getServerInfo(): Promise<ServerInfo> {
    return this.rpcBackend.serverCall("info.get");
  }

  addReaction(cardId: string, reaction: Reaction): Promise<void> {
    return this.rpcBackend.boardCall("reaction.add", [cardId, reaction]);
  }

  removeReaction(cardId: string, reaction: Reaction): Promise<void> {
    return this.rpcBackend.boardCall("reaction.remove", [cardId, reaction]);
  }

  addShape(boardId: string, shape: Shape): Promise<string> {
    return this.rpcBackend.boardCall("shape.add", [boardId, shape]);
  }

  editShape(boardId: string, shape: Shape): Promise<void> {
    return this.rpcBackend.boardCall("shape.edit", [boardId, shape]);
  }

  removeShape(boardId: string, shapeId: string): Promise<void> {
    return this.rpcBackend.boardCall("shape.remove", [boardId, shapeId]);
  }

  async getAlmStickyTypes(): Promise<ServerAlmStickyTypeData[]> {
    return this.almType
      ? this.rpcBackend.almToolCall("alm_type.list", [this.almSyncId])
      : [];
  }

  getBoardIdsOfGroupedStickies(stickyId: string) {
    return this.rpcBackend.staticSessionCall<ServerStickyGroupBoardIds>(
      "sticky.get_group_board_ids",
      [stickyId],
    );
  }

  getStickyChanges(id: string) {
    return this.rpcBackend.staticSessionCall<ServerStickyChange[]>(
      "sticky.changes",
      [id],
    );
  }
}
