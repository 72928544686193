import * as ss from "simple-statistics";

/**
 * Calculates the coefficient of variation (CV) for feature distribution across
 * iterations in PI planning. The CV is a standardized measure of dispersion
 * of a probability distribution and is used here to assess the variability
 * in the number of features per iteration relative to the mean number of
 * features.
 *
 * If the dataset is empty or the mean of the values is 0 (indicating no
 * features or uniform distribution with no variation), the function returns
 * a default value of 1.
 *
 * The function computes the CV as the ratio of the standard deviation to the
 * mean of the feature counts. This raw CV is then normalized to be within the
 * range of 0 to 1, where:
 * - 0 indicates no variability (all iterations have the same number of features).
 * - 1 indicates maximum variability (one iteration has all the features).
 *
 * This normalization adjusts the CV based on the possible maximum CV given the
 * number of iterations, offering a relative measure of variability. A higher
 * normalized CV suggests greater unevenness in the distribution of features,
 * highlighting potential risks in planning and execution phases due to
 * overloading or underutilizing specific iterations.
 */
export function calculateStandardDeviation(values: number[]): number {
  if (ss.sum(values) < values.length) return 0;
  const cv = ss.standardDeviation(values);
  const maxValues = [ss.sum(values), ...new Array(values.length - 1).fill(0)];
  const maxCv = ss.standardDeviation(maxValues);
  return cv / maxCv;
}

/**
 * Calculates a normalized balance point for the distribution of features
 * across iterations within PI planning. This metric provides an indication
 * of the weighted average position of features in the distribution, offering
 * insights into the planning's temporal balance.
 *
 * The calculation first determines if there are features to analyze; if the
 * total sum of features is 0, it returns a default value of 0, indicating
 * an undefined or empty state.
 *
 * Each feature count is then weighted by its iteration index, reflecting its
 * position within the PI. This weighting accounts for the temporal aspect of
 * feature distribution, emphasizing when features are planned for completion.
 *
 * The balance point is the weighted sum of features divided by the total
 * number of features, yielding a raw balance value that represents the average
 * position of feature workload in the PI timeline.
 *
 * This raw balance point is then normalized by dividing it by the maximum
 * possible index (values.length - 1), ensuring the result is a fraction
 * between 0 and 1:
 * - A value closer to 0 indicates a front-loaded distribution, with most
 *   features planned for earlier iterations.
 * - A value closer to 1 suggests a back-loaded distribution, with more
 *   features pushed towards later iterations.
 *
 * The normalized balance point aids in evaluating the distribution strategy
 * for features across the PI, helping teams to identify and adjust for
 * potential imbalances in workload and resource allocation.
 */
export function calculateBalancePoint(values: number[]): number {
  const total = ss.sum(values);
  if (total < 2) return 0;

  const weightedValues = values.map((value, index) => value * index);
  const balancePoint = ss.sum(weightedValues) / (total * (values.length - 1));
  return balancePoint < 0.5 ? 0 : (balancePoint - 0.5) * 2;
}
