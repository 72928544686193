<script setup lang="ts">
import { cardActions } from "@/action/cardActions";
import { Reaction } from "@/model/card";

import ReactionIcon from "./ReactionIcon.vue";

const emit = defineEmits<{ input: [] }>();
const props = defineProps<{ cardId: string; reactions: Reaction[] }>();

async function toggleReaction(reaction: Reaction) {
  await cardActions.toggleReaction("card", props.cardId, reaction);
  emit("input");
}
</script>

<template>
  <div class="add-reactions">
    <ReactionIcon
      v-for="reaction in reactions"
      :key="reaction"
      :reaction="reaction"
      class="ignore-click"
      @click="toggleReaction(reaction)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins" as *;

.add-reactions {
  position: absolute;
  bottom: -2.5em;
  left: -1 * variables.$border-width;
  background-color: colors-old.$back-color;
  border-radius: 30px;
  display: flex;
  gap: 0.8em;
  padding: 0.5em;
  align-items: center;

  @include default-shadow;

  img {
    cursor: pointer;
    top: 0;
    left: 0;
    transition:
      0.2s ease-in-out transform,
      0.2s ease-in-out opacity;

    &:hover {
      transform: scale(1.2);
      opacity: 1;
    }
  }

  &:hover img:not(:hover) {
    opacity: 0.5;
  }
}
</style>
