import { Vue } from "vue-class-component";

import { useAppSizeStore } from "@/store/appSize";
import { getPanelWidth } from "@/utils/dom";

export default class SidePanelLeft extends Vue {
  created() {
    useAppSizeStore().setLeft(0, getPanelWidth() + 1);
  }

  unmounted() {
    useAppSizeStore().setLeft(68, 0);
  }
}
