import { sortBy } from "lodash-es";

import { BoardCard, Card } from "@/model/card";
import { LinkableCard } from "@/model/link";
import { useBoardStore } from "@/store/board";
import { linkBetween } from "@/store/link";
import { useTeamStore } from "@/store/team";

export function calcLinkableCards(targetCards: Card[]): LinkableCard[] {
  return sortBy(
    useBoardStore().backlogStickies.filter(isNotTarget).map(makeLinkable),
    [sameTeam, teamName, text],
  );

  function isNotTarget(card: BoardCard) {
    return !targetCards.some((target) => target.id === card.data.id);
  }

  function makeLinkable(card: BoardCard) {
    return {
      ...card.data,
      linked: targetCards.every((c) => !!linkBetween(card.data, c)),
    };
  }

  function sameTeam(card: Card) {
    return card.teamId !== useTeamStore().currentTeam.id;
  }

  function teamName(card: Card) {
    return useTeamStore().findTeam({ id: card.teamId ?? undefined })?.name;
  }

  function text(card: Card) {
    return card.text;
  }
}
