<script setup lang="ts">
import { ComponentInstance, markRaw, ref } from "vue";

import { isCloud } from "@/Environment";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";

import LoginPageEmail from "./components/LoginPageEmail.vue";
import { useServicePopups } from "./useServicePopups";

const currentLoginPage = ref<ComponentInstance<any>>(markRaw(LoginPageEmail));

const { showServices, openServices } = useServicePopups();

function pageSwitch(page: ComponentInstance<any>) {
  currentLoginPage.value = markRaw(page);
}
</script>

<template>
  <div>
    <div v-if="showServices" class="services">
      <BaseButton data-t @click="openServices">Open services</BaseButton>
    </div>
    <div class="split left">
      <div class="form">
        <div>
          <img
            src="@/assets/logo-named.svg"
            class="piplanning-logo"
            :alt="$t('label.mainLogo')"
          />
        </div>
        <component :is="currentLoginPage" @page-switch="pageSwitch" />
      </div>
      <div v-if="isCloud" class="footer">
        <I18nT keypath="userAgreement.base" scope="global">
          <template #termsOfService>
            <a
              href="https://www.piplanning.io/legal/terms-of-service"
              target="_blank"
            >
              {{ $t("userAgreement.termsOfService") }}
            </a>
          </template>
          <template #privacyPolicy>
            <a href="https://scaledagile.com/privacy-policy/" target="_blank">
              {{ $t("userAgreement.privacyPolicy") }}
            </a>
          </template>
        </I18nT>
      </div>
    </div>

    <div class="split right">
      <div class="container">
        <img
          src="@/assets/login-background.svg"
          class="img"
          aria-hidden="true"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.services {
  position: absolute;
  z-index: z-index.$menu;
}

.split {
  height: 100%;
  width: 50%;
  position: fixed;
  top: 0;
  overflow-x: hidden;
}

.left {
  left: 0;
  background-color: colors-old.$back-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;

  .piplanning-logo {
    margin-bottom: 16px;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;
    max-width: 290px;
  }

  .footer {
    color: colors-old.$text-secondary-color;
    font-size: 12px;
  }
}

.right {
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .img {
    min-width: 100%;
    min-height: 100%;
    max-inline-size: none;
    max-block-size: none;
  }

  .container {
    width: 100%;
    height: 100%;
  }
}
</style>
