import { clamp, floor } from "lodash-es";
import * as ss from "simple-statistics";

import { BoardPattern, RiskScenarioRecommendation } from "@/coconut/types";
import { countStickiesInTeam } from "@/coconut/utils/data";
import { calculateStandardDeviation } from "@/coconut/utils/statistics";
import { Board } from "@/model/board";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";

// 3. Distribution of work throughout teams [BOARD PATTERN]
// https://www.figma.com/file/QEGprRz74Z5m41MVuwRUI6/Plan-Analyzer?type=design&node-id=1584-823&mode=design&t=aRI1tzOVjdTBnhBW-4

const distributionInTeams: BoardPattern = {
  id: 3,
  title: "Uneven Distribution of work Across Teams",
  type: "board",
  description:
    "This risk pattern arises when the workload (features) is not evenly distributed among all teams within a Planning Interval (PI). An uneven distribution can lead to several negative outcomes, such as overburdening certain teams, underutilizing others, creating bottlenecks, reducing overall efficiency, and potentially lowering morale. The objective is to ensure an equitable distribution of work across all teams to maintain balance and optimize productivity.",
  weight: 1,
  process(board: Board) {
    const iterations = useSessionStore().iterations;
    const cardsPerTeam = useTeamStore().teamsInCurrentArt.map((team) => {
      const teamData = countStickiesInTeam(team, board, iterations);
      return ss.sum(Object.values(teamData));
    });

    const value = calculateStandardDeviation(cardsPerTeam);

    return [
      {
        patternId: this.id,
        scope: { id: board.id, type: distributionInTeams.type },
        title: this.title,
        description: this.description,
        weight: this.weight,
        score: floor(clamp(value * 100, 0, 100)),
        recommendations: this.recommendations,
      },
    ];
  },
};

export const recommendations: RiskScenarioRecommendation[] = [
  {
    title: "Workload Redistribution",
    description:
      "Review the workload across all teams and try to adjust work from overburdened teams to those with lighter loads. Use the board to visualize and adjust the distribution of features, aiming for a more balanced workload.",
  },
  {
    title: "Task Swapping",
    description:
      "Identify features or work that can be swapped between teams to balance the workload. Adjust the board to reflect these swaps, ensuring all teams have a comparable amount of work.",
  },
  {
    title: "Capacity-Based Planning",
    description:
      "During planning sessions, consider each team’s capacity and ensure that features are allocated based on the available bandwidth.",
  },
];

export default distributionInTeams;
