<script setup lang="ts">
import { computed } from "vue";

import { linkActions } from "@/action/linkActions";
import LinkableObjectiveRow from "@/components/LinkableObjectiveRow.vue";
import NavigableList from "@/components/NavigableList.vue";
import { Card } from "@/model/card";
import { LinkableObjective } from "@/model/link";
import { useBoardStore } from "@/store/board";
import { useSelectionStore } from "@/store/selection";

type CommittableObjective = LinkableObjective & {
  isCommitted: boolean;
};

const props = defineProps<{
  cards: Card[];
  objectives: CommittableObjective[];
}>();
const emit = defineEmits<{ "link-change": [] }>();

const committedObjectives = computed(() =>
  props.objectives.filter((objective) => objective.isCommitted),
);
const uncommittedObjectives = computed(() =>
  props.objectives.filter((objective) => !objective.isCommitted),
);
const showEmptyMessage = computed(() => props.objectives.length === 0);

function toggleLink(linkableObjective: CommittableObjective) {
  if (useBoardStore().areMultipleStickiesSelected) {
    useSelectionStore().addLinkingToHistory();
  }
  if (linkableObjective.linked) {
    linkableObjective.linked = false;
    props.cards.forEach((card) => {
      linkActions.removeObjective(
        "modal",
        card.id,
        useBoardStore().boardByType("team").id,
        linkableObjective.id,
        "linking-modal",
      );
    });
  } else {
    linkableObjective.linked = true;
    props.cards.forEach((card) => {
      linkActions.addObjective(
        "modal",
        card.id,
        useBoardStore().boardByType("team").id,
        linkableObjective.id,
        "linking-modal",
      );
    });
  }
  emit("link-change");
}
</script>

<template>
  <div class="sticky-link-objectives">
    <div class="scrollable-list">
      <navigable-list
        :first-list="committedObjectives"
        :second-list="uncommittedObjectives"
        @select="toggleLink"
      >
        <template #first-list-header>
          <span v-if="committedObjectives.length" class="objectives-header">
            {{ $t("objectives.committed") }}
          </span>
        </template>
        <template #row="{ item }">
          <linkable-objective-row :linkable-objective="item" />
        </template>
        <template #second-list-header>
          <span v-if="uncommittedObjectives.length" class="objectives-header">
            {{ $t("objectives.uncommitted") }}
          </span>
        </template>
        <template #second-list-row="{ item }">
          <linkable-objective-row :linkable-objective="item" />
        </template>
      </navigable-list>
    </div>
    <span v-if="showEmptyMessage" class="empty-placeholder">
      {{ $t("linkModal.empty") }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/utils";
@use "@/styles/modal" as *;

.sticky-link-objectives {
  .objectives-header {
    margin: 1rem 0 0.5rem;
    font-size: font.$size-normal;
    font-weight: font.$weight-bold;
  }

  .empty-placeholder {
    @include empty-placeholder;
  }

  .scrollable-list {
    overflow-y: scroll;
    max-height: 45vh;

    @include utils.hide-scrollbar;
  }
}
</style>
