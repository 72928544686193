<template>
  <img :src="image[reaction]" :aria-label="$t(label[reaction])" />
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import hot from "@/assets/reactions/reaction-hot.svg";
import increment from "@/assets/reactions/reaction-increment.svg";
import love from "@/assets/reactions/reaction-love.svg";
import { i18n } from "@/i18n";
import { Reaction } from "@/model/card";

@Component({})
export default class ReactionIcon extends Vue {
  @Prop(String) readonly reaction!: Reaction;

  image: { [reaction in Reaction]: string } = { hot, increment, love };

  label: { [reaction in Reaction]: string } = {
    hot: /*$t*/ i18n.global.t("label.reaction.hot"),
    increment: /*$t*/ i18n.global.t("label.reaction.increment"),
    love: /*$t*/ i18n.global.t("label.reaction.love"),
  };
}
</script>

<style lang="scss" scoped>
@use "@/styles/board";

img {
  height: board.len(40px);
}
</style>
