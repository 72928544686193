import { clamp, floor } from "lodash-es";

import { BoardPattern, RiskScenarioRecommendation } from "@/coconut/types";
import { countStickiesInIteration as getStickiesPerIterationCount } from "@/coconut/utils/data";
import { calculateStandardDeviation } from "@/coconut/utils/statistics";
import { Board } from "@/model/board";
import { useSessionStore } from "@/store/session";

// 4. Distribution of work throughout iterations [BOARD PATTERN]
// https://www.figma.com/file/QEGprRz74Z5m41MVuwRUI6/Plan-Analyzer?type=design&node-id=1584-823&mode=design&t=aRI1tzOVjdTBnhBW-4

const pattern: BoardPattern = {
  id: 4,
  title: "Imbalanced Workload Distribution Across Iterations",
  description: `This risk pattern occurs when the distribution of work across iterations is imbalanced for the entire board, encompassing all teams within a Planning Interval (PI). The risk is highest when the work is unevenly spread, causing some iterations to be overloaded while others are underutilized. This can lead to delays, quality issues, and inefficient resource utilization. The goal is to achieve an even distribution of work across all iterations within the PI to maintain steady progress and optimize efficiency for all teams.`,
  type: "board",
  weight: 1,
  process(board: Board) {
    const iterations = useSessionStore().iterations;
    const data = getStickiesPerIterationCount(iterations, board);

    const value = calculateStandardDeviation(Object.values(data));

    return [
      {
        patternId: pattern.id,
        scope: { id: board.id, type: pattern.type },
        title: pattern.title,
        description: pattern.description,
        weight: pattern.weight,
        score: floor(clamp(value * 100, 0, 100)),
        recommendations: this.recommendations,
      },
    ];
  },
};

/**
 * Workload Redistribution Across Iterations: Review the workload distribution across all iterations and adjust work from overloaded iterations to those with lighter loads. Use the board to visualize and adjust the distribution of features, aiming for a more balanced workload across the entire PI.
Work Swapping Between Iterations: Identify features or tasks that can be swapped between iterations to balance the workload. Adjust the board to reflect these swaps, ensuring all iterations have a comparable amount of work.
Iteration Capacity Planning: During planning sessions, consider the capacity of all teams for each iteration and ensure that features are allocated based on the overall available bandwidth.
 * 
 */

export const recommendations: RiskScenarioRecommendation[] = [
  {
    title: "Workload Redistribution Across Iterations",
    description:
      "Review the workload distribution across all iterations and adjust work from overloaded iterations to those with lighter loads. Use the board to visualize and adjust the distribution of features, aiming for a more balanced workload across the entire PI.",
  },
  {
    title: "Work Swapping Between Iterations",
    description:
      "Identify features or tasks that can be swapped between iterations to balance the workload. Adjust the board to reflect these swaps, ensuring all iterations have a comparable amount of work.",
  },
  {
    title: "Iteration Capacity Planning",
    description:
      "During planning sessions, consider the capacity of all teams for each iteration and ensure that features are allocated based on the overall available bandwidth.",
  },
];

export default pattern;
