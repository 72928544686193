<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import SelectStickyType from "@/components/ui/SelectStickyType/SelectStickyType.vue";
import { StickyType } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { stickyNoteTypeChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const dropdown = injectStrict(dropdownKey);

const props = defineProps<{
  trigger: "action-menu"; // used for event tracking analytics
}>();

const stickyTypes = computed(() => useBoardStore().originStickyTypes);

const onChange = (targetType: StickyType | StickyType[]) => {
  const type = Array.isArray(targetType) ? targetType[0] : targetType;
  const fromType = card.type.functionality;
  const toType = type.functionality;
  const boardType = board.value.type;
  trackEvent(stickyNoteTypeChanged(fromType, toType, boardType, props.trigger));

  cardActions.setType("card", card.id, type.id);

  dropdown.close();
};
</script>

<template>
  <SelectStickyType
    :sticky-types="stickyTypes"
    @update:model-value="onChange"
  />
</template>
