<template>
  <div
    class="overlay labels scrollable no-wheel"
    @wheel="stopScrollPropagation"
  >
    <div
      v-for="link in links"
      :id="optionId(link)"
      :key="link.id"
      :class="$style['link-row']"
      @pointerdown.stop
    >
      <span :class="$style.sign">
        <img
          v-if="isObjective(link)"
          :class="$style.icon"
          src="@/assets/objectives.svg"
        />
        <svg v-else viewBox="0 0 11 11" :class="$style.dot">
          <circle cx="5" cy="5" r="5" :fill="getColor(link)" />
        </svg>
      </span>
      <span :class="$style.entry" @click.stop="select(link)">
        {{ link.text }}
        <link-info :link="link" />
        <div class="unlink" @click.stop="unlink(link)">
          {{ $t("cardLabelsOverlay.unlink") }}
        </div>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { linkActions } from "@/action/linkActions";
import ScrollSupport from "@/mixins/ScrollSupport";
import SelectList from "@/mixins/SelectList";
import { Card } from "@/model/card";
import { CardLink } from "@/model/link";
import { useLinkStore } from "@/store/link";
import color from "@/styles/color.module.scss";
import { toCssColor } from "@/utils/color";

import LinkInfo from "./LinkInfo.vue";

@Component({ components: { LinkInfo }, emits: ["select"] })
export default class CardLabelsOverlay extends mixins(
  SelectList,
  ScrollSupport,
) {
  @Prop(Object) readonly card!: Card;

  @Watch("links", { immediate: true })
  linksChanged() {
    this.selectList = this.links;
  }

  mounted() {
    this.adjustScrollbarWidth();
  }

  get links(): CardLink[] {
    return useLinkStore().getCardLinks(this.card);
  }

  select(link: CardLink) {
    this.$emit("select", this.links[0] === link ? null : link);
  }

  async unlink(link: CardLink) {
    if (link.kind === "objective") {
      await linkActions.removeObjective(
        "card",
        this.card.id,
        link.boardId,
        link.objectiveId,
      );
      return;
    }
    await linkActions.remove("card", { linkId: link.id });
    if (this.links.length === 0) {
      this.$emit("select");
    }
  }

  optionId(link: CardLink) {
    return "labelsOverlay-" + link.id;
  }

  isObjective(link: CardLink) {
    return link.kind === "objective";
  }

  getColor(link: CardLink) {
    if (!link.color) {
      return color.placeholder;
    }
    return toCssColor(link.color);
  }
}
</script>

<style lang="scss">
.overlay.labels {
  & > div {
    position: relative;
  }

  .unlink {
    position: absolute;
    right: 4em;
    bottom: 0.3em;
    font-size: 50%;
    line-height: 1em;
    text-decoration: underline;
  }
}
</style>

<style lang="scss" module>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.link-row {
  display: flex;
  place-items: center;
  justify-content: center;
  font-size: 150%;

  .dot {
    width: 1.1em;
    height: 1.1em;
  }

  .icon {
    width: 1em;
    height: 1em;
  }

  .sign {
    display: flex;
    justify-content: center;
    flex: 0 0 2.3em;
  }

  .entry {
    position: relative;
    line-height: 1.6em;
    display: inline-block;
    border-bottom: 0.1em solid colors-old.$divider-color;
    cursor: pointer;
    word-break: break-word;
    padding: 0.3em 0.5em;
    min-height: 2em;
    flex: 1 1 100%;

    &:hover {
      background-color: colors-old.$divider-color;
    }
  }
}
</style>
