import { defineStore } from "pinia";
import { DefineComponent, EmitsOptions, PropType, Raw, markRaw } from "vue";

import FluidBoard from "@/components/FluidBoard";
import { WindowCoordinate } from "@/model/coordinates";

export type VueComponent<
  PropTypes,
  E extends EmitsOptions = Record<string, any>,
> = DefineComponent<
  PropTypes,
  object,
  unknown,
  Record<string, any>,
  Record<string, any>,
  Record<string, any>,
  Record<string, any>,
  E
>;

type RequiredProps<T> = {
  [K in keyof T]: { type: PropType<T[K]>; required: true };
};

export interface ContextMenu {
  component: Raw<VueComponent<any>>;
  props: PositionProps;
  eventHandlers?: EventHandlers;
}

export interface PositionProps {
  position: WindowCoordinate;
}

export type EventHandlers = { [name: string]: (event: any) => void };

export const useContextMenuStore = defineStore("contextMenu", {
  state: () => ({
    menu: null as ContextMenu | null,
    nextMenu: null as ContextMenu | null,
  }),
  actions: {
    open<T extends PositionProps, E extends EmitsOptions>(
      component: VueComponent<RequiredProps<T & { board: FluidBoard }>, E>,
      props: T,
      eventHandlers?: EventHandlers,
    ) {
      const newMenu = {
        component: markRaw(component) as Raw<VueComponent<any>>,
        props,
        eventHandlers,
      };
      if (!this.menu) {
        this.menu = newMenu;
      } else {
        this.nextMenu = newMenu;
      }
    },
    close() {
      this.menu = null;
      if (this.nextMenu) {
        this.menu = this.nextMenu;
        this.nextMenu = null;
      }
    },
  },
});
