<script lang="ts" setup>
import { computed } from "vue";

import BaseListItemButton from "@/components-ng/BaseList/components/BaseListItemFluid/BaseListItemButton.vue";
import BaseListItemFluid from "@/components-ng/BaseList/components/BaseListItemFluid/BaseListItemFluid.vue";
import BaseListItemText from "@/components-ng/BaseList/components/BaseListItemFluid/BaseListItemText.vue";

import { RiskScenario } from "../../types";
import RiskProgressBadge from "../RiskProgressBadge.vue";

type Props = {
  scenario: RiskScenario;
  maxScore: number;
};
const props = defineProps<Props>();
const score = computed(() => props.scenario.score);
</script>

<template>
  <BaseListItemFluid class="risk-scenario">
    <BaseListItemButton>
      <BaseListItemText class="text">
        <span class="primary">{{ scenario.title }}</span>
        <template #secondary> here we have more text </template>
      </BaseListItemText>
      <RiskProgressBadge :score="score" :max-score="maxScore" />
    </BaseListItemButton>
  </BaseListItemFluid>
</template>

<style lang="scss" scoped>
.risk-scenario {
  user-select: none;

  .text {
    .primary {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5;
      max-height: calc(1.5em * 2);
    }
  }
}
</style>
