<script lang="ts" setup>
import { cardActions } from "@/action/cardActions";
import { getAlmInfo } from "@/backend/Backend";
import BasePopupTitle from "@/components-ng/BasePopup/components/BasePopupTitle/BasePopupTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import UserSelector from "@/components/card/components/UserSelector/UserSelector.vue";
import { cardKey } from "@/components/card/injectKeys";
import InfoMessage from "@/components/ui/InfoMessage/InfoMessage.vue";
import { AuthUser, isUnmappedUser } from "@/model/user";
import { useStickyTypeStore } from "@/store/stickyType";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

function setAssignee(user: AuthUser | null) {
  cardActions.setAssignee("card", card.id, user);
  dropdown.close();
}
</script>

<template>
  <div class="set-assignee">
    <template v-if="useStickyTypeStore().almStickyTypeOf(card.type)">
      <BasePopupTitle>{{ $t("general.assignee") }}</BasePopupTitle>
      <InfoMessage>
        {{ $t("assignee.cannotSet", { almType: getAlmInfo().name }) }}
      </InfoMessage>
      <InfoMessage v-if="isUnmappedUser(card.assignee)" level="warn">
        {{ $t("assignee.userNotMapped", { almType: getAlmInfo().name }) }}
        <br /><br />
        <a
          target="_blank"
          href="https://help.piplanning.io/en/articles/150612-connect-your-user-accounts"
        >
          {{ $t("assignee.userNotMapped.link") }}
        </a>
      </InfoMessage>
    </template>
    <template v-else>
      <UserSelector
        :title="$t('general.assignee')"
        :selected="card.assignee"
        @select="setAssignee"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";

.set-assignee {
  width: 240px;
  padding: 8px;
}
</style>
