<template>
  <base-planning-board
    ref="board"
    :planning-board="board"
    :group-iterations="groupIterations"
    :cards-for-distribution="cardsForDistribution"
    :field-class="fieldClass"
    :aria-label="$t('board.program')"
    tabindex="0"
    @click="boardComponent()?.blur()"
  />
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Provide } from "vue-property-decorator";

import { BoardIteration } from "@/model/board";
import { Card } from "@/model/card";
import { Group } from "@/model/session";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useTeamStore } from "@/store/team";

import BasePlanningBoard from "./BasePlanningBoard.vue";
import { boardKey } from "./card/injectKeys";

@Component({ components: { BasePlanningBoard } })
export default class ProgramBoard extends Vue {
  @Provide({ to: boardKey as symbol })
  get board() {
    return useBoardStore().boardByType("program");
  }

  get groupIterations() {
    return Object.fromEntries(
      useBoardStore()
        .artTeamBoards()
        .map((board) => [board.team.id, board.iterations]),
    );
  }

  fieldClass(group: Group, iter?: BoardIteration) {
    const teamColor =
      group.name === useTeamStore().currentTeam.name ? "group-current" : "";
    if (!iter || !useClientSettingsStore().overload) {
      return teamColor;
    }
    const v = iter.velocity;
    if (!v) {
      return teamColor;
    }
    const r = iter.load / v;
    return r < useClientSettingsStore().loadWarn / 100
      ? teamColor
      : r >= 1
        ? "overload"
        : "warn";
  }

  get cardsForDistribution() {
    return useBoardStore()
      .artTeamBoards()
      .reduce(
        (acc, board) => [
          ...acc,
          ...Object.values(board.cards).map((card) => card.data),
        ],
        [] as Card[],
      );
  }

  boardComponent() {
    return this.$refs.board as BasePlanningBoard;
  }

  // This should be callable by the parent component
  focus() {
    this.boardComponent()?.focus();
  }
}
</script>
