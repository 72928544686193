import TextMeasurer, { wordDownsize } from "@/TextMeasurer";
import {
  FontData,
  RawFontData,
  TextFontData,
  fontSizeCache,
} from "@/components/input/fontSizeCache";
import { replaceLinks, toHtmlWithLinks } from "@/linkParser";
import { useClientSettingsStore } from "@/store/clientSettings";

export function optimalOneLineFontSize(
  el: HTMLElement,
  maxWidth: number,
  minScale: number,
  maxScale: number,
) {
  let scale = 100 * minScale;
  el.style.fontSize = scale + "%";
  const expand = maxWidth / el.clientWidth;
  if (expand <= 1) {
    return scale;
  }
  scale = Math.min(100 * maxScale, scale * expand);
  el.style.fontSize = scale + "%";
  return scale;
}

const maxSteps = 7;

export async function optimalFontSize<EDIT extends boolean>(
  el: HTMLElement,
  inputText: string,
  edit: EDIT,
  newSticky = false,
  isDependency?: boolean,
): Promise<FontData<EDIT>> {
  let width = el.clientWidth;
  let height = el.clientHeight;

  // thew new sticky note text input has fixed width and height
  if (newSticky) {
    width = 224;
    height = isDependency ? 132 : 160;
  }

  return fontSizeCache(
    inputText,
    width,
    height,
    edit,
    useClientSettingsStore().textScale,
    useClientSettingsStore().stickyFont,
    () => {
      const { text, links } = edit
        ? { text: inputText, links: [] }
        : replaceLinks(inputText);

      const measurer = TextMeasurer.of(el)!;

      const maxFontSize = measurer.maxFontSize();
      const maxFontSizeForText = measurer.maxFontSizeForText();
      const wordSize = wordDownsize(maxFontSizeForText, text);
      const textScale = useClientSettingsStore().textScale;
      let max = maxFontSize * (textScale ? wordSize : 0.33333);

      let min = max / 20;
      for (let i = 0; i < maxSteps; i++) {
        const size = (min + max) / 2;
        const measure = measurer.measureLines(size, text);

        if (measure.height <= el.clientHeight) {
          min = size;
        } else {
          max = size;
        }
      }
      min = Math.round(min * 1000) / 1000;

      measurer.setFontSize(min);
      const minMeasure = measurer.measureLines(min, text);
      //textareaButNotEdit happens for the sticky that has the cursor during zoom
      //this is ok, but we should not cache the result as it's wrong after zooming has finished
      const textareaButNotEdit = !edit && el.nodeName === "TEXTAREA";
      const raw: RawFontData = { size: min, cache: !textareaButNotEdit };
      const res = edit
        ? raw
        : ({
            ...raw,
            htmlLines: toHtmlWithLinks(text, links, minMeasure.lines),
            textLines: minMeasure.lines.map(([startPos, endPos]) =>
              text.substring(startPos, endPos),
            ),
          } as TextFontData);
      return res as FontData<EDIT>;
    },
  );
}
