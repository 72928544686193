<template>
  <div class="overlay risks scrollable no-wheel">
    <div
      v-for="(r, index) in selectList"
      :key="r"
      @pointermove="selectIndex = index"
      @click.stop="select(r)"
    >
      <span
        class="entry"
        :class="{ selected: risk === r, current: index === selectIndex }"
      >
        {{ $t(title(r)) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import SelectList from "@/mixins/SelectList";
import { riskTitle } from "@/model/baseTypeI18n";
import { RiskType, riskTypes } from "@/model/baseTypes";

@Component({ emits: ["select"] })
export default class CardRisksOverlay extends mixins(SelectList<RiskType>) {
  @Prop(String) readonly risk?: RiskType;

  mounted() {
    this.selectList = riskTypes;
  }

  select(risk: RiskType) {
    this.$emit("select", risk);
  }

  title(risk: RiskType) {
    return riskTitle(risk);
  }
}
</script>
