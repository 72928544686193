<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";

import { accept } from "@/Shortcuts";
import { cardActions } from "@/action/cardActions";
import { isAlmSync } from "@/backend/Backend";
import { boardKey, cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { TextFontData } from "@/components/input/fontSizeCache";
import { replaceEmoji } from "@/emojiReplacer";
import { optimalFontSize } from "@/fontSizeOptimizer";
import { useBoardStore } from "@/store/board";
import { useZoomStore } from "@/store/zoom";
import { stickyNoteTextChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/context";
import { removeNonPrintable } from "@/utils/general";

defineExpose({
  focus: async () => {
    if (isReadonly) return;

    edit.value = true;
    await nextTick();
    textareaRef.value?.focus();
  },
});

const emit = defineEmits(["deactivate-note"]);

onMounted(() => {
  watch(() => card.text, calculateOptimalFontSize, { immediate: true });
  watch([() => useZoomStore().factor, () => useBoardStore().board?.id], () =>
    calculateOptimalFontSize(card.text),
  );
});

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const { isReadonly } = injectStrict(cardMetaKey);
const isActive = computed(() => useBoardStore().activeCardId === card.id);

const fontSize = ref(300);
const textareaRef = ref<HTMLTextAreaElement>();
const divRef = ref<HTMLTextAreaElement>();

// used for analytics
watch(textareaRef, (el) => {
  if (el) {
    const initialTextLength = el.value.length;
    el.addEventListener(
      "input",
      () => {
        const cardType = card.type.functionality;
        const boardType = board.value.type;
        trackEvent(
          stickyNoteTextChanged(cardType, boardType, initialTextLength),
        );
      },
      { once: true },
    );
  }
});

const edit = ref(false);
const lines = ref<string[]>([]);

const maxTextLength = computed(() => (isAlmSync() ? 255 : 640));

const handleBlur = async () => {
  // zooming removes the StickyNote from the DOM and causes a blur event, ignore it
  if (useZoomStore().zooming) return;

  edit.value = false;
  await nextTick();
  await calculateOptimalFontSize(card.text);
};

const handleKeydown = (event: KeyboardEvent) => {
  if (accept(cardActions.duplicate.data.shortcut!.key)(event)) {
    cardActions.duplicate("keyboard");
    event.preventDefault();
  } else if (
    event.key === "Escape" ||
    (event.key === "Enter" &&
      (isAlmSync() || (!event.altKey && !event.shiftKey)))
  ) {
    event.preventDefault();
    event.stopPropagation();

    emit("deactivate-note");
    textareaRef.value?.blur();
  }
};

const handleTextInput = (event: Event) => {
  const target = event.target as HTMLTextAreaElement;
  if (isAlmSync()) {
    target.value = removeNonPrintable(target.value);
  }
  replaceEmojis(target);
  cardActions.setText("card", card.id, target.value);
};

function replaceEmojis(element: HTMLTextAreaElement) {
  const replaced = replaceEmoji(element.value);
  if (replaced) {
    const cursor = element.selectionStart;
    element.value = replaced.text;
    nextTick(() => {
      // restore the cursor position
      element.selectionStart = cursor - replaced.deltaLen;
      element.selectionEnd = cursor - replaced.deltaLen;
    });
  }
}

const calculateOptimalFontSize = async (value: string) => {
  await nextTick();

  const el = edit.value ? textareaRef.value : divRef.value;

  if (!el?.clientHeight) return;

  return optimalFontSize(
    el,
    value,
    edit.value,
    true,
    card.type.functionality === "dependency",
  ).then((fontData) => {
    fontSize.value = fontData.size;
    if (!edit.value) {
      lines.value = (fontData as TextFontData).htmlLines;
    }
  });
};
</script>

<template>
  <div class="sticky-note-text-input" :data-type="card.type.functionality">
    <textarea
      v-if="edit"
      ref="textareaRef"
      spellcheck="true"
      :maxlength="maxTextLength"
      :value="card.text"
      :style="{ fontSize: fontSize + '%' }"
      @input="handleTextInput"
      @blur="handleBlur"
      @keydown="handleKeydown"
      @pointerdown.stop
    />
    <div
      v-else
      ref="divRef"
      :style="{ fontSize: fontSize + '%' }"
      :role="isActive ? 'button' : 'presentation'"
      :tabindex="isActive ? 0 : undefined"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-for="(line, index) in lines" :key="index" v-html="line" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "sass:color";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.sticky-note-text-input {
  font-family: var(--card-font);
  overflow: hidden;
  margin: 4px;
  padding: 4px;

  &:focus-within {
    background-color: colors.$black-alpha-5;
  }

  textarea {
    all: unset;
    line-height: 1.25;
    cursor: text;
    pointer-events: auto;
    background-color: transparent;
    user-select: auto;
    overflow: hidden;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
  }

  & > div {
    overflow: hidden;
    white-space: pre;
    width: 100%;
    height: 100%;

    &:deep(a) {
      text-decoration: underline;
    }
  }
}
</style>
