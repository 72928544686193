<script lang="ts" setup>
import { boardKey, cardKey } from "@/components/card/injectKeys";
import { useLinkStore } from "@/store/link";
import { stickyNoteUnpined } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const handleClick = () => {
  useLinkStore().removeAllMarks();

  const stickyType = card.type.functionality;
  const boardType = board.value.type;
  trackEvent(stickyNoteUnpined(stickyType, boardType, "pin-button"));
};
</script>

<template>
  <div class="sticky-note-pin" @click="handleClick">
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="10"
        y1="7"
        x2="10"
        y2="12"
        stroke-width="2"
        stroke="#1c1e21"
        stroke-linecap="round"
      />
      <circle cx="10" cy="5" r="5" fill="#ff123b" />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors";

.sticky-note-pin {
  position: absolute;
  cursor: pointer;
  width: 30px;
  display: flex;
  justify-content: center;
  right: 41%;
  transform: rotate(50deg);
  top: -12px;
}
</style>
