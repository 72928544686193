<template>
  <div class="sr-only" aria-live="polite">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
// This component is visually hidden but still accessible to screen readers
// eg. aria-live 'search results count' region
.sr-only {
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}
</style>
