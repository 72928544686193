<script lang="ts" setup>
import { useRiskAnalysisStore } from "@/coconut/store/riskAnalysis";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";

defineProps<{ title: string; hideBackButton?: boolean }>();
defineEmits<{ back: [] }>();

const riskStore = useRiskAnalysisStore();

const handleButtonClick = () => {
  const isOpen = riskStore.isConfigOpen;
  riskStore.isConfigOpen = !isOpen;
};
</script>

<template>
  <div class="risk-header">
    <BaseButton
      v-if="!hideBackButton"
      class="back-button"
      variant="ghost"
      color="grey"
      icon-before="arrow/left"
      @click="$emit('back')"
    />
    <div class="title">{{ title }}</div>
    <BaseButton
      class="icon"
      variant="ghost"
      color="grey"
      icon-before="user-action/settings"
      @click="handleButtonClick"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";
@use "@/styles/mixins/typography";

.risk-header {
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid colors.$border-subtle;
  height: 70px;

  .back-button {
    margin-left: -10px;
  }

  .title {
    @include typography.medium("bold");

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    margin-left: auto;
  }
}
</style>
