<script setup lang="ts">
import { computed } from "vue";

import BaseFlag from "@/components/card/BaseFlag.vue";
import { Card } from "@/model/card";
import { toCssColor } from "@/utils/color";

type Props = { item: Card };
const props = defineProps<Props>();

const hasFlag = computed(() => {
  return !!props.item.flagType && !props.item.flagType.isEmpty();
});

const backgroundColor = computed(() => toCssColor(props.item.type.color));
</script>

<template>
  <div v-if="item" class="presentation-list-item">
    <div class="icon" :style="{ backgroundColor: backgroundColor }"></div>
    <div class="content">
      <div class="title">{{ item.text }}</div>
      <div class="flags">
        <BaseFlag v-if="hasFlag" :flag="item.flagType" class="flag-icon" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.presentation-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 12px 32px;
  max-width: 100%;
  flex-direction: row;
  column-gap: 12px;

  > .icon {
    width: 40px;
    height: 40px;
    background-color: colors-old.$light-background-color;
    border-radius: 4px;
    flex: 0 0 40px;
  }

  .content {
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .title {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: font.$weight-semi-bold;
    font-size: 14px;
    line-height: 18px;
  }

  .flags {
    .flag-icon {
      width: 14px;
      height: 14px;
      padding: 0;
    }
  }

  &.selected {
    .title {
      font-weight: font.$weight-bold;
    }
  }
}
</style>
