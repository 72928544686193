<script lang="ts" setup>
import { computed } from "vue";

import { getRiskScenarioColor } from "@/coconut/utils/color";
import { hexToRgba } from "@/utils/color";

import { RiskItem } from "../types";

type Props = {
  score: number;
  pulse?: boolean;
  selectedRiskItem: RiskItem | null;
};

const props = defineProps<Props>();
const backgroundColor = computed(() => getRiskScenarioColor(props.score));
const riskColor = computed(() => hexToRgba(backgroundColor.value, 0.9));
</script>

<template>
  <div class="bubble" :style="{ backgroundColor }">
    <span>{{ Math.floor(score) }}</span>
  </div>
  <div v-if="pulse" class="pulse" :style="{ '--risk-color': riskColor }"></div>
  <div class="highlight" :style="{ '--risk-color': riskColor }"></div>
</template>

<style lang="scss" scoped>
@use "@/styles/mixins/typography";
@use "@/styles/variables/colors";

.highlight {
  position: absolute;
  inset: 0;
  box-shadow: 0 0 0 8px var(--risk-color); // /check-style-disable/
  pointer-events: none;
}

.bubble {
  @include typography.extra-small("extra-bold");

  aspect-ratio: 1/1;
  min-width: 24px;
  transform: scale(3);
  position: absolute;
  top: -20px;
  right: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  border-radius: 10px;
  padding: 2px 4px;
  color: colors.$text-inverse;
  z-index: 1; // /check-style-disable/
}

.pulse {
  position: absolute;
  inset: 0;

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0 var(--risk-color); // check-style-disable
      opacity: 0.9;
    }

    70% {
      box-shadow: 0 0 0 80px var(--risk-color); // check-style-disable
      opacity: 0;
    }

    100% {
      box-shadow: 0 0 0 0 var(--risk-color); // check-style-disable
      opacity: 0;
    }
  }

  &.pulse {
    background-color: rgba(var(--risk-color) 0.7);
    animation: pulse-animation 0.8s infinite;
  }
}
</style>
