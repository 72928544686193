<script lang="ts" setup>
import { computed, onMounted } from "vue";

import { cardActions } from "@/action/cardActions";
import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import StatusDot from "@/components/StatusDot.vue";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import { type Status } from "@/model/status";
import { useAlmItemTypeStore } from "@/store/almItemType";
import { useCardStore } from "@/store/card";
import { stickyNoteStatusChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const dropdown = injectStrict(dropdownKey);

const props = defineProps<{
  trigger: "action-menu" | "attribute-chip"; // used for event tracking analytics
}>();

onMounted(async () => {
  if (!card.status) return; // Ignore stickies without statuses (e.g. notes)

  // In case the status is dynamic, we potentially (our backend caches) don't know the next statuses yet (Jira server)
  // thus we fetch the possible statuses from the backend for this particular sticky.
  if (card.status.next.length === 0 && card.status.dynamic) {
    await useAlmItemTypeStore().updateAlmItemType(card, board.value);
    useCardStore().updateCardsStatus(card, board.value);
  }
});

const statuses = computed(() => {
  if (!card.status) return [];

  // Make sure the current status is in order with all the other next statuses
  const statuses = [
    card.status,
    ...card.status.next.map((next) => next.status),
  ];
  return statuses.sort((a, b) => a.order - b.order);
});

const handleStatusChange = (statusId: Status["id"]) => {
  const target = card.status?.next.find((next) => next.status.id === statusId);

  if (!target) return;

  const from = card.status?.statusClass || "undefined";
  const to = target.status.statusClass;
  trackEvent(
    stickyNoteStatusChanged(
      from,
      to,
      card.type.functionality,
      board.value.type,
      props.trigger,
    ),
  );

  cardActions.setStatus(
    "card",
    board.value,
    card.id,
    target.status.name,
    target.transition.id,
  );

  dropdown.close();
};
</script>

<template>
  <BaseList role="dialog" aria-labelledby="sticky-status-title">
    <BaseListTitle id="sticky-status-title">
      {{ $t("stickyStatus.status") }}
    </BaseListTitle>
    <BaseListItems>
      <BaseListItem
        v-for="status in statuses"
        :key="status.id"
        :active="status.id == card.status?.id"
        :static="status.id == card.status?.id"
        @click="handleStatusChange(status.id)"
      >
        <template #before>
          <StatusDot
            class="status-dot-list-item"
            :status-class="status.statusClass || 'undefined'"
          />
        </template>
        {{ status.name }}
      </BaseListItem>
    </BaseListItems>
  </BaseList>
</template>

<style lang="scss" scoped>
.status-dot-list-item {
  height: 20px;
  width: 20px;
}
</style>
