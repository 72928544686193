import { defineStore } from "pinia";

import { i18n } from "@/i18n";
import { Art, emptySelectList } from "@/model/session";

import { useBoardStore } from "./board";
import { useNavigationStore } from "./navigation";

export const NO_ART_ID = "";

export const useArtStore = defineStore("art", {
  state: () => ({ art: emptySelectList<Art>() }),
  getters: {
    currentArt: (state) => {
      return state.art.current;
    },
    arts: (state) => {
      return state.art.list;
    },
    isMultiArt: (state) => {
      return state.art.list.length > 1;
    },
    artById: (state) => (artId?: Art["id"] | null) => {
      const art = state.art.list.find((a) => a.id === artId);
      if (art) return art as Art;
      return null;
    },
    noArt: () => {
      return { id: NO_ART_ID, name: i18n.global.t("general.noArt") };
    },
  },
  actions: {
    setArt(art: Art) {
      this.art.selected = this.art.current = art;
      useBoardStore().updateObjectiveBoardId();
      useNavigationStore().artSelected(art);
    },
    setArts(e: Art[]) {
      this.art.list = e;
    },
    isCurrentArt(artId?: string) {
      return !this.isMultiArt || artId === this.art.current.id;
    },
  },
});
