import { Mode } from "./actions";

export class ModeHistory {
  items: Array<Mode>;
  readonly defaultMode: Mode = "normal";

  constructor(private maxSize: number = 5) {
    this.items = new Array(this.maxSize);
  }

  get size(): number {
    return this.items.length;
  }

  pop(): Mode {
    return this.items.pop() || this.defaultMode;
  }

  push(mode: Mode): number {
    if (this.items.length >= this.maxSize) {
      this.items = this.items.slice(1);
    }
    return this.items.push(mode);
  }

  peek(index = 0): Mode {
    if (this.size < index + 1) {
      return this.defaultMode;
    }
    return this.items[this.items.length - 1 - index] || this.defaultMode;
  }
}
