export function getRiskScenarioColor(score: number): string {
  const colors = [
    "#3BB200",
    "#4EB004",
    "#62AE08",
    "#75AC0C",
    "#89AA10",
    "#9CA914",
    "#AFA718",
    "#C3A51C",
    "#D6A320",
    "#EAA124",
    "#FD9F28",
    "#FD8F2A",
    "#FD802C",
    "#FE702E",
    "#FE6030",
    "#FE5133",
    "#FE4135",
    "#FF3137",
    "#FF2239",
    "#FF123B",
  ];

  const index = Math.floor((score / 100) * (colors.length - 1));
  return colors[index];
}
