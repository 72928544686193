<script lang="ts" setup>
import { computed, onUnmounted } from "vue";

import { useRiskAnalysisStore } from "@/coconut/store/riskAnalysis";
import { type RiskItem as RiskItemType } from "@/coconut/types";
import BaseList from "@/components-ng/BaseList/BaseList.vue";
import { AuthUser } from "@/model/user";
import { useBoardStore } from "@/store/board";
import { useSessionStore } from "@/store/session";
import { useUserStore } from "@/store/user";

import RiskItem from "../RiskItems/RiskItem.vue";
import AIUserAvatar from "./AIUserAvatar.vue";
import PromptButton from "./PromptButton.vue";
import RecommedationsChat from "./RecommedationsChat.vue";
import RiskScenario from "./RiskScenario.vue";

const riskStore = useRiskAnalysisStore();

const props = defineProps<{ selectedRiskItem: RiskItemType }>();
const maxScoreRiskItem = computed(() => {
  return Math.max(...riskStore.riskItems.map((result) => result.totalScore));
});
const maxScoreRiskScenarios = computed(() => {
  return Math.max(
    ...props.selectedRiskItem.riskScenarios.map((scenario) => scenario.score),
  );
});

defineEmits<{
  itemHovered: [id: number | null];
  itemSelected: [id: number | null];
}>();

const isListVisible = computed(() => {
  const lastMessage = riskStore.messages[riskStore.messages.length - 1];
  return !lastMessage?.isUserMessage && lastMessage?.status === "done";
});

const handleButtonClick = async (msgIndex: number, message: string) => {
  const boardId = useBoardStore().currentBoard().id;
  const stickyId = props.selectedRiskItem.sticky.data.id;
  const sessionId = useSessionStore().session.current.id;
  const scenarios = props.selectedRiskItem.riskScenarios;
  const user = useUserStore().currentUser;

  if (!user) return;

  riskStore.sendUserMessage(
    { user, message, isUserMessage: true, status: "to-do" },
    { predefinedPrompt: msgIndex, scenarios },
    { boardId, stickyId, sessionId },
  );
};

const questions = [
  "What can I do that the risk gets minimized?",
  "What are the riskiest scenarios this feature is affected by?",
  "Which objectives are at risk?",
];

const aiUserMock = {
  name: "Risky Rick",
  imageUrl: "avatars/risky-rick.png",
} as AuthUser;

onUnmounted(() => {
  riskStore.endConversation();
});
</script>

<template>
  <RiskItem
    class="selected-risk-item"
    :item="selectedRiskItem"
    :max-score="maxScoreRiskItem"
  />

  <div class="risk-scenarios">
    <div v-if="!riskStore.messages.length" class="default-prompts">
      <AIUserAvatar :user="{ ...aiUserMock, name: 'Ask Risky Rick' }" bold />
      <template v-for="(q, i) in questions" :key="i">
        <PromptButton :question="q" @click="handleButtonClick(i, q)" />
      </template>
    </div>

    <div v-else class="recommendation">
      <RecommedationsChat class="chat" :messages="riskStore.messages" />
      <template v-if="isListVisible">
        <div class="title">These are the underlying risk scenarios</div>
        <BaseList class="list">
          <template
            v-for="scenario in selectedRiskItem.riskScenarios"
            :key="scenario.patternId + '-' + scenario.scope.id"
          >
            <RiskScenario
              v-if="scenario.score"
              :scenario="scenario"
              :max-score="maxScoreRiskScenarios"
              @mouseover="$emit('itemHovered', scenario.patternId)"
              @mouseleave="$emit('itemHovered', null)"
              @click="$emit('itemSelected', scenario.patternId)"
            />
          </template>
        </BaseList>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";
@use "@/styles/mixins/typography";

.risk-scenarios {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  gap: 16px;
  padding: 16px 0;

  .default-prompts {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0 16px;
  }

  .title {
    @include typography.medium("bold");

    margin-left: 16px;
  }

  .chat {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0 16px;
  }

  .main {
    flex-grow: 1;
    overflow: auto;
    gap: 0;
  }

  .recommendation {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
  }
}
</style>
