import {
  ConnectionOptions,
  DebugEvents,
  ErrorCode,
  Events,
  NatsConnection,
  NatsError,
  connect,
} from "nats.ws";

import * as Environment from "@/Environment";
import { LogoutReason } from "@/router/types";
import { captureException, captureMessage } from "@/sentry";
import AuthService from "@/services/auth.service";
import { useBoardStore } from "@/store/board";
import { useConnectionStore } from "@/store/connection";
import { useLoadingStore } from "@/store/loading";
import { useStatisticsStore } from "@/store/statistics";
import { useUserStore } from "@/store/user";

import { closeSession, initSession, reload } from "./Backend";
import { HttpRpcBackend } from "./HttpRpcBackend";
import { NatsBackendSession } from "./NatsBackendSession";
import { NatsRpcBackend } from "./NatsRpcBackend";
import { SwitchingRpcBackend } from "./SwitchingRpcBackend";

const connectRetries = 1;
const httpCallPaths = ["link.list"];

function useHttp(path: string) {
  return httpCallPaths.includes(path);
}

let connection: NatsConnection | undefined;

async function initNatsSession(connection: NatsConnection) {
  const session = new NatsBackendSession(
    connection,
    useUserStore().technicalUser,
    new SwitchingRpcBackend(
      [
        new NatsRpcBackend(
          connection,
          useUserStore().technicalUser.company,
          useUserStore().technicalUser.id,
        ),
        new HttpRpcBackend(),
      ],
      (path) => (useHttp(path) ? 1 : 0),
    ),
  );
  await useLoadingStore().load(
    /*$t*/ "loading.initBackend",
    initSession(session),
  );
  return session;
}

interface CloseHandler {
  login: () => void;
  logout: (reason: LogoutReason) => void;
}

export async function openConnection(
  currentPath: () => string,
  onClose: CloseHandler,
  connectionName = "",
): Promise<void> {
  const refreshToken = async () => {
    try {
      await AuthService.refreshToken({ path: "#" + currentPath() });
    } catch (error) {
      onClose.logout("refresh-failed");
    }
    window.location.reload();
  };
  const connection = await doConnect(
    {
      servers: [Environment.natsUrl],
      pingInterval: 4500,
      maxPingOut: 1,
      maxReconnectAttempts: -1,
      name: connectionName,
      waitOnFirstConnect: true,
    },
    refreshToken,
  );
  if (connection) {
    addConnectionStateHandlers(connection);
    addCloseConnectionHandlers(connection, refreshToken, onClose.logout);
    await initNatsSession(connection);
    useConnectionStore().netWs = true;
    if (useBoardStore().boardsInited) {
      // it's a reconnect
      reload();
    }
  }
}

async function doConnect(
  options: ConnectionOptions,
  refreshToken: () => Promise<void>,
  retries = 0,
) {
  const start = Date.now();
  try {
    return await connect(options);
  } catch (error) {
    if (shouldRefreshToken(error)) {
      await refreshToken();
      return;
    }
    if (shouldReload(error)) {
      if (retries < connectRetries) {
        return doConnect(options, refreshToken, retries + 1);
      }
      captureException(error, { request: { timeout: Date.now() - start } });
      // TODO show a message somehow?
      throw error;
    }
    if (shouldLogout(error)) {
      // TODO show a message somehow?
      throw error;
    }
    captureException(error);
    throw error;
  }
}

export function closeConnection() {
  try {
    if (connection) {
      const open = !connection.isClosed();
      closeSession(open);
      if (open) {
        connection.close();
      }
    } else {
      closeSession();
    }
  } catch (e) {
    captureException(e);
  }
}

async function addConnectionStateHandlers(connection: NatsConnection) {
  for await (const status of connection.status()) {
    if (status.type !== DebugEvents.PingTimer) {
      useConnectionStore().addWebsocketState(status.type);
    }
    switch (status.type) {
      case Events.Disconnect:
        useConnectionStore().disconnectWebsocket();
        break;
      case Events.Reconnect:
        useStatisticsStore().current.reconnects++;
        useConnectionStore().reconnectWebsocket();
        break;
    }
  }
}

async function addCloseConnectionHandlers(
  connection: NatsConnection,
  refreshToken: () => Promise<unknown>,
  logout: (reason: LogoutReason) => Promise<unknown> | unknown,
) {
  const clientId = connection.info?.client_id;
  const error = await connection.closed();
  closeConnection();
  if (!error) {
    return;
  }
  if (shouldRefreshToken(error)) {
    await refreshToken();
    return;
  }
  if (shouldReload(error)) {
    window.location.reload();
    return;
  }
  if (shouldLogout(error)) {
    await logout("connection-refused");
    return;
  }
  captureMessage(`Unhandled connection error: ${error.message}`, {
    error: {
      clientId,
      info: connection.info,
      error,
    },
  });
  if (error instanceof NatsError && error.chainedError) {
    captureException(error.chainedError);
  }
  await logout("unknown");
}

function shouldRefreshToken(error: any) {
  if (error instanceof NatsError) {
    const refreshErrors = [
      ErrorCode.AuthenticationExpired,
      ErrorCode.AuthorizationViolation,
      ErrorCode.Disconnect,
    ].map((err) => err.valueOf());
    if (refreshErrors.includes(error.code)) {
      return true;
    }

    const refreshServerErrors = ["'User Authentication Revoked'"];
    if (
      error.isProtocolError() &&
      refreshServerErrors.includes(error.message)
    ) {
      return true;
    }
  }
}

function shouldReload(error: any) {
  if (error instanceof NatsError) {
    const reloadServerErrors = [
      "'Authentication Timeout'",
      "'Stale Connection'",
    ];
    if (error.isProtocolError() && reloadServerErrors.includes(error.message)) {
      return true;
    }
  }
  if (
    unexpectedServerMessage(error)?.startsWith("-ERR 'Authentication Timeout'")
  ) {
    return true;
  }
}

function shouldLogout(error: any) {
  if (error instanceof NatsError) {
    return error.code === ErrorCode.ConnectionRefused;
  }
}

function unexpectedServerMessage(error: any): string | undefined {
  if (error.message === "unexpected response from server") {
    return error.contexts?.response?.data;
  }
}
