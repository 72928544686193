import { ApplicationError } from "@/ApplicationError";

export class BackendError extends ApplicationError {
  constructor(
    name: string,
    message: string,
    contexts: { request: any; response: any },
  ) {
    super(name, message, contexts);
  }
}
