import { clamp, noop } from "lodash-es";

import { longClickDist } from "@/Settings";
import { IdMap } from "@/model/baseTypes";
import {
  BoardCoordinate,
  WindowCoordinate,
  add,
  boundsCoord,
  clientCoord,
  distance2,
  interpolate,
  minus,
  offsetCoord,
  plus,
  scrollCoord,
  windowCoord,
} from "@/model/coordinates";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useZoomStore } from "@/store/zoom";
import { firstValue, isEmpty } from "@/utils/general";
import { normalizeWheelEvent } from "@/utils/wheelEvent";

const scrollSensibility = 2;
const zoomSensibility = 0.014;

interface Pointer {
  draggable: boolean;
  pos: WindowCoordinate;
  downPos: WindowCoordinate;
}

interface PinchZoom {
  a: number;
  b: number;
  dist: number;
}

let pinchZoom: PinchZoom | undefined;
const mouse = { downs: 0, ups: 0 };
const pointer: { [id: number]: Pointer } = {};
const drags: IdMap<Drag<any>> = {};
let longClickTimeout: number;

export interface Drag<T> {
  pos: WindowCoordinate;
  el: HTMLElement;
  cursor: string;
  imageEl: HTMLElement;
  target: HTMLElement;
  scrollable?: Scrollable;
  pointerId: number;
  startFn: (d: Drag<T> & T, data: any) => boolean;
  moveFn: (d: Drag<T> & T) => WindowCoordinate | BoardCoordinate;
  stopFn: (d: Drag<T> & T) => WindowCoordinate | BoardCoordinate | void;
  dragging: boolean;
  start: WindowCoordinate;
  offset: WindowCoordinate;
  type: "card" | "link";
}

interface Scrollable {
  el: HTMLElement;
  scrollSize: WindowCoordinate;
  timeout?: number;
}

export function onLongClick(
  el: HTMLElement,
  longClick: number,
  handler: (param: { element: HTMLElement; pos: WindowCoordinate }) => void,
) {
  el.addEventListener("pointerdown", (e: PointerEvent) => {
    const id = e.pointerId;
    const downs = mouse.downs + 1;
    const scrollBefore = scrollCoord();
    const target = e.target as HTMLElement;

    // cancel clicks on link drag
    if (target.classList.contains("link-drag")) return;

    longClickTimeout = window.setTimeout(() => {
      const noMove =
        pointer[id] &&
        distance2(pointer[id].pos, pointer[id].downPos) < longClickDist;
      const noScroll =
        scrollBefore.x === window.scrollX && scrollBefore.y === window.scrollY;
      const noClick =
        e.pointerType !== "mouse" ||
        (mouse.downs > mouse.ups && mouse.downs === downs);
      if (noMove && noScroll && !isZoomPair(id) && noClick) {
        handler({
          element: el,
          pos: pointer[id].pos,
        });
      }
    }, longClick);
  });
}

export function cancelLongClick() {
  window.clearTimeout(longClickTimeout);
}

export function init(dragStartDist: number, minZoom: number, maxZoom: number) {
  document.addEventListener("contextmenu", (e: Event) => {
    if ((e.target as HTMLElement)?.nodeName !== "TEXTAREA") {
      e.preventDefault();
    }
  });

  // CSS user-drag: none is not supported by firefox, therefore disable dragging links and images with JS
  document.addEventListener("dragstart", (e) => {
    const targetNode = (e.target as HTMLElement)?.nodeName;
    if (targetNode === "A" || targetNode === "IMG") {
      e.preventDefault();
    }
  });

  document.addEventListener(
    "wheel",
    (e: WheelEvent) => {
      if (isNoWheel(e)) {
        return;
      }
      e.preventDefault();
      const modifiers = e.ctrlKey || e.metaKey;
      const { x, y } = normalizeWheelEvent(e);
      if (useClientSettingsStore().wheelZoom === modifiers) {
        window.scrollBy(scrollSensibility * x, scrollSensibility * y);
      } else {
        if (Math.abs(y) > Math.abs(x)) {
          const zoomFactor =
            y > 0 ? 1 / (1 + y * zoomSensibility) : 1 - y * zoomSensibility;
          processZoom(clientCoord(e), zoomFactor, "relative");
        }
      }
    },
    { passive: false },
  );

  let zoomEnd = 0;

  function processZoom(
    center: WindowCoordinate,
    factor: number,
    mode: "absolute" | "relative",
  ) {
    const currentZoom = useZoomStore().factor;
    const base =
      mode === "relative" ? useZoomStore().dynamicFactor : currentZoom;
    const newFactor = clamp(base * factor, minZoom, maxZoom);
    if (useZoomStore().dynamicFactor !== newFactor) {
      useZoomStore().setDynamicZoomFactor(newFactor);
      window.clearTimeout(zoomEnd);
      zoomEnd = window.setTimeout(emitZoom, 400);
      if (!useZoomStore().zooming) {
        emitZoom(center);
      }
    }
  }

  document.addEventListener("pointerdown", (e: PointerEvent) => {
    releaseCapture(e);
    if (isLeftClick(e)) {
      for (const key in pointer) {
        const p = pointer[key];
        if (!p.draggable && +key !== e.pointerId) {
          const dist = distance2(p.pos, clientCoord(e));
          pinchZoom = {
            a: +key,
            b: e.pointerId,
            dist,
          };
        }
      }
      down(e.pointerId, clientCoord(e));
    }
  });

  document.addEventListener("pointermove", (e: PointerEvent) => {
    move(e.pointerId, clientCoord(e));
  });

  document.addEventListener("pointerup", (e: PointerEvent) => {
    if (e.pointerType === "mouse") {
      mouse.ups = mouse.downs;
    }
    up(e.pointerId, e.target as HTMLElement);
  });

  document.addEventListener("pointerleave", (e: PointerEvent) => {
    if (isLeftClick(e)) {
      up(e.pointerId);
    }
  });

  document.addEventListener("pointerenter", (e: PointerEvent) => {
    if (!isLeftClick(e)) {
      if (e.pointerType === "mouse") {
        mouse.ups = mouse.downs;
      }
    }
  });

  function down(id: number, pos: WindowCoordinate) {
    mouse.downs++;
    pointer[id] = { draggable: false, pos, downPos: { ...pos } };
  }

  function move(id: number, pos: WindowCoordinate) {
    if (!pointer[id]) {
      return;
    }
    pointer[id].pos = pos;
    if (pinchZoom && isZoomPair(id)) {
      const pa = pointer[pinchZoom.a].pos;
      const pb = pointer[pinchZoom.b].pos;
      const dist = distance2(pa, pb);
      const f = Math.sqrt(dist / pinchZoom.dist);
      if (Math.abs(f - 1) > 0.01) {
        processZoom(interpolate(0.5, pa, pb), f, "absolute");
      }
    }
    const d = drags[id];
    if (!d) {
      return;
    }
    d.pos = plus(pointer[id].pos, plus(d.start, scrollCoord()));
    if (d.scrollable) {
      add(
        d.pos,
        windowCoord(d.scrollable.el.scrollLeft, d.scrollable.el.scrollTop),
      );
      scrollInScrollable(pos, d.scrollable, () => move(id, pos));
    } else {
      scrollNearBoarder(pos);
    }
    if (d.dragging) {
      setPosition(d.imageEl, d.moveFn(d));
    } else if (distance2(d.pos, windowCoord(0, 0)) > dragStartDist) {
      d.el.style.cursor = "grabbing";
      d.dragging = true;
      if (d.el !== d.imageEl) {
        setPosition(d.imageEl, d.pos);
        const imageDist = minus(boundsCoord(d.el), boundsCoord(d.imageEl));
        add(d.start, imageDist);
        add(d.pos, imageDist);
        setPosition(d.imageEl, d.pos);
        d.imageEl.style.width = d.el.offsetWidth + "px";
        d.imageEl.style.height = d.el.offsetHeight + "px";
      }
      if (!d.startFn(d, null)) {
        release(id);
      }
    }
  }

  function up(id: number, target?: HTMLElement) {
    const d = drags[id];
    if (d) {
      try {
        if (d.dragging) {
          // if we dragged a link, avoid following it
          if (target?.nodeName === "A") {
            target.onclick = () => false;
            window.setTimeout(() => (target.onclick = null), 100);
          }
          d.pos = plus(pointer[id].pos, plus(d.start, scrollCoord()));
          const pos = d.stopFn(d);
          if (pos) {
            setPosition(d.imageEl, pos);
          }
        }
      } finally {
        release(id);
      }
    }
    delete pointer[id];
    if (isZoomPair(id)) {
      pinchZoom = undefined;
    }
  }

  function release(id: number) {
    drags[id].el.style.cursor = drags[id].cursor;

    // fix for the position of the link drag in the new sticky note
    if (drags[id].el.hasAttribute("data-sticky-note")) {
      drags[id].el.style.left = "";
      drags[id].el.style.top = "";
    }

    delete drags[id];
  }

  function setPosition(
    el: HTMLElement,
    pos: WindowCoordinate | BoardCoordinate,
  ) {
    el.style.left = pos.x + "px";
    el.style.top = pos.y + "px";
  }
}

function isZoomPair(id: number) {
  return pinchZoom && (id === pinchZoom.a || id === pinchZoom.b);
}

function emitZoom(center?: WindowCoordinate) {
  if (center) {
    useZoomStore().startZoom(center);
  } else {
    executeAfterDragScroll(useZoomStore().endZoom);
  }
}

let dragScrolling = false;
let onDragScrollEnd = noop;

function executeAfterDragScroll(action: () => void) {
  if (dragScrolling) {
    onDragScrollEnd = action;
  } else {
    action();
  }
}

function endDragScroll() {
  dragScrolling = false;
  onDragScrollEnd();
  onDragScrollEnd = noop;
}

export function dragScroll(el: HTMLElement) {
  let x: number;
  let y: number;

  el.addEventListener("pointerdown", (e) => {
    releaseCapture(e);
    if (isLeftClick(e)) {
      dragScrolling = true;
      setPos(e);
    }
  });
  el.addEventListener("pointerup", endDragScroll);
  el.addEventListener("pointerenter", (e) => {
    if (!isLeftClick(e)) {
      endDragScroll();
    }
  });
  el.addEventListener("pointermove", (e) => {
    if (dragScrolling && !pinchZoom && isEmpty(drags)) {
      window.scrollTo(x - Math.trunc(e.clientX), y - Math.trunc(e.clientY));
      setPos(e);
    }
  });

  function setPos(e: PointerEvent) {
    x = Math.trunc(e.clientX) + window.scrollX;
    y = Math.trunc(e.clientY) + window.scrollY;
  }
}

function releaseCapture(e: PointerEvent) {
  document.documentElement.setPointerCapture?.(e.pointerId);
  document.documentElement.releasePointerCapture?.(e.pointerId);
}

export function isDragging() {
  const d = firstValue(drags);
  return d && d.dragging;
}

export function isDraggingLink() {
  const drag = firstValue(drags);
  return drag?.target?.classList.contains("link-drag");
}

export interface DragHandlers<M, T> {
  start: (this: M, d: Drag<T> & T, data: any) => boolean;
  move: (this: M, d: Drag<T> & T) => WindowCoordinate | BoardCoordinate;
  stop: (this: M, d: Drag<T> & T) => WindowCoordinate | BoardCoordinate | void;
}

export function registerDrag<T>(
  obj: any,
  event: PointerEvent,
  options: DragHandlers<typeof obj, T> & { imageEl?: HTMLElement | null },
) {
  if (options.imageEl) {
    const imageStyle = options.imageEl.style;
    imageStyle.position = "absolute";
    imageStyle.pointerEvents = "none";
  }

  releaseCapture(event);
  if (isLeftClick(event)) {
    event.stopPropagation();
    down(
      event.pointerId,
      event.currentTarget as HTMLElement,
      event.target as HTMLElement,
      plus(clientCoord(event), scrollCoord()),
      offsetCoord(event),
    );
  }

  function down(
    id: number,
    el: HTMLElement,
    target: HTMLElement,
    pos: WindowCoordinate,
    offset: WindowCoordinate,
  ) {
    pointer[id] = { draggable: true, pos, downPos: { ...pos } };
    const scrollable = findScrollable(el);
    const d = scrollable
      ? windowCoord(scrollable.el.scrollLeft, scrollable.el.scrollTop)
      : windowCoord(0, 0);
    drags[id] = {
      el,
      cursor: el.style.cursor,
      imageEl: options.imageEl || el,
      target,
      scrollable,
      startFn: options.start.bind(obj),
      moveFn: options.move.bind(obj),
      stopFn: options.stop.bind(obj),
      pointerId: id,
      pos: windowCoord(0, 0),
      dragging: false,
      offset,
      start: minus(plus(pos, d)),
      type: target.classList.contains("link-drag") ? "link" : "card",
    };
  }
}

function findScrollable(el: HTMLElement | null): Scrollable | undefined {
  while (el !== null) {
    if (el.classList.contains("scrollable")) {
      return {
        el,
        scrollSize: windowCoord(
          el.scrollWidth - el.offsetWidth,
          el.scrollHeight - el.offsetHeight,
        ),
      };
    }
    el = el.parentElement;
  }
}

function isLeftClick(e: PointerEvent) {
  // eslint-disable-next-line no-bitwise
  return (e.buttons & 1) === 1;
}

function scrollNearBoarder(pos: WindowCoordinate) {
  const el = document.documentElement;
  const scrollX = check(pos.x, el.scrollLeft, el.clientWidth);
  const scrollY = check(pos.y, el.scrollTop, el.clientHeight);
  if (scrollX !== undefined || scrollY !== undefined) {
    el.scrollTo({ left: scrollX, top: scrollY });
  }

  function check(pos: number, scroll: number, size: number) {
    let d = 20 - pos;
    if (d > 0) {
      return scroll - d;
    }
    d = 20 - size + pos;
    if (d > 0) {
      return scroll + d;
    }
  }
}

function scrollInScrollable(
  pos: WindowCoordinate,
  scrollable: Scrollable,
  recur: () => void,
) {
  window.clearTimeout(scrollable.timeout);
  const rect = scrollable.el.getBoundingClientRect();
  if (pos.y - rect.top < 20) {
    scrollBy(-2);
  } else if (
    rect.top + rect.height - pos.y < 20 &&
    scrollable.el.scrollTop < scrollable.scrollSize.y
  ) {
    scrollBy(2);
  }

  function scrollBy(delta: number) {
    scrollable.el.scrollBy({ top: delta });
    scrollable.timeout = window.setTimeout(recur, 20);
  }
}

// found a .scrollable parent -> don't zoom but let default scrolling happen (of scrollable element)
// found a .no-wheel parent -> don't zoom and no default wheel action
// else not default wheel action, but zoom
function isNoWheel(e: MouseEvent): boolean {
  let el = e.target as HTMLElement | null;
  while (el !== null) {
    if (
      el.classList.contains("scrollable") &&
      el.scrollHeight !== el.offsetHeight
    ) {
      return true;
    }
    if (el.classList.contains("no-wheel")) {
      e.preventDefault();
      return true;
    }
    el = el.parentElement;
  }
  e.preventDefault();
  return false;
}
