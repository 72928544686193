import {
  Pattern,
  PatternSavedConfig,
  RiskScenarioRecommendation,
} from "../types";

type PatternExports = {
  default: Pattern;
  recommendations?: RiskScenarioRecommendation[];
};

export function loadPatterns() {
  const modules = import.meta.glob("../patterns/**/*.pattern.ts", {
    eager: true,
  }) as Record<string, PatternExports>;

  const config = JSON.parse(
    localStorage.getItem("pattern-config") || "[]",
  ) as PatternSavedConfig[];

  return Object.values(modules).flatMap((pattern) => {
    const patternConfig = config.find((c) => c.id === pattern.default.id);

    if (patternConfig) {
      pattern.default.weight = patternConfig.weight ?? 1;
    }

    return {
      ...pattern.default,
      recommendations: pattern.recommendations,
    };
  });
}
