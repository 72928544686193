import { pipaiAPIUrl } from "@/Environment";
import { createApiClient } from "@/services/api.config";

import { RiskScenario } from "../types";

const apiClient = createApiClient(pipaiAPIUrl);

type RisckyRickRequestPayload = {
  predefinedPrompt: number;
  scenarios: RiskScenario[];
};

export async function initRiskyRickChat(
  sessionId: string,
  boardId: string,
  stickyId: string,
  body: RisckyRickRequestPayload,
) {
  const path = `/${sessionId}/${boardId}/${stickyId}/explain`;
  const { data } = await apiClient.post(path, body);
  return data;
}
