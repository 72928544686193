<script lang="ts" setup>
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";

import { useRiskAnalysisStore } from "../store/riskAnalysis";
import BuddyChip from "./BuddyChip.vue";

const riskStore = useRiskAnalysisStore();

const handleButtonClick = () => {
  riskStore.isSidebarOpen = false;
};
</script>

<template>
  <div class="topbar">
    <BuddyChip @click="handleButtonClick" />
    <BaseButton
      class="close-button"
      variant="ghost"
      color="grey"
      icon-before="close"
      @click="handleButtonClick"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";
@use "@/styles/variables" as vars;
@use "@/styles/z-index" as zIndex;

.topbar {
  box-shadow: 0 2px 4px colors.$black-alpha-20; // /check-style-disable/
  position: fixed;
  top: 0;
  left: 0;
  height: vars.$top-header-height;
  z-index: zIndex.$menu + 1;
  background-color: colors.$background-ui;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 4px;

  .close-button {
    margin-left: auto;
  }
}
</style>
