<script lang="ts" setup>
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import { AuthUser } from "@/model/user";

const aiUserMock = {
  name: "Risky Rick",
  imageUrl: "avatars/risky-rick.png",
} as AuthUser;
</script>

<template>
  <div class="buddy-chip">
    <UserAvatar :user="aiUserMock" />
    <span class="text">{{ aiUserMock.name }}</span>
    <SvgIcon class="icon" name="close" height="16" width="16" />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";
@use "@/styles/mixins/typography";

.buddy-chip {
  background-color: colors.$primary-brand-light;
  display: flex;
  gap: 8px;
  height: 40px;
  align-items: center;
  padding: 6px 8px;
  border-radius: 6px;
  border: 1px solid colors.$primary-brand-light;
  cursor: pointer;

  &:hover {
    background-color: colors.$primary-brand-light-hover;
    border-color: colors.$primary-brand-light-hover;
  }

  .text {
    @include typography.medium("bold");
  }

  .icon {
    color: colors.$icon-subtle;
  }
}
</style>
