<script setup lang="ts">
import { computed, onMounted } from "vue";

import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/TypeDot.vue";
import SidePanelRight from "@/components/menu/SidePanelRight.vue";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { mirrorOriginId } from "@/store/card";

import ChangesByDay from "./components/ChangesByDay.vue";

const store = useActivityStore();
const card = computed(() => useActivityStore().card);

onMounted(() =>
  // Close this panel when the Card loses focus
  useBoardStore().$subscribe((_mutation, state) => {
    if (store.active && state.activeCardId !== store.card?.id) {
      close();
    }
  }),
);

function close() {
  store.inactivate();
}
</script>

<template>
  <div class="activity-panel" data-ignore-click @scroll.stop>
    <transition>
      <SidePanelRight
        v-if="store.active"
        v-focus-trap
        v-autofocus
        icon="action-menu/activity"
        :title="$t('activity.cardTitle')"
        @close="close"
      >
        <template v-if="card" #sub-title>
          <TypeDot
            size="medium"
            :color="card.type.color"
            :aria-label="
              $t('label.activityPanel.cardType', { type: card.type.name })
            "
          />
          <span class="text">{{ card.text }}</span>
          <SvgIcon
            v-if="!!mirrorOriginId(card)"
            name="mirror"
            width="20"
            height="20"
            role="img"
            :aria-label="$t('label.activityPanel.mirrored')"
          />
        </template>

        <ChangesByDay :changes="store.stickyChanges" />

        <div v-if="store.stickyChanges.length === 0" class="group">
          {{ $t("stickyChange.empty") }}
        </div>
        <div v-else-if="store.incomplete" class="group">
          {{ $t("stickyChange.incomplete") }}
        </div>
      </SidePanelRight>
    </transition>
  </div>
</template>

<style lang="scss">
@use "@/styles/z-index";
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.activity-panel {
  position: relative;
  z-index: z-index.$menu;

  .side-panel-right .panel-icon {
    color: colors-old.$text-subtle-color;
  }

  .text {
    color: colors-old.$text-subtle-color;
    font-weight: font.$weight-medium;
    overflow-wrap: anywhere;
  }

  .group {
    padding: 0 1em;
    margin-top: 2.5em;
    margin-bottom: 1.5em;
  }
}
</style>
