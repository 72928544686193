<template>
  <div class="timer-panel" @click.stop>
    <transition>
      <edit-timers v-if="timersEditing" @close="toggleTimer()" />
    </transition>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import { toggleActions } from "@/action/toggleActions";
import ShortcutUser from "@/mixins/ShortcutUser";
import { useTimerStore } from "@/store/timer";

import EditTimers from "./components/EditTimers.vue";

@Component({ components: { EditTimers } })
export default class TimerPanel extends mixins(ShortcutUser) {
  created() {
    this.globalActionShortcut(toggleActions.showTimer);
  }

  @Watch("searchStore.isSearchActive") onDataChange() {
    useTimerStore().active = false;
  }

  toggleTimer() {
    toggleActions.showTimer("mainMenu");
  }

  get timersEditing() {
    return useTimerStore().active;
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/z-index";

.timer-panel {
  display: flex;
  align-items: center;
  position: relative;
  z-index: z-index.$menu;
}
</style>
